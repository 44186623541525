<app-header></app-header>
<main>
    <div class="main-container" *ngIf="isDataLoaded == true">
        <div class="container">
            <div class="row">
                <div class="wrapper fadeInDown">
                    <div id="formContent" >
                        <div class="front-arrow">
                            <h4>Enter code to sign your document</h4>
                            <p class="my-3 ps-3 pe-2">Please enter the security code provided in the alternate signing section of your electronic signature request email, and submit to view and sign the document.</p>

                            <label class="form-field mt-3">
                                <input type="text" class="inp-full-width cursor txtSecurityCode headerSpan"
                                    placeholder="Envelope security code" ondrop="return false"
                                    spellcheck="false" id="txtSecurityCode" autocomplete="off" (keyup.enter)="submitSecurityCode()">                                
                            </label>
                        </div>
                        <div class="form-buttons mt-1 mb-3">
                            <button id="btnSubmit" type="submit" class="btn btn-full-width cursorpoint btn-green"
                                data-key-config="Submit" (click)="submitSecurityCode()">Submit</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div class="container1" style="font-size: 10px;">
        <div  class="fotertext" [innerHTML]="htmlFooterString">
        </div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>