export const environment = {
  production: false,
  uiAngularBaseUrl: 'https://sign2.use.rsign.com/',
  uiBaseUrl: 'https://app3.use.rsign.com/',
  apiBaseUrl: 'https://signapi.use.rsign.com',
  brandUrl: 'https://rportalapi.s1.rpost.info/api/v1/brand',
  environmentName: 'qa',
  googleDriveClientIdKey: '14294752285-2albgc986aajei87mulbf5m0ktdaft1o.apps.googleusercontent.com',
  oneDriveApplicationId: '53fb2dd5-3cb9-49e8-a873-4bcb55eb8978',
  dropBoxKey: '7odbv7tkqsfim2p',
  validateBotUrl: 'https://webapi.s1.rpost.info',
  recaptchaSiteKey: '6LfgNxcjAAAAAFOYTztDrOXnW5UbRCxsuTYCbRxa',
  sessionTimeOut: 1200,
  sessionInActivity: 300
};