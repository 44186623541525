import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants, RouteUrls } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Title } from '@angular/platform-browser';
import { TranslationsService } from 'src/app/services/translations.service';

@Component({
  selector: 'app-security-code',
  templateUrl: './security-code.component.html',
  styleUrls: ['./security-code.component.scss']
})
export class SecurityCodeComponent implements OnInit {
  showLoader: boolean = false;
  copyEmailId: string = "";
  htmlFooterString: string = '';
  isDataLoaded: boolean = false;

  constructor(private router: Router, private signerLandingService: SignerLandingService,
    private toastr: ToastrService, private commonService: CommonService, private titleService: Title, private translationsService: TranslationsService) { }

  ngOnInit(): void {
    this.titleService.setTitle("RSign | Security Code");
    this.htmlFooterString = '<p> ' +
      '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
      '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
      '</p>';
    let footer: any = localStorage.getItem("htmlFooterString");
    if(footer != null && footer != "" && footer != undefined && footer !='null'){
      this.htmlFooterString = footer;
    }
    this.commonService.saveSignerProfileDetailsToSession();
    localStorage.setItem("documentControlsResponse", '');
    localStorage.setItem("responseSigningUrlModel", '');
    let securityCodeUrl: any = window.location.href;
    securityCodeUrl = securityCodeUrl.substring(0, securityCodeUrl.length - 1);
    localStorage.setItem("securityCodeUrl", securityCodeUrl);
    let securityCodeValidUrl = decodeURI(securityCodeUrl.split('?')[1]);

    if (securityCodeValidUrl != undefined && securityCodeValidUrl != "undefined" && securityCodeValidUrl != null && securityCodeValidUrl != "") {
      this.showLoader = true;
      this.getDecryptSecurityCodeUrl(securityCodeValidUrl);
    }
  }

  getDecryptSecurityCodeUrl(securityCodeUrl: string) {
    let decryptSecurityCodeUrl = CommonConstants.DecryptSecurityCodeUrl;
    let paramsObj = { SecurityCodeUrl: securityCodeUrl };
    this.signerLandingService.getDecryptSigningData(decryptSecurityCodeUrl, paramsObj).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
          localStorage.setItem("AutoLockRecpObj", '');
          this.showLoader = false;
          this.isDataLoaded = true;
          this.copyEmailId = resp.CopyMailId;
          localStorage.setItem("CopyMailId", resp.CopyMailId);
          localStorage.setItem("BrandName", (resp.BrandName != null || resp.BrandName != '') ? resp.BrandName : '');
          localStorage.setItem("senderEmail", (resp.SenderEmail != null || resp.SenderEmail != '') ? resp.SenderEmail : '');
          localStorage.setItem("header", (resp.Header != null || resp.Header != '') ? resp.Header : '');
          localStorage.setItem("footer", (resp.Footer != null || resp.Footer != '') ? resp.Footer : '');
          this.commonService.callHeaderCompMethod(resp.BrandName);
        }
        else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 403 &&
          (resp.originalError.error.Message == CommonConstants.AIAutoLockEnabled || resp.originalError.error.Message == CommonConstants.RequestAlreadySent || resp.originalError.error.Message == CommonConstants.RequestDeclined)) {
          this.showLoader = false;
          localStorage.setItem("DisplayMessage", CommonConstants.AccessDeniedMessage);
          localStorage.setItem("MessageType", "Warning");
          localStorage.setItem("BrandName", (resp.originalError.error.BrandName != null || resp.originalError.error.BrandName != '') ? resp.originalError.error.BrandName : '');
          localStorage.setItem("header", (resp.originalError.error.Header != null || resp.originalError.error.Header != '') ? resp.originalError.error.Header : '');
          localStorage.setItem("footer", (resp.originalError.error.Footer != null || resp.originalError.error.Footer != '') ? resp.originalError.error.Footer : '');
          localStorage.setItem('senderEmail', resp.originalError.error.SenderEmail);
          this.commonService.callHeaderCompMethod(resp.originalError.error.BrandName);

          let autoLockResp: any = {
            SenderEmail: resp.originalError.error.SenderEmail,
            EnvelopeTemplateCode: resp.originalError.error.EnvelopeTemplateCode,
            SenderRecId: resp.originalError.error.SenderRecId,
            Subject: resp.originalError.error.Subject,
            RecipientID: resp.originalError.error.RecipientID,
            RecipientEmail: resp.originalError.error.RecipientEmail,
            Feature: resp.originalError.error.Feature,
            IPAddress: resp.originalError.error.IPAddress,
            RecipientType: resp.originalError.error.RecipientType,
            AutoLockMessage: resp.originalError.error.AutoLockMessage,
            ReferenceId: resp.originalError.error.ReferenceId,
            Type: "Envelope",
            CultureInfo: resp.originalError.error.CultureInfo,
            RecipientMobile: resp.originalError.error.RecipientMobile,
            RecipientDialCode: resp.originalError.error.RecipientDialCode,
            RecipientDeliveryMode: resp.originalError.error.RecipientDeliveryMode,
          };
          localStorage.setItem("AutoLockRespMessage", JSON.stringify(autoLockResp));
          localStorage.setItem("cultureInfo", autoLockResp.CultureInfo);
          sessionStorage.removeItem('LaungaueTransalation');
          this.translationsService.getLanguageTranslations(null, null);
          setTimeout(() => {
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }, 10);
        }
        else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 400) {
          this.showLoader = false;
          localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
          localStorage.setItem("MessageType", "Warning");
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
      })
  }

  submitSecurityCode() {
    let txtSecurityCodeInput: any = document.getElementById("txtSecurityCode");
    let txtSecurityCode = txtSecurityCodeInput.value.trim();
    if (txtSecurityCode.length > 0) {
      let copyEmail: any = this.copyEmailId == "" ? "false" : this.copyEmailId;
      let validateRecipientBySecurityCode: any = CommonConstants.ValidateRecipientBySecurityCode + '/' + txtSecurityCode + '/' + copyEmail;
      this.showLoader = true;
      this.signerLandingService.validateRecipientBySecurityCode(validateRecipientBySecurityCode).subscribe(
        (resp: any) => {
          if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
            this.showLoader = false;
            let message: any;
            if (resp.IsEnvelopePurging == true || resp.IsEnvelopePurging) {
              this.toastr.warning(resp.message, 'Warning', { timeOut: 2500 });
            }
            else if (resp.success) {
              message = resp.message;
              window.location.href = resp.returnUrl;
            }
            else {
              message = resp.message;
              this.toastr.warning('Please enter the security code.', 'Warning', { timeOut: 1500 });
            }
          }
          else if (resp.originalError.error.StatusCode == 400) {
            this.showLoader = false;
            this.toastr.warning(resp.originalError.error.message, 'Warning', { timeOut: 1500 });
          }
        })
    }
    else {
      this.toastr.warning('Please enter the security code.', 'Warning', { timeOut: 1500 });
      this.showLoader = false;
    }
  }
}
