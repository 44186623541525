export class CommonConstants {
    public static bkey: string = 'WI_RSign';
    public static InvalidUrl: string = 'The URL of the envelope is incorrect.';
    public static SessionExpirationMessage: string = 'Session expired. Please close this browser tab and open the link in a new tab.';

    //Decline
    public static SelectAtleastOneOption: string = 'Select at least one option to decline.';
    public static ErrorTitle: string = 'Please let us know why you want to decline signing this documents.';
    public static DocumentGenerationMessage: string = 'The document is getting generated. Please wait or download the document later from the completed folder of your RSign account.';
    public static DeleteFinalContractDisplayMsg: string = 'The signed contract is deleted from RSign server.';

    public static LearnMoreTextOnly: string = 'Learn More';
    public static FreeTrialText: string = 'Buy Now';
    public static RSignMakesESignatureText: string = 'RSign makes E-Signatures simple with drag and drop document preparation, shareable templates, reporting, document access, and more. It has everything advanced users and administrators need in an e-sign service.';
    public static RpostValidateBotFailure: string = 'There is a problem with validating user authentication. Please try again or contact administrator.';
    public static AIAutoLockEnabled: string = 'AIAutoLockEnabled';
    public static RequestAlreadySent: string = 'RequestAlreadySent';
    public static RequestDeclined: string = 'RequestDeclined';
    public static AccessDeniedMessage: string = 'Access Denied';

    //API's
    public static GetDeclineTemplate: string = '/api/V1/SignDocument/GetDeclineTemplate';
    public static GetDownloadPDFPreview: string = '/api/V1/SignDocument/GetDownloadPDFPreview';
    public static GetEnvelopeOrTemplateFields: string = '/api/V1/SignDocument/GetEnvelopeOrTemplateFields';
    public static EncryptQueryParamString: string = '/api/V1/SignDocument/EncryptQueryParamString';
    public static GetAdditonalfileAttachmentInfo: string = '/api/V1/SignDocument/GetAdditonalfileAttachmentInfo';

    public static DownloadFinalContract: string = '/api/V1/SignDocument/DownloadFinalContract';
    public static GetDownloadFileReview: string = '/api/V1/SignDocument/GetDownloadFileReview';
    public static DownloadDisclaimerPDF: string = '/api/V1/SignDocument/DownloadDisclaimerPDF';

    public static InitializeSignerSignDocument: string = '/api/V1/SignDocument/InitializeSignerSignDocument';
    public static UpdatedEvelopeStatusbySigner: string = '/api/V1/SignDocument/UpdatedEvelopeStatusbySigner';
    public static ChangeSigner: string = '/api/V1/SignDocument/ChangeSigner';
    public static SignDocument: string = '/api/V1/SignDocument/SignDocument';
    public static FinishLater: string = '/api/V1/SignDocument/FinishLaterSubmit';
    public static SaveAttachmentRequest: string = '/api/V1/Document/SaveAttachmentRequest';
    public static DeleteRequestedUploads: string = '/api/V1/Document/DeleteRequestedUploads';
    public static DeleteNewlyAddedUpload: string = '/api/V1/Document/DeleteNewlyAddedUpload';
    public static UploadSignerAttachments: string = '/api/V1/Document/UploadSignerAttachments';
    public static DownloadDriveDocumentsForSigner: string = '/api/V1/SignDocument/DownloadDriveDocumentsForSigner';
    public static UploadSignerSignature: string = '/api/V1/Document/UploadSignerSignature';
    public static DownloadDriveImageForSigner: string = '/api/V1/SignDocument/DownloadDriveImageForSigner';
    public static UploadSignerSignatureFromOtherDirves: string = '/api/V1/Document/UploadSignerSignatureFromOtherDirves';
    public static DownloadOtherDriveDocumentsForSigner: string = '/api/V1/SignDocument/DownloadOtherDriveDocumentsForSigner';
    public static ConvertTextToSignImage: string = '/api/V1/SignDocument/ConvertTextToSignImage';
    public static ConvertHandDrawnSignImageUpload: string = '/api/V1/SignDocument/ConvertHandDrawnSignImageUpload';
    public static ConvertHandDrawnSignImage: string = '/api/V1/SignDocument/ConvertHandDrawnSignImage';
    public static DeleteAndUpdateSignerDocFromDirectory: string = '/api/V1/Document/DeleteAndUpdateSignerDocFromDirectory';
    public static DeleteSignerDocFromDirectory: string = '/api/V1/Document/DeleteSignerDocFromDirectory';

    //Authenticate Signer
    public static AuthenticateSigner: string = '/api/V1/SignDocument/AuthenticateSigner';
    public static GetCodeAndValidateSigner: string = '/api/V1/SignDocument/GetCodeAndValidateSigner';

    //GetImages and GetImagesForSubEnvelopePrefill
    public static GetEnvelopeImages: string = '/api/V1/Envelope/GetImages';
    public static GetTemplateImages: string = '/api/V1/Template/GetImages';
    public static GetImagesForSubEnvelopePrefill: string = '/api/V1/Envelope/GetImagesForSubEnvelopePrefill';

    //url direction
    public static HelpAndSupport: string = 'https://support.rpost.com/hc/en-us?rsign-list';
    public static SignupROne: string = 'https://rsign.com/#signup';
    public static RSignSignupCompleted: string = "https://rsign.com/#signup-completed";
    public static RSignSignIn: string = "https://rsign.com/login";

    //Security Code
    public static DecryptSecurityCodeUrl: string = '/api/V1/SignDocument/DecryptSecurityCodeUrl';
    public static ValidateRecipientBySecurityCode: string = '/api/V1/SignDocument/ValidateRecipientBySecurityCode';

    public static GetRedirectURL: string = '/api/V1/Envelope/GetRedirectURL';

    //StaticLink
    public static DecryptStaticLinkSigningUrl: string = '/api/V1/SignDocument/DecryptStaticLinkUrl';
    public static InitializeStaticTemplate: string = '/api/V1/SignDocument/InitializeStaticTemplate';
    public static AllowFirstSigner: string = '/api/V1/SignDocument/AllowFirstSigner';
    public static AllowStaticSigner: string = '/api/V1/SignDocument/AllowStaticSigner';
    public static ReSendPasswordEmail: string = '/api/V1/SignDocument/ReSendPasswordEmail';
    public static InitializeMultiSignerStaticDocument: string = '/api/V1/SignDocument/InitializeMultiSignerStaticDocument';
    public static InitializeMultiSignerStaticDocument1: string = '/api/V1/SignDocument/InitializeMultiSignerStaticDocument1';
    public static InitializeSignerStaticSignDocument: string = '/api/V1/SignDocument/InitializeSignerStaticSignDocument';
    public static InitializeSignerStaticSignDocument1: string = '/api/V1/SignDocument/InitializeSignerStaticSignDocument1';
    public static CreateStaticEnvelope: string = '/api/V1/Envelope/CreateStaticEnvelope';
    public static CreateInviteSignerEnvelope: string = '/api/V1/Envelope/CreateInviteSignerEnvelope';
    public static DeleteInviteByEmailUsersAttachments: string = '/api/V1/Document/DeleteInviteByEmailUsersAttachments';

    //Group Envelopes
    public static GetTemplateDetailsForSignMultiTemplate: string = '/api/V1/SignDocument/GetTemplateDetailsForSignMultiTemplate';
    public static GetMasterEnvelopeStats: string = '/api/V1/Dashboard/GetMasterEnvelopeStats';
    public static FinishSubEnvelope: string = '/api/V1/SignDocument/FinishSubEnvelope';
    public static DelegateMultiSigner: string = '/api/V1/SignDocument/DelegateMultiSigner';
    public static GetBasicEnvelopeHistory: string = '/api/V1/SignDocument/GetBasicEnvelopeHistory'
    public static DownloadDocument: string = '/api/V1/Document/DownloadDocument'

    public static ConvertSignImageWithStamp: string = '/api/V1/SignDocument/ConvertSignImageWithStamp';
    public static ConfirmUserOnFinalSubmit: string = '/api/V1/SignDocument/ConfirmUserOnFinalSubmit';
    public static ValidateFinalSubmitOTP: string = '/api/V1/SignDocument/ValidateFinalSubmitOTP';
    public static RetriveAllDocumentControlData: string = '/api/V1/SignDocument/RetriveAllDocumentControlData';
    public static GetEnvelopeCode: string = '/api/V1/Envelope/GetEnvelopeCode';
    public static SendRequestAccess: string = '/api/V1/SignDocument/SendRequestAccess';
    public static AIAutoLockUrl: string = '/api/V1/SignDocument/AIAutoLock';
}

export class RecipientType {
    public static CC: string = "63EA73C2-4B64-4974-A7D5-0312B49D29D0";
    public static Signer: string = "C20C350E-1C6D-4C03-9154-2CC688C099CB";
    public static Sender: string = "26E35C91-5EE1-4ABF-B421-3B631A34F677";
    public static Prefill: string = "712f1a0b-1ac6-4013-8d74-aac4a9bf5568";
}

export class Controls {
    public static Signature: string = "CC8F7E49-E9CF-41A2-9D7B-5BBC4759D9CA";
    public static Initial: string = "9506A039-DF8D-4650-8552-8B650C9889B1";
}

export class ControlsName {
    public static Signature: string = "Signature";
    public static Initial: string = "Int.";
}

export class SignatureType {
    public static Auto: string = "05280C40-3DC8-479E-BD58-AE2FE0D62A97";
    public static Hand: string = "AEB3D4A0-9DCF-4441-98A8-8ECDB7169936";
    public static UploadSignature: string = "8D122A62-56FB-4118-9140-FCFF5FD66AC5";
}

export class InitialType {
    public static Auto: string = "05280C40-3DC8-479E-BD58-AE2FE0D62A97";
    public static Hand: string = "AEB3D4A0-9DCF-4441-98A8-8ECDB7169936";
}

export class Defaults {
    public static DefaultImages: string = "../../../assets/images/"; // "/Content/NewTheme/img/";
}

export class ResourceKeys {
    public static Updatedby: string = "FF8B9EE8-AD55-4C68-8453-02CD7A3055C4";
    public static EnvelopeData: string = "66E777DF-365D-4DE4-9188-0DA8EEF29108";
    public static Name: string = "391FF1F4-6A14-4782-A7C9-0FA49F36A104";
    public static Submit: string = "CBD33E2D-8F50-4A92-B934-183D03D0EE34";
    public static DefaultSignature: string = "F10734C1-189F-4545-A2F0-1A8848AB1056";
    public static Iagree: string = "F54DEA9B-E74E-4280-B543-1AC466C16A79";
    public static Hi: string = "EE0583CE-968E-4BCC-B32A-25252C31E152";
    public static SessionLogOut: string = "E1D5B882-A547-498C-AC7D-2872178EDE78";
    public static Date: string = "0EAB0BE2-0420-4D2D-AC8E-34FF54A73A3A";
    public static getStarted: string = "A91F0BA8-4711-438A-ABAD-35012E00958D";
    public static SessionKeepSign: string = "C4F375B8-E46C-410D-A774-3F1C8B72A2E7";
    public static TermsOfService: string = "626C7F9E-B167-424F-B195-43C99C4BCC3E";
    public static Sender: string = "26E35C91-5EE1-4ABF-B421-3B631A34F677";
    public static Recipient: string = "32128ED5-531A-4AB1-ACE6-4B8953C70D13";
    public static Sessionpopupminutes: string = "2EADAFC9-0378-4849-85F0-4D20C9C87210";
    public static AccessCodeToOpenSignedDocument: string = "2E4DF021-1399-4531-9302-4EF4FE08D204";
    public static Local: string = "756661BB-2461-45C2-8113-5201EB58EBE5";
    public static Yes: string = "F0E0417A-2482-45FD-89B0-54269409999E";
    public static SessionKeepOut: string = "01C61630-ABF3-4E28-BB0E-57E7F869C7BC";
    public static Password: string = "BE2EABAF-9096-4027-BE83-5802B8F3A50B";
    public static Sequence: string = "3747D0FE-1021-4BCF-B22A-590779C19799";
    public static Signature: string = "CC8F7E49-E9CF-41A2-9D7B-5BBC4759D9CA";
    public static Localmachine: string = "7FD82CF2-6413-418A-B3A7-5D2400CE1808";
    public static Download: string = "C4BF6ECB-D741-4F1F-B1CC-5D80A066F2F8";
    public static EmailAddress: string = "04F496B6-4135-42F9-B2C0-604C569B0D0C";
    public static freeTrial: any = "A6FD5C43-BF20-49C5-A321-616DD7ADC006";
    public static SignUp: any = "0A2A4C06-A2BF-446F-97D9-655B281B63CB";
    public static FinishLater: string = "B50BBC95-D622-4C6C-94FF-664629F1C524";
    public static Signer: string = "C20C350E-1C6D-4C03-9154-2CC688C099CB";
    public static Sign: string = "D6BA71C2-91A4-41CE-ABE8-6AD9CD5F757A";
    public static Required: string = "93F9CE7C-3295-4B61-AA5B-6E116BC5F078";
    public static Initials: string = "0F001FA1-C71F-490B-93CF-6E5A55ABAAD5";
    public static Add: string = "06547D5E-8427-4956-A8A3-76211E98B1C7";
    public static EnvelopeID: string = "9C2A99C8-DF21-4FCF-9968-7E2F5A8629BC";
    public static TemplateCode: string = "C607560E-54F0-48F1-91ED-52D032B57962";
    public static SessionAboutExpire: string = "04C148F5-E4B8-4EE7-A7B8-87152953E8AA";
    public static Prev: string = "5F2140EB-6923-43F2-8ED4-8B5CB3A0164E";
    public static OldUser: string = "2B501A77-5A69-4DD2-85F3-8B8B87EB5217";
    public static All: string = "F874B17E-F1E9-4195-8687-8C4D0C6067F5";
    public static Document: string = "23930417-0123-4AAB-8B7F-8CD783FDD770";
    public static Title: string = "A17FC9D6-CA82-4E80-9F6B-9974AA1709F7";
    public static DocumentDetails: string = "BDC91145-B75D-4C12-9D2D-9C6FBCB5694A";
    public static SupportedDoc: string = "711E4774-9989-4739-BF6D-9E43FA5902B7";
    public static Cancel: string = "66B1DC00-482B-4980-8710-AAFC3DADA3C3";
    public static Update: string = "57A8EA2B-246A-4491-944B-ACC90CE67CE6";
    public static Sessionpopupseconds: any = "3367D21B-93C0-48F7-B299-AD51327DD48C";
    public static Status: string = "0EBF7088-E4B7-4692-8DBE-B14A6100850C";
    public static DateTimeStamp: string = "A022376B-9495-4EAA-8CFE-B3293618BEBE";
    public static Continue: string = "38F7EC6A-02D4-4D13-8498-B3F089AB057E";
    public static Email: string = "982A95AB-6FE0-4764-A8E1-B5760121CCBC";
    public static AccessCode: string = "D052E338-5447-4619-B07A-B61D6F31EA25";
    public static Accepted: string = "D9AA6F04-E337-4332-9D81-C0A1DDF0F635";
    public static IPAddress: string = "09122DE2-BF2F-46A3-A89B-C3B0F3093A55";
    public static SignIn: string = "87262D2C-DFD5-4471-B07E-CA98AF4B1BCE";
    public static Page: string = "1F1728BC-8711-4ECD-B62E-D08C4D4B5A94";
    public static Select: string = "D0565066-0ABD-40DA-A9F8-DC769E6D4F48";
    public static Copy: string = "380EF85A-E330-4516-8675-DC7DFE285257";
    public static title_Close: string = "548A19E7-DE15-40DD-B461-E96F4FDA5A49";
    public static Decline: string = "784FC921-B967-4D4B-974F-EB19E69422A3";
    public static Send: string = "1CE1860B-143A-4958-99A4-EC0967B21FFE";
    public static Description: string = "8E9D6844-5904-4C08-83CE-ECC621B36DAF";
    public static Manage: string = "619550AA-5AB1-4EE2-95DF-F752EEF08460";
    public static SessionStayIn: string = "BFEFEC19-5256-4A37-A408-FA547AF5B89B";
    public static ChangeSigner: string = "6598064B-33FD-450D-A982-FDACA94B1F57";
    public static lang_attention: string = "683C2554-3FF9-4E5A-BBC0-BC5259ECAE4C";
    public static waitingMsgAddressStatic: string = "79D71697-27E3-46A9-862E-CE9280F28AC2";
    public static waitingMsgSignStatic: string = "F643AFF9-00EF-4975-9D45-466AB068286A";
    public static waitingMsgTryAgainStatic: string = "3728ABE7-3343-4049-8887-7867B198E31C";
    public static waitingMsgProceedStatic: string = "074490B9-972A-41C6-974F-28D9AAB5583D";
    public static lang_N: string = "A5F19D7F-FB5C-4451-9DB2-F499FE9AC583";
    public static SignupForFree: string = "b8af9d7b-7d57-47ca-b751-63e9c3aa9a44";
    public static SendDocuments: string = "665b8d7e-5a99-4165-aacc-e2b566d5c46e";
    public static ManageDocuments: string = "f9aeaa1d-4d56-435e-b9ee-66aeebb7b7d4";
    public static CreateRules: string = "cf5dc542-b906-4acf-bbd4-99b550132331";
    public static AutomateNotifications: string = "19574b35-1829-49a8-afc8-3fcbb0a2c481";
    public static TermsAndConditions: string = "de3f7d3c-4887-4806-ac77-2c5e0d456aeb";
    public static PrivacyPolicy: string = "4469bc07-5c24-4796-876a-f720d09ab182";
    public static LoginInto: string = "D90E1DCF-8E8A-4DAA-84D9-1CBE9C21CE8E";
    public static eSignExpiredFeatureList: any = "25b27318-817c-4aa8-9972-837de7d41e92";
    public static eSignExpiredServiceFeature: any = "435424f7-2ef0-4a54-a9b4-eb479f1f4a43";
    public static FreeTrial: string = "d0f7d3f7-42aa-4a47-ba58-fcde4364fb4e";
    public static lang_Information: string = "79A6075C-5313-404C-93A5-6099751E823C";
    public static lang_LogIn: any = "1494F113-AA0A-4697-9510-158079AF6D18";
    public static lang_LOGINUpperCase: string = "7F158550-3BCE-4930-A8A9-5B89C3E52818";
    public static Lang_SignUpUpperCase: string = "C4E2D154-FDC3-4D6D-9ED0-9DB46E9F3F29";
    public static lang_NOTHANKSUpperCase: string = "64C6DFE9-B452-433F-85E6-6F5DFAB9A62C";
    public static ReferenceCode: string = "C9CC6C6E-6F8E-4A88-8549-C95BD6EFCA2F";
    public static ReferenceEmail: string = "C350C9F2-A49D-4F27-93CE-DD164B55BD04";
    public static SendDocument: string = "E61F4FF1-926A-4D81-99EE-9B69853A864E";
    public static SignMultipleTempLabel: string = "E446F305-B910-4163-BA56-50158958E8A7";
    public static SignMultipleTempLabel2: string = "0FE5BDE6-F0BC-45E0-B987-C1B72AAF30C4";
    public static Finish: string = "057DC8AB-1B98-4E88-8FDB-6473CCA54346";
    public static Prescriber: string = "54CCAAEF-A269-4963-8553-16565458FF1B";
    public static UpdatedStatusDate: string = "A936EB37-A890-4141-9EEC-D086B6205B3D";
    public static AdditionalInfo: string = "B8079C9D-D1E9-4506-A12B-48AE6F7093B2";
    public static Upload: string = "36AD1580-7F22-4BD8-B84B-4951BE47530C";
    public static AddNew: string = "C5197180-05EB-46BD-A64F-A3A03E575E3F";
    public static lang_delegate: string = "F8FA7589-3D2A-425A-9F5A-E5079428FBD5";
    public static DGPostSignPopupDesc: string = "E3950879-FFE2-42AA-9BF2-E0DF2320F549";
    public static DGPostSignPopupSignAnotherButton: string = "8C8CA552-782C-4951-9236-A5BCD3B11F25";
    public static DGPostSignPopupBackButton: string = "D219EB75-B9F2-4B0D-B949-D678C2CBBE19";
    public static Privacy: string = "F19EBF72-F694-4565-B008-25CFA06657B4";
    public static Or: string = "F77AF692-0768-4B62-A6C8-D354B7BCC624";
    public static Message: string = "333851F9-10D6-484A-83B6-1AB5A940EA26";
    public static Preview: string = "258DBE19-9F81-4D8D-913F-0469AE78B324";
    public static ClicktoSign: string = "908BC191-6129-40DD-9018-E168245475FC";
    public static SignerTermsCond: string = "4C983255-3604-4C21-84D0-92990960941E";
    public static AcceptandSign: string = "5FE387E8-1304-49CF-A6B4-07B47A1FC3B3";
    public static EnableWebhook: string = "775B86A4-1137-4254-94C5-93430DF5B4C3";
    public static ValidateSigner: string = "E99F3D36-D42C-4634-ABAA-6EAE51892B95";
    public static TypographySizeHint: string = "7985D3B9-3596-49BC-8C32-247E0A193BC5";
    public static TypographySize: string = "BBBAA225-9330-4200-A954-0FABAB6C377F";
    public static UploadSignature: string = "F09ABA05-C4BB-4383-B382-30AE01F812A4";
    public static ViewSettings: string = "CC147CC8-ED00-47CE-939C-F120CC6894B8";
    public static OnlycharactersAndnumbers: string = "CAFD9E78-1CA7-4A80-9AF1-B11C35E77DC6";
    public static checkboxGroupsOptional: string = "52258AAE-CC2F-4ED1-B8FC-1D8DCDF177F2";
    public static ForgotPasswordSubmit: string = "8DE5D787-26F9-4756-B68A-3D030D87DF32";
    public static BackToLogin: string = "E273049D-E34B-4571-B219-504CCA3D7855";
    public static ForgotPasswordLabelText: string = "FB9EA4C5-6C0F-4C9F-AC51-9F1FF0FEC1BF";
    public static EmailIsRequired: string = "78AC958F-4BF8-4EF6-B7BE-39152C04F8CB";
    public static lang_export: string = "B77BCAFE-DE2A-4395-8E0C-6D853DD418EB";
    public static orText: string = "F4A168B9-A7BC-4F77-B8EC-F6317EC0FF1F";
    public static EnterPassword: string = "A965B8FB-52A4-4065-9B30-4035E9B71F2F";
    public static Success: string = "CC207874-D065-46AD-8C51-07B00BAFA1EB";
    public static Redirecting: string = "6A80203D-D572-49B9-A967-4E0E666BB328";
    public static DocumentPassword: string = "65E0A063-BDA0-4BC6-97EF-0173B00B28B6";
    public static Lang_NoThanksCamelCase: string = "9D9DBEC3-BF4F-489F-9A62-36CB488DF029";
    public static TermsandConditions: string = "2DEB715A-6B4C-4A7B-91AC-D456E709E62E";
    public static SigningAs: string = "743E2355-CB89-4296-87B7-7A554FCBD5E2";
    public static InviteSigners: string = "E3F93499-AD60-4DF5-8C0E-FC7EE0FC400C";
    public static SignAs: string = "6B112C1A-5AB3-4274-B0AD-86697D5B2EF4";
    public static SignNow: string = "D362F62F-CA4E-4880-8F1E-6903CDBA5892";
    public static InviteByEmail: string = "29FAA277-105E-479C-A654-80B67D62AF91";
    public static Confirm: string = "3B1F4201-52DD-4501-BFAD-8DDACD240F68";
    public static SelectSignAs: string = "F2FB594B-BF9A-4A70-955A-C30F0412EDFA";
    public static Acceptingthetermsandconditions: string = "6880F76B-6136-423E-8DA4-D2B6E9D26BA1";
    public static Lang_Information: string = "95C106C9-F93D-4BE1-8EF6-DECE953C7E3B";
    public static Lang_InformationWarning: string = "6EBE22F5-5F9A-476E-89A5-4EC946EDD096";
    public static SendConfirmationEmail: string = "06E9C7F1-C1EB-438A-95CE-2ECA5E4D1C11";
    public static ShowTags: string = "18CE2312-4D51-4518-8A8F-B643A21B0D80";
    public static HideTags: string = "EB421BC0-73D6-4ACD-A868-2051593B657C";
    public static UploadDocumentValidation: string = "E8B6D88B-F56F-449E-9BF3-F889ECDB6835";
    public static RedirectPostSigningLandingPage: any = "A43B475A-1180-4117-965C-53E65B4C1B82";
    public static ConfirmYourIdentityText: string = "117E105A-D32B-4423-84AC-8AB816FE020F";
    public static RSignConfirmationMail: string = "5F3665F1-3E35-4D4E-9F4E-99023CBEBF34";
    public static AdditionalSignerRequiredDocument: string = "B9CEC1C9-9CC5-4CD4-A67C-23878A55FA37";
    public static DuplicateEmailsNotAllowed: string = "DB3F20AB-7654-4354-9D5A-2EC35449DDC1";
    public static RSignMakesESignatureText: any = "EF529659-7036-4772-982C-0D7EF54A51F2";
    public static LearnMore: string = "b7c92495-5f50-4d67-ad3d-f3e83a68f71f";
    public static RSignSingleSignerConfirmationMail: string = "CE42E1E3-745B-4765-82C9-54E4DB94350A";
    public static GetStartedHere: string = "699d9c5c-715b-4f36-a582-612dcb65a8bd";
    public static LearnMoreTextOnly: any = "30DAEF26-A701-4CB4-8C9D-9A63E5D760DB";
    public static lang_SlectOption: string = "6308473C-B431-4241-A588-142C5B69CA6A";
    public static lang_PleaseEnter: string = "665C2646-9818-455E-9D1F-3CF909077640";
    public static lang_SelectRadio: string = "05B398C1-E49C-4B63-92A0-A71C9FC8B84F";
    public static lang_SelectCheckbox: string = "9BF6ABAB-FAB9-4B1A-9408-4169A77F0762";
    public static lang_PleaseSelect: string = "F7B28788-2575-4D7C-9D1A-CF9171AF35D6";
    public static FilesforReview: string = "5965EE72-4A75-4668-8F89-F9BA93F9A458";
    public static DownloadAndReviewFiles: string = "08F02C00-CC7F-451A-920C-157EB7A36158";
    public static ConfirmFilesReviewed: string = "CF1B673C-20A4-442A-91F8-0050C598348F";
    public static DownloadFiles: string = "2E2D10EB-9E9A-4E9A-9466-9BDB682D6EF6";
    public static SendFinalSignDocumentChangeSigner: string = "69A5E504-6AB5-4871-887B-E1A5F3530AD5";
    public static MobilePaswordText: string = "82EF8EDF-64D9-4203-8130-0C5DBDD673BF";
    public static MobileEmailPasswordText: string = "2D569ED9-38E0-4E7F-85A2-9CCA8C84B8ED";
    public static InviteSignerHeaderText: string = "EA216AB3-4549-4C24-8C01-7885B0E8E026";
    public static SendConfirmationMobileText: string = "C13FB96E-3E4A-4006-A857-A3FEC0E5DA95";
    public static SendConfirmationEmailMobileText: string = "527EDA87-5534-4F68-8C0E-29459509F57A";
    public static ConfirmEmailOnly: string = "07A75514-EC57-42B0-B38C-38AEE36D2C82";
    public static ConfirmMobileText: string = "3E116D0C-DEDC-4F2D-A881-6E7BFF4B1F49";
    public static MultiSignerConfirmEmailMobileText: string = "CA3C3B74-5E6D-428A-AB31-9EFD46D83D2A";
    public static ConfirmEmailMobileText: string = "A8BB729A-4A47-48BB-BDF1-9D3601C9A459";
    public static ConfirmSingleSignerEmailOnly: string = "A42BD520-A8B4-4020-A0FB-35464AD4826A";
    public static SignerTermsCondEmailOrMobile: string = "49BB6191-3F3E-46E0-8437-C33772C46934";
    public static SignerTermsCondMobile: string = "A8822828-7E19-4297-A959-8D49CD584760";
    public static EmailPaswordText: string = "67FE0B83-C563-4240-9E8E-6D3A39385CEF";
    public static SendSingleSignerConfirmationEmailMobileText: string = "D65EB200-D15B-443A-A0E1-FDE471D31907";
    public static SendSingleSingnerConfirmationMobileText: string = "5315364D-5D9E-45D2-8488-A8C66B79E39A";
    public static lang_MobileNumber: string = "4670FE7E-0DF6-4234-964F-2CD6B7985373";
    public static DownloadFinalSignedDocText: any = "FB1A24BD-3B96-4EB7-946C-39BB7EC789FE";
    public static DownloadDocAfterSignedText: any = "BB0C81DD-8766-4AEB-92C6-93BC7F6131DB";
    public static Lang_DownloadFinalSignedDoc: string = "C55681A4-0E36-4F64-94E7-997385A4A68A";
    public static DocumentGenerationMessage: string = "DA9B9765-5116-4291-AAF7-B8AD40B0FE97";
    public static SignupDownloadFinalSignedDocText: any = "6B4597D8-EAD8-4725-A689-0FED9D40E74D";
    public static Lang_DeclineDownloadFinalSignedDoc: string = "4AE24E94-6D93-44BD-A20C-914C34D6284F";
    public static CountryCode: string = "FA034064-961C-406B-9ACE-07974408484A";
    public static Mobile: string = "C340A9BC-622A-4C39-85D4-BA311056D212";
    public static CopyEmailDownloadFinalDoc: string = "AA072DC9-C2B4-4085-B4E6-097D893BE651";
    public static MyDetails: string = "66674093-9F44-47D4-BF24-3835085E4964";
    public static EnterSignerDetails: string = "0A4E48F2-E290-4CCF-A9C0-943A719DF3B3";
    public static Subject: string = "A521C3A5-5AEF-4E26-BFBD-B5D6B3E1054D";
    public static Recipients: string = "C5F1A5C7-11A2-424C-9186-B9D23A1CBFA6";
    public static No: string = "0E9CDDC4-25BF-4BF0-AD53-80D8C89E185D";
    public static sessionTimeout: string = "59897347-4CBB-4D2C-8BBF-66079DA635DD";
    public static ConfirmToAccess: string = "91879639-3784-4CC1-AC67-FFF435B0823D";

    public static AIRequestAccess: string = "0625327B-7DBE-47FF-AC01-0566FC2A34EF";
    public static AIAccessDenied: string = "7113F77F-BAA7-4A98-99F3-183CFF9F76C0";
    public static AIAccessRequest: string = "9FFB6833-124B-4D47-B2AE-1D00394238C8";
    public static Lang_AIEmailMissMatch: string = "01640738-FB59-4EFA-874F-2E6DBAB33F70";
    public static AIEmailaddress: string = "B541D007-96F3-4CEC-96D5-67789EA29A5F";
    public static AIAccessReqBody: string = "683AE1B5-9C78-4809-8DB5-7DA62FC221AF";
    public static Lang_AIMobileMissMatch: string = "9E0DCBC1-EA14-4C41-A144-9B42EE7DC511";
    public static AINotice: string = "FC08AFDA-7DCA-4D83-90A7-A75A63364AB3";
    public static AIAccessText: string = "58A4DA5F-BCE4-4660-8A7E-A88B3CE0944E";
    public static AIAccessEmail: string = "972D3628-57D4-4CD5-91B9-ACFBF770EAFF";
    public static ValidEmail: string = "8E6285DB-16BE-4987-8D9F-B30CA463172F";
    public static Lang_AIcountryCode: string = "4FF1D94C-AEB3-4BCC-AF63-B4A6A5B352CA";
    public static AIEnterEmail: string = "91CCA461-C020-4CEC-AA89-EC998D45A08F";
    public static AIEmailMailTemp: string = "6301E0D8-CA73-4ED6-A2FB-F1C825F16B89";
    public static Lang_AiMissMatchDet: string = "57C4F3DF-4EDA-420D-BDFC-2C7E0531BE5F";
    public static AIReaonForAccess : string = "3DD981BB-DA90-4B12-A3C9-59D856295920";
    public static AiAccessDeniedForSender : string = "BA163B4E-1C8A-47DE-BC49-5904AFA039C8";
    public static Lang_AIMobileMissMatchDetails : string = "81459AE7-B38A-47A3-94E2-9073B127F9DB";
    public static DocumentAccessDeniedText : string = "C74D4056-36F4-4BE5-B6D9-0FFD50D9BC53";
    public static lang_InvalidEmailAddress : string = "5C78379A-C472-47B8-98FA-E23F8E61F846";
    public static lang_CountryRequired : string = "516A0711-4A96-46CC-AC2D-7056136B3C85";
    public static AddSignature : string = "467DFEA1-063F-47E5-897B-A8BE8E25172D";
    public static Type : string = "1911745D-DB10-41E9-A6A3-465DE82553C6";
    public static Draw : string = "32502D86-7080-4D47-B2FB-1D57B2819E01";
    public static UploadSign : string = "36AD1580-7F22-4BD8-B84B-4951BE47530C";
    public static MaxFileSize40KB : string = "850BE5AC-79AC-474A-8A97-888C35028266";
    public static DragAndDropSignatureFileHere : string = "259BD804-D461-44DE-A45A-0073D281BDB6";
    public static UploadaPictureOfYourSignature : string = "4354F5D0-81FC-433B-A238-E6734BA12610";
    public static CancelSign : string = "66B1DC00-482B-4980-8710-AAFC3DADA3C3";
    public static DrawSign : string = "E91661BC-6F33-44CE-B86A-2E320E23983C";
    public static PreviewSignature : string = "85879FE5-D232-416A-B3DA-851F0EC0C1CE";
    public static Clear : string = "1789531D-417F-48A1-ACD4-5342F30C3A47";
    public static TypeName : string = "691E1244-FEDB-437D-94C6-389365EA48D3";
    public static FieldsRemaining : string = "79FDB8A5-8EC4-4BC1-8853-B94849F260DC";
    public static PleaseClickToAddSignature : string = "C8F0D7CB-1684-4A2C-854E-CA04AADCC41F";
    public static DownloadPreview : string = "1FD671A4-754B-49AC-A0F1-861F3F5F575A";
    public static SessionExpireAvailableTime : string = "BF50CD37-1949-493E-8A73-B833B6A9B6E4";
    public static AddEditSignature : string = "0B4F39FE-B55F-4768-AFC0-B5921541094A";
    public static Help : string = "B34EDC79-E91C-4F43-A397-420E119ADB16";
    public static Filter : string = "BD0839F3-440E-4B8D-8F02-9DE67761E68F";
    public static SelectAll : string = "9DA713AF-2EA7-4536-A5A6-03C1BBF31908";
    public static Apply : string = "007DDDA4-68B0-447D-9EB2-F2E636CAF1CF";
    public static PleaseClickToAddInitials : string = "3B36B907-D2CD-4878-92E9-F6C3D382FABA";
    public static PleaseEnterRequiredFields : string = "9ACAD4FB-B72A-46A9-9FBD-16D4F6B6BA4F";
    public static OK : string = "9D30B519-47FF-463A-82A5-6FBB42A57E5A";
    public static SelectSignature : string = "26FBE34A-6A4B-442D-B353-0BBF5234444F";
    public static SignupROne : string ="7957D9E3-6A7F-4957-AAC6-4A99C107DADA";
    public static lang_warning : string ="74CC2315-C9A6-4D46-AF56-5A3AA63735B0";
    public static lang_error : string ="497B1187-AF8F-4347-8C5A-C0814DF36E5E";
}

export class MessageTypes {
    public static Success: string = "Success";
    public static Error: string = "Error";
    public static Warning: string = "Warning";
    public static SuccessWarning: string = "SuccessWarning";
    public static SuccessErrorMessage: string = "SuccessErrorMessage";
    public static Expired: string = "Expired";
    public static SendConfirmationEmail: string = "SendConfirmationEmail";
    public static ArchivedOrPurged: string = "ArchivedOrPurged";
}

export class RouteUrls {
    public static SignDocument: string = 'sign-document';
    public static SignerLanding: string = 'signer-landing';
    public static SignMultiTemplate: string = 'sign-multi-template';
    public static AllowMultipleSigner: string = 'confirm-to-begin';
    public static PasswordWindow: string = 'password-window';
    public static Info: string = 'info';
    public static StaticTemplateLanding: string = 'static-template-landing';
    public static StaticTemplate: string = 'static-template';
    public static SignerLandingStaticTemplate: string = 'signer-landing-static-template';
    public static AuthenticateSigner: string = 'authenticate-signer';
    public static ViewDocumentLanding: string = 'view-document';
    public static ConfirmToBegin: string = 'confirm-to-begin';
    public static ValidateSigner: string = 'validate-signer';
    public static GlobalError: string = 'global-error';
    public static ValidateAutoLock: string = 'validate-lock';
}

export class ReturnUrl {
    public static List: any = [
        'sign-document',
        'signer-landing',
        'sign-multi-template',
        'allow-multiple-signer',
        'password-window',
        'info',
        'static-template-landing',
        'static-template',
        'get-allow-multiple-signer',
        'signer-landing-static-template',
        'authenticate-signer',
        'confirm-to-begin',
        'view-document',
        'validate-signer',
        'global-error',
        'rclick',
        'validate-lock'
    ];
}

export class UploadDriveType {
    public static Google: string = 'Google';
    public static Dropbox: string = 'Dropbox';
    public static Skydrive: string = 'Skydrive';
}

export class StaticUrlWatingTime {
    public static StaticUrlWatingTimeInSeconds: string = '300';
}

export class RPostUrl {
    public static TermAndService: string = 'https://rpost.com/legal-notices/terms-and-conditions';
    public static PrivacyPolicy: string = 'https://rpost.com/legal-notices/privacy-notice';
}
export class Signer {
    public static Sent: string = "1B5D8208-B1BF-40E1-9FBE-1D18F8A87B44}";
    public static Viewed: string = "D30DBB92-E6D6-4729-93E2-BD02F56CEBE1}";
    public static Rejected: string = "B03B5387-50BB-49BE-92A0-0745C1136092}";
    public static Signed: string = "4F564EA5-009C-4F52-A3DE-C6D0AC598617}";
    public static Pending: string = "20D49160-82E6-48F2-93AF-CE78F86177D2}";
    public static Delegated: string = "3B229C22-16AE-48BB-AA06-CF05C78A60D7}";
    public static Incomplete_and_Expired: string = "468DF264-DD7A-41BE-A0AF-D0EC9B95001F}";
    public static Finish_Later_Selected: string = "D5ADF209-18AC-4D7E-985E-ECAE72307F2F}"; // Changed status to Finish_Later_Selected from Saved_As_Draft as per ticketID:S3-74
    public static AwaitingConfirmation: string = "51E85610-7671-4407-A4DE-2A2AA5851E89";
    public static Update_And_Resend: string = "B656B331-D44C-4489-9CB8-BB8DACB56682";
}

export class DateFormatString {
    public static US_mm_dd_yyyy_slash: string = "mm/dd/yyyy";
    public static US_mm_dd_yyyy_colan: string = "mm-dd-yyyy";
    public static US_mm_dd_yyyy_dots: string = "mm.dd.yyyy";
    public static Europe_mm_dd_yyyy_slash: string = "dd/mm/yyyy";
    public static Europe_mm_dd_yyyy_colan: string = "dd-mm-yyyy";
    public static Europe_mm_dd_yyyy_dots: string = "dd.mm.yyyy";
    public static Europe_yyyy_mm_dd_dots: string = "yyyy.mm.dd.";
    public static US_dd_mmm_yyyy_colan: string = "dd-mmm-yyyy";
}
export class DateFormatGuid {
    public static US_mm_dd_yyyy_slash: string = "E3DAD8D9-E16F-40F5-B112-BBA204538136";
    public static US_mm_dd_yyyy_colan: string = "9FC73C2B-34D7-42A1-B2A6-702ED2FD312B";
    public static US_mm_dd_yyyy_dots: string = "BDA0023F-AFC1-46E5-A134-884EDCA48799";
    public static Europe_mm_dd_yyyy_slash: string = "577D1738-6891-45DE-8481-E3353EB6A963";
    public static Europe_mm_dd_yyyy_colan: string = "6685D1ED-60D2-4028-94E9-BC875C2E551D";
    public static Europe_mm_dd_yyyy_dots: string = "374FE10E-4313-4541-B3CB-627310A14499";
    public static Europe_yyyy_mm_dd_dots: string = "8FAC284C-AB19-456C-BC73-1CE0D66D7220";
    public static US_dd_mmm_yyyy_colan: string = "7F472F00-CD12-443E-B38D-085F8872115F";
}
export class ActionTypes {
    public static Sign: string = "Sign";
    public static Review: string = "Review";
}
export class AttachSignedPDF {
    public static SenderOnly = "CDFC7178-03C4-4864-B3FB-097AA2335557";
    public static SenderSigner = "AAFB0BBB-21DC-4AAD-816F-6814B191CEDC";
    public static RetrievalLink = "037CC450-5065-4DC1-BF01-DA12637C4FB8";
    public static SenderOnlyandNoemailtoRecipient: string = "6F0CC529-F418-4A33-852C-3E470A150DD9";
    public static SenderRetrievallinkandNoemailtoRecipient: string = "EA92FA10-71FC-445E-B5D9-50E317A9F7A1";
    public static SenderOnlyandRecipientSignIn = "EA92FA10-71FC-445E-B5D9-50E317A9F7A1";
    public static SenderRetrievallinkandRecipientSignIn = "461EEB77-4DFF-44B9-B2A0-00A5DD015B5D";

}
export class EnvelopeStatus {
    public static Incomplete_and_Expired = "2A8C3F8D-B512-43A6-8579-00BFF4EFE546";
    public static Terminated = "EB0BB5BD-DADA-4C37-AD2F-704CE4992E1C";
    public static Completed = "C9596319-6F6B-4840-B49D-85010206C7C7";
    public static Waiting_For_Signature = "63E17398-AFDC-48C7-B9EC-90582CFEB562";
    public static CancelledTransaction = "3AC65996-8529-4415-B895-54F27B3CC609";
}
export class EnvelopeStatusText {
    public static EnvelopeAccepted = "EnvelopeAccepted";
    public static Completed = "ContactSender";
    public static Declined = "Declined";
}

export class EmailAddressFormat {
    public static ValidEmail = /^(?!\.)+(?!.*?\.\.)+([a-zåäöÅÄÖ\d_!#$&%'*+-/=?^{}`|~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-zåäöÅÄÖ\d_!#$&%'*.+-/=?^{}`|~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-zåäöÅÄÖ\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-zåäöÅÄÖ\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-zåäöÅÄÖ\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-zåäöÅÄÖ\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-zåäöÅÄÖ\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-zåäöÅÄÖ\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-zåäöÅÄÖ\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-zåäöÅÄÖ\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
}

export class MessageDeliveryModes {
    public static PrefillNA = "NA";
    public static EmailSlashEmail = "Email / Email";
    public static EmailSlashMobile = "Email / Mobile";
    public static EmailSlashEmailAndMobile = "Email / Email & Mobile";
    public static MobileSlashMobile = "Mobile / Mobile";
    public static MobileSlashEmail = "Mobile / Email";
    public static MobileSlashNone = "Mobile / None";
    public static MobileSlashEmailAndMobile = "Mobile / Email & Mobile";
    public static EmailAndMobileSlashMobile = "Email & Mobile / Mobile";
    public static EmailAndMobileSlashEmail = "Email & Mobile / Email";
    public static EmailAndMobileSlashEmailAndMobile = "Email & Mobile / Email & Mobile";
    public static EmailSlashNone = "Email / None";
    public static EmailAndMobileSlashNone = "Email & Mobile / None";
}

export class DeliveryModes {
    public static EmailSlashEmail = "1";
    public static EmailSlashMobile = "2";
    public static EmailSlashEmailAndMobile = "3";
    public static MobileSlashMobile = "4";
    public static MobileSlashEmail = "5";
    public static MobileSlashNone = "6";
    public static MobileSlashEmailAndMobile = "7";
    public static EmailAndMobileSlashMobile = " 8";
    public static EmailAndMobileSlashEmail = "9";
    public static EmailAndMobileSlashEmailAndMobile = "10";
    public static EmailSlashNone = "11";
    public static EmailAndMobileSlashNone = "12";
}