import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AppError } from '../common/app-error';

@Injectable({
    providedIn: 'root'
})
export class SignerLandingService {

    baseUrl = environment.apiBaseUrl;

    constructor(
        private http: HttpClient
    ) { }

    private handleError(err: HttpErrorResponse): Observable<AppError> {
        const appError = new AppError(err);
        const errorStatus = err.status;
        const errorStatusText = err.statusText;
        const error: any = err.error;
        let errorMsg = '';
        let msgId = '';

        if (error && error.Message !== undefined && Array.isArray(error.Message)) {
            errorMsg = err.error.Message[0].Message;
            msgId = err.error.Message[0].MessageId;
        } else {
            if (error && error.error_description) {
                errorMsg = error.error_description;
            } else {
                if (error && error.Message) {
                    errorMsg = error.Message;
                    msgId = (error.MessageId) ? error.MessageId : '';
                } else {
                    errorMsg = errorStatusText;
                }
            }
        }

        const retMsg = `Error (${errorStatus}): ${errorMsg}`;
        appError.Message = retMsg;
        appError.MessageId = msgId;

        return of(appError);
    }

    getDownloadPDFPreview(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    //To get company, app logos and header text
    getHeaderLogosAndtext(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getSignerSignDocumentData(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getIPAddress() {
        return this.http.get("http://api.ipify.org/?format=json");
    }

    getDecryptSigningData(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getDeclineTemplate(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    updatedEvelopeStatusbySigner(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    changeSigner(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    finishLater(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getValidatePassword(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getEncryptQueryString(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getReviewFilesDownload(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getDownloadFinalContract(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getDownloadDisclaimerPDF(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    convertTextToSignImage(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    ConvertHandDrawnSignImage(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    convertHandDrawnSignImageUpload(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    submitEnvelope(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    uploadSignerSignature(url: any, paramObj: any) {
        //No need to pass headers because file will handle
        return this.http.post(this.baseUrl + url, paramObj).pipe(
            catchError(this.handleError)
        );
    }

    getLanguageTranslations(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getDownloadFileReview(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getAdditonalfileAttachmentInfo(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getUploadSignerAttachments(url: any, paramObj: any) {
        //No need to pass headers because file will handle
        return this.http.post(this.baseUrl + url, paramObj).pipe(
            catchError(this.handleError)
        );
    }

    getDeleteNewlyAddedUpload(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getDeleteRequestedUploads(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getSaveAttachmentRequest(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getDeleteAndUpdateSignerDocFromDirectory(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getDeleteSignerDocFromDirectory(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    validateRecipientBySecurityCode(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    getRedirectURL(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    uploadDriveSignerAttachmentsUrl(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }

    postcall(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(this.baseUrl + url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }
    getcall(url: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.get(this.baseUrl + url, { headers }).pipe(
            catchError(this.handleError)
        );
    }
    validateBotIp(url: any, paramObj: any) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })
        return this.http.post(url, paramObj, { headers }).pipe(
            catchError(this.handleError)
        );
    }
    getEnvelopeCode(url: any) {
        return this.http.get(this.baseUrl + url).pipe(
            catchError(this.handleError)
        );
    }
}
