import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants, RouteUrls } from '../../constants/common-constants';
import { SignerLandingService } from '../../services/signer-landing.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { ImageService } from 'src/app/services/image.service';
import { CommonService } from 'src/app/services/common.service';
import { ResourceKeys } from 'src/app/constants/common-constants';
import { TranslationsService } from 'src/app/services/translations.service';

@Component({
  selector: 'app-signer-intermediate-close',
  templateUrl: './signer-intermediate-close.component.html',
  styleUrls: ['./signer-intermediate-close.component.scss']
})
export class SignerIntermediateCloseComponent implements OnInit {
  emailAddress: any;
  signerRegObject: any;
  returnUrl: any;
  uiRedirectUrl: any = environment.uiBaseUrl;
  domainUrl: any;
  showLoader: boolean = false;
  MEmail: any;
  spEmail: any;
  isSignedDocumentService: boolean = false
  toastrMessage: any;
  resourceKeys = ResourceKeys;

  constructor( public activeModal: NgbActiveModal,
    private router: Router,
    private commonService: CommonService,
    private toastr: ToastrService,
    private signerLandingService: SignerLandingService,
    private imageService: ImageService,
    private translationsService: TranslationsService) { }

    ngOnInit(): void {
      let encodedEmail:any = localStorage.getItem('EmailId')
      const decodedEmail = decodeURIComponent(encodedEmail);
      this.emailAddress = decodedEmail;
      localStorage.setItem('EmailId', decodedEmail);
      this.signerRegObject = this;
      this.returnUrl = this.signerRegObject.modalPopupObj.returnUrl;
      this.MEmail = this.signerRegObject.modalPopupObj.MEmail;
      this.spEmail = this.signerRegObject.modalPopupObj.spEmail;

      // if (this.emailAddress == null || this.emailAddress == "null" || this.emailAddress == "" || this.emailAddress == undefined)
      //     this.emailAddress = this.MEmail;

      // if (this.emailAddress == null || this.emailAddress == "null" || this.emailAddress == "" || this.emailAddress == undefined) {
      //     let divRegisterEmail: any = document.getElementById("divRegisterEmail")!;
      //     divRegisterEmail.style.visibility = 'collapse';
      // }
  }

  close() {
      this.activeModal.dismiss('Cross click');    
      this.commonService.saveSignerProfileDetailsToSession(); 
      this.commonService.getReturnUrlDirection(this.returnUrl);
  }

  getLanguageTranslationValue(resourcekeyId: any) {
      return this.translationsService.getLanguageTranslationVal(resourcekeyId);
  }

}

