import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants } from '../constants/common-constants';
import { SignerLandingService } from './signer-landing.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root'
})

export class ClickToSignService {
    webOrMobile: any = 'web';
    isSignatureTyped: any = "";
    isInitialChanged: any = "";
    constructor(private router: Router, private signerLandingService: SignerLandingService, private toastr: ToastrService, private commonService: CommonService,) {
        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.webOrMobile = 'mobile';

        }
        else {
            this.webOrMobile = 'web';
        }
    }

    getCurrentSessionUserDtls() {
        let profileDetails = [];
        let signerProfileDetails: any = localStorage.getItem("SignerProfile");
        if (signerProfileDetails && signerProfileDetails != "[object Object]" && signerProfileDetails != "null" && signerProfileDetails != "undefined" && signerProfileDetails != "[]") {
            profileDetails = JSON.parse(signerProfileDetails);
            if (profileDetails != null && profileDetails.length > 0 && !profileDetails[0].hasOwnProperty("signerProfileName")) {
                profileDetails = [];
            }
        }
        return profileDetails;
    }

    generateClickToSignObj(documentControlsResponse: any) {
        let userData: any = documentControlsResponse.userdata;
        var profileDetails = this.getCurrentSessionUserDtls();
        if (userData && userData.UserSignatureImgSrcWithoutStamp) {
            this.updateHandDrawnUploadSignature(CommonConstants.ConvertHandDrawnSignImageUpload, profileDetails, documentControlsResponse, userData.UserSignatureImgSrcWithoutStamp, "1");
        }
        else {
            this.updateProfileSignature(userData.UserName, profileDetails, documentControlsResponse);
        }

        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        txtsignerProfileName.value = userData.UserName;
        let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
        if (userData.UserName && userData.UserName != '' && userData.UserName != null) {
            if (userData.UserInitials && userData.UserInitials != "" && userData.UserInitials != null)
                txtsignerProfileInitials.value = userData.UserInitials;
            else
                txtsignerProfileInitials.value = ((userData.UserName).match(/\b(\w)/g).join('')).substring(0, 4);
            this.UpdateInitialsimageFromSignerProfileText();
        }
    }

    updateProfileSignature(nameVal: any, profileDetails: any, documentControlsResponse: any) {
        let fontType: any = profileDetails[0].FontType;
        let fontColor: any = profileDetails[0].FontColor;
        let requestSigObj = {
            text: nameVal.trim(),
            font: (fontType == null || fontType == "" || fontType == undefined || fontType == "null") ? "HomemadeApple" : fontType,
            fontColor: (fontColor == null || fontColor == "" || fontColor == undefined || fontColor == "null") ? "#000000" : fontColor,
            fontsize: "13px",
            height: "13",
            width: "13",
            envelopeCode: localStorage.getItem("eDisplayCode"),
            electronicSignIndicationId: documentControlsResponse.EnvelopeInfo.ElectronicSignIndication,
            dateFormat: documentControlsResponse.DateFormat,
            userTimezone: documentControlsResponse.EnvelopeInfo.TimeZoneSettingOptionValue,
            dateFormatID: documentControlsResponse.EnvelopeInfo.DateFormatID
        };
        let sigUrl = CommonConstants.ConvertTextToSignImage;
        this.signerLandingService.convertTextToSignImage(sigUrl, requestSigObj).subscribe(
            (resp: any) => {
                if (resp && resp.StatusCode == 200) {
                    this.updatePopUpdata(resp, profileDetails);
                }
            });
    }

    UpdateInitialsimageFromSignerProfileText() {
        let imgProfNewInitials: any = document.getElementById("imgProfNewInitials");
        let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
        let txtInitialVal = txtsignerProfileInitials.value;
        if (this.isNullOrEmptyCheck(txtInitialVal.trim()) && txtInitialVal.trim().length >= 1 && txtInitialVal.trim().length < 5) {
            let requestObj = {
                text: txtInitialVal.trim(),
                font: "DancingScript",
                fontsize: "13px",
                fontColor: "#000000",
                height: "13",
                width: "13"
            };

            let url = CommonConstants.ConvertTextToSignImage;
            this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
                (resp: any) => {
                    if (resp && resp.StatusCode == 200) {
                        if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
                            imgProfNewInitials.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                            var profileDetails = this.getCurrentSessionUserDtls();
                            if (profileDetails != null && profileDetails.length > 0) {
                                profileDetails[0].imgProfNewInitialssrc = resp.ResponseConvertTextToSignImage.imgsrc;
                                profileDetails[0].signerProfileInitials = txtInitialVal.trim();
                                localStorage.setItem("SignerProfile", '');
                                localStorage.setItem("SignerProfile", JSON.stringify(profileDetails));
                            }
                            imgProfNewInitials.style['height'] = "98px";
                        }
                    }
                });
        }
        else {
            if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
                imgProfNewInitials.setAttribute("src", "");
                imgProfNewInitials.style['height'] = "98px";
            }
        }
    }

    updatePopUpdata(resp: any, profileDetails: any) {
        var profileDetails = this.getCurrentSessionUserDtls();
        let imgProfileSignature: any = document.getElementById("imgProfileSignature");
        if (imgProfileSignature != undefined && imgProfileSignature != null && resp.ResponseConvertTextToSignImage && resp.ResponseConvertTextToSignImage.imgsrc != "") {
            imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
            profileDetails[0].imgProfileSignaturerc = resp.ResponseConvertTextToSignImage.imgsrc;
            if (this.webOrMobile == 'mobile') {
                imgProfileSignature.style['padding-bottom'] = "15px";
            } else {
                imgProfileSignature.style['padding-bottom'] = "10px";
            }
            localStorage.setItem("SignerProfile", '');
            localStorage.setItem("SignerProfile", JSON.stringify(profileDetails));
        }

        let imgProfNewInitials: any = document.getElementById("imgProfNewInitials");
        if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
            imgProfNewInitials.setAttribute("src", profileDetails[0].imgProfNewInitialssrc);
            if (this.webOrMobile == 'mobile') {
                imgProfNewInitials.style['padding-bottom'] = "15px";
            } else {
                imgProfNewInitials.style['padding-bottom'] = "10px";
            }
        }

        this.updateSignerProfilePopupheight();
    }

    updateSignerProfilePopupheight() {
        if (this.webOrMobile == 'mobile') {
            setTimeout(() => {
                let clickToSign: any = document.getElementById("clickToSign");
                clickToSign.scrollTop = clickToSign.scrollHeight - clickToSign.clientHeight;
            }, 100);
        }
    }



    updateHandDrawnUploadSignature(url: any, profileDetails: any, documentControlsResponse: any, userSigImage: any, type: any) {
        if (type == "1") {
            let base64Data: any = userSigImage;
            let requestObj = {
                imageBytes: base64Data,
                envelopeCode: localStorage.getItem("eDisplayCode"),
                electronicSignIndicationId: documentControlsResponse.EnvelopeInfo.ElectronicSignIndication,
                dateFormat: documentControlsResponse.DateFormat,
                userTimezone: documentControlsResponse.EnvelopeInfo.TimeZoneSettingOptionValue,
                dateFormatID: documentControlsResponse.EnvelopeInfo.DateFormatID
            };
            this.signerLandingService.ConvertHandDrawnSignImage(url, requestObj).subscribe(
                (resp: any) => {
                    if (resp && resp.StatusCode == 200) {
                        this.updatePopUpdata(resp, profileDetails);
                    }
                });
        }
        else {
            if (profileDetails[0].SigHandUpload == null || profileDetails[0].SigHandUpload == "" || profileDetails[0].SigHandUpload == undefined) {
                var profileDetails = this.getCurrentSessionUserDtls();
                let imgProfileSignature: any = document.getElementById("imgProfileSignature");
                if (imgProfileSignature != undefined && imgProfileSignature != null && profileDetails[0].imgProfileSignaturerc != "") {
                    imgProfileSignature.setAttribute("src", profileDetails[0].imgProfileSignaturerc);
                }
            }
            else {
                let base64Data: any = profileDetails[0].SigHandUpload;
                let requestObj = {
                    imageBytes: base64Data,
                    envelopeCode: localStorage.getItem("eDisplayCode"),
                    electronicSignIndicationId: documentControlsResponse.EnvelopeInfo.ElectronicSignIndication,
                    dateFormat: documentControlsResponse.DateFormat,
                    userTimezone: documentControlsResponse.EnvelopeInfo.TimeZoneSettingOptionValue,
                    dateFormatID: documentControlsResponse.EnvelopeInfo.DateFormatID
                };
                this.signerLandingService.ConvertHandDrawnSignImage(url, requestObj).subscribe(
                    (resp: any) => {
                        if (resp && resp.StatusCode == 200) {
                            this.updatePopUpdata(resp, profileDetails);
                        }
                    });
            }
        }
    }

    UpdateSignimageFromSignerProfileText(documentControlsResponse: any, val: any) {
        let imgProfileSignature: any = document.getElementById("imgProfileSignature");
        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        let txtSignVal = txtsignerProfileName.value;
        let userData: any = documentControlsResponse.userdata;
        let userInitialsImgSrc: any = userData.UserInitialsImgSrc;
        if (this.isNullOrEmptyCheck(txtSignVal.trim()) && txtSignVal.trim().length >= 1 && txtSignVal.trim().length < 51) {
            //Call API ConvertTextToSignImage
            let requestObj = {
                text: txtSignVal.trim(),
                font: "HomemadeApple",
                fontsize: "13px",
                fontColor: "#000000",
                height: "13",
                width: "13",
                envelopeCode: localStorage.getItem("eDisplayCode"),
                electronicSignIndicationId: documentControlsResponse.EnvelopeInfo.ElectronicSignIndication,
                dateFormat: documentControlsResponse.DateFormat,
                userTimezone: documentControlsResponse.EnvelopeInfo.TimeZoneSettingOptionValue,
                dateFormatID: documentControlsResponse.EnvelopeInfo.DateFormatID
            };

            let url = CommonConstants.ConvertTextToSignImage;
            this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
                (resp: any) => {
                    if (resp && resp.StatusCode == 200) {
                        if (imgProfileSignature != undefined && imgProfileSignature != null) {
                            let respImage: any;
                            if (val === 0) {
                                if (userInitialsImgSrc === null || userInitialsImgSrc === undefined) {
                                    respImage = resp.ResponseConvertTextToSignImage.imgsrc;
                                    imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                                } else {
                                    respImage = userInitialsImgSrc;
                                    imgProfileSignature.setAttribute("src", userInitialsImgSrc);
                                }
                            } else {
                                respImage = resp.ResponseConvertTextToSignImage.imgsrc;
                                imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                            }

                            var profileDetails = this.getCurrentSessionUserDtls();
                            if (profileDetails != null && profileDetails.length > 0) {
                                profileDetails[0].imgProfileSignaturerc = resp.ResponseConvertTextToSignImage.imgsrc;
                                profileDetails[0].signerProfileName = txtSignVal.trim();
                                localStorage.setItem("SignerProfile", '');
                                localStorage.setItem("SignerProfile", JSON.stringify(profileDetails));
                            }

                            imgProfileSignature.style['height'] = "98px";
                            if (this.webOrMobile == 'mobile') {
                                imgProfileSignature.style['padding-bottom'] = "15px";
                                imgProfileSignature.style['height'] = "88px";
                            } else {
                                imgProfileSignature.style['padding-bottom'] = "10px";
                                imgProfileSignature.style['height'] = "98px";
                            }
                        }
                    }
                });
        }
        else {
            if (imgProfileSignature != undefined && imgProfileSignature != null) {
                imgProfileSignature.setAttribute("src", "../../../assets/images/lisa.png");
                imgProfileSignature.style['height'] = "98px";
            }
        }
    }

    UpdateSigAndInitialProfileText(documentControlsResponse: any, val: any) {
        localStorage.setItem('isUploadImgFlag', '0');
        this.UpdateSignimageFromSignerProfileText(documentControlsResponse, val);
        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        let txtProfileName = txtsignerProfileName.value.trim();
        let initialData = '';
        if (txtProfileName && txtProfileName != '') {
            initialData = ((txtProfileName).match(/\b(\w)/g).join('')).substring(0, 4);
            let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
            txtsignerProfileInitials.value = initialData.trim();
            this.UpdateInitialsimageFromSignerProfileText();
            if (val == "1") {
                this.isSignatureTyped = "1";
                this.isInitialChanged = "1";
            }
            else {
                this.isSignatureTyped = "";
                this.isInitialChanged = "";
            }
        }
    }

    isNullOrEmptyCheck(stringValueFlag: any) {
        let boolFlag = false;
        if (typeof stringValueFlag != 'undefined' && stringValueFlag !== '' && stringValueFlag !== null && stringValueFlag !== 'false') {
            boolFlag = true;
        }
        return boolFlag;
    }

    addModelPopupClassForBodyTag() {
        let bodyEle: any = document.getElementsByTagName('body');
        if (bodyEle && bodyEle[0].classList) {
            bodyEle[0].classList.add('model-popup', 'overflowHidden');
        }
    }

    bindsignerPopupDtls(documentControlsResponse: any) {
        var profileDetails = this.getCurrentSessionUserDtls();
        let userData: any = documentControlsResponse.userdata;
        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");

        if (profileDetails[0].signerProfileName)
            txtsignerProfileName.value = profileDetails[0].signerProfileName.trim();
        else txtsignerProfileName.value = "";

        if (profileDetails[0].signerProfileInitials)
            txtsignerProfileInitials.value = profileDetails[0].signerProfileInitials.trim();
        else txtsignerProfileInitials.value = "";

        if (txtsignerProfileInitials.value == "") {
            if (txtsignerProfileName.value) {
                txtsignerProfileInitials.value = ((txtsignerProfileName.value).match(/\b(\w)/g).join('')).substring(0, 4);;
            }
        }

        if (profileDetails && profileDetails.length > 0) {
            let isStatic: any = localStorage.getItem("isStatic");
            if (isStatic && isStatic.toLowerCase() == "false") profileDetails = profileDetails.filter((element: any) => element.recipientEmailId == localStorage.getItem("recipientEmail"));
            else profileDetails = profileDetails.filter((element: any) => element.recipientEmailId == "Static" || element.recipientEmailId == localStorage.getItem("recipientEmail"));

            if (profileDetails && profileDetails.length > 0) {
                this.addModelPopupClassForBodyTag();
                let tabType: any = profileDetails[0].SignatureType;
                if (tabType == "1" || tabType == "") {
                    if (tabType == "") {
                        if (userData && userData.UserSignatureImgSrcWithoutStamp) {
                            this.updateHandDrawnUploadSignature(CommonConstants.ConvertHandDrawnSignImageUpload, profileDetails, documentControlsResponse, userData.UserSignatureImgSrcWithoutStamp, "1");
                        }
                        else{
                            this.updateProfileSignature(txtsignerProfileName.value, profileDetails, documentControlsResponse);
                        }                      
                    }
                    else {
                        if (profileDetails[0].imgProfileSignaturerc != "") {                           
                            this.updateProfileSignature(txtsignerProfileName.value, profileDetails, documentControlsResponse);
                        }
                        else if (userData && userData.UserSignatureImgSrcWithoutStamp) {
                            this.updateHandDrawnUploadSignature(CommonConstants.ConvertHandDrawnSignImageUpload, profileDetails, documentControlsResponse, userData.UserSignatureImgSrcWithoutStamp, "1");
                        }
                        else {
                            this.updateProfileSignature(txtsignerProfileName.value, profileDetails, documentControlsResponse);
                        }
                    }
                }
                else if (tabType == "2" || tabType == "3") {
                    profileDetails[0].SignatureType = tabType;
                    localStorage.setItem("SignerProfile", '');
                    localStorage.setItem("SignerProfile", JSON.stringify(profileDetails));
                    this.updateHandDrawnUploadSignature(tabType == "2" ? CommonConstants.ConvertHandDrawnSignImage : CommonConstants.ConvertHandDrawnSignImageUpload, profileDetails, documentControlsResponse, userData.UserSignatureImgSrcWithoutStamp, "2");
                }

                let tabInitialType: any = profileDetails[0].InitialType;
                if (tabInitialType == "1" || tabInitialType == "") {
                    this.UpdateInitialsimageFromSignerProfileText();
                }
            }
            else {
                this.generateClickToSignObj(documentControlsResponse);
            }
        }
        else {
            this.generateClickToSignObj(documentControlsResponse);
        }
    }

    setSessionUserProfileDetails(AddEditClicked: any, sigHandUpload: any, IsStaticTemplate: any, isSignerpopupCount: any) {
        let chkterms: any = document.getElementById("chkterms");
        let imgProfileSignature: any = document.getElementById("imgProfileSignature");
        let imgProfNewInitials: any = document.getElementById("imgProfNewInitials");
        let txtsignerProfile: any = document.getElementById("txtsignerProfileName");
        let txtsignerProfileName = txtsignerProfile.value.trim();

        let txtsignerProfileInitial: any = document.getElementById("txtsignerProfileInitials");
        let txtsignerProfileInitials = txtsignerProfileInitial.value.trim();

        let isTermsChecked = chkterms.attributes["disabled"].value;
        let signSrc = imgProfileSignature.attributes["src"].value;
        let initialSrc = imgProfNewInitials.attributes["src"].value;
        let isTermsAndConditionsChecked: boolean = false;

        if (isTermsChecked != "disabled") {
            this.commonService.showToaster('Please select terms and conditions.', 'Warning'); return;
        }
        else {
            isTermsAndConditionsChecked = true;
        }

        if (signSrc == "" || signSrc == undefined || signSrc == null || signSrc.indexOf("lisa.png") != -1) {
            this.commonService.showToaster('Please enter Signature.', 'Warning'); return;
        }

        if (initialSrc == "" || initialSrc == undefined || initialSrc == null) {
            this.commonService.showToaster('Please enter Initials.', 'Warning'); return;
        }

        let valueToPush: any = {};
        let StorageValues = [];
        let signerTimeStamp: any = this.commonService.getCookie("signerProfileTimeStamp");
        if (!signerTimeStamp || !this.commonService.isNumber(signerTimeStamp)) {
            signerTimeStamp = new Date().valueOf();
            sessionStorage.setItem("signerProfileTimeStamp", signerTimeStamp);
            this.commonService.setCookie("signerProfileTimeStamp", signerTimeStamp, 14);
        }

        valueToPush.signerProfileName = txtsignerProfileName.trim();
        valueToPush.signerProfileInitials = txtsignerProfileInitials.trim();
        valueToPush.imgProfileSignaturerc = signSrc;
        valueToPush.imgProfNewInitialssrc = initialSrc;
        valueToPush.checkTermsAndCond = isTermsAndConditionsChecked;
        valueToPush.signerProfileTimeStamp = signerTimeStamp;
        valueToPush.decline = 0;
        valueToPush.AcceptandSign = 1;

        valueToPush.FontType = sessionStorage.getItem("selectedFont") == null ? "HomemadeApple" : sessionStorage.getItem("selectedFont");
        valueToPush.FontColor = sessionStorage.getItem("signFontColor") == null ? "#000000" : sessionStorage.getItem("signFontColor");

        let signatureTypeID: any = localStorage.getItem('signatureTypeID');
        let initialTypeID: any = localStorage.getItem('InitialTypeID');

        var profileDetails = this.getCurrentSessionUserDtls();
        if (signatureTypeID == null) {
            // if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].SignatureType != undefined &&
            //     profileDetails[0].SignatureType != "" && profileDetails[0].SignatureType != null) {
            //     valueToPush.SignatureType = profileDetails[0].SignatureType;
            // }
            // else {
            valueToPush.SignatureType = "";
            //}
        }
        else {
            if (localStorage.getItem('signatureTypeID') == "05280C40-3DC8-479E-BD58-AE2FE0D62A97")
                valueToPush.SignatureType = "1";
            else if (localStorage.getItem('signatureTypeID') == "AEB3D4A0-9DCF-4441-98A8-8ECDB7169936")
                valueToPush.SignatureType = "2";
            else if (localStorage.getItem('signatureTypeID') == "8D122A62-56FB-4118-9140-FCFF5FD66AC5")
                valueToPush.SignatureType = "3";
            else valueToPush.SignatureType = "1";
        }

        if (initialTypeID == null) {
            // if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].initialTypeID != undefined &&
            //     profileDetails[0].initialTypeID != "" && profileDetails[0].initialTypeID != null) {
            //     valueToPush.InitialType = profileDetails[0].InitialType;
            // }
            // else {
            valueToPush.InitialType = "";
            //}
        }
        else {
            if (localStorage.getItem('InitialTypeID') == "05280C40-3DC8-479E-BD58-AE2FE0D62A97")
                valueToPush.InitialType = "1";
            else if (localStorage.getItem('InitialTypeID') == "AEB3D4A0-9DCF-4441-98A8-8ECDB7169936")
                valueToPush.InitialType = "2";
            else valueToPush.InitialType = "1";
        }

        if (valueToPush.SignatureType != "1" || AddEditClicked == "1") {
            valueToPush.FontColor = "";
            valueToPush.FontType = "";
            if (sigHandUpload == undefined || sigHandUpload == null) {

                if (this.isSignatureTyped == "1") {
                    valueToPush.SignatureType = "1";
                    valueToPush.InitialType = "1";
                    valueToPush.SigHandUpload = "";
                }
                else {
                    valueToPush.SigHandUpload = profileDetails && profileDetails[0] && profileDetails[0].SigHandUpload ? profileDetails[0].SigHandUpload : '';
                    valueToPush.SignatureType = profileDetails && profileDetails[0] ? profileDetails[0].SignatureType : "";
                    valueToPush.InitialType = profileDetails && profileDetails[0] ? profileDetails[0].InitialType : "";
                }

            }
            else {
                if (this.isSignatureTyped == "1") {
                    valueToPush.SignatureType = "1";
                    valueToPush.InitialType = "1";
                    valueToPush.SigHandUpload = "";
                }
                else valueToPush.SigHandUpload = sigHandUpload;
            }
        }

        if (valueToPush.SignatureType == "") {

        }

        this.isSignatureTyped = "";

        sessionStorage.removeItem("selectedFont");
        sessionStorage.removeItem("signFontColor");
        sessionStorage.removeItem("SignatureType");

        // let isStatic: any = localStorage.getItem("isStatic");
        // if (isStatic.length == 1 || isStatic.toLowerCase() == "false") {
        //     valueToPush.recipientEmailId = localStorage.getItem("recipientEmail");
        // }
        // else if (IsStaticTemplate == true || isStatic == "true") {
        //     valueToPush.recipientEmailId = localStorage.getItem("recipientEmail");
        // }
        valueToPush.recipientEmailId = localStorage.getItem("recipientEmail");

        StorageValues.push(valueToPush);
        localStorage.setItem("SignerProfile", '');
        localStorage.setItem("SignerProfile", JSON.stringify(StorageValues));

        if (sessionStorage.getItem('isSignerpopupCount') == null || sessionStorage.getItem('isSignerpopupCount') == undefined) {
            sessionStorage.setItem("isSignerpopupCount", isSignerpopupCount);
        }

        if (sessionStorage.getItem('isSignerpopupCount') != "0" && isSignerpopupCount != 0) {
            sessionStorage.setItem("isSignerpopupCount", isSignerpopupCount);
        }

        this.updateAllNameControlsValue(txtsignerProfileName);
        this.changeImageSrcForSignatureControls(StorageValues);
        this.changeImageSrcForInitialControls(StorageValues);

        this.isSignatureTyped == "";
        this.isInitialChanged == "";

    }

    updateAllNameControlsValue(profileName: any) {
        let nameControlsArray: string[] = [];
        let nameControlsRemoveArray: string[] = [];
        Array.from(document.getElementsByClassName("nameControlClass") as HTMLCollectionOf<HTMLElement>)
            .forEach((popUpItem: any) => {
                let controlHtmlID: any = popUpItem.attributes["Id"].value;
                let inputNameCtrl: any = document.getElementById("control_" + controlHtmlID);
                let cntType = popUpItem.attributes["data-ctntype"].value;
                if (cntType == "Name") {
                    if (profileName && profileName.trim() != '') {
                        inputNameCtrl.value = profileName;
                        inputNameCtrl.style['background'] = "none";
                        nameControlsArray.push(controlHtmlID);
                        popUpItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
                    }
                    else {
                        inputNameCtrl.value = '';
                        inputNameCtrl.style['background'] = "none";
                        nameControlsRemoveArray.push(controlHtmlID);
                        popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                    }
                }
            });


        if (nameControlsArray && nameControlsArray.length > 0) {
            for (let index = 0; index < nameControlsArray.length; index++) {
                const element = nameControlsArray[index];
                this.commonService.applyStylesToCheckList("add", element);
            }
        }
        if (nameControlsRemoveArray && nameControlsRemoveArray.length > 0) {
            for (let index = 0; index < nameControlsRemoveArray.length; index++) {
                const element = nameControlsRemoveArray[index];
                this.commonService.applyStylesToCheckList("remove", element);
            }
        }
    }

    changeImageSrcForInitialControls(StorageValues: any) {
        if (sessionStorage.length > 0 && sessionStorage.getItem('isSignerpopupCount') == "0") {
            let storevalues = StorageValues;
            let initialControlsArray: string[] = [];
            Array.from(document.getElementsByClassName("newInitialcommonSignatureControl") as HTMLCollectionOf<HTMLElement>)
                .forEach((popUpItem: any) => {
                    let controlHtmlID: any = popUpItem.attributes["Id"].value;
                    let initialCtrl: any = document.getElementById("control_" + controlHtmlID);
                    let cntType = popUpItem.attributes["data-ctntype"].value;
                    if (cntType == "NewInitials") {
                        let initialImageSrcAttr: any = initialCtrl.attributes["src"];
                        if (this.isNullOrEmptyCheck(initialImageSrcAttr)) {
                            initialCtrl.removeAttribute('src');

                            if (popUpItem && popUpItem.getElementsByClassName("additionalInfo").length > 0) {
                                popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            }

                            if (popUpItem && popUpItem.getElementsByClassName("newInitialsignature").length > 0) {
                                popUpItem.getElementsByClassName("newInitialsignature")[0].classList.add("updatenewInitial");

                                if (popUpItem.getElementsByClassName("signerControl").length > 0) {
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                                    // popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "#eef2f5";
                                }
                            }

                            if (popUpItem && popUpItem.getElementsByClassName("updatenewInitial").length > 0) {
                                popUpItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                            }
                        }
                        else {
                            if (popUpItem && popUpItem.getElementsByClassName("newInitialsignature").length > 0) {
                                popUpItem.getElementsByClassName("newInitialsignature")[0].classList.add("updatenewInitial");
                                if (popUpItem.getElementsByClassName("signerControl").length > 0) {
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "#eef2f5";
                                }
                            }
                        }
                        initialControlsArray.push(controlHtmlID);
                    }
                });

            if (initialControlsArray && initialControlsArray.length > 0) {
                for (let index = 0; index < initialControlsArray.length; index++) {
                    const element = initialControlsArray[index];
                    this.commonService.applyStylesToCheckList("remove", element);
                }
            }
        }

        let issignerpopupCount: any = sessionStorage.getItem('isSignerpopupCount');
        if (this.isNullOrEmptyCheck(issignerpopupCount)) {
            let issignerpopupCountVal: number = parseInt(issignerpopupCount);
            if (issignerpopupCountVal > 0) {
                let storevalues = StorageValues;
                let initialControlsArray: string[] = [];
                Array.from(document.getElementsByClassName("newInitialcommonSignatureControl") as HTMLCollectionOf<HTMLElement>)
                    .forEach((popUpItem: any) => {
                        let controlHtmlID: any = popUpItem.attributes["Id"].value;
                        let initialCtrl: any = document.getElementById("control_" + controlHtmlID);
                        let cntType = popUpItem.attributes["data-ctntype"].value;
                        if (cntType == "NewInitials") {
                            let initialImageSrcAttr: any = initialCtrl.attributes["src"];
                            if (this.isNullOrEmptyCheck(initialImageSrcAttr)) {
                                if (popUpItem && popUpItem.getElementsByClassName("updatenewInitial").length > 0) {
                                    popUpItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                                }

                                popUpItem.style['background'] = 'none';
                                initialCtrl.setAttribute("src", storevalues[0].imgProfNewInitialssrc);
                                initialCtrl.style['width'] = 'auto';
                                initialCtrl.style['height'] = "22px";
                                popUpItem.style['background'] = 'none';

                                if (popUpItem && popUpItem.getElementsByClassName("additionalInfo").length > 0) {
                                    popUpItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("newInitialsignature").length > 0) {
                                    popUpItem.getElementsByClassName("newInitialsignature")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("newInitialsignature")[0].classList.remove("updatenewInitial");
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("signerControl").length > 0) {
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                                }
                            }
                            initialControlsArray.push(controlHtmlID);
                        }
                    });

                if (initialControlsArray && initialControlsArray.length > 0) {
                    for (let index = 0; index < initialControlsArray.length; index++) {
                        const element = initialControlsArray[index];
                        this.commonService.applyStylesToCheckList("add", element);
                    }
                }
            }
        }
    }

    changeImageSrcForSignatureControls(StorageValues: any) {
        if (sessionStorage.length > 0 && sessionStorage.getItem('isSignerpopupCount') == "0") {
            let signatureControlsArray: string[] = [];
            Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
                .forEach((popUpItem: any) => {
                    let controlHtmlID: any = popUpItem.attributes["Id"].value;
                    let signatureCtrl: any = document.getElementById("control_" + controlHtmlID);
                    let cntType = popUpItem.attributes["data-ctntype"].value;
                    if (cntType == "Signature") {
                        let signatureImageSrcAttr: any = signatureCtrl.attributes["src"];
                        if (this.isNullOrEmptyCheck(signatureImageSrcAttr)) {
                            signatureCtrl.removeAttribute('src');
                            signatureCtrl.style['height'] = "";
                            signatureCtrl.style['width'] = "";

                            if (popUpItem && popUpItem.getElementsByClassName("signature").length > 0) {
                                popUpItem.getElementsByClassName("signature")[0].classList.remove("after_sign");
                                popUpItem.getElementsByClassName("signature")[0].removeAttribute('background');
                                popUpItem.getElementsByClassName("signature")[0].style["background"] = "";
                                popUpItem.getElementsByClassName("signature")[0].classList.add("updatesignature");
                                popUpItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                            }

                            if (popUpItem && popUpItem.getElementsByClassName("additionalInfo").length > 0) {
                                popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            }

                            if (popUpItem && popUpItem.getElementsByClassName("btn_sign").length > 0) {
                                popUpItem.getElementsByClassName("btn_sign")[0].style["background-color"] = "#eef2f5 !important";
                            }
                        }
                        else {
                            if (popUpItem && popUpItem.getElementsByClassName("signature").length > 0) {
                                popUpItem.getElementsByClassName("signature")[0].classList.add("updatesignature");
                                popUpItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                            }
                        }

                        if (popUpItem.getElementsByClassName("stampicon").length > 0) {
                            popUpItem.getElementsByClassName("stampicon")[0].classList.remove("filledControlsStamp");
                        }

                        signatureControlsArray.push(controlHtmlID);
                    }
                });

            if (signatureControlsArray && signatureControlsArray.length > 0) {
                for (let index = 0; index < signatureControlsArray.length; index++) {
                    const element = signatureControlsArray[index];
                    this.commonService.applyStylesToCheckList("remove", element);
                }
            }
        }

        let issignerpopupCount: any = sessionStorage.getItem('isSignerpopupCount');
        if (this.isNullOrEmptyCheck(issignerpopupCount)) {
            let issignerpopupCountVal: number = parseInt(issignerpopupCount);
            if (issignerpopupCountVal > 0) {
                let storevalues = StorageValues;
                let signatureControlsArray: string[] = [];
                Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
                    .forEach((popUpItem: any) => {
                        let controlHtmlID: any = popUpItem.attributes["Id"].value;
                        let signatureCtrl: any = document.getElementById("control_" + controlHtmlID);
                        let cntType = popUpItem.attributes["data-ctntype"].value;
                        if (cntType == "Signature") {
                            let signatureImageSrcAttr: any = signatureCtrl.attributes["src"];
                            if (this.isNullOrEmptyCheck(signatureImageSrcAttr)) {
                               // let height = window.getComputedStyle(signatureCtrl).height;
                               // let heightForSignImage = parseInt(height) - 2;
                                let imgProfileSignature: any = document.getElementById("imgProfileSignature");
                                let signSrc = imgProfileSignature.attributes["src"].value;
                                signatureCtrl.setAttribute("src", signSrc);
                                signatureCtrl.style['width'] = 'auto';
                                signatureCtrl.style['height'] = "34px";

                                if (popUpItem && popUpItem.getElementsByClassName("btn_sign").length > 0) {
                                    popUpItem.getElementsByClassName("btn_sign")[0].removeAttribute('padding-left');
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("commonSignatureControl").length > 0) {
                                    popUpItem.getElementsByClassName("commonSignatureControl")[0].style["background"] = "none";
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("signature").length > 0) {
                                    popUpItem.getElementsByClassName("signature")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("signature")[0].style["padding-left"] = "0px !important";
                                    popUpItem.getElementsByClassName("signature")[0].classList.add("after_sign");
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("additionalInfo").length > 0) {
                                    popUpItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
                                }

                                if (popUpItem.getElementsByClassName("requiredControlsStamp").length > 0) {
                                    popUpItem.getElementsByClassName("requiredControlsStamp")[0].classList.add("filledControlsStamp");
                                }
                            }
                            signatureControlsArray.push(controlHtmlID);
                        }
                    });

                if (signatureControlsArray && signatureControlsArray.length > 0) {
                    for (let index = 0; index < signatureControlsArray.length; index++) {
                        const element = signatureControlsArray[index];
                        this.commonService.applyStylesToCheckList("add", element);
                    }
                }
            }
        }

        let footerSignNav: any = document.getElementById("FooterSignNav");
        if (footerSignNav != undefined && footerSignNav != null) {
            let footerSignNavCol = footerSignNav.getElementsByTagName('img');
            if (footerSignNavCol && footerSignNavCol.length > 0) {
                footerSignNavCol[0].setAttribute("src", StorageValues[0].imgProfileSignaturerc);
                footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                this.commonService.applyStyleFordefaultSignNavControlIndicator();
            }
        }
    }

    removeSrcForSignatureControls() {
        let signatureControlsArray: string[] = [];
        Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((popUpItem: any) => {
                let controlHtmlID: any = popUpItem.attributes["Id"].value;
                let signatureCtrl: any = document.getElementById("control_" + controlHtmlID);
                let cntType = popUpItem.attributes["data-ctntype"].value;
                if (cntType == "Signature") {
                    let signatureImageSrcAttr: any = signatureCtrl.attributes["src"];
                    if (this.isNullOrEmptyCheck(signatureImageSrcAttr)) {
                        signatureCtrl.removeAttribute('src');
                        signatureCtrl.style['height'] = "";
                        signatureCtrl.style['width'] = "";

                        popUpItem.getElementsByClassName("signature")[0].classList.remove("after_sign");
                        popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        popUpItem.getElementsByClassName("signature")[0].removeAttribute('background');
                        popUpItem.getElementsByClassName("signature")[0].style["background"] = "";
                        popUpItem.getElementsByClassName("signature")[0].classList.add("updatesignature");
                        popUpItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                        popUpItem.getElementsByClassName("btn_sign")[0].style["background-color"] = "#eef2f5 !important";

                        if (popUpItem.getElementsByClassName("stampicon").length > 0) {
                            popUpItem.getElementsByClassName("stampicon")[0].classList.remove("filledControlsStamp");
                        }
                    }
                    else {
                        popUpItem.getElementsByClassName("signature")[0].classList.remove("after_sign");
                        popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        popUpItem.getElementsByClassName("signature")[0].removeAttribute('background');
                        popUpItem.getElementsByClassName("signature")[0].style["background"] = "";
                        popUpItem.getElementsByClassName("signature")[0].classList.add("updatesignature");
                        popUpItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                        popUpItem.getElementsByClassName("btn_sign")[0].style["background-color"] = "#eef2f5 !important";
                    }
                    signatureControlsArray.push(controlHtmlID);
                }
            });


        if (signatureControlsArray && signatureControlsArray.length > 0) {
            for (let index = 0; index < signatureControlsArray.length; index++) {
                const element = signatureControlsArray[index];
                this.commonService.applyStylesToCheckList("remove", element);
            }
        }
    }

    removeSrcForInitialControls() {
        let initialControlsArray: string[] = [];
        Array.from(document.getElementsByClassName("newInitialcommonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((popUpItem: any) => {
                let controlHtmlID: any = popUpItem.attributes["Id"].value;
                let initialCtrl: any = document.getElementById("control_" + controlHtmlID);
                let cntType = popUpItem.attributes["data-ctntype"].value;
                if (cntType == "NewInitials") {
                    let initialImageSrcAttr: any = initialCtrl.attributes["src"];
                    if (this.isNullOrEmptyCheck(initialImageSrcAttr)) {
                        initialCtrl.removeAttribute('src');
                        popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        popUpItem.getElementsByClassName("newInitialsignature")[0].classList.add("updatenewInitial");
                        popUpItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                    }
                    else {
                        popUpItem.getElementsByClassName("newInitialsignature")[0].classList.add("updatenewInitial");
                        if (popUpItem.getElementsByClassName("signerControl").length > 0) {
                            popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                            popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "#eef2f5";
                        }
                    }
                    initialControlsArray.push(controlHtmlID);
                }
            });


        if (initialControlsArray && initialControlsArray.length > 0) {
            for (let index = 0; index < initialControlsArray.length; index++) {
                const element = initialControlsArray[index];
                this.commonService.applyStylesToCheckList("remove", element);
            }
        }
    }

    onEnableAcceptAndSign(checkedVal: boolean) {
        let btnAccept: any = document.getElementById("btnAccept");
        if (checkedVal) {
            btnAccept?.removeAttribute('disabled');
        }
        else if (!checkedVal) {
            btnAccept?.setAttribute('disabled', 'disabled');
        }
    }

    onAcceptAndSign(AddEditClicked: any, sigHandUpload: any, IsStaticTemplate: any, isSignerpopupCount: any) {
        let chkterms: any = document.getElementById("chkterms");
        chkterms?.setAttribute('disabled', 'disabled');
        chkterms.style.cursor = "no-drop";
        localStorage.setItem("isDisabled", "true");
        localStorage.setItem("isAcceptAndSignClicked", "true");
        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        localStorage.setItem("txtsignerProfileNameValue", txtsignerProfileName.value);
        let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
        localStorage.setItem("txtsignerProfileInitialsValue", txtsignerProfileInitials.value);

        this.setSessionUserProfileDetails(AddEditClicked, sigHandUpload, IsStaticTemplate, isSignerpopupCount);
        this.commonService.removeModelPopupClassForBodyTag();
    }

    applyFooterNavSignature(controlSigImage: any) {
        let footerSignNav: any = document.getElementById("FooterSignNav");
        if (footerSignNav != undefined && footerSignNav != null) {
            let footerSignNavCol = footerSignNav.getElementsByTagName('img');
            footerSignNavCol[0].setAttribute("src", controlSigImage);
            footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
            footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
            this.commonService.applyStyleFordefaultSignNavControlIndicator();
        }
    }

    applySignImageToControl(controlSigImage: any, controlId: any) {
        let ctrlImageAttr: any = document.getElementById("control_" + controlId);
        if (ctrlImageAttr) {
            ctrlImageAttr.setAttribute("src", controlSigImage);
            ctrlImageAttr.style['height'] = "34px";
            ctrlImageAttr.style['width'] = "auto";
            let ctrlItem: any = document.getElementById(controlId);
            if (ctrlItem.getElementsByClassName("stampicon").length > 0) {
                ctrlItem.getElementsByClassName("stampicon")[0].classList.add("filledControlsStamp");
            }
            ctrlItem.getElementsByClassName("signature")[0].style["background"] = "none";
            ctrlItem.getElementsByClassName("signature")[0].style["padding-left"] = "0px !important";
            ctrlItem.getElementsByClassName("signature")[0].classList.add("after_sign");

            ctrlItem.getElementsByClassName("signature")[0].classList.remove("btn_sign");
            ctrlItem.getElementsByClassName("signature")[0].classList.remove("updatesignature");
            ctrlItem.getElementsByClassName("signerControl")[0].style["background"] = "none";

            ctrlItem.style['background'] = "none";
            ctrlItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
        }
    }

    applyInitialImageToControl(controlSigImage: any, controlId: any) {
        let ctrlImageAttr: any = document.getElementById("control_" + controlId);
        if (ctrlImageAttr) {
            ctrlImageAttr.setAttribute("src", controlSigImage);
            ctrlImageAttr.style['height'] = "22px";
            ctrlImageAttr.style['width'] = "auto";
            ctrlImageAttr.style['max-width'] = "100%";

            let ctrlItem: any = document.getElementById(controlId);
            ctrlItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
            ctrlItem.style['background'] = 'none';
            ctrlItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
            ctrlItem.getElementsByClassName("newInitialsignature")[0].style["background"] = "none";
            ctrlItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
            ctrlItem.getElementsByClassName("newInitialsignature")[0].classList.remove("updatenewInitial");
        }
    }
}
