import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'eSign';
  
  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
    console.log('Back button pressed');
    window.location.reload();
  }  

  ngOnInit() {
    
  }

}
