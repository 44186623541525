import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CommonConstants, EmailAddressFormat, MessageDeliveryModes, ReturnUrl, RouteUrls } from '../constants/common-constants';
import { ToastrService } from 'ngx-toastr';
import { DeclineModalComponent } from '../modal/decline-modal/decline-modal.component';
import { AttachmentsComponent } from '../modal/attachments/attachments.component';
import { InviteSignersComponent } from '../modal/invite-signers/invite-signers.component';
import { ChangeSignerModalComponent } from '../modal/change-signer-modal/change-signer-modal.component';
import { StaticDeclineModalComponent } from '../modal/static-decline-modal/static-decline-modal.component';
import * as moment from 'moment';
import { ExpandableService } from './expandable.service';
import { TranslationsService } from './translations.service';
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    uservalue: any = 1;
    currentPinchZoomScale: any;
    documentData = new Subject<any>();
    userSource$ = new Subject<any>();
    currentPinchZoomScale$ = new Subject<any>();
    requiredFlag: boolean = false;
    fieldsRemainingFlag: boolean = false;
    requiredFields: string = 'Please enter required fields.';
    invalidEmail: string = "Invalid email address.";
    invalidDate: string = 'Invalid date.';
    invlaidSSN: string = 'Invalid ssn.';
    invlaidZIP: string = 'Invalid Zip.';
    private callHeaderCompMethodSubject = new Subject<void>();
    callHeaderCompMethod$ = this.callHeaderCompMethodSubject.asObservable();
    requiredField: string = '';
    lang_warning: string = '';

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private expandableService: ExpandableService,
        private TranslationService : TranslationsService
    ) {
        this.documentData.next(1);
        this.requiredField = this.TranslationService.getLanguageValidationVal('PleaseEnterRequiredFields');
        this.lang_warning = this.TranslationService.getLanguageValidationVal("lang_warning");
    }

    callHostListenerEvent(e:any,webOrMobile:any){
        this.isCheckDatePopupOpen(webOrMobile);

        if (e.textContent != "" || e.title != "") {
            if (e.textContent.trim() == "zoom_in" || e.textContent.trim() == "zoom_out" || e.textContent.trim() == "zoomin" || e.textContent.trim() == "zoomout") {
                let btnmore_horiz: any = document.getElementById('more');
                btnmore_horiz.classList.remove("show");
                let btnattachement: any = document.getElementById('attachement');
                if (btnattachement) {
                    btnattachement.classList.remove("show");
                }
            }
            else if (e.textContent.trim() == "more_horiz" || e.title.trim() == "more_horiz") {
                let btnZoom: any = document.getElementById('zoom');
                btnZoom.classList.remove("show");
                let btnattachement: any = document.getElementById('attachement');
                if (btnattachement) {
                    btnattachement.classList.remove("show");
                }
            }
            else if (e.textContent.trim() == "View Attachments" || e.title.trim() == "View Attachments" || e.title.trim() == "Attachment") {
                let btnZoom: any = document.getElementById('zoom');
                btnZoom.classList.remove("show");
                let btnmore_horiz: any = document.getElementById('more');
                btnmore_horiz.classList.remove("show");
            }
            else if (e.textContent.trim() == "download" || e.title.trim() == "Click here to download files to review" || e.title.trim() == "Files for Review") {
                this.hideOpenedSection();
            }
            else if (e.textContent.trim() == "onPageSelection" || e.textContent.trim() == "navigate_next" || e.textContent.trim() == "chevron_left"
                || e.textContent.trim() == "first_page" || e.textContent.trim() == "last_page" || e.textContent.trim() == "format_list_bulleted"
                || e.textContent.trim() == "Preview" || e.title.trim() == "Filter") {
                this.hideOpenedSection();
            }
            else if (e.textContent.trim() == "Add/Edit Signature" || e.textContent.trim() == "Add Attachments" || e.textContent.trim() == "Attachments"
                || e.textContent.trim() == "Decline" || e.textContent.trim() == "Change Signer" ||
                e.textContent.trim() == "Signup-ROne" || e.textContent.trim() == "Finish Later") {
                let btnmore_horiz: any = document.getElementById('more');
                btnmore_horiz.classList.remove("show");
            }
            else if (e.textContent.trim() == 'filter_list') {
                if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                    const offcanvas = document.getElementById('filter')!;
                    const computedStyles = window.getComputedStyle(offcanvas);

                    // Check the visibility of the offcanvas element by inspecting its 'visibility' property
                    const visibilityValue = computedStyles.getPropertyValue('visibility');

                    if (visibilityValue === 'visible') {
                        let bodyTag: any = document.getElementsByTagName('body');
                        if (bodyTag && bodyTag[0])
                            bodyTag[0].style.position = 'fixed';
                    }
                }
            }
        }
        else {
            this.hideOpenedSection();
            if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                this.removeBodyTagPositionFixed();

                let otherActionOffcanvas: any = document.getElementById('demo')!;
                let eleRef: any;
                if (otherActionOffcanvas) {
                    eleRef = otherActionOffcanvas.classList.contains('showing');
                }
                if (eleRef) {
                    let bodyTag: any = document.getElementsByTagName('body');
                    if (bodyTag && bodyTag[0])
                        bodyTag[0].style.position = 'fixed';
                } else {
                    this.removeBodyTagPositionFixed();
                }
            }
        }
    }

    removeBodyTagPositionFixed() {
        //for mobile, if user toggle the filter section and other actions
        let bodyTag: any = document.getElementsByTagName('body');
        if (bodyTag && bodyTag[0]) {
            bodyTag[0].style.position = '';
        }
    }

    hideOpenedSection() {
        let btnZoom: any = document.getElementById('zoom');
        btnZoom.classList.remove("show");
        let btnmore_horiz: any = document.getElementById('more');
        btnmore_horiz.classList.remove("show");
        let btnattachement: any = document.getElementById('attachement');
        if (btnattachement) {
            btnattachement.classList.remove("show");
        }
    }

    isCheckDatePopupOpen(webOrMobile:any) {
        if (webOrMobile === 'mobile') {
            setTimeout(() => {
                let prevNextBtnAlignELe: any = document.getElementById('prevNextBtnAlignMobileContainer');
                if (document.querySelectorAll('ngb-datepicker') && document.querySelectorAll('ngb-datepicker')[0] && document.querySelectorAll('ngb-datepicker')[0].classList.value === 'dropdown-menu show') {
                    prevNextBtnAlignELe.classList.add('iphone-date-popup-align');
                } else {
                    prevNextBtnAlignELe.classList.remove('iphone-date-popup-align');
                }
            }, 50);
        }
    }

    setUser(user: any): void {
        this.userSource$.next(user);
        this.uservalue = user;
    }

    getUser() {
        return this.uservalue;
    }

    setDocumentData(id_estado: any) {
        this.documentData.next(id_estado);
    }

    getCookie(cname: any) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie(cname: any, cvalue: any, exdays: any) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    checkCookie(cname: any) {
        var cookieExists = this.getCookie(cname);
        return (cookieExists != "");
    }

    deleteCookie(cname: any) {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    isNumber(str: string): boolean {
        if (typeof str !== 'string') return false;
        if (str.trim() === '') return false;
        return !Number.isNaN(Number(str));
    }

    isNumberExists(str: string): boolean {
        if (typeof str !== 'string') return false;
        if (str.trim() === '') return false;
        if (str.trim() === ' ') return false;
        if (/\s/.test(str)) return false;
        if (str.indexOf(".") >= 0) return false;
        return !Number.isNaN(Number(str));
    }

    b64toBlob(b64Data: any, contentType: any) {
        contentType = contentType || '';
        let sliceSize = 512;
        var byteCharacters = atob(b64Data);
        var byteArrays = [];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    getReturnUrlDirection(returnUrlValue: any) {
        let returnUrl: any = ReturnUrl.List;
        let filteredReturnUrl: any = returnUrl.filter((c: any) => c === returnUrlValue);
        if (filteredReturnUrl.length >= 1) {
            this.router.navigate([returnUrlValue]);
        } else {
            this.saveSignerProfileDetailsToSession();
            window.location.href = returnUrlValue;
        }
    }

    checkIsUrlExistsInBrowserMemory() {
        localStorage.setItem("DisplayMessage", CommonConstants.SessionExpirationMessage);
        localStorage.setItem("MessageType", "Warning");
        this.getReturnUrlDirection(RouteUrls.Info);
    }

    //Checking all pages are loaded or not
    loadImages(urls: string[]): Promise<boolean> {
        const imagePromises: Promise<boolean>[] = [];

        urls.forEach((url) => {
            const imagePromise = new Promise<boolean>((resolve) => {
                const image = new Image();
                image.onload = () => resolve(true);
                image.onerror = () => resolve(false);
                image.src = url;
            });

            imagePromises.push(imagePromise);
        });

        return Promise.all(imagePromises)
            .then((results) => results.every((result) => result));
    }

    setPinchZoomScale(scale: any): void {
        this.currentPinchZoomScale$.next(scale);
        this.currentPinchZoomScale = scale;
    }

    getPinchZoomScale() {
        return this.currentPinchZoomScale;
    }

    redirectTo(type: any) {
        switch (type) {
            case "Features":
                window.open("https://rsign.com/product/electronic-signature", "_blank");
                break;
            case "CustomerSuccess":
                window.open("https://rsign.com/customer-success", "_blank");
                break;
            case "Webinars":
                window.open("https://rsign.com/webinars", "_blank");
                break;
            case "Videos":
                window.open("https://rsign.com/product-video-playlist", "_blank");
                break;
            case "Apps":
                window.open("https://rsign.com/apps", "_blank");
                break;
            case "Blogs":
                window.open("https://rsign.com/blog", "_blank");
                break;
            case "News":
                window.open("https://rsign.com/news", "_blank");
                break;
            case "ContactUs":
                window.open("https://rsign.com/contact/questions", "_blank");
                break;
            case "Support":
                window.open("https://support.rpost.com/hc/en-us", "_blank");
                break;
        }
    }

    GenerateMaskedEmail(emailAddr: any) {
        let emailArray = emailAddr.split('@');
        let arrayfirstPartstr = emailArray[0];
        let arraylastPaststr = emailArray[1];
        var firstDigits = arrayfirstPartstr.substring(0, 3);
        var maskedString1 = firstDigits + "***" + "@";
        let lastDotPosition = arraylastPaststr.lastIndexOf('.');
        let firstLastPart = arraylastPaststr.substring(0, lastDotPosition);
        let firstLastPartDigits = firstLastPart.substring(0, 3);
        var maskedString2 = firstLastPartDigits + "***";
        let lastPart = arraylastPaststr.substring(lastDotPosition + 1);
        let maskedString3 = maskedString1 + maskedString2 + "." + lastPart;
        return maskedString3;
    }

    returnValBasedOnDeliveryMode(deliveryModeval: any) { //Email&Mobile
        if (deliveryModeval == 3 || deliveryModeval == 7 || deliveryModeval == 10 || deliveryModeval == 2 || deliveryModeval == 4 || deliveryModeval == 8) return true;
        else if (deliveryModeval == "3" || deliveryModeval == "7" || deliveryModeval == "10" || deliveryModeval == "2" || deliveryModeval == "4" || deliveryModeval == "8") return true;
        else return false;
    }
    returnValBasedOnDeliveryModeVal(deliveryModeval: any) { //Email
        if (deliveryModeval == 3 || deliveryModeval == 7 || deliveryModeval == 10 || deliveryModeval == 1 || deliveryModeval == 5 || deliveryModeval == 9) return true;
        else if (deliveryModeval == "3" || deliveryModeval == "7" || deliveryModeval == "10" || deliveryModeval == "1" || deliveryModeval == "5" || deliveryModeval == "9") return true;
        else return false;
    }


    returnEmailAndMobileDeliveryModeVal(deliveryMode: any) {
        if (deliveryMode == 2 || deliveryMode == 3 || deliveryMode == 5 || deliveryMode == 7 || deliveryMode == 8 || deliveryMode == 9 || deliveryMode == 10 || deliveryMode == 12) return true;
        else if (deliveryMode == "2" || deliveryMode == "3" || deliveryMode == "5" || deliveryMode == "7" || deliveryMode == " 8" || deliveryMode == "9" || deliveryMode == "10" || deliveryMode == "12") return true;
        else return false;
    }

    returnEmailBasedOnDeliveryModeVal(deliveryMode: any) {
        if (deliveryMode == 1 || deliveryMode == 11 || deliveryMode == "1" || deliveryMode == "11") return true;
        else return false;
    }

    returnMobileDeliveryModeVal(deliveryMode: any) {
        if (deliveryMode == 4 || deliveryMode == 6 || deliveryMode == "4" || deliveryMode == "6") return true;
        else return false;
    }

    returnShowEmailBasedOnDeliveryModeVal(deliveryMode: any) {
        if (deliveryMode == 2 || deliveryMode == 3 || deliveryMode == 5 || deliveryMode == 7 || deliveryMode == 8 || deliveryMode == 9 || deliveryMode == 10 || deliveryMode == 12 || deliveryMode == 1 || deliveryMode == 11) return true;
        else if (deliveryMode == "2" || deliveryMode == "3" || deliveryMode == "5" || deliveryMode == "7" || deliveryMode == " 8" || deliveryMode == "9" || deliveryMode == "10" || deliveryMode == "12" || deliveryMode == "1" || deliveryMode == "11") return true;
        else return false;
    }

    returnShowMobileDeliveryModeVal(deliveryMode: any) {
        if (deliveryMode == 2 || deliveryMode == 3 || deliveryMode == 5 || deliveryMode == 7 || deliveryMode == 8 || deliveryMode == 9 || deliveryMode == 10 || deliveryMode == 12 || deliveryMode == 4 || deliveryMode == 6) return true;
        else if (deliveryMode == "2" || deliveryMode == "3" || deliveryMode == "5" || deliveryMode == "7" || deliveryMode == " 8" || deliveryMode == "9" || deliveryMode == "10" || deliveryMode == "12" || deliveryMode == "4" || deliveryMode == "6") return true;
        else return false;
    }

    GetRecipientDeliveryMode(deliveryMode: any) {
        if (deliveryMode == 1 || deliveryMode == null) return MessageDeliveryModes.EmailSlashEmail;
        else if (deliveryMode == 2) return MessageDeliveryModes.EmailSlashMobile;
        else if (deliveryMode == 3) return MessageDeliveryModes.EmailSlashEmailAndMobile;
        else if (deliveryMode == 4) return MessageDeliveryModes.MobileSlashMobile;
        else if (deliveryMode == 5) return MessageDeliveryModes.MobileSlashEmail;
        else if (deliveryMode == 6) return MessageDeliveryModes.MobileSlashNone;
        else if (deliveryMode == 7) return MessageDeliveryModes.MobileSlashEmailAndMobile;
        else if (deliveryMode == 8) return MessageDeliveryModes.EmailAndMobileSlashMobile;
        else if (deliveryMode == 9) return MessageDeliveryModes.EmailAndMobileSlashEmail;
        else if (deliveryMode == 10) return MessageDeliveryModes.EmailAndMobileSlashEmailAndMobile;
        else if (deliveryMode == 11) return MessageDeliveryModes.EmailSlashNone;
        else if (deliveryMode == 12) return MessageDeliveryModes.EmailAndMobileSlashNone;
        else return MessageDeliveryModes.EmailSlashEmail;
    }

    returnCodeToolTipText(deliverymode: any) {
        var messageCodeText = "";
        switch (deliverymode) {
            case 1: case "1": messageCodeText = "Email / Email"; break;
            case 2: case "2": messageCodeText = "Email / Mobile"; break;
            case 3: case "3": messageCodeText = "Email / Email & Mobile"; break;
            case 4: case "4": messageCodeText = "Mobile / Mobile"; break;
            case 5: case "5": messageCodeText = "Mobile / Email"; break;
            case 6: case "6": messageCodeText = "Mobile / None"; break;
            case 7: case "7": messageCodeText = "Mobile / Email & Mobile"; break;
            case 8: case "8": messageCodeText = "Email & Mobile / Mobile"; break;
            case 9: case "9": messageCodeText = "Email & Mobile / Email"; break;
            case 10: case "10": messageCodeText = "Email & Mobile / Email & Mobile"; break;
            case 11: case "11": messageCodeText = "Email / None"; break;
            case 12: case "12": messageCodeText = "Email & Mobile / None"; break;
            default: messageCodeText = "Email / Email";
        }
        return messageCodeText;
    }

    saveSignerProfileDetailsToSession() {
        let signerProfileDetails: any = localStorage.getItem("SignerProfile");
        let autoLockRecpObj: any = localStorage.getItem("AutoLockRecpObj");
        let senderEmailId: any = localStorage.getItem("senderEmail");
        let header: any = localStorage.getItem("header");
        let footer: any = localStorage.getItem("footer");
        let brandName: any = localStorage.getItem("BrandName");
        localStorage.clear();
        let isFromSignMultiTemplate: any = sessionStorage.getItem("isFromSignMultiTemplate");
        sessionStorage.clear();
        localStorage.setItem("SignerProfile", signerProfileDetails);
        localStorage.setItem("AutoLockRecpObj", autoLockRecpObj);
        localStorage.setItem('header', header? header: '');
        localStorage.setItem('footer', footer? footer: '');
        localStorage.setItem('htmlFooterString', footer? footer: '');
        localStorage.setItem('senderEmail', senderEmailId? senderEmailId: '');
        localStorage.setItem('BrandName', brandName? brandName: '');
        if (isFromSignMultiTemplate == null) isFromSignMultiTemplate = "false";
        sessionStorage.setItem("isFromSignMultiTemplate", isFromSignMultiTemplate);
    }

    updateDefaultValueOnDateControl() {
        let inpDateControlCollection: any = document.getElementsByClassName("inpDateControl");
        if (inpDateControlCollection != null && inpDateControlCollection.length > 0) {
            Array.from(inpDateControlCollection).forEach((inpDateControl: any) => {
                let isdefaultrequired: any = inpDateControl.getAttribute('data-defaultrequired');
                if (isdefaultrequired == "true") {
                    let dataDefaultDate: any = inpDateControl.getAttribute('data-default-date');
                    let dateCtrlId: any = inpDateControl.getAttribute('id');
                    let dateCtrlIdEle: any = document.getElementById(dateCtrlId);
                    if (dateCtrlIdEle) {
                        dateCtrlIdEle.value = dataDefaultDate;
                        dateCtrlIdEle.style.border = '2px solid #38b16e';
                        this.applyStylesToCheckList("add", dateCtrlId.replace('control_', ''));
                    }
                }
            });
        }
    }

    applyStylesToCheckList(type: any, ControlHtmlID: any) {
        let currentLblControl: any = document.getElementById("label_" + ControlHtmlID);
        let currentLblIconControl: any = document.getElementById("iicon_" + ControlHtmlID);
        if (currentLblControl && currentLblIconControl && type == "remove") {
            currentLblControl.classList.remove("success-color", "success-icon");
            currentLblControl.classList.add("requried-color");
            currentLblControl.classList.add("requried-color1");
            currentLblControl.classList.add("requried-icon");
            currentLblIconControl.classList.remove("success-icon");
            currentLblIconControl.classList.add("requried-icon");
        }
        else if (currentLblControl && currentLblIconControl && type == "add") {
            currentLblControl.classList.remove("requried-color", "requried-color1", "requried-icon");
            currentLblControl.classList.add("success-color");
            currentLblControl.classList.add("success-icon");
            currentLblIconControl.classList.remove("requried-icon");
            currentLblIconControl.classList.add("success-icon");
        }
    }

    getOnlyControlsPendingCount(visibleControls: any) {
        let controls: any = visibleControls;
        let controlCollection: any = [];
        Array.from(controls).forEach((ctrlItem: any) => {
            let ctrlId = ctrlItem.attributes["id"].value;
            let controlType = ctrlItem.attributes["data-ctntype"].value;
            let ctrlReq: any;
            let ctrlValue: any;
            switch (controlType) {
                case "Signature":
                    let sigImageCollection = ctrlItem.getElementsByTagName('img');
                    ctrlValue = sigImageCollection[0].attributes["src"] != undefined ? sigImageCollection[0].attributes["src"].value : '';

                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
                case "NewInitials":
                    let initialImageCollection = ctrlItem.getElementsByTagName('img');
                    ctrlValue = initialImageCollection[0].attributes["src"] != undefined ? initialImageCollection[0].attributes["src"].value : '';
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
                case "Text":
                    let currentTextCtrl: any = document.getElementById("control_" + ctrlId);
                    let currentTextCtrlTitle: any = currentTextCtrl.attributes['data-title'] != undefined ? currentTextCtrl.attributes['data-title'].value : '';
                    if (currentTextCtrlTitle === 'Date') {
                        ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                    }
                    else if (currentTextCtrlTitle === 'Email') {
                        ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                    }
                    else {
                        ctrlValue = ctrlItem.getElementsByTagName("textarea") != undefined ? ctrlItem.getElementsByTagName("textarea")[0].value : '';
                    }

                    ctrlValue = ctrlValue != undefined ? ctrlValue : '';
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }

                    break;
                case "Email":
                    let currEmailCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currEmailCtrl.value != undefined ? currEmailCtrl.value : '';
                    ctrlValue = ctrlValue != undefined ? ctrlValue : '';

                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
                case "Name":
                case "Date":
                case "Initials":
                case "Title":
                case "Company":
                    let currCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currCtrl.value != undefined ? currCtrl.value : '';
                    ctrlValue = ctrlValue != undefined ? ctrlValue : ''
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
                case "Checkbox":
                    let curCheckboxctrl: any = document.getElementById("control_" + ctrlId);
                    let isChecked: string = "false";
                    isChecked = curCheckboxctrl.checked ? "true" : "false";
                    ctrlValue = isChecked;

                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined || ctrlValue == 'false') {
                        var name = (<HTMLInputElement>document.getElementById("control_" + ctrlId)).getAttribute("name");
                        if (name != "") {
                            if ($('.signerControl input:checkbox[name="' + name + '"]:checked').length == 0) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else {
                            controlCollection.push(ctrlId);
                        }
                    }
                    break;
                case "Radio":
                    let curRadioctrl: any = document.getElementById("control_" + ctrlId);
                    let isRadioChecked: string = "false";
                    isRadioChecked = curRadioctrl.checked ? "true" : "false";
                    ctrlValue = isRadioChecked;
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined || ctrlValue == 'false') {
                        var name = (<HTMLInputElement>document.getElementById("control_" + ctrlId)).getAttribute("name");
                        if (name != "" && $('.signerControl input:radio[name="' + name + '"]:checked').length == 0) {
                            controlCollection.push(ctrlId);
                        }
                    }
                    break;
                case "DropDown":
                    let currentdrpCtrl: any = (document.getElementById("control_" + ctrlId)) as HTMLSelectElement;
                    var sel = currentdrpCtrl.selectedIndex;
                    var opt = currentdrpCtrl.options[sel];
                    var curValue = (<HTMLSelectElement>opt).value;
                    ctrlValue = curValue;
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
            }
        });
        return controlCollection.length;
    }

    getFileNameIconFromExtension(fileName: any) {
        let returnExtn: any = '';
        if (fileName != null && fileName != "" && fileName != undefined) {
            var extn = fileName.substr((fileName.lastIndexOf('.') + 1));
            extn = (extn != null && extn != undefined) ? extn.toLowerCase() : '';
            switch (extn) {
                case "pdf":
                    returnExtn = "fa-file-pdf-o";
                    break;
                case "doc":
                case "docx":
                    returnExtn = "fa-file-word-o";
                    break;
                case "ppt":
                case "pptx":
                    returnExtn = "fa-file-powerpoint-o";
                    break;
                case "xls":
                case "xlsx":
                    returnExtn = "fa-file-excel-o";
                    break;
                case "ico":
                case "jpg":
                case "jpeg":
                case "png":
                case "tif":
                case "tiff":
                case "gif":
                case "bpg":
                    returnExtn = "fa-file-image-o";
                    break;
                case "txt":
                    returnExtn = "fa-file-text-o";
                    break;
                default:
                    returnExtn = "fa-file-o";
                    break;
            }
        }
        return returnExtn;
    }

    applyStyleFordefaultSignNavControlIndicator() {
        let defaultSignNavControlIndicator: any = document.getElementsByClassName("defaultSignNavControlIndicator");
        if (defaultSignNavControlIndicator != null && defaultSignNavControlIndicator.length > 0) {
            defaultSignNavControlIndicator[0].classList.remove("requried-color");
            defaultSignNavControlIndicator[0].classList.add("success-color");
        }

        let defaultSignNavControlIndicatorIcon: any = document.getElementsByClassName("defaultSignNavControlIndicatorIcon");
        if (defaultSignNavControlIndicatorIcon != null && defaultSignNavControlIndicatorIcon.length > 0) {
            defaultSignNavControlIndicatorIcon[0].classList.remove("requried-icon");
            defaultSignNavControlIndicatorIcon[0].classList.add("success-icon");
        }
    }

    updatedmobileControlPosition(id: any) {
        const zoomStyles: any = getComputedStyle(document.getElementsByClassName('document-controls-zoom')[0]);
        let zoomScale: any = zoomStyles.getPropertyValue('transform').match(/-?[\d\.]+/g)[0];
        let ele: any = document.getElementById(id.replace('control_', ''))!;
        let pageEle: any;
        let posControl: any = ele;
        let top: any = parseFloat(posControl.style.top.split('px')[0]) * parseFloat(zoomScale);
        let left: any = parseFloat(posControl.style.left.split('px')[0]) * parseFloat(zoomScale);
        $('html, body').animate({ scrollTop: top, scrollLeft: left }, 100);
    }

    removeModelPopupClassForBodyTag() {
        let bodyEle: any = document.getElementsByTagName('body');
        if (bodyEle && bodyEle[0].classList) {
            bodyEle[0].classList.remove('model-popup', 'overflowHidden');
        }
    }

    addModelPopupClassForBodyTag() {
        let bodyEle: any = document.getElementsByTagName('body');
        if (bodyEle && bodyEle[0].classList) {
            bodyEle[0].classList.add('model-popup', 'overflowHidden');
        }
    }

    getdefaultSelectedColor(isActiveFlag: any) {
        if (isActiveFlag === 1) {
            let prevPageEle: any = document.getElementById('pagePreview1');
            if (prevPageEle && prevPageEle.classList) {
                prevPageEle.classList.add('active-preview-card');
            }

            let prevPageCountEle: any = document.getElementById('pageCountColor1');
            if (prevPageCountEle && prevPageCountEle.classList) {
                prevPageCountEle.classList.add('active-preview');
            }

        }
    }
    getPreviewSelectedColor(p: any) {
        Array.from(document.getElementsByClassName("prevImg") as HTMLCollectionOf<HTMLElement>)
            .forEach((pcard: any, index: any) => {
                let ele: any = document.getElementById(pcard.id);
                if (ele.classList.contains('active-preview-card')) {
                    ele.classList.remove('active-preview-card')
                }
            })

        let prevPageEle: any = document.getElementById('pagePreview' + p);
        if (prevPageEle && prevPageEle.classList) {
            prevPageEle.classList.add('active-preview-card');
        }
        this.getPreviewSelected(p);
    }

    getPreviewSelected(p: any) {
        localStorage.setItem('currentPagination', p);
        Array.from(document.getElementsByClassName("thumbnails-page-btn") as HTMLCollectionOf<HTMLElement>)
            .forEach((pcard: any, index: any) => {
                let ele: any = document.getElementById(pcard.id);
                if (ele.classList.contains('active-preview')) {
                    ele.classList.remove('active-preview')
                }
            })

        let prevPageEle: any = document.getElementById('pageCountColor' + p);
        if (prevPageEle && prevPageEle.classList) {
            prevPageEle.classList.add('active-preview');
        }
    }

    showToaster(msg: string, errorType: any) {
        let error: any = this.TranslationService.getLanguageTranslationVal('lang_error');
        let warning: any = this.TranslationService.getLanguageValidationVal('lang_warning');
        let success: any = this.TranslationService.getLanguageTranslationVal('lang_success');
        let attention: any = this.TranslationService.getLanguageValidationVal('lang_attention');

        if (errorType === error) {
            this.toastr.error(msg, errorType, { timeOut: 2000 });
        }
        else if (errorType === warning) {
            this.toastr.warning(msg, errorType, { timeOut: 2000 });
        }
        else if (errorType === success) {
            this.toastr.success(msg, errorType, { timeOut: 2000 });
        }
        else if (errorType === attention) {
            this.toastr.info(msg, errorType, { timeOut: 2000 });
        }
    }

    validateAndApplyControlShowWebTooltip(ele: any, prevOrNext: string) {
        let controlEle: any = document.getElementById(ele.controlId);
        let attrDataTitle: any = controlEle.getAttribute('data-title');
        if (ele.controlName !== 'Date' && attrDataTitle !== 'Date') {
            controlEle.style.outline = 'none';
            controlEle.focus();
            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
            tooltiplEle.style.display = 'block';
        }
        else if (ele.controlName == 'Date' || attrDataTitle == 'Date') {
            controlEle.style.outline = 'none';
            if (prevOrNext == 'prev' || prevOrNext == 'next') {
                controlEle.focus();
            }
            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
            tooltiplEle.style.display = 'none';
        }
    }

    validateAndApplyControlShowMobileTooltip(ele: any, prevOrNext: string, mobileControlArr: any) {
        for (let i = 0; i < mobileControlArr.length; i++) {
            let tooltipControlEle: any = document.getElementById('tooltip_' + mobileControlArr[i].controlId.replace('control_', ''));
            tooltipControlEle.style.display = 'none';
            if (mobileControlArr[i].controlName !== 'Signature' && mobileControlArr[i].controlName !== 'NewInitials') {
                document.getElementById(mobileControlArr[i].controlId)!.style.border = '1px solid grey';
            }
        }
        this.hideAllControlTooltips(mobileControlArr);
        if (ele.controlName === 'NewInitials') {
            let controlValue: any = document.getElementById(ele.controlId)!;
            let eleRef: any = document.getElementsByClassName('newInitialsignature') as HTMLCollectionOf<HTMLElement>;
            if (controlValue.getAttribute('src') !== null) {
                eleRef[0].style.border = '1px solid #ddd';
            }
        }
        else {
            if (ele.controlName !== 'Checkbox') {
                if (ele.controlName !== 'Signature') {
                    setTimeout(() => {
                        document.getElementById(ele.controlId)!.style.border = '1px solid orange';
                    }, 200);
                }
            }
        }
    }

    hideAllControlTooltips(mobileControlArr: any) {
        for (let i = 0; i < mobileControlArr.length; i++) {
            let tooltipControlEle: any = document.getElementById('tooltip_' + mobileControlArr[i].controlId.replace('control_', ''));
            if (tooltipControlEle && tooltipControlEle.style) {
                tooltipControlEle.style.display = 'none';
            }
        }
    }

    removepaginationDisabledClass() {
        let paginationLoader: any = document.getElementsByClassName('spinner-border');
        if (paginationLoader && paginationLoader.length > 0) {
            Array.from(paginationLoader as HTMLCollectionOf<HTMLElement>)
                .forEach((eleChild: any) => {
                    eleChild.style.display = 'none';
                })
        }
        let paginationContainer: any = document.getElementsByClassName('paginationContainer');
        if (paginationContainer && paginationContainer.length > 0) {
            Array.from(paginationContainer as HTMLCollectionOf<HTMLElement>)
                .forEach((eleChild: any) => {
                    eleChild.classList.remove('pagination-disabled');
                })
        }
    }

    getUpdateCheckList(checkListData: any) {
        checkListData.forEach((element: any) => {
            if (element.ControlsData != null && element.ControlsData.length > 0) {
                element.ControlsData.forEach((eleChild: any) => {
                    if (eleChild.ControlName == "Signature" || eleChild.ControlName == "NewInitials") {
                        let sigImageCollection: any = document.getElementById('control_' + eleChild.ControlHtmlID);
                        let sigSignatureScr = sigImageCollection.attributes["src"] != undefined ? sigImageCollection.attributes["src"].value : '';
                        if (sigSignatureScr != null && sigSignatureScr != '' && sigSignatureScr != undefined) {
                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        } else {
                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "dropdown") {
                        let currentdrpCtrl: any = (document.getElementById("control_" + eleChild.ControlHtmlID)) as HTMLSelectElement;
                        let sel = currentdrpCtrl.selectedIndex;
                        let opt = currentdrpCtrl.options[sel];
                        let value = (<HTMLSelectElement>opt).textContent;
                        if (value === '' || value === ' ') {
                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }
                        else {
                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "radio") {
                        let curCheckboxctrl: any = "control_" + eleChild.ControlHtmlID;
                        var name = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).getAttribute("name");
                        if (name != "" && $('.signerControl input:radio[name="' + name + '"]:checked').length > 0) {
                            let cc: any = $('.signerControl input:radio[name="' + name + '"]:checked');
                            Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                                let ctrId: any = ele.getAttribute("id");
                                this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                            });
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "checkbox") {
                        let curCheckboxctrl: any = "control_" + eleChild.ControlHtmlID;
                        var name = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).getAttribute("name");
                        if (name != "" && $('.signerControl input:checkbox[name="' + name + '"]:checked').length > 0) {
                            let cc: any = $('.signerControl input:checkbox[name="' + name + '"]:checked');
                            Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                                let ctrId: any = ele.getAttribute("id");
                                this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                            });
                        }
                        else {
                            let value = (<HTMLInputElement>document.getElementById(curCheckboxctrl)).checked;
                            if (value === true) {
                                this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                            }
                            else if (value === false) {
                                this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                            }
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "date" || eleChild.ControlName.toLowerCase() == "email" || eleChild.ControlName.toLowerCase() == "name"
                        || eleChild.ControlName.toLowerCase() == "company" || eleChild.ControlName.toLowerCase() == "title" || eleChild.ControlName.toLowerCase() == "text") {
                        let curCtrl: any = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID));
                        if (curCtrl) {
                            let value = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).value;
                            if (value !== '' && value !== ' ' && value !== null) {
                                this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                            }
                            else {
                                this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                            }
                        }
                    }
                    else {
                        if (eleChild.ControlValue !== '' && eleChild.ControlValue !== ' ' && eleChild.ControlValue !== null && eleChild.ControlValue.toLowerCase() !== 'false') {
                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        } else {
                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }
                    }
                });
            }
        });
    }

    isMobileViewNumberKey(evt: any) {
        var regExp = /^[a-zA-Z ]*$/; // /^[a-zA-Z ]/;
        var regExpNumber = /^[0-9]*$/; // /^[0-9]/;
        var regExpComma = /^[0-9,]+$/;
        var regExpPeriod = /^[0-9.]+$/;

        let controlId: any = document.getElementById('txtMaximizeControl')!;
        let cname: any = controlId.getAttribute('data-title');
        let charCode: any = evt.data;
        if (cname == "SSN" || cname == "Zip") {
            controlId.removeAttribute('maxlength');
            if (regExpNumber.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                this.showToaster('Please enter a number.', this.lang_warning);
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d]/g, "");
                }
                return;
            }
        }
        if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') === '') {
            if (regExpNumber.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                //if user copy paste the text string
                //validating each chracter if it is text then reset to empty
                if (controlId && controlId.value && controlId.value.length > 2) {
                    let arrstr: any = controlId.value.split('');
                    for (var i = 0; i < arrstr.length; i++) {
                        if (!regExpNumber.test(arrstr[i])) {
                            controlId.value = '';
                            let enteredVal: any = document.getElementById('txtMaximizeControl');
                            if (enteredVal && enteredVal.value) {
                                enteredVal.value = enteredVal.value.replace(/[^\d]+/g, '');
                            }
                            this.showToaster('Please enter a number.', this.lang_warning);
                            break;
                        }
                    }
                } else {
                    let enteredVal: any = document.getElementById('txtMaximizeControl');
                    if (enteredVal && enteredVal.value) {
                        enteredVal.value = enteredVal.value.replace(/[^\d]+/g, '');
                    }
                    this.showToaster('Please enter a number.', this.lang_warning);
                }
                return;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Comma') {
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split(',') : '';
            if (number.length > 2) {
                evt.preventDefault();
                this.showToaster('Only one comma allowed.', this.lang_warning);
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d.,]|(?<=\..*)\.|(?<=,.*),/g, '');
                }
                return;
            }
            if (regExpComma.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                this.showToaster('Numeric 0 to 9 only and comma.', this.lang_warning);
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d,]|(?<=\,,*)\,/g, '');
                }
                return;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Both') {
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split('.') : [];
            if (charCode != null) {
                if (number.length > 2) {
                    evt.preventDefault();
                    this.showToaster('Only one decimal allowed.', this.lang_warning);
                    let enteredVal: any = document.getElementById('txtMaximizeControl');
                    if (enteredVal && enteredVal.value) {
                        enteredVal.value = enteredVal.value.replace(/(\..*?)\./g, '$1');
                    }
                    return;
                } else if (number[1] && number[1].length > 2) {
                    evt.preventDefault();
                    this.showToaster('Only two digits allowed after decimal.', this.lang_warning);
                    let enteredVal: any = document.getElementById('txtMaximizeControl');
                    if (enteredVal && enteredVal.value) {
                        const inputString = enteredVal.value;
                        const parts = inputString.split('.');
                        let decimalPart = parts[1] || '';
                        if (decimalPart.length > 2) {
                            decimalPart = decimalPart.substring(0, 2);
                        }
                        enteredVal.value = `${parts[0]}.${decimalPart}${inputString.slice(parts[0].length + parts[1]?.length + 1)}`;
                    }
                    return;
                }
                if (regExpComma.test(charCode) || regExpPeriod.test(charCode)) {
                    return true;
                } else {
                    evt.preventDefault();
                    this.showToaster('Numeric 0 to 9 only with comma and decimal.', this.lang_warning);
                    let enteredVal: any = document.getElementById('txtMaximizeControl');
                    if (enteredVal && enteredVal.value) {
                        enteredVal.value = enteredVal.value.replace(/[^\d,\.]/g, "");
                    }
                    return;
                }
            }
            return;
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Period') {
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split('.') : [];
            if (number.length > 2) {
                evt.preventDefault();
                this.showToaster('Only one decimal allowed.', this.lang_warning);
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d.]|(?<=\..*)\./g, '');
                }
                return;
            }
            if (regExpComma.test(charCode) || regExpPeriod.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                this.showToaster('Numeric 0 to 9 only and Period.', this.lang_warning);
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d.]|(?<=\..*)\./g, '');
                }
                return;
            }
        } else if (controlId.getAttribute('data-title') === 'Alphabet') {

            if (regExp.test(charCode)) {
                //if user copy paste the number string
                //validating each characters if it is number then reset to empty
                if (controlId && controlId.value && controlId.value.length > 2) {
                    let arrstr: any = controlId.value.split('');
                    for (var i = 0; i < arrstr.length; i++) {
                        if (!regExp.test(arrstr[i])) {
                            controlId.value = '';
                            let enteredVal: any = document.getElementById('txtMaximizeControl');
                            if (enteredVal && enteredVal.value) {
                                enteredVal.value = enteredVal.value.replace(/[^a-zA-Z]+/g, '');
                            }
                            this.showToaster('Characters only.', this.lang_warning);
                            break;
                        }
                    }
                }
                return true;
            }
            else {
                evt.preventDefault();
                this.showToaster('Characters only.', this.lang_warning);
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^a-zA-Z]+/g, '');
                }
                return;
            }
        }
        else if (controlId.getAttribute('data-title') === 'Text') {
            var controlHeight = document.getElementById(controlId.getAttribute('id')!.replace("control_", ""))!.style.height;
            if (evt.charCode == 13) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Enter not allowed.', this.lang_warning);
                }
                return false;
            }
            return true;
        }
        else {
            return true;
        }
    }

    isSingleKeyPress(event: any) {
        return (
            event.key.length === 1 && // A single character key
            !event.ctrlKey &&          // Control key isn't pressed
            !event.metaKey &&          // Command key isn't pressed (for Mac)
            !event.altKey              // Alt key isn't pressed
        );
    }

    getFooterAlignment(hidePagesViewSection: any) {
        let className: any = 'col-md-12';
        if (hidePagesViewSection == false) {
            className = 'col-md-10 offset-md-2'
        }
        return className;
    }
    checkSignAndInitialsDisabled(signClickCount: any) {
        let className: any = 'enabledCtrl'
        if (signClickCount > 0) {
            className = 'disabledCtrl'
        }
        return className;
    }
    getDocumentPagStyle(webOrMobile: any) {
        if (webOrMobile === 'mobile') {
            return { 'overflow-y': 'visible', 'overflow-x': 'visible' };
        }
        return;
    }
    getPageSectionClass(webOrMobile: any, type: any) {
        if ((webOrMobile === 'mobile' && type == 1) || (webOrMobile === 'web' && type == 2))
            return "";
        else if ((webOrMobile === 'mobile' && type == 2) || (webOrMobile === 'web' && type == 1))
            return 'page-section';
        else
            return 'page-section';
    }

    getIsRequiredLabel(ctrlObj: any, type: any) {
        let classname: any = 'requried-color requried-color1';
        if (ctrlObj.Required === true && (ctrlObj.isControlFiled === true)) {
            classname = 'success-color';
        }
        if (ctrlObj.isControlFiled === true) {
            classname = 'success-color';
        }
        if (type == "2") {
            if (ctrlObj.ControlName === 'Signature' || ctrlObj.ControlName === 'NewInitials') {
                let controlEle: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                if (controlEle && controlEle.getAttribute('src') != '' && controlEle.getAttribute('src') != null && controlEle.getAttribute('src') != undefined) {
                    classname = 'success-color';
                }
            }
        }
        return classname;
    }

    getInputIsRequired(ctrlObj: any, type: any) {
        let classname: any = '';
        if (ctrlObj.Required === true && ctrlObj.isControlFiled === false) {
            classname = '';
        }
        if (ctrlObj.Required === true && ctrlObj.isControlFiled === true) {
            classname = 'success-color success-icon';
        }
        if (type == "2") {
            if (ctrlObj.ControlName === 'Signature' || ctrlObj.ControlName === 'NewInitials') {
                let controlEle: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                if (controlEle && controlEle.getAttribute('src') != '' && controlEle.getAttribute('src') != null && controlEle.getAttribute('src') != undefined) {
                    classname = 'success-color success-icon';
                }
            }
        }
        return classname;
    }

    getIsRequired(ctrlObj: any, type: any) {
        let classname: any = ''; //cr rsigncolor
        if (ctrlObj.Required === true && (ctrlObj.isControlFiled === false || ctrlObj.isControlFiled === undefined)) {
            classname = 'requried-icon';
        }
        if (ctrlObj.Required === true && (ctrlObj.isControlFiled === true)) {
            classname = 'success-icon';
        }
        if (type == "2") {
            if (ctrlObj.ControlName === 'Signature' || ctrlObj.ControlName === 'NewInitials') {
                let controlEle: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                if (controlEle && controlEle.getAttribute('src') != '' && controlEle.getAttribute('src') != null && controlEle.getAttribute('src') != undefined) {
                    classname = 'success-icon';
                }
            }
        }
        return classname;
    }

    hideValidationErrormessages(documentControlsResponse: any) {
        if (documentControlsResponse && documentControlsResponse.EnvelopeImageControlData &&
            documentControlsResponse.EnvelopeImageControlData.length) {
            documentControlsResponse.EnvelopeImageControlData.forEach((ctrl: any) => {
                ctrl.ControlsData.forEach((ctrlItem: any) => {
                    if (ctrlItem.Required === true && ctrlItem.ControlName !== 'Hyperlink' && ctrlItem.ControlName !== 'Label') {
                        if (ctrlItem.ControlName === 'Checkbox' || ctrlItem.ControlName === 'Radio') {
                            if ((<HTMLInputElement>document.getElementById('control_' + ctrlItem.ControlHtmlID))) {
                                let checkboxParentCtrl: any = (<HTMLInputElement>document.getElementById('control_' + ctrlItem.ControlHtmlID)).parentNode;
                                checkboxParentCtrl.style.border = 'none';
                            }
                        } else if (ctrlItem.ControlName === 'Signature' || ctrlItem.ControlName === 'NewInitials') {
                            let IsAnySignatureExists: boolean = documentControlsResponse.IsAnySignatureExists;
                            if (ctrlItem.ControlName === 'Signature' && IsAnySignatureExists) {
                                if ((<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID))) {
                                    (<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID)).style.border = 'none';
                                    let sigItem: any = (<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID));
                                    sigItem.getElementsByClassName("signature")[0].classList.remove("btn_sign_validation");
                                }
                            }
                            else if (ctrlItem.ControlName === 'NewInitials') {
                                if ((<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID))) {
                                    (<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID)).style.border = 'none';
                                }
                            }
                        } else {
                            if ((<HTMLInputElement>document.getElementById('control_' + ctrlItem.ControlHtmlID)) && ctrlItem.IsSigned != true) {
                                (<HTMLInputElement>document.getElementById('control_' + ctrlItem.ControlHtmlID)).style.border = '1px solid';
                            }
                        }
                    }
                })
            })
        }
    }

    onFilterApply(ctrlId: any, controlType: any, ctrlItem: any, controlCollection: any, requiredFlag: any, fieldsRemainingFlag: any) {
        let ctrlReq: any;
        let ctrlValue: any;
        this.requiredFlag = requiredFlag;
        this.fieldsRemainingFlag = fieldsRemainingFlag;
        switch (controlType) {
            case "Signature":
                let sigImageCollection = ctrlItem.getElementsByTagName('img');
                ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                ctrlValue = sigImageCollection[0].attributes["src"] != undefined ? sigImageCollection[0].attributes["src"].value : '';

                if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                    if (ctrlReq == true && ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                    if (ctrlReq == true) {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                    if (ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                    controlCollection.push(ctrlId);
                }
                break;
            case "NewInitials":
                let initialImageCollection = ctrlItem.getElementsByTagName('img');
                ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                ctrlValue = initialImageCollection[0].attributes["src"] != undefined ? initialImageCollection[0].attributes["src"].value : '';

                if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                    if (ctrlReq == true && ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                    if (ctrlReq == true) {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                    if (ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                    controlCollection.push(ctrlId);
                }
                break;
            case "Text":
                let currentTextCtrl: any = document.getElementById("control_" + ctrlId);
                let currentTextCtrlTitle: any = currentTextCtrl.attributes['data-title'] != undefined ? currentTextCtrl.attributes['data-title'].value : '';
                if (currentTextCtrlTitle === 'Date') {
                    ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                }
                else if (currentTextCtrlTitle === 'Email') {
                    ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                }
                else {
                    ctrlValue = ctrlItem.getElementsByTagName("textarea") != undefined ? ctrlItem.getElementsByTagName("textarea")[0].value : '';
                }
                ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                ctrlValue = ctrlValue != undefined ? ctrlValue : '';

                if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                    if (ctrlReq == true && ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                    if (ctrlReq == true) {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                    if (ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                    controlCollection.push(ctrlId);
                }
                break;
            case "Email":
                let currEmailCtrl: any = document.getElementById("control_" + ctrlId);
                ctrlValue = currEmailCtrl.value != undefined ? currEmailCtrl.value : '';
                ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                ctrlValue = ctrlValue != undefined ? ctrlValue : '';

                if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                    if (ctrlReq == true && ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                    if (ctrlReq == true) {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                    if (ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                    controlCollection.push(ctrlId);
                }
                break;
            case "Name":
            case "Date":
            case "Initials":
            case "Title":
            case "Company":
                let currCtrl: any = document.getElementById("control_" + ctrlId);
                ctrlValue = currCtrl.value != undefined ? currCtrl.value : '';
                ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                ctrlValue = ctrlValue != undefined ? ctrlValue : ''

                if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                    if (ctrlReq == true && ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                    if (ctrlReq == true) {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                    if (ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                    controlCollection.push(ctrlId);
                }
                break;
            case "Checkbox":
                let curCheckboxctrl: any = document.getElementById("control_" + ctrlId);
                let isChecked: string = "false";
                isChecked = curCheckboxctrl.checked ? "true" : "false";
                ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                ctrlValue = isChecked;

                if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                    if (ctrlReq == true && ctrlValue == 'false') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                    if (ctrlReq == true) {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                    if (ctrlValue == 'false') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                    controlCollection.push(ctrlId);
                }
                break;
            case "Radio":
                let curRadioctrl: any = document.getElementById("control_" + ctrlId);
                let isRadioChecked: string = "false";
                isRadioChecked = curRadioctrl.checked ? "true" : "false";
                ctrlValue = isRadioChecked;
                ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;

                if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                    if (ctrlReq == true && ctrlValue == 'false') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                    if (ctrlReq == true) {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                    if (ctrlValue == 'false') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                    controlCollection.push(ctrlId);
                }
                break;
            case "DropDown":
                let currentdrpCtrl: any = (document.getElementById("control_" + ctrlId)) as HTMLSelectElement;
                var sel = currentdrpCtrl.selectedIndex;
                var opt = currentdrpCtrl.options[sel];
                var curValue = (<HTMLSelectElement>opt).value;
                ctrlValue = curValue;
                ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                    if (ctrlReq == true && ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                    if (ctrlReq == true) {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                    if (ctrlValue == '') {
                        controlCollection.push(ctrlId);
                    }
                }
                else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                    controlCollection.push(ctrlId);
                }
                break;
        }
        return controlCollection;
    }

    showHideCheckListData(documentControlsResponse: any, controlCollection: any, filterCount: any, ind: any) {
        documentControlsResponse.CheckListData.forEach((element: any, index: any) => {
            if (element.ControlsData != null && element.ControlsData.length > 0) {
                element.ControlsData.forEach((eleChild: any) => {
                    if (controlCollection.includes(eleChild.ControlHtmlID)) {
                        if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                            document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.removeAttribute('style');
                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.setAttribute('style', '');
                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.classList.add('visible');
                        }
                    }
                    else {
                        if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                            document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'none';
                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'none';
                        }
                    }
                });
            }
        });

        let expandIndex: any = ind;
        let chekListAccordainClass: any = document.getElementsByClassName("chekListAccordainClass");
        let chekListAccordainClassArray: any = [];
        if (chekListAccordainClass != null && chekListAccordainClass.length > 0) {
            if (filterCount > 0) {
                let isChekListAccordainClassDivVisible: boolean = false;
                let isExpanded: boolean = false;
                Array.from(chekListAccordainClass).forEach((item: any, chkIndex) => {
                    let panel: any = item.getElementsByClassName("panel");
                    if (panel != null && panel.length > 0) {
                        Array.from(panel).forEach((panelitem: any, panelIndex: any) => {
                            let checkListDataItemClass: any = panelitem.getElementsByClassName("checkListDataItem");
                            if (checkListDataItemClass != null && checkListDataItemClass.length > 0) {
                                Array.from(item.getElementsByClassName("checkListDataItem") as HTMLCollectionOf<HTMLElement>)
                                    .forEach((checkListItem: any, index: any) => {
                                        let display: any = window.getComputedStyle(checkListItem).display;
                                        if (display == "block") {
                                            item.style["display"] = "block";
                                            isChekListAccordainClassDivVisible = true;
                                            checkListItem.style["display"] = "block";
                                            panel[index].removeAttribute("style");
                                            if (chkIndex == 0 || controlCollection.length == 1) {
                                                panel[index].classList.add("visible");
                                            }
                                            else {
                                                panel[index].classList.remove("visible");
                                            }
                                            if (chekListAccordainClassArray.length == 0) {
                                                let attRID = item.getAttribute("id");
                                                chekListAccordainClassArray.push(attRID);
                                                let accordion1Class: any = item.getElementsByClassName("accordion1");
                                                accordion1Class[0].classList.add("active1");
                                                panel[index].classList.add("visible");
                                                expandIndex = Number(attRID.split('_')[1]);
                                            }
                                        }
                                        else {
                                            checkListItem.style["display"] = "none";
                                            panel[index].style["display"] = "none";
                                            panel[index].classList.remove("visible");
                                        }
                                    });
                            }
                            else {
                                item.style["display"] = "none";
                            }
                        });

                        if (!isChekListAccordainClassDivVisible) {
                            item.style["display"] = "none";
                            let accordion1Class: any = item.getElementsByClassName("accordion1");
                            accordion1Class[0].classList.remove("active1");
                            isChekListAccordainClassDivVisible = false;
                        }
                        else {
                            isChekListAccordainClassDivVisible = false;
                        }
                    }
                    else {
                        item.style["display"] = "none";
                    }
                });
            }
        }
        return expandIndex;
    }

    getDateFormat(day: any, month: any, year: any, df: any, dmonth: any) {
        var dateRetVal = null;
        switch (df) {
            case 'dd/mm/yyyy':
                dateRetVal = '' + day + '/' + month + '/' + year; // '01/09/2023'
                break;
            case 'mm/dd/yyyy':
                dateRetVal = '' + month + '/' + day + '/' + year; // '01/09/2023'
                break;
            case 'mm-dd-yyyy':
                dateRetVal = '' + month + '-' + day + '-' + year; //'01-09-2023'
                break;
            case 'mm.dd.yyyy':
                dateRetVal = '' + month + '.' + day + '.' + year; //'01.09.2023'
                break;
            case 'dd-mmm-yyyy':
                let monthsArr = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                dateRetVal = '' + day + '-' + monthsArr[dmonth] + '-' + year;
                break;
            case 'dd-mm-yyyy':
                dateRetVal = '' + day + '-' + month + '-' + year;
                break;
            case 'dd.mm.yyyy':
                dateRetVal = '' + day + '.' + month + '.' + year;
                break;
            case 'yyyy.mm.dd.':
                dateRetVal = '' + year + '.' + month + '.' + day + '.';
                break;
            case 'yyyy/mm/dd':
                dateRetVal = '' + year + '/' + month + '/' + day;
                break;
        }
        return dateRetVal;
    }

    getModalPopup(val: any) {
        var retVal = null;
        switch (val) {
            case 'Decline':
                retVal = DeclineModalComponent;
                break;
            case 'Attachments':
                retVal = AttachmentsComponent;
                break;
            case 'Invite Signers':
                retVal = InviteSignersComponent;
                break;
            case 'Change Signer':
                retVal = ChangeSignerModalComponent;
                break;
            case 'Static Decline':
                retVal = StaticDeclineModalComponent;
                break;
        }
        return retVal;
    }

    getClass(isFavorite: any) {
        let className = 'col-lg-8 col-md-8 col-xs-8 col-sm-8';
        if (isFavorite === false) {
            className = '';
        }
        return className;
    }

    getLeftSideClass(isFavorite: any) {
        let className = 'col-lg-2 col-md-2 col-xs-2 col-sm-2';
        if (isFavorite === false) {
            className = '';
        }
        return className;
    }

    getRightSideClass(isFavorite: any) {
        let className = 'col-lg-2 col-md-2 col-xs-2 col-sm-2';
        if (isFavorite === false) {
            className = '';
        }
        return className;
    }

    getEachControlValidations(mobileControlArr: any, isFinishLater: any, documentControlsResponse: any) {
        let validateFlag = false;
        let currentRecpId = localStorage.getItem("RecipientID");
        mobileControlArr.forEach((ctrlItem: any) => {
            if (ctrlItem.controlName !== 'Hyperlink' && ctrlItem.controlName !== 'Label' && ctrlItem.controlName !== 'DateTimeStamp') {
                let value: any;
                let chkItem: any = (<HTMLInputElement>document.getElementById(ctrlItem.controlId.replace('control_', '')));
                if (ctrlItem.required === 'true' || ctrlItem.required === true) {
                    if (ctrlItem.controlName === 'Checkbox') {
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).checked;
                        var groupName = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).getAttribute("name");
                        if (!isFinishLater && groupName != undefined && groupName != null && groupName != "") {
                            if ($('.signerControl input:checkbox[name="' + groupName + '"]:checked').length == 0) {
                                let checkboxCtrlid: any;
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                checkboxCtrlid = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).parentNode;
                                checkboxCtrlid.style.border = '2px solid red';
                                validateFlag = true;
                                this.showToaster(this.requiredField, this.lang_warning);
                            }
                        }
                        else if (!isFinishLater && value === false) {
                            let checkboxCtrlid: any;
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            checkboxCtrlid = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).parentNode;
                            checkboxCtrlid.style.border = '2px solid red';
                            validateFlag = true;
                            this.showToaster(this.requiredField, this.lang_warning);
                        }
                    }
                    else if (ctrlItem.controlName === 'Radio') {
                        var name = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).getAttribute("name");
                        if (!isFinishLater && $('.signerControl input:radio[name="' + name + '"]:checked').length == 0) {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            let radioCtrllid: any;
                            radioCtrllid = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).parentNode;
                            radioCtrllid.style.border = '2px solid red';
                            validateFlag = true;
                            this.showToaster(this.requiredField, this.lang_warning);
                        }
                    }
                    else if (ctrlItem.controlName === 'Signature' || ctrlItem.controlName === 'NewInitials') {
                        let sigSignatureScr: any = "";
                        let IsAnySignatureExists: boolean = documentControlsResponse.IsAnySignatureExists;
                        if (!isFinishLater && ctrlItem.controlName === 'Signature' && IsAnySignatureExists) {
                            let sigImageCollection: any = document.getElementById(ctrlItem.controlId);
                            sigSignatureScr = sigImageCollection.attributes["src"] != undefined ? sigImageCollection.attributes["src"].value : '';
                        }

                        if (!isFinishLater && ctrlItem.controlName === 'NewInitials') {
                            let initialImageCollection: any = document.getElementById(ctrlItem.controlId);
                            sigSignatureScr = initialImageCollection.attributes["src"] != undefined ? initialImageCollection.attributes["src"].value : '';
                        }

                        if (!isFinishLater && (sigSignatureScr === '' || sigSignatureScr === null)) {
                            if (ctrlItem.controlName === 'Signature' && IsAnySignatureExists) {
                                let sigItem: any = (<HTMLInputElement>document.getElementById(ctrlItem.controlId.replace('control_', '')));
                                sigItem.getElementsByClassName("signature")[0].classList.remove("btn_sign");
                                sigItem.getElementsByClassName("signature")[0].classList.add("btn_sign_validation");
                                validateFlag = true;
                                this.showToaster(this.requiredField, this.lang_warning);
                            }

                            if (ctrlItem.controlName === 'NewInitials') {
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId.replace('control_', ''))).style.border = '2px solid red';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId.replace('control_', ''))).style["height"] = '';
                                validateFlag = true;
                                this.showToaster(this.requiredField, this.lang_warning);
                            }

                        }
                    }
                    else if (ctrlItem.controlName === 'DropDown') {
                        let currentdrpCtrl: any = (document.getElementById(ctrlItem.controlId)) as HTMLSelectElement;
                        var sel = currentdrpCtrl.selectedIndex;
                        var opt = currentdrpCtrl.options[sel];
                        value = (<HTMLSelectElement>opt).textContent;
                        if (!isFinishLater && (value === '' || value === ' ')) {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            validateFlag = true;
                            this.showToaster(this.requiredField, this.lang_warning);
                        }
                    }
                    else if (ctrlItem.controlName === 'Email') {
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                        value = value.trim();
                        if (!isFinishLater && value === '') {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            validateFlag = true;
                            this.showToaster(this.requiredField, this.lang_warning);
                        }
                        else if (value != '' && !this.validateEmail(value)) {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            validateFlag = true;
                            this.showToaster(this.invalidEmail, this.lang_warning);
                        }
                    }
                    else if (ctrlItem.controlName === 'Date') {
                        let txtCtrl: any = (<HTMLInputElement>document.getElementById(ctrlItem.controlId));
                        let dateFormatToPass: any = txtCtrl.attributes['dateformat'].value;
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                        if (!isFinishLater && value === '') {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            validateFlag = true;
                            this.showToaster(this.requiredField, this.lang_warning);
                        }
                        else if (value != '' && !this.isValidDateCustom(value, dateFormatToPass)) {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            validateFlag = true;
                            this.showToaster(this.invalidDate, this.lang_warning);
                        }
                    }
                    else if (ctrlItem.controlName === 'Text') {
                        let txtCtrl: any = (<HTMLInputElement>document.getElementById(ctrlItem.controlId));
                        let ctrlType: any = txtCtrl.attributes["data-title"].value;
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                        if (ctrlType === 'Zip' || (ctrlType === 'SSN')) {
                            if (!isFinishLater && value === '') {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                validateFlag = true;
                                this.showToaster(this.requiredField, this.lang_warning);
                            }
                            else if (value != '') {
                                if (value.indexOf('_') > 0 || value.indexOf('_') > -1 || value.indexOf('-') > 5) {
                                    chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                    chkItem.style.border = '';
                                    (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                    validateFlag = true;
                                    if (ctrlType === 'Zip') {
                                        this.showToaster(this.invlaidZIP, this.lang_warning);
                                    }
                                    if (ctrlType === 'SSN') {
                                        this.showToaster(this.invlaidSSN, this.lang_warning);
                                    }
                                }
                            }
                        }
                        else if (ctrlType === 'Date') {
                            let dateFormatToPass: any = txtCtrl.attributes['data-ctntype'].value;
                            value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                            if (!isFinishLater && value === '') {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                validateFlag = true;
                                this.showToaster(this.requiredField, this.lang_warning);
                            }
                            else if (value != '' && !this.isValidDateCustom(value, dateFormatToPass)) {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                validateFlag = true;
                                this.showToaster(this.invalidDate, this.lang_warning);
                            }
                        }
                        else if (ctrlType === 'Email') {
                            value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                            value = value.trim();
                            if (!isFinishLater && value === '') {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                validateFlag = true;
                                this.showToaster(this.requiredField, this.lang_warning);
                            }
                            else if (value != '' && !this.validateEmail(value)) {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                validateFlag = true;
                                this.showToaster(this.invalidEmail, this.lang_warning);
                            }
                        }
                        else {
                            value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                            value = value.trim();
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value = value;
                            if (!isFinishLater && value === '') {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                validateFlag = true;
                                this.showToaster(this.requiredField, this.lang_warning);
                            }
                        }
                    }
                    else {
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                        value = value.trim();
                        (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value = value;
                        if (!isFinishLater && value === '') {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            validateFlag = true;
                            this.showToaster(this.requiredField, this.lang_warning);
                        }
                    }
                    setTimeout(() => {
                        this.hideValidationErrormessages(documentControlsResponse);
                    }, 5000);
                }
            }
        })

        return validateFlag;
    }

    validateEmail(email: any) {
        var re = EmailAddressFormat.ValidEmail;
        return re.test(email);
    }

    isValidDateCustom(dateValue: any, dateFormat: any) {
        var dateFormat = dateFormat.toUpperCase();
        var isValid1 = moment(dateValue, dateFormat, true).isValid();
        var isValid2 = moment(dateValue, dateFormat.replace("YY", "YYYY"), true).isValid();
        return isValid1 || isValid2;
    }

    validateInputText(value: any, ctrlObj: any) {
        let targetValue: any;
        if (ctrlObj.ControlName.toLowerCase() === 'checkbox') {
            targetValue = value.target.checked;
            if (targetValue !== '' && targetValue !== undefined) {
                let chkItem: any = (<HTMLInputElement>document.getElementById(ctrlObj.ControlHtmlID));
                if (targetValue === false) {
                    this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID);
                    chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                } else {
                    this.applyStylesToCheckList("add", ctrlObj.ControlHtmlID);
                }
            }
            else {
                this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
            }
        }
        else if (ctrlObj.ControlName.toLowerCase() === 'radio') {
            targetValue = value.target.checked;
            if (targetValue !== '' && targetValue !== undefined) {
                if (targetValue === false) {
                    this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
                } else {
                    this.applyStylesToCheckList("add", ctrlObj.ControlHtmlID);
                }
            }
            else {
                this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
            }
        }
        else {
            targetValue = value.target.value.trim();
            value.target.value = targetValue;
            if (targetValue !== '' && targetValue !== undefined) {
                let contrl: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                let isValid: boolean = false;
                if (contrl) {
                    let datatitle: any = contrl.getAttribute("data-title");
                    if (datatitle == "SSN" || datatitle == "Zip") {
                        if (contrl.value.indexOf('_') > 0 || contrl.value.indexOf('_') > -1 || contrl.value.indexOf('-') > 5) {
                            isValid = true;
                        }
                    }
                }

                if (isValid) { this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID) }
                else { this.applyStylesToCheckList("add", ctrlObj.ControlHtmlID); }
            }
            else {
                this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
            }
        }
    }

    applyControlsStylesToCheckList(txRadioArray: string[], ctrlObj: any, txAccordinArray: string[]) {
        if (txRadioArray && txRadioArray.length > 0) {
            for (let index = 0; index < txRadioArray.length; index++) {
                const controlHtmlId = txRadioArray[index];
                let currentCtrl: any = document.getElementById("control_" + controlHtmlId);
                if (currentCtrl) {
                    let groupName: any = document.getElementById("control_" + controlHtmlId)!.getAttribute("name");
                    if (groupName != '') {
                        let curCheckboxctrl: any = document.getElementById("control_" + ctrlObj.ControlHtmlID);
                        let isChecked: string = "false";
                        isChecked = curCheckboxctrl.checked ? "true" : "false";
                        if (isChecked == "true") {
                            this.applyStylesToCheckList("add", controlHtmlId);
                        }
                        else {
                            this.applyStylesToCheckList("remove", controlHtmlId)
                        }
                    }
                    else {
                        this.applyStylesToCheckList("add", controlHtmlId);
                    }
                }
            }
        }

        if (ctrlObj.ControlName.toLowerCase() === 'radio' && ctrlObj.GroupName != '') {
            let curCheckboxctrl: any = "control_" + ctrlObj.ControlHtmlID;
            var name = (<HTMLInputElement>document.getElementById("control_" + ctrlObj.ControlHtmlID)).getAttribute("name");
            if (name && name != '' && $('.signerControl input:radio[name="' + name + '"]:checked').length > 0) {
                let cc: any = $('.signerControl input:radio[name="' + name + '"]:checked');
                Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                    let ctrId: any = ele.getAttribute("id");
                    if (ctrId !== curCheckboxctrl) {
                        (<HTMLInputElement>document.getElementById(ctrId)).checked = false;
                    }
                });
            }
        }

        if (ctrlObj.ControlName.toLowerCase() === 'checkbox' && ctrlObj.GroupName != '') {
            var name = (<HTMLInputElement>document.getElementById("control_" + ctrlObj.ControlHtmlID)).getAttribute("name");
            if (name && name != '' && $('.signerControl input:checkbox[name="' + name + '"]:checked').length > 0) {
                let cc: any = $('.signerControl input:checkbox[name="' + name + '"]');
                Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                    let ctrId: any = ele.getAttribute("id");
                    let controlID: any = ctrId.replace('control_', '')
                    let ctrIdGroupName: any = ele.getAttribute("name");
                    if (ctrIdGroupName != '' && ctrIdGroupName == name) {
                        this.applyStylesToCheckList("add", controlID);
                    }
                })
            }

        }

        if (txAccordinArray && txAccordinArray.length > 0) {
            setTimeout(() => {
                this.showCheckListAccordinLeftSide(txAccordinArray);
            }, 20);
        }
    }

    showCheckListAccordinLeftSide(txtArray: any) {
        for (let index = 0; index < txtArray.length; index++) {
            const pcontrolHtmlID = txtArray[index];
            let parentNodeItem: any = document.getElementById('panel_' + pcontrolHtmlID)!.parentNode;
            if (parentNodeItem && parentNodeItem.classList && parentNodeItem.classList.length > 0) {
                let pClassItemsPageNumber: any = parentNodeItem.classList[0].replace("divChekListAccordain_", "");
                document.getElementById('chekListAccordain_' + (pClassItemsPageNumber))!.style.display = 'block';
            }
        }
    }

    appliChildDependencyForDropdown(ctrlObj: any, selectedDropdownValue: any) {
        let isHavingDependentFieldsArray: any = document.querySelectorAll('[data-parent-id="' + ctrlObj.Id + '"]');
        if (isHavingDependentFieldsArray && isHavingDependentFieldsArray.length > 0) {
            let txdropdownArray: string[] = [];
            Array.from(isHavingDependentFieldsArray).forEach((childDependentItem: any, ind: any) => {
                let depItem: any = childDependentItem.getAttribute("id");
                let attr_dataRuleId: any = childDependentItem.getAttribute("data-rule-id");
                if (depItem && depItem != "") {
                    let controlHtmlID: any = depItem.replace("control_", "");
                    if (attr_dataRuleId == selectedDropdownValue) {
                        if (document.getElementById(controlHtmlID)) document.getElementById(controlHtmlID)!.style.display = 'block';
                        if (document.getElementById('anchor_' + controlHtmlID)) {
                            document.getElementById('anchor_' + controlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + controlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + controlHtmlID)!.removeAttribute('style');
                            document.getElementById('panel_' + controlHtmlID)!.setAttribute('style', '');
                            document.getElementById('panel_' + controlHtmlID)!.classList.add('visible');

                            txdropdownArray.push(controlHtmlID);
                        }
                    }
                    else {
                        if (document.getElementById(controlHtmlID)) {
                            document.getElementById(controlHtmlID)!.style.display = 'none';
                            if (document.getElementById('anchor_' + controlHtmlID)) {
                                document.getElementById('anchor_' + controlHtmlID)!.style.display = 'none';
                                document.getElementById('panel_' + controlHtmlID)!.style.display = 'none';
                            }
                        }
                    }

                    if (txdropdownArray && txdropdownArray.length > 0) {
                        setTimeout(() => {
                            this.showCheckListAccordinLeftSide(txdropdownArray);
                        }, 15);
                    }
                }
            });
        }

        if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
            let controlIdEle: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
            let value: any = controlIdEle.selectedOptions[0].innerText.trim();
            if (value !== '' && value !== ' ' && value !== null) {
                this.applyStylesToCheckList("add", ctrlObj.ControlHtmlID);
            }
            else {
                this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
            }
        }
    }

    maskSSN(event: any, controlObj: any) {
        if (event.key != "Tab") {
            var myMask: any;
            let controlId: any = document.getElementById(controlObj)!;
            if (controlId.getAttribute('data-mask') == '___-__-____') {
                myMask = '___-__-____';
            } else if (controlId.getAttribute('data-mask') == "_____") {
                myMask = "_____";
            } else if (controlId.getAttribute('data-mask') == "_____-____") {
                myMask = "_____-____";
            }
            let myCaja = document.getElementById(controlObj)!;
            let myText: any = "";
            let myNumbers = [];
            let myOutPut = "";
            let theLastPos = 1;
            myText = (<HTMLInputElement>controlId).value; //myCaja.value;
            for (let i = 0; i < myText.length; i++) {
                if (!isNaN(myText.charAt(i)) && myText.charAt(i) != " ") {
                    myNumbers.push(myText.charAt(i));
                }
            }

            if (myMask != undefined) {
                for (let j = 0; j < myMask.length; j++) {
                    if (myMask.charAt(j) == "_") {
                        if (myNumbers.length == 0)
                            myOutPut = myOutPut + myMask.charAt(j);
                        else {
                            myOutPut = myOutPut + myNumbers.shift();
                            theLastPos = j + 1;
                        }
                    } else {
                        myOutPut = myOutPut + myMask.charAt(j);
                    }
                }
                (<HTMLInputElement>controlId).value = myOutPut;
                (<HTMLInputElement>controlId).setSelectionRange(theLastPos, theLastPos);
            }
        }
    }

    getRadioCheckBoxPreloadControls(eleChild: any) {
        let txAccordinArray: string[] = [];
        let isChecked: string = "false";
        let curctrl: any = document.getElementById("control_" + eleChild.ControlHtmlID);
        if (curctrl && curctrl != null && (eleChild.ControlName.toLowerCase() == "radio" || eleChild.ControlName.toLowerCase() == "checkbox")) {
            isChecked = curctrl.checked ? "true" : "false";
        }

        if (eleChild.ControlName.toLowerCase() == "radio") {
            if (eleChild.ControlValue === 'true' && eleChild.IsReadOnly != true && isChecked == "true") {
                eleChild.ConditionDetails.DependentFields.forEach((eleChildDep: any) => {
                    let cntrlId: any = document.querySelector('[data-document-content-id="' + eleChildDep.ControlID + '"]')?.getAttribute('id');
                    let controlHtmlID: any = cntrlId?.replace("control_", "");
                    let ctrlId: any = document.getElementById(controlHtmlID);
                    let recpId: any = '';
                    if (document.getElementById(controlHtmlID)) {
                        recpId = document.getElementById(controlHtmlID)?.getAttribute('data-recipientid');
                    }

                    if (ctrlId) {
                        document.getElementById(controlHtmlID)!.style.display = 'block';
                        if (document.getElementById('anchor_' + controlHtmlID)) {
                            document.getElementById('anchor_' + controlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + controlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + controlHtmlID)!.removeAttribute('style');
                            document.getElementById('panel_' + controlHtmlID)!.setAttribute('style', '');
                            document.getElementById('panel_' + controlHtmlID)!.classList.add('visible');

                            txAccordinArray.push(controlHtmlID);
                        }
                    }

                })
            }
            else if (eleChild.ControlValue === 'false') {
                eleChild.ConditionDetails.DependentFields.forEach((eleChildDep: any) => {
                    let cntrlId: any = document.querySelector('[data-document-content-id="' + eleChildDep.ControlID + '"]')?.getAttribute('id');
                    let controlHtmlID: any = cntrlId?.replace("control_", "");
                    let ctrlId: any = document.getElementById(controlHtmlID);
                    if (ctrlId) {
                        document.getElementById(controlHtmlID)!.style.display = 'none';
                        if (document.getElementById('anchor_' + controlHtmlID)) {
                            document.getElementById('anchor_' + controlHtmlID)!.style.display = 'none';
                            document.getElementById('panel_' + controlHtmlID)!.style.display = 'none';
                        }
                    }
                })
            }
        }

        if (eleChild.ControlName.toLowerCase() == "checkbox") {
            eleChild.ConditionDetails.DependentFields.forEach((eleChildDep: any) => {
                let ctrlId: any = document.querySelector('[data-document-content-id="' + eleChildDep.ControlID + '"]')?.getAttribute('id');
                let controlHtmlID: any = ctrlId?.replace("control_", "");

                if (eleChild.ControlValue === 'true' && eleChildDep.ConditionID && eleChildDep.ConditionID.toLowerCase() == "1765014e-6611-4c9a-9268-833e7d853d1a") {
                    if (document.getElementById(controlHtmlID))
                        document.getElementById(controlHtmlID)!.style.display = 'block';
                    if (document.getElementById('anchor_' + controlHtmlID)) {
                        document.getElementById('anchor_' + controlHtmlID)!.style.display = 'block';
                        document.getElementById('panel_' + controlHtmlID)!.style.display = 'block';
                        document.getElementById('panel_' + controlHtmlID)!.removeAttribute('style');
                        document.getElementById('panel_' + controlHtmlID)!.setAttribute('style', '');
                        document.getElementById('panel_' + controlHtmlID)!.classList.add('visible');

                        txAccordinArray.push(controlHtmlID);
                    }
                }
                else if (eleChild.ControlValue === 'false' && eleChildDep.ConditionID && eleChildDep.ConditionID.toLowerCase() == "e6fc0258-fbd1-4b11-99a7-138193af2e64") {
                    if (document.getElementById(controlHtmlID)) document.getElementById(controlHtmlID)!.style.display = 'block';
                    if (document.getElementById('anchor_' + controlHtmlID)) {
                        document.getElementById('anchor_' + controlHtmlID)!.style.display = 'block';
                        document.getElementById('panel_' + controlHtmlID)!.style.display = 'block';
                        document.getElementById('panel_' + controlHtmlID)!.removeAttribute('style');
                        document.getElementById('panel_' + controlHtmlID)!.setAttribute('style', '');
                        document.getElementById('panel_' + controlHtmlID)!.classList.add('visible');

                        txAccordinArray.push(controlHtmlID);
                    }
                }
                else {
                    if (document.getElementById(controlHtmlID)) document.getElementById(controlHtmlID)!.style.display = 'none';
                    if (document.getElementById('anchor_' + controlHtmlID)) {
                        document.getElementById('anchor_' + controlHtmlID)!.style.display = 'none';
                        document.getElementById('panel_' + controlHtmlID)!.style.display = 'none';
                    }
                }
            })
        }

        if (txAccordinArray && txAccordinArray.length > 0) {
            this.showCheckListAccordinLeftSide(txAccordinArray);
        }
    }

    updateDefaultValueOnEmailControl(currentEmail:any,currentRoleID:any) {
        if(currentEmail != "" && currentEmail != null && currentEmail != undefined){
            let emailControlClassList: any = document.getElementsByClassName("emailControlClass");
            let controlsArray: string[] = [];
            Array.from(emailControlClassList).forEach((ctrl: any) => {
                let datarecipientid: any = ctrl.getAttribute("data-recipientid");
                if (datarecipientid.toLowerCase() == currentRoleID.toLowerCase()) {
                    let emailCtrl: any = document.getElementById("control_" + ctrl.getAttribute("id"));
                    emailCtrl.value = currentEmail;
                    controlsArray.push(ctrl.getAttribute("id"));
                    let controlele: any = document.getElementById('control_' + ctrl.getAttribute("id"))!
                    let controlId: any = controlele.parentNode;
                    controlId.classList.add('filledControls');
                }
            });

            if (controlsArray && controlsArray.length > 0) {
                for (let index = 0; index < controlsArray.length; index++) {
                    const element = controlsArray[index];
                    this.applyStylesToCheckList("add", element);
                }
            }
        }
    }

    updatedControlPositionForLandscape(){
        Array.from(document.getElementsByClassName('dispImg') as HTMLCollectionOf<HTMLElement>)
        .forEach((imgItem: any, index:any) => {
            let imageDoc:any = document.getElementById(imgItem.id);
            if(imageDoc){
                let documentControlsZoomLandScape:any = document.getElementsByClassName('document-controls-zoom');
                if(imageDoc.offsetHeight > 0 && imageDoc.offsetHeight <= 335){
                    if(documentControlsZoomLandScape && documentControlsZoomLandScape.length > 0 && documentControlsZoomLandScape[index]){
                        documentControlsZoomLandScape[index].classList.add('document-controls-zoom-landscape');
                    }
                } else {
                    if(documentControlsZoomLandScape && documentControlsZoomLandScape.length > 0 && documentControlsZoomLandScape[index]){
                        documentControlsZoomLandScape[index].classList.remove('document-controls-zoom-landscape');
                    }
                }
            }
        })
    }

    callHeaderCompMethod(data: any) {
        this.callHeaderCompMethodSubject.next(data);
    }

}
