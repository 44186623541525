import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants, MessageTypes, RecipientType, ResourceKeys, RouteUrls } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { TranslationsService } from 'src/app/services/translations.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-password-window',
    templateUrl: './password-window.component.html',
    styleUrls: ['./password-window.component.scss']
})
export class PasswordWindowComponent implements OnInit {
    showLoader: boolean = false;
    documentControlsResponse: any;
    showPassword: boolean = false;
    htmlFooterString: string = '';
    resourceKeys = ResourceKeys;
    isFromSignMultiTemplate: boolean = false;
    isDirect: string = '';
    isFromInbox: boolean = false;

    constructor(private router: Router, private signerLandingService: SignerLandingService, private translationsService: TranslationsService,
        private toastr: ToastrService, private commonService: CommonService, private titleService: Title) { }

    ngOnInit(): void {
        this.titleService.setTitle("RSign | Password");
        let footer: any = localStorage.getItem("htmlFooterString");
        this.htmlFooterString = '<p> ' +
            '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
            '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
            '</p>';
        localStorage.setItem("IsFromBotClick", "");
        if(footer != null && footer != "" && footer != undefined && footer != 'undefined' && footer != 'null'){
            this.htmlFooterString = footer;
        }
    }

    togglePassword() {
        this.showPassword = !this.showPassword;
        let showPasswordElement: any = document.getElementById('txtDocPassword');
        if (this.showPassword) {
            if (showPasswordElement) {
                showPasswordElement.classList.remove('showTextDocPassword');
            }
        }
        else {
            if (showPasswordElement) {
                showPasswordElement.classList.add('showTextDocPassword');
            }
        }
    }

    ValidatePassword(): void {
        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        this.documentControlsResponse = JSON.parse(documentControlsResp);

        let envelopeInfoResp: any = localStorage.getItem("EnvelopeInfo");
        this.documentControlsResponse.EnvelopeInfo = JSON.parse(envelopeInfoResp);

        let isPwdRqdToOpen: any = localStorage.getItem("passwordReqdtoOpen");
        let senderEmail: string = this.documentControlsResponse.EnvelopeInfo.SenderEmail;
        localStorage.setItem("senderEmail", senderEmail);
        let prefill: string = this.documentControlsResponse.Prefill;
        localStorage.setItem("prefill", prefill);
        let CopyEmailId = localStorage.getItem("CopyMailId");
        let cultureInfo: string = '';
        if (this.documentControlsResponse.EnvelopeInfo.CultureInfo == "") cultureInfo = "en-us";
        else cultureInfo = this.documentControlsResponse.EnvelopeInfo.CultureInfo;

        let currentStatus = '';
        let IsStatic: boolean = this.documentControlsResponse.EnvelopeInfo.IsStatic;

        let txtDocPasswordInput: any = document.getElementById("txtDocPassword");
        let txtDocPassword = txtDocPasswordInput.value.trim();

        if (txtDocPassword.length > 0) {
            //validatePassword
            this.showLoader = true;
            let envelopeId = localStorage.getItem("EnvelopeID");
            let recipientId = localStorage.getItem("RecipientID");
            let copyMailId = localStorage.getItem("CopyMailId");

            let validatePwdUrl = CommonConstants.GetEnvelopeOrTemplateFields + "/" + envelopeId + "/" + recipientId;
            this.signerLandingService.getValidatePassword(validatePwdUrl).subscribe(
                (resp: any) => {
                    if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                        let strURLWithData: string = '';
                        if (recipientId == '00000000-0000-0000-0000-000000000000') {
                            if (isPwdRqdToOpen) {
                                let strURL = "view-document";
                                if (currentStatus != null && currentStatus != '') {
                                    let encryptURL = CommonConstants.EncryptQueryParamString + '/TemplateID=' + envelopeId + '&CurrentStatus=' + currentStatus;
                                    this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
                                        (encryptresp: any) => {
                                            // console.log('getEncryptQueryString', encryptresp);
                                            strURLWithData = strURL + encryptresp.EnvryptedEncodedText;

                                            this.comparePassword(resp.DecryptPassword, txtDocPassword, strURL);
                                        });
                                } else {
                                    let encryptURL = CommonConstants.EncryptQueryParamString + '/TemplateID=' + envelopeId;
                                    this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
                                        (encryptresp: any) => {
                                            // console.log('getEncryptQueryString', encryptresp);
                                            strURLWithData = strURL + encryptresp.EnvryptedEncodedText;
                                            this.comparePassword(resp.DecryptPassword, txtDocPassword, strURL);
                                        })
                                }
                            }
                        }
                        else {
                            let strURL = "SignDocument";
                            let encryptURL = CommonConstants.EncryptQueryParamString + '/EnvelopeId=' + envelopeId + '&RecipientId=' + recipientId + "&CopyEmail=" + copyMailId;
                            this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
                                (encryptresp: any) => {
                                    //  console.log('getEncryptQueryString', encryptresp);
                                    strURLWithData = strURL + encryptresp.EnvryptedEncodedText;
                                    this.comparePassword(resp.DecryptPassword, txtDocPassword, strURL);
                                })
                        }
                        this.showLoader = false;
                    }
                    else {
                        this.showLoader = false;
                        this.commonService.getReturnUrlDirection(RouteUrls.Info);
                    }
                })
        }
        else {
            this.toastr.warning('Please enter the valid password.', 'Warning', { timeOut: 1500 });
            this.showLoader = false;
        }
    }

    comparePassword(decryptPassword: any, txtDocPassword: any, routeUrl: any) {
        if (decryptPassword == txtDocPassword) {
            document.getElementById('formContent')!.style.display = 'none';
            document.getElementById('divSuccess')!.style.display = 'block';
            this.showLoader = true;
            setTimeout(() => {
                if (routeUrl == "SignDocument") {
                    let signDocumentUrl = CommonConstants.InitializeSignerSignDocument;
                    let signingUrl = localStorage.getItem("signingUrl");
                    let requestObj = { SigningUrl: signingUrl, IsFromSignerPreLanding: false, IsFromBotClick: "1" };

                    sessionStorage.setItem("IsFromSignerPreLanding", "false");

                    this.signerLandingService.getSignerSignDocumentData(signDocumentUrl, requestObj).subscribe(
                        (resp: any) => {
                            if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                                this.showLoader = false;
                                this.documentControlsResponse = resp;
                                let cultureInfo: string = '';
                                if (this.documentControlsResponse.EnvelopeInfo.CultureInfo == "") cultureInfo = "en-us";
                                else cultureInfo = this.documentControlsResponse.EnvelopeInfo.CultureInfo;
                                localStorage.setItem("cultureInfo", cultureInfo);

                                setTimeout(() => {
                                    let respEnvelopeImageControlData: any = this.documentControlsResponse.EnvelopeImageControlData;
                                    let respCheckListData: any = this.documentControlsResponse.CheckListData;
                                    let respUserdata: any = this.documentControlsResponse.userdata;
                                    let respEnvelopeInfo: any = this.documentControlsResponse.EnvelopeInfo;
                                    localStorage.setItem("EnvelopeImageControlData", JSON.stringify(respEnvelopeImageControlData));
                                    localStorage.setItem("CheckListData", JSON.stringify(respCheckListData));
                                    localStorage.setItem("Userdata", JSON.stringify(respUserdata));
                                    localStorage.setItem("EnvelopeInfo", JSON.stringify(this.documentControlsResponse.EnvelopeInfo));
                                    this.documentControlsResponse.EnvelopeImageControlData = null;
                                    this.documentControlsResponse.Language = null;
                                    this.documentControlsResponse.CheckListData = null;
                                    this.documentControlsResponse.userdata = null;
                                    this.documentControlsResponse.EnvelopeInfo = null;
                                    localStorage.setItem("documentControlsResponse", JSON.stringify(this.documentControlsResponse));
                                    this.documentControlsResponse.EnvelopeImageControlData = respEnvelopeImageControlData;
                                    this.documentControlsResponse.Language = null;
                                    this.documentControlsResponse.CheckListData = respCheckListData;
                                    this.documentControlsResponse.userdata = respUserdata;
                                    this.documentControlsResponse.EnvelopeInfo = respEnvelopeInfo;

                                    localStorage.setItem("isStatic", this.documentControlsResponse.EnvelopeInfo.IsStatic);
                                    localStorage.setItem("prefill", this.documentControlsResponse.Prefill);
                                    localStorage.setItem("senderEmail", this.documentControlsResponse.EnvelopeInfo.SenderEmail);
                                    // localStorage.setItem("encryptedGlobalEnvelopeID", this.documentControlsResponse.EncryptedGlobalEnvelopeID);
                                    // localStorage.setItem("encryptedGlobalRecipientID", this.documentControlsResponse.EncryptedGlobalRecipientID);
                                    //localStorage.setItem("encryptedSender", this.documentControlsResponse.EncryptedSender);
                                    localStorage.setItem("isPasswordMailToSigner", this.documentControlsResponse.IsPasswordMailToSigner);
                                    localStorage.setItem("isStaticPwd", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoSign);
                                    localStorage.setItem("recipientEmail", this.documentControlsResponse.EnvelopeInfo.RecipientEmail);
                                    localStorage.setItem("recipientTypeId", this.documentControlsResponse.EnvelopeInfo.RecipientTypeId);
                                    localStorage.setItem("eDisplayCode", this.documentControlsResponse.EnvelopeInfo.EDisplayCode);
                                    // localStorage.setItem("passwordReqdtoSign", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoSign);
                                    localStorage.setItem("passwordReqdtoOpen", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoOpen);
                                    // localStorage.setItem("maxUploadID", this.documentControlsResponse.MaxUploadID);

                                    localStorage.setItem("EnvelopeID", this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID);

                                    if (this.documentControlsResponse.TemplateRolesInfo && this.documentControlsResponse.TemplateRolesInfo.length > 0) {
                                        //localStorage.setItem("statictRolesCount", this.documentControlsResponse.TemplateRolesInfo.length);
                                        localStorage.setItem("rolsInfos", JSON.stringify(this.documentControlsResponse.TemplateRolesInfo));
                                    }
                                    else {
                                        //localStorage.setItem("statictRolesCount", "0");
                                        localStorage.setItem("rolsInfos", '');
                                    }

                                    if ((this.documentControlsResponse.EnvelopeInfo.IsStatic == true && this.documentControlsResponse.IsDefaultSignatureForStaticTemplate == false) ||
                                        (this.documentControlsResponse.IsSharedTemplateContentUnEditable == true && this.documentControlsResponse.IsDefaultSignatureForStaticTemplate == false)) {
                                        let isStaticTemplate: any = true;
                                        localStorage.setItem("isStaticTemplate", isStaticTemplate);
                                    }
                                    else {
                                        localStorage.setItem("isStaticTemplate", "false");
                                    }

                                    //Based on response,need to redirect to other pages
                                    let isFrmMultitemplate: any = sessionStorage.getItem("isFromSignMultiTemplate");
                                    if (isFrmMultitemplate == "true") {
                                        this.isFromSignMultiTemplate = true;
                                    }
                                    else {
                                        this.isFromSignMultiTemplate = false;
                                    }
                                    if (this.documentControlsResponse.EnvelopeInfo.IsTemplateShared == true && this.isFromSignMultiTemplate == false &&
                                        this.documentControlsResponse.EnvelopeInfo.RecipientTypeIDPrefill.toLowerCase() != RecipientType.Prefill.toLowerCase() && !this.isNullOrEmptyCheck(this.isDirect)) {
                                        sessionStorage.removeItem("isFromSignMultiTemplate");
                                        this.commonService.getReturnUrlDirection(RouteUrls.SignMultiTemplate);
                                    }
                                    else if (this.documentControlsResponse.EnvelopeInfo.RecipientTypeIDPrefill.toLowerCase() == RecipientType.Prefill.toLowerCase()) {
                                        sessionStorage.removeItem("isFromSignMultiTemplate");
                                        this.commonService.getReturnUrlDirection(RouteUrls.SignDocument);
                                    }
                                    else if (this.documentControlsResponse.AllowMultipleSigner == true && this.documentControlsResponse.EnvelopeInfo.IsStatic) {
                                        sessionStorage.removeItem("isFromSignMultiTemplate");
                                        //localStorage.setItem("AllowMultipleSigner", "true");
                                        localStorage.setItem("IsStatic", "true");
                                        this.commonService.getReturnUrlDirection(RouteUrls.AllowMultipleSigner);
                                    }
                                    else if (this.documentControlsResponse != null && this.documentControlsResponse.InviteSignNowByEmail > 0) {
                                        sessionStorage.removeItem("isFromSignMultiTemplate");
                                        this.commonService.getReturnUrlDirection(RouteUrls.AllowMultipleSigner);
                                    }
                                    else {
                                        sessionStorage.removeItem("isFromSignMultiTemplate");
                                        this.commonService.getReturnUrlDirection(RouteUrls.SignDocument);
                                    }
                                    //this.commonService.getReturnUrlDirection(RouteUrls.SignDocument);
                                }, 10);
                            }
                            else if (resp && resp.originalError && resp.originalError.error && (resp.originalError.error.StatusCode == 400 || resp.originalError.error.StatusCode == 204)) {
                                sessionStorage.removeItem("isFromSignMultiTemplate");
                                let encryptedSender: string = resp.originalError.error.EncryptedSender;
                                // localStorage.setItem("encryptedSender", encryptedSender);
                                localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                                localStorage.setItem("MessageType", "Warning");
                                if (resp.originalError.error.ErrorAction == "Resend") {
                                    this.commonService.getReturnUrlDirection(RouteUrls.Info);
                                }
                                else if (resp.originalError.error.ErrorAction == "Expire") {
                                    resp.originalError.error.Message == "" ? localStorage.setItem("DisplayMessage", "This envelope is expired.") : localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                                    this.commonService.getReturnUrlDirection(RouteUrls.Info);
                                }
                                else if (resp.originalError.error.ErrorAction == "EnvelopeAccepted" || resp.originalError.error.ErrorAction == "ContactSender") {
                                    resp.originalError.error.Message == "" ? localStorage.setItem("DisplayMessage", "This document has already been signed and returned to the sender.") : localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                                    localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                                    localStorage.setItem("MessageType", MessageTypes.Success);
                                    if (resp.originalError.error.ErrorAction == "ContactSender") {
                                        if (resp.originalError.error.IsAllowSignerstoDownloadFinalContract == true) {
                                            if (resp.originalError.error.RecipientOrder == 1) { localStorage.setItem("DisplayLoginButton", "true"); localStorage.setItem("DisplaySignUpButton", ""); }
                                            if (resp.originalError.error.RecipientOrder == 0) { localStorage.setItem("DisplaySignUpButton", "true"); localStorage.setItem("DisplayLoginButton", ""); }
                                        }
                                        else {
                                            localStorage.setItem("DisplayLoginButton", ""); localStorage.setItem("DisplaySignUpButton", "");
                                        }
                                    }
                                    localStorage.setItem("EnvelopeStatus", resp.originalError.error.ErrorAction);
                                    this.commonService.getReturnUrlDirection(RouteUrls.Info);
                                }
                                else {
                                    if (resp.originalError.error.Message == "") {
                                        localStorage.setItem("MessageType", "Error");
                                        localStorage.setItem("DisplayMessage", "An Application error occurred.")
                                        localStorage.setItem("IsApplicationError", "true");
                                        localStorage.setItem("PageName", "InitializeSignerSign");

                                        let envid: any = localStorage.getItem("EnvelopeID");
                                        let emailid: any = localStorage.getItem("CopyMailId");
                                        localStorage.setItem("EnvelopeID", envid);
                                        localStorage.setItem("EmailId", emailid);
                                    }
                                    else {
                                        localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                                    }

                                    this.commonService.getReturnUrlDirection(RouteUrls.Info);
                                }

                                this.showLoader = false;
                            }
                            else {
                                this.showLoader = false;
                                sessionStorage.removeItem("isFromSignMultiTemplate");
                                // localStorage.setItem("encryptedSender", "");
                                localStorage.setItem("MessageType", "Error");
                                localStorage.setItem("IsApplicationError", "true");
                                localStorage.setItem("PageName", "InitializeSignerSign");
                                localStorage.setItem("DisplayMessage", "An Application error occurred.");

                                let envid: any = localStorage.getItem("EnvelopeID");
                                let emailid: any = localStorage.getItem("CopyMailId");
                                localStorage.setItem("EnvelopeID", envid);
                                localStorage.setItem("EmailId", emailid);

                                this.commonService.getReturnUrlDirection(RouteUrls.Info);
                            }
                        });
                }
                else if (routeUrl == "view-document") {
                    this.showLoader = false;
                    this.commonService.getReturnUrlDirection(RouteUrls.ViewDocumentLanding);
                }
            }, 20);
        }
        else {
            this.showLoader = false;
            this.toastr.warning('Password is invalid or not matched.', 'Warning', { timeOut: 1000 });
            let txtDocPasswordInput: any = document.getElementById("txtDocPassword");
            txtDocPasswordInput.value = '';
        }
    }
    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }
    isNullOrEmptyCheck(stringValueFlag: any) {
        let boolFlag = false;
        if (typeof stringValueFlag != 'undefined' && stringValueFlag !== '' && stringValueFlag !== null && stringValueFlag !== 'false') {
            boolFlag = true;
        }
        return boolFlag;
    }
}
