<app-header></app-header>
<main class="container-login100">
    <div class="wrapperIndex">
        <div class="dvRadius">
            <div *ngIf="isAutoLocked == false" class="divMainWrapper user_card">
                <div id="formContent" class="dvglobalInfo_2">
                    <div *ngIf="isSuccessFlag && !isFromFinishLater" class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalinfo_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                        <div *ngIf="AllowSignersDownloadFinalContract">

                            <p *ngIf="!envelopeCompleted && isDelegated != 'true' && IsSendConfirmEmailPopupOpen != 'true'"
                                class="ptextAlign">
                                {{downloadDocAfterSignedText}}
                            </p>

                            <p *ngIf="isDisplaySignUpButton && envelopeCompleted" class="ptextAlign">
                                {{signupDownloadFinalSignedDocText}}
                            </p>

                            <p *ngIf="isDisplayLoginButton && envelopeCompleted" class="ptextAlign">
                                {{downloadFinalSignedDocFromCompletedFolderText}}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="isFromFinishLater" class="dvdevices">
                        <img *ngIf="isFromSessionExpired !== '1'" src="../../../assets/images/success_info.png"
                            style="width:70px;" />
                        <img *ngIf="isFromSessionExpired === '1'" src="../../../assets/images/success_info.png"
                            style="width:70px;" />
                        <h5 class="Globalinfo_1 mt-3  mb-0 font-w600">
                            <h5 *ngIf="isFromSessionExpired === '1'" style="font-weight: bold;color:#000;">Session
                                Expired.</h5>
                            <div [innerHtml]="displayMessage"></div>
                        </h5>
                    </div>
                    <div *ngIf="isWarningFlag" class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalerror_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                    </div>
                    <div *ngIf="isErrorFlag" class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalerror_1 mt-3 mb-0 font-w600">Oops! we are having trouble processing your
                            request.</h5>

                    </div>
                    <div *ngIf="isSuccessWarning" class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalinfo_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                    </div>
                    <div *ngIf="isSuccessErrorMessage" class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalerror_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                    </div>
                    <div *ngIf="isApplicationErrorFlag" class="dvdevices">
                        <img src="../../../assets/images/success_info_warning.png" style="width:70px;" />
                        <div class="d-flex flex-column mt-3">
                            <h5 class="Globalerror_1 mb-0 font-w600">Oops! we are having trouble processing your
                                request.</h5>
                        </div>
                    </div>

                    <div *ngIf="!isDisplayLoginButton && !isDisplaySignUpButton">
                        <p *ngIf="isExpired" class="ptextAlign">
                            {{eSignExpiredFeatureList}}
                        </p>
                        <p *ngIf="isExpired" class="pserviceFeature">
                            {{eSignExpiredServiceFeature}}
                        </p>

                        <p *ngIf="isSendConfirmationEmail" class="ptextAlign" style="text-align:left;">
                            {{RSignMakesESignatureText}}
                        </p>

                        <p *ngIf="(isFromFinishLater || !AllowSignersDownloadFinalContract || AllowSignersDownloadFinalContract) && !isSendConfirmationEmail && !isExpired"
                            class="ptextAlign">
                            {{RSignMakesESignatureText}}
                        </p>
                    </div>
                </div>

                <div class="dvglobalInfo_3 form-buttons">
                    <button *ngIf="isDisplayLoginButton" id="btnSubmit" type="submit"
                        class="btn btn-full-width cursorpoint btn-green" data-key-config="Submit"
                        style="background-color: #3ab16d;color: white;border-radius: 5px;width: 100%;"
                        (click)="onSignin()">{{SignInText}}</button>
                    <button *ngIf="isDisplaySignUpButton" id="btnSubmit" type="submit"
                        class="btn btn-full-width cursorpoint btn-green" data-key-config="Submit"
                        style="background-color: #3ab16d;color: white;border-radius: 5px;width: 100%;"
                        (click)="onSignup()">{{SignUpText}}</button>
                </div>

                <div class="dvglobalInfo_3">
                    <p *ngIf="isExpired && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('RSignSignUp','self')"
                            (ontouchstart)="redirectCorporateURL('RSignSignUp','self')">
                            {{LearnMoreTextOnly}}
                        </a>
                    </p>
                    <p *ngIf="isExpired && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('FreeTrail','self')"
                            (ontouchstart)="redirectCorporateURL('FreeTrail','self')">
                            {{freeTrial}}
                        </a>
                    </p>

                    <input *ngIf="isSendConfirmationEmail" type="hidden" id="hdnLandingPageUrl"
                        value="@ViewBag.LandingPageUrl" />
                    <p *ngIf="isSendConfirmationEmail && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('RSignTrainingVideos','self')"
                            (ontouchstart)="redirectCorporateURL('RSignTrainingVideos','self')"> {{LearnMoreTextOnly}}
                        </a>
                    </p>

                    <p *ngIf="isSuccessWarning && isSendConfirmationEmail" class="plswait"
                        style="font-weight:bold;padding-top:10px"> {{RedirectPostSigningLandingPage}}
                        <span id="countdown" class="timer" style="color:red;"></span>
                        {{Sessionpopupseconds}}
                    </p>

                    <p *ngIf="notExpiredAndEmail && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('RSignSignUp','self')"
                            (ontouchstart)="redirectCorporateURL('RSignSignUp','self')"> {{LearnMoreTextOnly}} </a>
                    </p>

                    <p *ngIf="notExpiredAndEmail && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('FreeTrail','self')"
                            (ontouchstart)="redirectCorporateURL('FreeTrail','self')"> {{freeTrial}} </a>
                    </p>

                </div>

                <div id="formContent" class="dvglobalInfo_2">
                    <p *ngIf="isApplicationErrorFlag" class="ptextAlign" style="font-size: 13px;">
                        {{this.DateTimeMessage1}}
                        {{this.DateTimeMessage2}}
                    </p>
                </div>
            </div>

            <div *ngIf="isAutoLocked == true" class="divMainWrapper user_card">
                <div id="formContent" class="dvglobalInfo_2">
                    <div class="dvdevices">
                        <div *ngIf="isEmptyEnvelopeTemplateCode == true">
                            <img src="../../../assets/images/accessdenied.svg" style="width:80px;" />
                            <h5 class="Globalinfo_1 mt-3 mb-0 font-w600" style="font-weight: 600; font-size: 18px; color: black">{{getLanguageTranslationValue(resourceKeys.AIAccessDenied)}}</h5>
                            <h5 class="Globalinfo_1 mt-3 mb-0 font-w600" style="font-size: 14px; color: black">{{getLanguageTranslationValue(resourceKeys.AIAccessText)}}</h5>
                        </div>

                        <div *ngIf="isEmptyEnvelopeTemplateCode == false">
                            <div *ngIf="autoLockResp.AutoLockMessage == 'RequestAlreadySent' || autoLockResp.AutoLockMessage == 'RequestDeclined'">
                                <img *ngIf="autoLockResp.AutoLockMessage == 'RequestAlreadySent'" src="../../../assets/images/success_info.png" style="width:80px;" />
                                <h5 *ngIf="autoLockResp.AutoLockMessage == 'RequestAlreadySent'" class="Globalinfo_1 mt-3 mb-0 font-w600" style="font-weight: 600; font-size: 16px; color: black">{{getLanguageTranslationValue(resourceKeys.AINotice)}}</h5>
                                <h5 *ngIf="autoLockResp.Type == 'Envelope' && autoLockResp.AutoLockMessage == 'RequestAlreadySent'" class="GlobalAutoLockerror mt-3 mb-0 font-w600" style="font-size: 12px;">{{getLanguageTranslationValue(resourceKeys.EnvelopeID)}}:  {{autoLockResp.EnvelopeTemplateCode}}</h5>

                                <img *ngIf="autoLockResp.AutoLockMessage == 'RequestDeclined'" src="../../../assets/images/accessdenied.svg" style="width:80px;" />
                                <h5 *ngIf="autoLockResp.AutoLockMessage == 'RequestDeclined'" class="Globalinfo_1 mt-3 mb-0 font-w600" style="font-weight: 600; font-size: 16px; color: black">{{getLanguageTranslationValue(resourceKeys.AIAccessDenied)}}</h5>
                                <h5 *ngIf="autoLockResp.AutoLockMessage == 'RequestDeclined'" class="Globalinfo_1 mt-3 mb-0" style="font-size: 16px; color: black">{{getLanguageTranslationValue(resourceKeys.DocumentAccessDeniedText)}}</h5>
                                
                                 <!-- <h5 class="GlobalAutoLockerror" style="font-weight: 600;font-size: 12px;margin-top: 10px;">Subject:  {{autoLockResp.Subject}}</h5>  -->
                            </div>

                            <div *ngIf="autoLockResp.AutoLockMessage == 'AIAutoLockEnabled'">
                                <div id="divAccessdenied">
                                    <img src="../../../assets/images/accessdenied.svg" style="width:80px;" />
                                    <h5 class="Globalinfo_1 mt-3 mb-0 font-w600" style="font-weight: 600; font-size: 18px; color: black">{{getLanguageTranslationValue(resourceKeys.AIAccessDenied)}}</h5>
                                    <h5 class="GlobalAutoLockerror" style="font-size: 14px;margin-top: 10px;">{{getLanguageTranslationValue(resourceKeys.AiAccessDeniedForSender)}}</h5>     

                                    <input type="hidden" id="hdnReferenceId" value="{{autoLockResp.ReferenceId}}" />
                                    <input type="hidden" id="hdnEnvelopeTemplateCode" value="{{autoLockResp.EnvelopeTemplateCode}}" />
                                    <input type="hidden" id="hdnType" value="{{autoLockResp.Type}}" />
                                    <input type="hidden" id="hdnSenderEmail" value="{{autoLockResp.SenderEmail}}" />
                                    <input type="hidden" id="hdnSenderRecId" value="{{autoLockResp.SenderRecId}}" />
                                    <input type="hidden" id="hdnRecipientEmail" value="{{autoLockResp.RecipientEmail}}" />
                                    <input type="hidden" id="hdnRecipientID" value="{{autoLockResp.RecipientID}}" />
                                    <input type="hidden" id="hdnFeature" value="{{autoLockResp.Feature}}" />
                                    <input type="hidden" id="hdnRecipientMobile" value="{{autoLockResp.RecipientMobile}}" />
                                    <input type="hidden" id="hdnRecipientDeliveryMode" value="{{autoLockResp.RecipientDeliveryMode}}" />
                                    <input type="hidden" id="hdnRecipientDialCode" value="{{autoLockResp.RecipientDialCode}}" />
                                    <input type="hidden" id="hdnRecipientType" value="{{autoLockResp.RecipientType}}" />
                                    <input type="hidden" id="hdnIPAddress" value="{{autoLockResp.IPAddress}}" />
                                </div>
                            </div>

                            <div id="divConfirmRequestAccessSent" style="display:none">
                                <img src="../../../assets/images/success_info.png" style="width:60px" />
                                <h5 class="Globalinfo_1" style="font-weight: 600; font-size: 18px; color: black">{{getLanguageTranslationValue(resourceKeys.AIAccessRequest)}}</h5>
    
                                <p class="ptextAlign" style="margin-top: -5px; margin-left: 0px !important; ">
                                    {{getLanguageTranslationValue(resourceKeys.AIAccessReqBody)}}
                                </p>
                            </div>

                        </div>
                    </div>  

                    <div *ngIf="isEmptyEnvelopeTemplateCode == false && autoLockResp.AutoLockMessage == 'AIAutoLockEnabled'" class="dvglobalInfo_3 form-buttons" style="
                    padding-bottom: 0px;">
                        <button id="btnRequestAccess" type="submit"  class="btn btn-full-width cursorpoint btn-green" data-key-config="Submit" tabindex="2"
                            (click)="onRequestAccessClick()" style="background-color: #3ab16d;color: white;border-radius: 5px;width: 100%;">{{getLanguageTranslationValue(resourceKeys.AIRequestAccess)}}</button> 

                            <h5 id="h5EnvelopeCode" *ngIf="autoLockResp.Type == 'Envelope'" class="GlobalAutoLockerror mt-3 mb-0 font-w600" style="font-size: 12px;">{{getLanguageTranslationValue(resourceKeys.EnvelopeID)}}:  {{autoLockResp.EnvelopeTemplateCode}}</h5>
                            <h5 id="h5EnvelopeCode" *ngIf="autoLockResp.Type == 'Template'" class="GlobalAutoLockerror mt-3 mb-0 font-w600" style="font-size: 12px;">{{getLanguageTranslationValue(resourceKeys.Subject)}}:  {{autoLockResp.Subject}}</h5>
                   </div>                     
                </div>
            </div>
        </div>
    </div>
</main>

<div [ngStyle]="{'display':dvAutoLockContinuePopup}" id="dvAutoLockContinuePopup" class="modal" tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document" style="max-width: 470px;">
        <div class="modal-content" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.AIReaonForAccess)}} </h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close" (click)="closedvAutoLockContinuePopup()">
                        close
                    </span>
                </div>
            </div>

            <div class="modal-body modal-bodybg py-3">
                <div class="d-flex flex-wrap">
                    <div class="formContent">
                        <div class="front-arrow" style="text-align: left;">  
                            <textarea id="txtReasonComments" rows="3" cols="2" style="width: 430px; height: 100px; max-width: 430px; max-height: 100px; padding-left: 5px;font-size: 14px;resize:none;" maxlength="250" placeholder="Optional"></textarea>
                        </div>                        
                    </div>
                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer bg-modal modalfooter-padding">
                <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnAutoLockContinue" (click)="onAutoLockContinue()"               
                style="background-color: #2486fc;color: white;">{{getLanguageTranslationValue(resourceKeys.Continue)}}</button>
            </div>
        </div>
    </div>
</div>



<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div style="font-size: 10px;">
        <div class="fotertext" [innerHTML]="htmlFooterString">
        </div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>

