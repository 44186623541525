import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants, EmailAddressFormat, EnvelopeStatus, ResourceKeys, RouteUrls, StaticUrlWatingTime } from '../../constants/common-constants';
import { SignerLandingService } from '../../services/signer-landing.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SignerRegistrationComponent } from '../signer-registration/signer-registration.component';
import { SignerIntermediateComponent } from '../signer-intermediate/signer-intermediate.component';
import { SignerIntermediateContinueComponent } from '../signer-intermediate-continue/signer-intermediate-continue.component';
import { TranslationsService } from 'src/app/services/translations.service';
import { SignerIntermediateCloseComponent } from '../signer-intermediate-close/signer-intermediate-close.component';
@Component({
    selector: 'app-static-decline-modal',
    templateUrl: './static-decline-modal.component.html',
    styleUrls: ['./static-decline-modal.component.scss']
})
export class StaticDeclineModalComponent implements OnInit {
    commonConstants = CommonConstants;
    declinePopupStyle: string = 'block';
    waitModalPopupStyle: string = 'none';
    showLoader: boolean = false;
    declinedObj: any = {
        id: 0,
        value: ''
    }
    rejectText: string = '';
    title: string = '';
    selectAtleastOneOption: string = '';
    errorTitle: string = '';
    reasonList: any = [];
    declineparentObj: any;
    controlType: any;
    selectedDeclineReasonID: any;
    isStatic: any = false;
    IsOtherCommentsTextEntered: boolean = false;
    declineTemplateResponsesData: any = [];
    changedSignerEmail: any;
    changedSignerMobile: any;
    changedSignerMobileText: any;
    isToShowchangedSignerMobileText: boolean = false;
    timerValue: any;
    timer: any;
    counter: any;
    resourceKeys = ResourceKeys;
    enableMessageToMobile: boolean = false;
    DialCodeDropdownList: any;
    documentControlsResponse: any;
    mobilenumber: any = "";
    deliverymode: any = "";
    countrycode: any = "";
    dialcode: any = "";
    slCountryMaxMobileLength: any;
    AllowSignersDownloadFinalContract: boolean = false;

    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private signerLandingService: SignerLandingService,
        private modalService: NgbModal,
        private router: Router,
        private commonService: CommonService,
        private translationsService: TranslationsService
    ) { }

    ngOnInit(): void {
        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        this.documentControlsResponse = JSON.parse(documentControlsResp);
        let envelopeInfoResp: any = localStorage.getItem("EnvelopeInfo");
        this.documentControlsResponse.EnvelopeInfo = JSON.parse(envelopeInfoResp);

        this.selectAtleastOneOption = this.commonConstants.SelectAtleastOneOption;
        this.errorTitle = this.commonConstants.ErrorTitle;
        this.declineparentObj = this;
        this.isStatic = localStorage.getItem("isStatic");
        let isStaticTemplate: boolean = Boolean(this.isStatic);
        let envelopeId: any = localStorage.getItem("EnvelopeID");
        this.showLoader = true;
        let url = this.commonConstants.GetDeclineTemplate + '/' + envelopeId + '/' + isStaticTemplate;
        this.signerLandingService.getDeclineTemplate(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                if (resp && resp.Data && resp.Data.DeclineTemplateResponsesList) {
                    this.title = resp.Data.Title;
                    this.controlType = resp.Data.ControlType;
                    this.reasonList = resp.Data.DeclineTemplateResponsesList;
                }

                this.enableMessageToMobile = (this.documentControlsResponse.EnableMessageToMobile == null || this.documentControlsResponse.EnableMessageToMobile == false) ? false : true;
                this.DialCodeDropdownList = this.documentControlsResponse.DialCodeDropdownList;
                this.AllowSignersDownloadFinalContract = true;//this.documentControlsResponse.AllowSignersDownloadFinalContract;

                let MyDetailsVal: any = localStorage.getItem("MyDetailsVal");
                if (MyDetailsVal != "" && MyDetailsVal != null && MyDetailsVal != undefined) {
                    MyDetailsVal = MyDetailsVal = JSON.parse(MyDetailsVal);
                    this.mobilenumber = MyDetailsVal.mobilenumber;
                    this.deliverymode = MyDetailsVal.deliverymode;
                    this.countrycode = MyDetailsVal.countrycode;
                    this.dialcode = MyDetailsVal.dialcode;
                    localStorage.setItem("recipientEmail", MyDetailsVal.myEmail);
                }
                else {
                    let localConfirmDetailsVal: any = localStorage.getItem("ConfirmDetailsVal");
                    if (localConfirmDetailsVal != null && localConfirmDetailsVal != "" && localConfirmDetailsVal != undefined) {
                        let confirmDetailsVal = JSON.parse(localConfirmDetailsVal);
                        this.mobilenumber = confirmDetailsVal.mobilenumber;
                        this.deliverymode = confirmDetailsVal.deliverymode;
                        this.countrycode = confirmDetailsVal.countrycode;
                        this.dialcode = confirmDetailsVal.dialcode;
                    }
                }

                this.disableFileds();
            });
    }

    onOtherCLick(selectedRadio: any, reason: any, type: any) {
        if ((reason.ID.toString() === "999" || reason.ID.toString() === 999) && reason.ResponseText === 'Other') {
            let radioOrCheckboxEle: any = 'radio_' + reason.ID;
            if (this.controlType === 2) {
                radioOrCheckboxEle = 'checkbox_' + reason.ID;
            }
            if ((<HTMLInputElement>document.getElementById(radioOrCheckboxEle)).checked === true) {
                selectedRadio.style.display = 'block';
            } else {
                selectedRadio.style.display = 'none';
            }
        }
        if (type === 'radio' && (reason.ID.toString() !== "999" || reason.ID.toString() !== 999) && reason.ResponseText !== 'Other') {
            selectedRadio.style.display = 'none';
        }
    }

    showToastrError(msg: string, type: string) {
        this.toastr.error(msg, type, {
            timeOut: 2000
        });
    }

    showToastrWarning(msg: string) {
        this.toastr.warning(msg, 'Warning', {
            timeOut: 2000
        });
    }

    isEmail(email: any) {
        // var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        let re = EmailAddressFormat.ValidEmail;
        return re.test(email);
    }

    returnEmailValidation() {
        var isValid = true;
        let CurrentEmail: any = localStorage.getItem("recipientEmail");
        let recptMail: any = document.getElementById("changedSignerEmail")!;
        let recptMailValue = recptMail.value;
        if (recptMailValue == "") {
            isValid = false;
            this.showToastrWarning("Please provide email.");
            return isValid;
        }
        if (!this.isEmail(recptMailValue)) {
            isValid = false;
            this.showToastrWarning("Invalid email address");
            return isValid;
        }
        if (recptMailValue != CurrentEmail) {
            isValid = false;
            this.showToastrWarning("Email address not matched.");
            return isValid;
        }
        return isValid;
    }
    returnMobileValidation() {
        let isValidMobile = true;
        let mobilenumberVal: any = "";
        let txtDeclineSignerMobileNumber: any = document.getElementById("txtDeclineSignerMobileNumber")!;
        mobilenumberVal = txtDeclineSignerMobileNumber.value;
        if (mobilenumberVal == "" || mobilenumberVal == null) {
            isValidMobile = false;
            this.showToastrWarning("Please provide the mobile number.");
            return isValidMobile;
        }
        else if (!this.isValidPhone(mobilenumberVal)) {
            isValidMobile = false;
            this.toastr.warning('Please provide valid mobile number.', 'Warning', { timeOut: 1000 });
            return isValidMobile;
        }
        if (this.mobilenumber != mobilenumberVal) {
            isValidMobile = false;
            this.showToastrWarning("Mobile number not matched.");
            return isValidMobile;
        }
        return isValidMobile;
    }

    isValidPhone(phonenumber: any) {
        var isvalidPhoneNumber = true;
        if (phonenumber == "" || phonenumber.length != this.slCountryMaxMobileLength) {
            isvalidPhoneNumber = false;
        }
        return isvalidPhoneNumber;
    }

    getFinalValidation() {
        let deliverymode: any = "";
        let mobilenumberVal: any = "";
        let dialCode: any = "";
        let isEmailExists = false;
        let isMobileExists = false;
        let isValid: any = true;
        let isValidMobile: any = true;
        let IsStaticTemplate: any = localStorage.getItem("isStaticTemplate");
        let AllowStaticMultiSigner: any = localStorage.getItem("allowStaticMultiSigner");
        let CurrentEmail: any = localStorage.getItem("recipientEmail");
        let recptMailValue: any = "";
        deliverymode = this.deliverymode;
        dialCode = this.dialcode;
        let isNeedToCheckValidations: any = "0";

        if (Boolean(IsStaticTemplate) && AllowStaticMultiSigner.toLowerCase() == "true") {
            if (this.enableMessageToMobile) {
                isNeedToCheckValidations = "1";
            }
            else {
                isValid = this.returnEmailValidation();
                if (!isValid) {
                    isValid = false;
                    return;
                }
            }
        }
        else {
            if (this.enableMessageToMobile) {
                isNeedToCheckValidations = "1";
            }
            else {
                isValid = this.returnEmailValidation();
                if (!isValid) {
                    isValid = false;
                    return;
                }
            }
        }

        if (isNeedToCheckValidations == "1") {
            if (this.returnEmailDeliveryModeVal(deliverymode)) {
                isValid = this.returnEmailValidation();
                if (!isValid) {
                    isValid = false;
                    return;
                }
            }
            else if (this.returnEmailAndMobileDeliveryModeVal(deliverymode)) {
                let isEmailExists = false; let isMobileExists = false;

                let txtDeclineSignerMobileNumber: any = document.getElementById("txtDeclineSignerMobileNumber")!;
                mobilenumberVal = txtDeclineSignerMobileNumber.value;

                let recptMail: any = document.getElementById("changedSignerEmail")!;
                let recptMailValue = recptMail.value;

                if (mobilenumberVal != "" && mobilenumberVal != null && mobilenumberVal != undefined) {
                    isMobileExists = true;
                    isValid = this.returnMobileValidation();
                    if (!isValid) {
                        isValid = false;
                        return;
                    }
                }

                if (recptMailValue != "" && recptMailValue != null && recptMailValue != undefined) {
                    isEmailExists = true;
                    isValid = this.returnEmailValidation();
                    if (!isValid) {
                        isValid = false;
                        return;
                    }
                }


                if (!isMobileExists && !isEmailExists && !isValid) {
                    isValid = false;
                    this.showToastrWarning("Please provide Email Address or Mobile Number.");
                    return;
                }
            }
            else if (this.returnEmailDeliveryModeVal(deliverymode)) {
                isValid = this.returnMobileValidation();
                if (!isValid) {
                    isValid = false;
                    return;
                }
            }
        }

        if (isValid) {
            let isConfirmationReq = false;
            Array.from(document.getElementsByClassName("emailControlsInput") as HTMLCollectionOf<HTMLElement>)
                .forEach((item: any) => {
                    if (window.getComputedStyle(item).display !== 'none') {
                        if (!isConfirmationReq) {
                            isConfirmationReq = item.attributes["data-submit-required"].value == 1 ? true : false;
                        }
                    }
                });


            if (document.querySelectorAll("input[name='DeclineReasonRadioGroup1']:checked").length > 0 || this.controlType === 3) {
                let declineReasonId: any;
                let comments: any;
                let docOrder: any = 0;
                let count: any = 0;
                var declineTemplateResponses: any = [];
                if (this.rejectText !== null && this.rejectText !== '' && this.rejectText !== 'undefined') {
                    comments = this.rejectText;
                    count = comments.length;
                }
                if (this.controlType === 1) {
                    this.IsOtherCommentsTextEntered = false;
                    Array.from(document.querySelectorAll("input[name='DeclineReasonRadioGroup1']:checked") as NodeListOf<HTMLElement>)
                        .forEach((imgItem: any) => {
                            let responseId: any = imgItem.id;
                            let slDecReasonId: string = responseId.replace("radio_", '');
                            slDecReasonId = slDecReasonId.replace("checkbox_", '');

                            let responseText = imgItem.value;
                            if (responseId == '999' || responseId == 999 || responseText == 'Other' &&
                                (<HTMLInputElement>document.getElementById(responseId)).checked === true) {
                                if (this.rejectText == null || this.rejectText == '') {
                                    this.showToastrWarning(this.errorTitle);
                                    this.IsOtherCommentsTextEntered = false;
                                } else {
                                    this.IsOtherCommentsTextEntered = true;
                                    comments = this.rejectText;
                                }
                                responseText = this.rejectText;
                            } else {
                                comments = responseText;
                                this.IsOtherCommentsTextEntered = true;
                            }

                            docOrder++;
                            declineTemplateResponses.push({
                                ID: parseInt(slDecReasonId),
                                ResponseText: comments,
                                Order: docOrder
                            });
                            this.selectedDeclineReasonID = responseId;
                            this.declineTemplateResponsesData = declineTemplateResponses;
                        })
                }
                else if (this.controlType === 2) {
                    Array.from(document.querySelectorAll("input[name='DeclineReasonRadioGroup1']:checked") as NodeListOf<HTMLElement>)
                        .forEach((imgItem: any) => {
                            let responseId = imgItem.id;
                            let slDecReasonId: string = responseId.replace("radio_", '');
                            slDecReasonId = slDecReasonId.replace("checkbox_", '');
                            let responseText = imgItem.value;
                            if (responseId == '999' || responseId == 999 || responseText == 'Other' &&
                                (<HTMLInputElement>document.getElementById(responseId)).checked === true) {
                                if (this.rejectText == null || this.rejectText == '') {
                                    this.showToastrWarning(this.errorTitle);
                                    this.IsOtherCommentsTextEntered = false;
                                } else {
                                    this.IsOtherCommentsTextEntered = true;
                                }
                                responseText = this.rejectText;
                            } else {
                                this.IsOtherCommentsTextEntered = true;
                            }

                            docOrder++;
                            declineTemplateResponses.push({
                                ID: parseInt(slDecReasonId),
                                ResponseText: responseText,
                                Order: docOrder
                            });
                            this.selectedDeclineReasonID = responseId;
                            this.declineTemplateResponsesData = declineTemplateResponses;
                        })
                }
                else {
                    if (this.rejectText == null || this.rejectText == '') {
                        this.showToastrWarning(this.errorTitle);
                        this.IsOtherCommentsTextEntered = false;
                    } else if (count > 200) {
                        this.showToastrWarning('Comments should not be more than 250 characters.')
                    } else {
                        this.IsOtherCommentsTextEntered = true;
                    }

                    docOrder++;
                    declineTemplateResponses.push({
                        ID: 999,
                        ResponseText: comments,
                        Order: docOrder
                    });
                    this.selectedDeclineReasonID = 999;
                    this.declineTemplateResponsesData = declineTemplateResponses;
                }
            }
            else {
                this.showToastrWarning(this.selectAtleastOneOption);
            }
            if (this.IsOtherCommentsTextEntered) {
                this.rejectStaticLinkTemp(isConfirmationReq);
            }
        }
    }

    getConfirmationEmailReq(ctrlResp: any, isConfirmationReq: boolean) {
        let isStaticTemplate: boolean = false;
        let isSendConfirmationEmail: boolean = ctrlResp.EnvelopeInfo.IsSendConfirmationEmail;
        if (ctrlResp.EnvelopeInfo.IsStatic == true) {
            isStaticTemplate = true;
        }

        let AllowStaticMultiSigner: string = String(ctrlResp.AllowStaticMultiSigner);
        let isConfirmationReqValue: any = isStaticTemplate && AllowStaticMultiSigner.toLowerCase() == 'true' ? isSendConfirmationEmail : (isConfirmationReq ? isConfirmationReq : isSendConfirmationEmail)
        if (isConfirmationReqValue == 'true' || isConfirmationReqValue == true || isConfirmationReqValue == 'TRUE')
            return true;
        else
            return false;
    }

    rejectStaticLinkTemp(isConfirmationReq: boolean) {
        let envelopeId: any = localStorage.getItem("EnvelopeID");
        let staticEnvelopId: any = localStorage.getItem("StaticEnvelopId");
        let recipientId: any = localStorage.getItem("RecipientID");
        let copyMailId: any = localStorage.getItem("CopyMailId");
        let signGlobalTemplateKey: any = localStorage.getItem("SignGlobalTemplateKey");
        let isFileReviewed: any = localStorage.getItem("isFileReviewed");

        if (isFileReviewed == undefined || isFileReviewed == null || isFileReviewed == "null")
            isFileReviewed = "";
        else
            isFileReviewed == String(isFileReviewed);

        let recptMail: any;
        if (this.commonService.returnValBasedOnDeliveryModeVal(this.deliverymode)) {
            let changedSignerEmail: any = document.getElementById("changedSignerEmail")!;
            recptMail = changedSignerEmail.value;
        }

        let CurrentREmail: any = "";
        if (recptMail == undefined || recptMail == null || recptMail == "") {
            CurrentREmail = localStorage.getItem("recipientEmail");
            if (CurrentREmail == undefined || CurrentREmail == null || CurrentREmail == "") {
                CurrentREmail = "";
            }
        }

        if (this.selectedDeclineReasonID) {
            let slDecReasonId: string = this.selectedDeclineReasonID.toString();
            slDecReasonId = slDecReasonId.replace("radio_", '');
            slDecReasonId = slDecReasonId.replace("checkbox_", '');
            this.selectedDeclineReasonID = parseInt(slDecReasonId);
            this.changedSignerEmail = recptMail;
            var envelopeSignDocumentSubmitInfo = {
                StaticTemplateID: envelopeId,
                RecipientEmail: (recptMail == undefined || recptMail == null || recptMail == "") ? CurrentREmail : recptMail,
                IsEnvelopeComplete: false,
                comment: JSON.stringify(this.declineTemplateResponsesData),
                IsConfirmationEmailReq: this.getConfirmationEmailReq(this.documentControlsResponse, isConfirmationReq),
                TemplateKey: signGlobalTemplateKey,
                EnvelopeID: staticEnvelopId,
                DeclineReasonID: parseInt(slDecReasonId),
                IsReviewed: isFileReviewed,
                LanguageCode: localStorage.getItem("cultureInfo"),
                InviteSignerModels: [],
                ControlCollection: [],
                RecipientMobile: this.mobilenumber,
                RecipientDeliveryMode: String(this.deliverymode),
                RecipientCountryCode: this.countrycode,
                RecipientDialCode: this.dialcode,
            };

            let url = this.commonConstants.CreateStaticEnvelope;
            this.showLoader = true;
            this.signerLandingService.postcall(url, envelopeSignDocumentSubmitInfo).subscribe(
                (resp: any) => {
                    this.showLoader = false;
                    let returnUrl: any = '';
                    let encryptedSender: any = '';
                    let DisplayMessage: any = '';
                    let LanguageID: any = localStorage.getItem("cultureInfo");
                    let Success: boolean = false;
                    let MessageType: any = '';

                    if (resp && resp.StatusCode == 200) {
                        Success = true;
                        DisplayMessage = resp.message;
                        MessageType = "Success";

                        localStorage.setItem("MessageType", MessageType);
                        localStorage.setItem("DisplayMessage", DisplayMessage);
                        localStorage.setItem("LanguageID", LanguageID);

                        if (resp.returnUrl == "Info/Index") {
                            if (envelopeSignDocumentSubmitInfo.IsConfirmationEmailReq) {
                            }
                            if (resp.InfoSenderEmail != null && resp.InfoSenderEmail != "") {
                                returnUrl = RouteUrls.Info;
                            }
                            else {
                                returnUrl = RouteUrls.Info;
                            }
                        }
                        else {
                            returnUrl = resp.returnUrl;
                        }

                        if (returnUrl != '') {
                            if (isConfirmationReq) {
                                this.activeModal.dismiss('Cross click');
                                this.commonService.getReturnUrlDirection(returnUrl);
                            }
                            else if (resp.postSigningLogin == false) {
                                this.activeModal.dismiss('Cross click');
                                this.commonService.getReturnUrlDirection(returnUrl);
                            }
                            else {
                                this.activeModal.dismiss('Cross click');
                                let modalPopupObj = {
                                    returnUrl: returnUrl,
                                    MEmail: resp.data.EmailAddress,
                                    spEmail: resp.data.EmailAddress,
                                    isSignedDocumentService: false,
                                    type: "decline"
                                };

                                if (this.AllowSignersDownloadFinalContract == true) {
                                    if (resp.data.Order == 0) {
                                        if (resp.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Completed || resp.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Terminated) {
                                            const modalRef = this.modalService.open(SignerRegistrationComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerRegistration-class' });
                                            modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                        }
                                        else {
                                            const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                            modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                        }
                                    }
                                    else if (resp.data.Order == 1) {
                                        if (resp.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Completed || resp.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Terminated) {
                                            const modalRef = this.modalService.open(SignerIntermediateComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                            modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                        }
                                        else {
                                            const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                            modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                        }
                                    }
                                    else if (resp.data.Order == 2) {
                                        const modalRef = this.modalService.open(SignerIntermediateContinueComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediateContinue-class' });
                                        modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                    }
                                    else {
                                        this.commonService.getReturnUrlDirection(returnUrl);
                                    }
                                }
                                else {
                                    this.commonService.getReturnUrlDirection(returnUrl);
                                }
                            }
                        }
                        else {
                            this.showToastrWarning(resp.message);
                        }
                    }
                    else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 400) {
                        Success = false;
                        MessageType = "Warning";
                        DisplayMessage = resp.message;
                        localStorage.setItem("MessageType", MessageType);
                        localStorage.setItem("DisplayMessage", DisplayMessage);
                        localStorage.setItem("LanguageID", LanguageID);

                        if (resp.originalError.error.field == "Staticdelay") {
                            let watingTime = parseInt(StaticUrlWatingTime.StaticUrlWatingTimeInSeconds);
                            this.counter = watingTime - resp.originalError.error.data;
                            this.declinePopupStyle = "none";
                            this.waitModalPopupStyle = "block";
                            var rectDeliveryMode: any = envelopeSignDocumentSubmitInfo.RecipientDeliveryMode;

                            if (rectDeliveryMode == "1" || rectDeliveryMode == "11") {
                                this.changedSignerEmail = envelopeSignDocumentSubmitInfo.RecipientEmail + " ";
                            }
                            else if (this.returnEmailAndMobileDeliveryModeVal(rectDeliveryMode)) {
                                var emailtext = envelopeSignDocumentSubmitInfo.RecipientEmail;
                                let dialText = envelopeSignDocumentSubmitInfo.RecipientDialCode;
                                let recpMobiletext = envelopeSignDocumentSubmitInfo.RecipientMobile;
                                var mobileText = envelopeSignDocumentSubmitInfo.RecipientDialCode + envelopeSignDocumentSubmitInfo.RecipientMobile;
                                var emailmobileText = "";
                                if (emailtext != "" && emailtext != null && dialText != "" && dialText != null && recpMobiletext != "" && recpMobiletext != null) {
                                    // emailmobileText = emailtext + " " + this.translationsService.getLanguageTranslationVal(ResourceKeys.orText) + " " + this.translationsService.getLanguageValidationVal("lang_Mobile") + " " + mobileText;
                                    emailmobileText = emailtext;
                                    this.isToShowchangedSignerMobileText = true;
                                    this.changedSignerMobileText = " " + this.translationsService.getLanguageTranslationVal(ResourceKeys.orText) + " " + this.translationsService.getLanguageValidationVal("lang_Mobile") + " ";
                                    this.changedSignerMobile = mobileText;
                                }
                                else if (emailtext != "" && emailtext != null) {
                                    this.isToShowchangedSignerMobileText = false;
                                    emailmobileText = emailtext;
                                }
                                else if (dialText != "" && dialText != null && recpMobiletext != "" && recpMobiletext != null) {
                                    this.isToShowchangedSignerMobileText = false;
                                    emailmobileText = mobileText;
                                }

                                this.changedSignerEmail = emailmobileText + " ";
                            }
                            else if (rectDeliveryMode == "4" || rectDeliveryMode == "6") {
                                this.changedSignerEmail = envelopeSignDocumentSubmitInfo.RecipientDialCode + envelopeSignDocumentSubmitInfo.RecipientMobile + " ";
                            }
                            else {
                                this.changedSignerEmail = envelopeSignDocumentSubmitInfo.RecipientEmail + " ";
                            }

                            this.timerValue = (Math.floor(this.counter / 60)).toString() + ":" + this.addZero(Math.floor((this.counter % 60))).toString();
                            clearTimeout(this.timer);
                            this.timer = setTimeout(() => { this.myTimer() }, 1000);
                            return;
                        }

                        if (resp.returnUrl == "Info/Index") {
                            if (resp.InfoSenderEmail != null && resp.InfoSenderEmail != "") {
                                // let encryptURL = CommonConstants.EncryptQueryParamString + '/' + resp.InfoSenderEmail;
                                // this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
                                //     (encryptresp: any) => {
                                //         console.log('getEncryptQueryString', encryptresp);
                                //         encryptedSender = encryptresp.EnvryptedEncodedText;
                                //     });
                                returnUrl = RouteUrls.Info;
                                // localStorage.setItem("encryptedSender", encryptedSender);
                            }
                            else {
                                returnUrl = RouteUrls.Info;
                            }
                        }
                        else {
                            returnUrl = resp.returnUrl;
                        }
                    }
                });
        } else {
            this.showToastrWarning(this.selectAtleastOneOption);
        }
    }

    //other text filed
    textAreaInput(e: any) {
        this.rejectText = e.target.value;
    }

    addZero(i: any) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    myTimer() {
        this.counter--;
        if (this.counter < 0) {
            let changedSignerEmailtxt: any = document.getElementById("changedSignerEmailtxt");
            let timerValuetxt = document.getElementById("timerValuetxt");
            let proceddNowtxt = document.getElementById("proceddNowtxt");
            changedSignerEmailtxt!.style['display'] = "none";
            timerValuetxt!.style['display'] = "none";
            proceddNowtxt!.style['display'] = "block";
            clearTimeout(this.timer);
        } else {
            this.timerValue = (Math.floor(this.counter / 60)).toString() + ":" + this.addZero(Math.floor((this.counter % 60))).toString();
            this.timer = setTimeout(() => { this.myTimer() }, 1000);
        }
    }
    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    selectedCountryDialCode(event: any) {
        if (this.enableMessageToMobile) {
            $($('#DeclineDialCodeUl li a')[0]).css('background-color', '');
            let ed: any = event.target;
            let edd = event.target.parentElement.innerHTML;
            let spnTitle: any = document.getElementById("spnDeclineTitle");
            let dataCountryCodeMaxLength: any;
            let txtSignerMobileNumber: any = document.getElementById("txtDeclineSignerMobileNumber");
            if (spnTitle != null) {
                spnTitle.innerHTML = '';
                spnTitle.innerHTML = edd;

                let spnCountryFlagCol: any = document.getElementById("spnDeclineTitle")!.getElementsByTagName("span");
                if (spnCountryFlagCol && spnCountryFlagCol.length > 0) {
                    document.getElementById(spnCountryFlagCol[0].id)!.style.top = "4px";
                    $('#DeclineDialCodeUl li a').removeClass('selected');
                    $('#DeclineDialCodeUl li ').removeClass('selected');
                    if (event.target.parentElement.getElementsByTagName('a').length > 0) {
                        dataCountryCodeMaxLength = event.target.parentElement.getElementsByTagName('a')[0].getAttribute("data-maxlength");
                        event.target.parentElement.getElementsByTagName('a')[0].classList.add('selected');
                    }
                    else {
                        dataCountryCodeMaxLength = event.target.parentElement.getAttribute("data-maxlength");
                        event.target.parentElement.classList.add('selected');
                    }
                    txtSignerMobileNumber.value = '';
                    if (dataCountryCodeMaxLength != null && dataCountryCodeMaxLength != undefined) {
                        txtSignerMobileNumber.setAttribute('maxlength', dataCountryCodeMaxLength);
                        this.slCountryMaxMobileLength = dataCountryCodeMaxLength;
                    }
                }
            }
        }
    }

    // isNumberKeyVal(evt: any) {
    //     var regExpNumber = /[0-9]/;
    //     var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
    //     if (regExpNumber.test(charCode)) {
    //         return true;
    //     } else {
    //         evt.preventDefault();
    //         this.toastr.warning('Please enter only a number.', 'Warning', { timeOut: 1000 });
    //         return false;
    //     }
    // }

    validateInputValue(event: any) {
        let txtSignerMobileNumber: any = event.target.value;
        if (!this.commonService.isNumberExists(txtSignerMobileNumber)) {
            event.target.value = '';
            this.toastr.warning('Please enter only a number.', 'Warning', { timeOut: 1000 });
        }
    }

    disableFileds() {
        setTimeout(() => {
            if (this.enableMessageToMobile) {
                if (this.dialcode != null && this.dialcode != "" && this.dialcode != undefined) {
                    let signerCode = "bg-" + this.countrycode.toLowerCase();
                    let spnTitle: any = document.getElementById("spnDeclineTitle");
                    if (spnTitle != null) {
                        spnTitle.innerHTML = '';
                        spnTitle.innerHTML = '<a style="text-decoration-line: none; color: #000;"><span id="spnCountryFlag" style="top: 4px;display: inline-block;width: 25px;position: relative;" class="' + signerCode + '"></span>' + this.dialcode + '</a>';

                        spnTitle?.setAttribute('disabled', 'disabled');
                        spnTitle.style.cursor = "no-drop";
                        let DeclineDialCodeUl: any = document.getElementById("DeclineDialCodeUl");
                        DeclineDialCodeUl.style.display = 'none';
                        let ddrCountryFlag: any = document.getElementById("ddrCountryFlag");
                        ddrCountryFlag?.setAttribute('disabled', 'disabled');
                    }
                }

                if (this.mobilenumber != null && this.mobilenumber != "" && this.mobilenumber != undefined) {
                    let txtSignerMobileNumber: any = document.getElementById("txtDeclineSignerMobileNumber");
                    if (txtSignerMobileNumber != null && txtSignerMobileNumber != undefined) {
                        txtSignerMobileNumber.setAttribute('maxlength', this.mobilenumber.length);
                        $('#txtDeclineSignerMobileNumber').attr('maxlength', this.mobilenumber.length);
                        this.slCountryMaxMobileLength = this.mobilenumber.length;
                        let ddrCountryFlag: any = document.getElementById("ddrCountryFlag");
                        ddrCountryFlag.style.cursor = "no-drop";
                        ddrCountryFlag?.setAttribute('disabled', 'disabled');
                    }
                }

                if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                    if (this.commonService.returnValBasedOnDeliveryMode(this.deliverymode)) {
                        let txtDeclineSignerMobileNumber: any = document.getElementById("txtDeclineSignerMobileNumber");
                        txtDeclineSignerMobileNumber.style["width"] = '169px';
                    }
                    if (this.commonService.returnValBasedOnDeliveryModeVal(this.deliverymode)) {
                        let changedSignerEmail: any = document.getElementById("changedSignerEmail");
                        changedSignerEmail.style["width"] = '314px';
                    }
                }
            }
            else {
                if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                    let changedSignerEmail: any = document.getElementById("changedSignerEmail");
                    changedSignerEmail.style["width"] = '314px';
                }
            }
        }, 100);
    }

    returnEmailAndMobileDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnEmailAndMobileDeliveryModeVal(deliverymode);
    }

    returnEmailDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnEmailBasedOnDeliveryModeVal(deliverymode);
    }

    returnMobileDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnMobileDeliveryModeVal(deliverymode);
    }

    returnShowEmailBasedOnDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnShowEmailBasedOnDeliveryModeVal(deliverymode);
    }

    returnShowMobileDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnShowMobileDeliveryModeVal(deliverymode);
    }
}
