import { Component, LOCALE_ID, ViewChild, Renderer2, ElementRef, OnInit, HostListener, Output, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { SignerLandingService } from '../../services/signer-landing.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbInputDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonConstants, Defaults, InitialType, RouteUrls, SignatureType, ResourceKeys, EnvelopeStatus, EmailAddressFormat, DateFormatString, RecipientType, Signer, DateFormatGuid, ActionTypes } from '../../constants/common-constants';
import { SignaturePadModalComponent } from 'src/app/modal/signature-pad-modal/signature-pad-modal.component';
import { CommonService } from '../../services/common.service';
import { Observable } from 'rxjs';
import { Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import * as moment from 'moment';
import { parseInt } from 'lodash';
const jstz = require("jstimezonedetect");
import 'moment-timezone';
import { TranslationsService } from '../../services/translations.service';
import { InitialsPadComponent } from 'src/app/modal/initials-pad/initials-pad.component';
import { Router } from '@angular/router';
import { SignerRegistrationComponent } from '../../modal/signer-registration/signer-registration.component';
import { SignerIntermediateComponent } from '../../modal/signer-intermediate/signer-intermediate.component';
import { SignerIntermediateContinueComponent } from '../../modal/signer-intermediate-continue/signer-intermediate-continue.component';
import { ChangeDetectorRef } from '@angular/core';
import * as $ from 'jquery';
import { StyleService } from '../../services/style.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SignerIntermediateCloseComponent } from 'src/app/modal/signer-intermediate-close/signer-intermediate-close.component';
import { Title } from '@angular/platform-browser';
import { ClickToSignService } from 'src/app/services/clicktosign.service';
import { ExpandableService } from 'src/app/services/expandable.service';

@Component({
    selector: 'app-signer-landing',
    templateUrl: './signer-landing.component.html',
    styleUrls: ['./signer-landing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignerLandingComponent implements OnInit, OnDestroy {

    @ViewChild('customeDate', { static: true }) customDate: any;
    model: any;
    //$documentImg is subject and its updating value pagination changes.
    $documentImg: Observable<string> | undefined;

    @ViewChild('termsOfService', { static: false }) private termsOfService: any;
    @ViewChild('clickToSign', { static: false }) private clickToSign: any;
    @ViewChild('fileReview', { static: false }) private fileReview: any;
    @ViewChild('pinchZoom', { static: false }) private pinchZoom: any;
    @ViewChild('dateControl', { static: false }) private dateControl: any;
    @ViewChild('dateTextDate', { static: false }) private dateTextDate: any;
    @ViewChild('dControl', { static: false }) dControll: NgbInputDatepicker | undefined;
    //documentData passing to pagination component
    @Output() documentData: any;
    commonConstants = CommonConstants;
    resourceKeys = ResourceKeys;
    //documentPageContainer is all pages container
    @ViewChild('documentPageContainer', { static: false })
    documentPageContainer!: ElementRef;
    // model: Date = new Date();
    imageSize: any = 1;
    zoomingVal: any = 0.5;
    percentageValue: string = '';
    showLoader: boolean = true;
    isFavorite: boolean = true;
    documentControlsResponse: any;
    documentCheckListsResponse: any;
    hideFilterSection: boolean = true;
    hidePagesViewSection: boolean = false;
    showControlSignatureTooltip: boolean = false;
    showControlCompanyTooltip: boolean = false;
    showControlNameTooltip: boolean = false;
    showControlInitialsTooltip: boolean = false;
    showControleDateTimeStampTooltip: boolean = false;
    showControlEmailTooltip: boolean = false;
    showControlDateTooltip: boolean = false;
    showControlTextTooltip: boolean = false;
    showControlTitleTooltip: boolean = false;
    toDate: any = null;
    pageViewArr: any;
    documentWidth: any;
    doc1: any;
    doc2: any;
    id: any;
    popupWin: any;
    searchTerm!: string;
    searchTermDisplay!: string;
    currentIndex: any = 0;
    show: boolean = false;
    buttonName: any = 'Show';
    hideFilter: boolean = true;
    fieldsRemainingFlag: boolean = false;
    requiredFlag: boolean = false;
    filterControlInfo: string = '';
    showDefaultSignatureContol: any;
    defaultSignatureValue: any;
    defaultSignatureObj: any;
    cloneDocumentCheckListsResponse: any = [];
    validateFlag: boolean = false;
    EnvelopeID: any;
    element: any;
    baseUrl = environment.apiBaseUrl;
    ipAddress: string = '';
    requiredFields: string = 'Please enter required fields.';
    invalidEmail: string = "Invalid email address.";
    invalidDate: string = 'Invalid date.';
    invlaidSSN: string = 'Invalid ssn.';
    invlaidZIP: string = 'Invalid Zip.';
    fileReviewInfo: any = [];
    dvDGIntermediateDisplayStyle: string = 'none';
    dgIntermediateReturnURL: any;
    signAnotherDocumentUrl: any;
    backToMultiViewUrl: any;
    totalRemainigFilledControls: any = 0;
    remainingFieldsTotalCount: any = 0;
    remainingFieldsCount: any = 0;
    attachmentList: any;
    attachmentCount: any = 0;
    lastTabIndex: any;
    attachmentListCount: any = 0;
    pagePreviewTotalPages: any = 0;
    pinchZoomDisabled: any = true;
    webOrMobile: any = 'web';
    mobileControlArr: any = [];
    mobileControlArrReverse: any = [];
    tabIndex: any = 0;
    clicktoSignAgree: boolean = false;
    clickToSignNote: string = '';
    clickToSignAgrrement: string = '';
    checkboxCheckedImageSrc: string = "../../../assets/images/checked.jpg";
    checkboxNotCheckedImageSrc: string = "../../../assets/images/unchecked.jpg";
    radioCheckedImageSrc: string = "../../../assets/images/radio.jpg";
    radioNotCheckedImageSrc: string = "../../../assets/images/unradio.jpg";
    checkedCheckboxImagesrc: string = "";
    checkedRadioImagesrc: string = "";
    IsStaticTemplate: boolean = false;
    isSignerpopupCount: any = "0";
    responseSigningUrlModel: any;
    isFileReviewed: string = "";
    myArray: any = [];
    allSignControls: any = [];
    IsEnableClickToSign: boolean = false;
    languageValidation: any;
    defaultSignatureFlag: boolean = false;
    envelopeSignDocumentSubmitRequestInfo: any;
    IsSignerAttachFileReqNew: any;
    IsPrefill: any;
    DisableDownloadOptionOnSignersPage: any;
    IsAdditionAttamRequest: boolean = false;
    ShowAttachmentNavMenu: boolean = false;
    ShowFinishLaterNavMenu: boolean = false;
    ShowDeclineNavMenu: boolean = false;
    ShowAttachmentCountDiv: boolean = false;
    ShowFilesReviewCountDiv: boolean = false;
    isBrowserRefreshed: boolean = false;
    validationPopupStyle: string = 'none';
    clickToSignPopupStyle: string = 'none';
    EnvelopeAdditionalUploadInfoList: any;
    mobilePrevBtn: string = 'false';
    mobileNextBtn: string = 'true';
    showNoFieldsRemainingMsg: boolean = false;
    showMobilePopup: string = 'none';
    spnMaximizeControlTitle: string = '';
    defaultControlCheckListData: any = [];
    showDateControlPopup: string = 'false';
    showDateControlPopupDisplay: string = 'none';
    showNotDateControlPopupDisplay: string = 'none';
    showTextDateControlPopupDisplay: string = 'none';
    mouseClickedPositions: any;
    isDateSelected: string = 'false';
    signClickCount: any = 0;
    maxDate = { year: new Date().getUTCFullYear() + 100, month: 12, day: 31 };
    minDate = { year: new Date().getUTCFullYear() - 100, month: 12, day: 31 };
    byDefaultShowCtrlTooltip: boolean = false;
    isShowFooter: any = 'false';
    showControlsFlag: any = 'none';
    loadedImagesTemp: string[] = [];
    loadedImages: { [key: string]: string } = {};
    globalCtrlObj: any;
    showFinishModalDailog: any = 'none';
    popuptempArr: any = [];
    textAreaQueued: any = false;
    mobileDoneBtn: string = 'false';
    sendPasswordPopupStyle: string = 'none';
    EnableMessageToMobile: any;
    DeliveryMode: any;
    emailAddr: any;
    Mobile: any;
    verificationCode: any;
    userPasscodeOnSubmit: any;
    sendAccessCodeTo: number = 0;
    isDocumentSubmitted: any = "false";
    AddEditClicked: any = "0";
    sigHandUpload: any;
    UploadSignature: boolean = false;

    public getPinchZoomRef() {
        return this.pinchZoom;
    }
    private inputSignChangeSubject = new Subject<string>();
    private signDestroy$ = new Subject<void>();
    private inputInitChangeSubject = new Subject<string>();
    private initDestroy$ = new Subject<void>();
    scrollingTimeout: any;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = undefined;
    showSessionExpireModalDailog: any = 'none';
    idleTimer: any;
    idleMaxTimerCount: any = environment.sessionTimeOut;
    idleCounter: any = environment.sessionTimeOut;
    sessionTimeoutVal: any = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
    popupShowCounter: any = environment.sessionInActivity;
    showDefaultSessionTimeOut: boolean = false;
    AllowSignersDownloadFinalContract: boolean = false;
    sessionTimeOutMinutes: any = (Math.floor(this.idleMaxTimerCount / 60)).toString();
    idleTimerMinsValue: any = (Math.floor(this.idleMaxTimerCount / 60)).toString();
    idleTimerSecondsValue: any = '00';
    timer = 0;
    timerWorker: any = new Worker('../../../assets/workers/timer-worker.js');

    showAndHideDecline: boolean = false;
    showAndHideFinishLater: boolean = false;
    showAndHideChangeSigner: boolean = false;

    controlPositionDetailsArr: any = [];
    expandableCtrlNextTopValue: any;
    lang_warning: string = '';
    lang_success: string = '';

    constructor(
        @Inject(DOCUMENT) private document: any,
        @Inject(LOCALE_ID) public locale: string,
        private renderer2: Renderer2,
        private elementRef: ElementRef,
        private modalService: NgbModal,
        private signerLandingService: SignerLandingService,
        private toastr: ToastrService,
        private commonService: CommonService,
        public datePipe: DatePipe,
        private http: HttpClient,
        private router: Router,
        private translationsService: TranslationsService,
        private cdRef: ChangeDetectorRef,
        private styleService: StyleService,
        private titleService: Title,
        private clickToSignService: ClickToSignService,
        private expandableService: ExpandableService
    ) {
        this.commonService.userSource$.subscribe(data => {
            this.$documentImg = data;
        });
        this.addTabKeyEventListener();

        //check idle time
        this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + " M";
        this.handleIdleTime();

        // Reset the idle timer when the user interacts.
        document.addEventListener('mousemove', () => {
            if (this.showSessionExpireModalDailog === 'none') {
                if (this.showDefaultSessionTimeOut === true) {
                    //reset Timer
                    this.onContinue();
                }
            }
        });

        this.commonService.documentData.subscribe(data => {
            this.documentData = data;
        });
    }

    @HostListener('mouseover', ['$event'])
    onMouseOver(event: MouseEvent) {
        this.resetSessionTimer();
    }

    @HostListener('click', ['$event.target']) onClick(e: any) {
        this.resetSessionTimer();
        this.commonService.callHostListenerEvent(e, this.webOrMobile);
    }

    @HostListener('mouseenter', ['$event']) onEnter(e: MouseEvent) {
        this.resetSessionTimer();
    }

    @HostListener('mouseleave', ['$event']) onLeave(e: MouseEvent) {
        this.resetSessionTimer();
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        this.resetSessionTimer();
    }

    @HostListener('keydown', ['$event']) onKeyDown() {
        this.resetSessionTimer();
    }

    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown', ['$event'])
    onStart(event: any) {
        if (event.touches) {
            this.resetSessionTimer();
        }
    }

    ngAfterViewInit() {
    }

    ngOnInit(): void {
        this.showLoader = true;
        this.getInitializeSignDocument();
        this.requiredFields = this.translationsService.getLanguageTranslationVal('PleaseEnterRequiredFields');
        this.lang_success = this.translationsService.getLanguageValidationVal('lang_success');
        this.lang_warning = this.translationsService.getLanguageValidationVal('lang_warning');
        this.onPreviewPagesView();
        this.titleService.setTitle("RSign | Sign Document");
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.pinchZoomDisabled = false;
            this.webOrMobile = 'mobile';
        } else {
            this.pinchZoomDisabled = true;
        }

        let zoomOutbtn: any = document.getElementById('zoomOutbtn');
        if (zoomOutbtn) {
            zoomOutbtn.setAttribute('disabled', 'disabled');
        }

        this.searchTerm = '';
        this.searchTermDisplay = '';
        this.percentageValue = '100%';
        this.isFavorite = true;

        this.inputSignChangeSubject
            .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.signDestroy$))
            .subscribe(value => {
                this.UpdateSigAndInitialProfileText(1);
            });

        this.inputInitChangeSubject
            .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.initDestroy$))
            .subscribe(value => {
                this.UpdateInitialsimageFromSignerProfileText();
            });
    }

    addTabKeyEventListener() {
        window.addEventListener('keyup', this.handleTooltip, { passive: true });
    }

    handleTooltip = (event: any) => {
        //isModalOpen to checking any popup modal is opened or not
        let isModalOpen: any = this.document.body.className.indexOf('modal-open');
        if (isModalOpen === -1) {
            if (event.key == "Tab" && (event.keyCode == 9 || event.which == 9)) {
                if (event.target.offsetParent && event.target.offsetParent.tagName !== 'NGB-DATEPICKER') {
                    if (this.dControll) {
                        this.dControll!.close();
                    }
                    this.hideAllControlTooltips();
                    this.getControlNextNav('fromtabevent');
                }
                else if (event.target.offsetParent && event.target.offsetParent.tagName == 'NGB-DATEPICKER') {

                }
            }
        }
    }

    addTextAreEventListener() {
        let textarea: any = document.getElementsByClassName('mycustomTextAreaClass');
        for (var i = 0; i < textarea.length; i++) {
            textarea[i].addEventListener('input', this.customTextArea, { passive: true });
        }
    }

    customTextArea = (event: any) => {
        if (!this.textAreaQueued) {
            this.textAreaQueued = true;
            requestIdleCallback(() => {
                let start = Date.now()
                while (Date.now() - start < 10) { };
                this.textAreaQueued = false;
            })
        }
    }

    getInitializeSignDocument() {
        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        if (documentControlsResp == null || documentControlsResp == undefined) {
            let actualSigningUrl: any = localStorage.getItem("actualSigningUrl");
            if (actualSigningUrl == null || actualSigningUrl == undefined) {
                this.showLoader = false;
                localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
                localStorage.setItem("MessageType", "Warning");
                this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else {
                window.location.href = actualSigningUrl;
            }
        }
        else {
            let respSigningUrlModel: any = localStorage.getItem("responseSigningUrlModel");
            this.responseSigningUrlModel = JSON.parse(respSigningUrlModel);
            this.documentControlsResponse = JSON.parse(documentControlsResp);

            let envelopeImageControlData: any = localStorage.getItem("EnvelopeImageControlData");
            let checkListData: any = localStorage.getItem("CheckListData");
            let userdata: any = localStorage.getItem("Userdata");
            let envelopeInfo: any = localStorage.getItem("EnvelopeInfo");
            this.documentControlsResponse.EnvelopeImageControlData = JSON.parse(envelopeImageControlData);
            this.documentControlsResponse.CheckListData = JSON.parse(checkListData);
            this.documentControlsResponse.userdata = JSON.parse(userdata);
            this.documentControlsResponse.EnvelopeInfo = JSON.parse(envelopeInfo);

            if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeImageControlData) {
                this.isFileReviewed = this.documentControlsResponse.EnvelopeInfo.IsReviewed;
                this.EnvelopeID = this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID;
                this.commonService.setDocumentData(this.documentControlsResponse.EnvelopeImageControlData);
                this.documentCheckListsResponse = this.documentControlsResponse.CheckListData;
                this.showDefaultSignatureContol = this.documentControlsResponse.ShowDefaultSignatureContol;
                this.defaultSignatureValue = this.translationsService.getLanguageValidationVal('DefaultSignature'); //  this.documentControlsResponse.Language.filter((c: any) => c.KeyName === 'DefaultSignature')[0].KeyValue;
                if (this.documentControlsResponse.EnvelopeInfo.ControlsData && this.documentControlsResponse.EnvelopeInfo.ControlsData.length > 0) {
                    this.defaultSignatureObj = this.documentControlsResponse.EnvelopeInfo.ControlsData.filter((c: any) => c.ControlName === 'FooterSignature');
                }
                this.IsEnableClickToSign = this.documentControlsResponse.EnableClickToSign;
                this.IsStaticTemplate = this.isStaticTemplate(this.documentControlsResponse);
                this.IsSignerAttachFileReqNew = this.documentControlsResponse.EnvelopeInfo.IsSignerAttachFileReqNew;
                this.IsPrefill = this.documentControlsResponse.Prefill;
                this.DisableDownloadOptionOnSignersPage = this.documentControlsResponse.DisableDownloadOptionOnSignersPage;
                this.EnableMessageToMobile = this.documentControlsResponse.EnableMessageToMobile == true ? 1 : 0;
                this.DeliveryMode = this.documentControlsResponse.DeliveryMode;
                localStorage.setItem("StaticEnvelopId", this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID);
                localStorage.setItem("eDisplayCode", this.documentControlsResponse.EnvelopeInfo.EDisplayCode);
                localStorage.setItem('IsAdditionalAttmReq', this.documentControlsResponse.EnvelopeInfo.IsAdditionalAttmReq);
                //localStorage.setItem("IsSMSAccessCode", this.documentControlsResponse.IsSMSAccessCode);
                localStorage.setItem("recipientTypeId", this.documentControlsResponse.EnvelopeInfo.RecipientTypeId);

                this.AllowSignersDownloadFinalContract = this.documentControlsResponse.IsAllowSignerstoDownloadFinalContract;
                localStorage.setItem("AllowSignersDownloadFinalContract", this.AllowSignersDownloadFinalContract == true ? "true" : "false");
                let envelopeAdditionalUploadInfoList: any;
                envelopeAdditionalUploadInfoList = this.documentControlsResponse.EnvelopeAdditionalUploadInfoList;
                if (this.documentControlsResponse.EnvelopeInfo.IsAdditionalAttmReq || (envelopeAdditionalUploadInfoList != null && envelopeAdditionalUploadInfoList.length > 0)) {
                    this.IsAdditionAttamRequest = true;
                }
                if (this.IsSignerAttachFileReqNew > 0 && this.IsPrefill != "prefill" && this.IsAdditionAttamRequest) {
                    this.ShowAttachmentNavMenu = true;
                }
                if (this.documentControlsResponse.EnvelopeInfo.IsSingleSigning == false) {
                    this.ShowFinishLaterNavMenu = true;
                    localStorage.setItem("ShowFinishLaterNavMenu", "true");
                }
                else {
                    localStorage.setItem("ShowFinishLaterNavMenu", "false");
                }
                if (Boolean(this.documentControlsResponse.EnvelopeInfo.IsTemplateShared) == false) {
                    this.ShowDeclineNavMenu = true;
                }
                if (this.IsSignerAttachFileReqNew > 0 && this.IsPrefill != "prefill" && (this.IsAdditionAttamRequest == true || (Boolean(this.documentControlsResponse.EnvelopeInfo.IsTemplateShared) == true))) {
                    this.ShowAttachmentCountDiv = true;
                }
                if (this.documentControlsResponse.FileReviewCount > 0) {
                    this.ShowFilesReviewCountDiv = true;
                }

                this.UploadSignature = this.documentControlsResponse.EnvelopeInfo.UploadSignature;

                setTimeout(() => {
                    if (this.webOrMobile === 'mobile') {
                        this.hidePagesViewSection = false;
                    }

                    this.isTermsModelPopupExists();
                    this.showLoader = false;
                    this.commonService.updateDefaultValueOnDateControl();
                    this.updateDefaultValueOnEmailControl();
                    this.getPreloadControls();
                    this.getTotalRemaingFieldsControls('initial');
                    this.onFilterApply();

                    let footerSigSrc = this.document.getElementById("footerSigSrc");
                    let launguageSignImageName: string = this.translationsService.getLanguageValidationVal('lang_yourNameImg'); //this.documentControlsResponse.Language.filter((c: any) => c.KeyName == "lang_yourNameImg");

                    let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
                    if (footerSigSrc != null && footerSigSrc != undefined) {
                        footerSigSrc.setAttribute("src", defaultImage);
                    }

                    if (this.documentControlsResponse.EnvelopeInfo.IsDisclaimerEnabled === false && localStorage.getItem('prefill') !== 'prefill' &&
                        this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign === false) {
                        setTimeout(() => {
                            this.defaultControlShowTooltip();
                        }, 500);
                    }

                    this.showAndHideDecline = this.documentControlsResponse.DisableDeclineOption;
                    this.showAndHideFinishLater = this.documentControlsResponse.DisableFinishLaterOption;
                    this.showAndHideChangeSigner = this.documentControlsResponse.DisableChangeSigner;

                    this.getAttachmentList(this.documentControlsResponse);

                }, 500);
            }
            else {
                let actualSigningUrl: any = localStorage.getItem("actualSigningUrl");
                if (actualSigningUrl == null || actualSigningUrl == undefined) {
                    this.showLoader = false;
                    localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
                    localStorage.setItem("MessageType", "Warning");
                    this.commonService.getReturnUrlDirection(RouteUrls.Info);
                }
                else {
                    window.location.href = actualSigningUrl;
                }
            }
            localStorage.setItem("signatureInputText", "");
            localStorage.setItem("signatureText", "");
            localStorage.setItem("initialInputText", "");
            localStorage.setItem("initialText", "");

            let beforeRefreshValFrmLocalStorage: any = localStorage.getItem("beforeRefreshVal");
            if (beforeRefreshValFrmLocalStorage == "1") {
                this.isBrowserRefreshed = true;
            }
            else {
                this.isBrowserRefreshed = false;
                localStorage.setItem("beforeRefreshVal", "1");
            }
            this.showLoader = false;
        }

        this.imageLoaded(this.documentControlsResponse.EnvelopeImageControlData);
        this.cdRef.detectChanges();
    }

    getAttachmentList(obj: any) {
        let envelopeID = localStorage.getItem("EnvelopeID");
        let recipientID = localStorage.getItem("RecipientID");
        let additionalRecipients: any = "";
        if (additionalRecipients == "") {
            additionalRecipients = "null"
        }
        let fileAttachmentInfoUrl: any = CommonConstants.GetAdditonalfileAttachmentInfo + '/' + envelopeID + '/' + recipientID + '/' + additionalRecipients;
        this.signerLandingService.getAdditonalfileAttachmentInfo(fileAttachmentInfoUrl).subscribe(
            (resp: any) => {
                if (resp && resp.EnvelopeAdditionalUploadInfoList && resp.EnvelopeAdditionalUploadInfoList.length > 0) {
                    let respAttachFiles: any = resp.EnvelopeAdditionalUploadInfoList;
                    this.EnvelopeAdditionalUploadInfoList = resp.EnvelopeAdditionalUploadInfoList;
                    this.attachmentList = [];
                    Array.from(respAttachFiles).forEach((item: any) => {
                        if (item.FileName != "" && item.FileName !== null) {
                            this.attachmentList.push(item.FileName);
                        }
                    });
                    this.attachmentListCount = this.attachmentList.length;
                }
                else {
                    this.attachmentList = [];
                    this.attachmentListCount = 0;
                }
                this.cdRef.detectChanges();
            })
    }

    onAttachmentDelete(file: any) {
        let reqParams: any = {
            EnvelopeID: localStorage.getItem('EnvelopeID'),
            RecipientID: localStorage.getItem('RecipientID'),
            FileName: file
        }
        let deleteAndUpdateSignerDocUrl = CommonConstants.DeleteSignerDocFromDirectory;
        this.showLoader = true;
        this.signerLandingService.getDeleteSignerDocFromDirectory(deleteAndUpdateSignerDocUrl, reqParams).subscribe(
            (resp: any) => {
                this.showLoader = false;
                this.attachmentList = this.attachmentList.filter((item: any) => item !== file)
                this.attachmentListCount = this.attachmentList.length;
                if (resp && resp.StatusCode === 200 && resp.StatusMessage.toLowerCase() === 'ok') {
                    var files = resp.data;
                    this.toastr.success('Document deleted successfully.', this.lang_success, { timeOut: 2000 });
                }
                this.cdRef.detectChanges();
            })
    }

    onClickSignClose() {
        this.modalService.dismissAll();
        this.clickToSignPopupStyle = 'none';
        this.commonService.removeModelPopupClassForBodyTag();
        this.getTotalRemaingFieldsControls('');
        if (this.byDefaultShowCtrlTooltip === false) { setTimeout(() => { this.defaultControlShowTooltip(); }, 500); this.byDefaultShowCtrlTooltip = true; }
    }

    openClickToSignModal(typeSign: any) {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            if (typeSign == "signature" || typeSign == "upload") {
                const modalRef = this.modalService.open(SignaturePadModalComponent, { windowClass: 'signaturePadModalComponent-class', keyboard: false, backdrop: 'static' });
                modalRef.componentInstance.signControlId = "";
                modalRef.componentInstance.dataFontColor = "";
                modalRef.componentInstance.type = typeSign;
                let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
                modalRef.componentInstance.ClickToSignsignatureText = txtsignerProfileName.value;

                modalRef.componentInstance.filterCheckListSingature.subscribe((singObj: any) => {
                    if (singObj.status === 'true') {
                        this.sigHandUpload = singObj.originalSignImage;
                    }
                });
            }
            if (typeSign == "initials") {
                const modalRef = this.modalService.open(InitialsPadComponent, { windowClass: 'initialsPadModalComponent-class', keyboard: false, backdrop: 'static' });
                modalRef.componentInstance.signControlId = "";
                modalRef.componentInstance.dataFontColor = "";
                modalRef.componentInstance.type = typeSign;
                let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
                modalRef.componentInstance.ClickToSignInitialText = txtsignerProfileInitials.value;
            }
        }
    }

    openFilesReview() {
        this.modalService.open(this.fileReview, { windowClass: 'fileReviewModalComponent-class', keyboard: false, backdrop: 'static' });
        this.fileReviewInfo = this.documentControlsResponse.FileReviewInfo
    }

    onConfirmFilesReviewed() {
        this.modalService.dismissAll(this.fileReview);
        this.showLoader = true;
        let envelopeSignDocumentSubmitInfo: any = this.prepareSubmitEnvelopeObject();
        this.commonSubmitClickevent(envelopeSignDocumentSubmitInfo);
    }

    onFileReviewDownload() {
        this.showLoader = true;
        this.isFileReviewed = '1';
        let envelopeId = localStorage.getItem('EnvelopeID');
        let recipientId = localStorage.getItem('RecipientID');
        let isStaticTemplate: string = '';
        let isStatic: any = localStorage.getItem('isStatic');
        if (isStatic === true || isStatic === "true") {
            isStaticTemplate = 'Static';
        }

        let url: any = this.commonConstants.GetDownloadFileReview + '/' + envelopeId + '/' + recipientId + '/' + isStaticTemplate;
        this.signerLandingService.getDownloadFileReview(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                let btnConfirm: any = document.getElementById('btnConfirm');
                let btnFileReviewDownload: any = document.getElementById('btnFileReviewDownload');
                if (this.isFileReviewed == "" || this.isFileReviewed == null) {
                    btnConfirm?.setAttribute('disabled', 'disabled');
                    btnFileReviewDownload?.setAttribute('background-color', '');
                    btnFileReviewDownload?.removeAttribute('disabled');
                } else {
                    this.isFileReviewed = '1';
                    btnConfirm?.removeAttribute('disabled');
                }

                let base64FileData: any = resp.Base64FileData;
                if (base64FileData) {
                    this.toastr.success('Downloaded successfully.', this.lang_success, { timeOut: 2000 });
                    var blob = this.commonService.b64toBlob(base64FileData, 'application/pdf');
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = window.URL.createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    window.URL.revokeObjectURL(bloburl);
                    a.remove();
                }
                this.cdRef.detectChanges();
            });
    }

    getPreloadControls() {
        //shows the dependencies controls if pre seelcted
        let drpControlArray: any = [];
        let txtControlArray: any = [];
        let txAccordinArray: string[] = [];
        if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
            this.documentControlsResponse.EnvelopeImageControlData.forEach((ele: any) => {
                ele.ControlsData.forEach((eleChild: any) => {

                    if (eleChild.ControlName.toLowerCase() == "radio" || eleChild.ControlName.toLowerCase() == "checkbox") {
                        this.commonService.getRadioCheckBoxPreloadControls(eleChild);
                    }

                    if (eleChild.ControlName.toLowerCase() === 'dropdown') {
                        drpControlArray.push(eleChild);
                    }

                    if (eleChild.ControlName.toLowerCase() === 'text') {
                        txtControlArray.push(eleChild);
                    }

                    this.calculateTopLeftForAllControls(eleChild);
                });
            });
            this.getAllControlPositionDetail();
        }

        if (drpControlArray && drpControlArray.length > 0) {
            for (let index = 0; index < drpControlArray.length; index++) {
                const ctrlObj = drpControlArray[index];
                let cudrpctrl: any = document.getElementById("control_" + ctrlObj.ControlHtmlID);
                let selectedDropdownValue: any = "";
                if (cudrpctrl) {
                    selectedDropdownValue = cudrpctrl.getAttribute("data-selected-value");
                }
                this.checkDependenciesControl(ctrlObj, selectedDropdownValue, "1");
            }
        }

        if (txtControlArray && txtControlArray.length > 0) {
            for (let index = 0; index < txtControlArray.length; index++) {
                const ctrlObj = txtControlArray[index];
                let textCtrlVal: any = ctrlObj.ControlValue;
                if (ctrlObj.ControlValue == '') {
                    let curCtrl: any = (<HTMLInputElement>document.getElementById("control_" + ctrlObj.ControlHtmlID));
                    if (curCtrl) {
                        let value = (<HTMLInputElement>document.getElementById("control_" + ctrlObj.ControlHtmlID)).value;
                        if (value !== '' && value !== ' ' && value !== null) {
                            textCtrlVal = value;
                        }
                    }
                }
                this.showTextDependenciesControl(ctrlObj, textCtrlVal);
            }
        }

        this.ShowDefaultSignature();
        //update checklist
        this.commonService.getUpdateCheckList(this.documentControlsResponse.CheckListData);
    }

    getImagePath(record: any) {
        return this.styleService.getImagePath(record, this.documentControlsResponse);
    }

    //is signed == true
    getCtrlInlineStyle(ctrlObj: any) {
        return this.styleService.getCtrlInlineStyle(ctrlObj);
    }

    getImageInlineStyle(ctrlObj: any) {
        return this.styleService.getImageInlineStyle(ctrlObj);
    }

    getCtrlDisplayStyle(controlingFieldId: any) {
        return this.styleService.getCtrlDisplayStyle(controlingFieldId, '', 'signer');
    }

    getCtrlInlineStyleIfIsReadOnlyNull(ctrlObj: any) {
        return this.styleService.getCtrlInlineStyleIfIsReadOnlyNull(ctrlObj, 'signer');
    }

    getBackGroundBasedONSignature(control: any) {
        if (control.SignatureScr !== null || control.SignatureScr !== '') {
            return { 'background': 'none' };
        } else {
            return;
        }
    }

    isSelected(p1: any, p2: any) {
        return this.isNullOrEmptyCheck(p1) && this.isNullOrEmptyCheck(p2) && p1 === p2 ? 'selected' : '';
    }

    getRadioAndCheckboxDisplayCondition(control: any) {
        if (control.IsReadOnly === true && ((control.ControlValue != "" && control.ControlValue != null && control.ControlValue.toLowerCase() === 'true') || (control.SenderControlValue != "" && control.SenderControlValue != null && control.SenderControlValue.toLowerCase() == 'true'))) { return true; }
        else if (control.IsReadOnly === true && ((control.ControlValue != "" && control.ControlValue != null && control.ControlValue.toLowerCase() === 'false') || (control.SenderControlValue != "" && control.SenderControlValue != null && control.SenderControlValue.toLowerCase() == 'false'))) { return false; }
        else { return false; }
    }

    isChecked(control: any) {
        if (this.isNullOrEmptyCheck(control.ControlValue) && control.ControlValue.toLowerCase() === 'true') {
            return 'checked';
        } else {
            return;
        }
    }

    getTextDateCtrlInlineStyle(ctrlObj: any) {
        return this.styleService.getTextDateCtrlInlineStyle(ctrlObj);
    }

    getTextInputCtrlInlineStyle(ctrlObj: any) {
        return this.styleService.getTextInputCtrlInlineStyle(ctrlObj);
    }

    getTextEmailCtrlInlineStyle(ctrlObj: any) {
        return this.styleService.getTextEmailCtrlInlineStyle(ctrlObj);
    }

    getTextAlign(ctrlObj: any) {
        return this.styleService.getTextAlign(ctrlObj);
    }

    getCtrlLabelStyle(ctrlObj: any) {
        return this.styleService.getCtrlLabelStyle(ctrlObj);
    }

    getDefaultplaceHolder(defaultplaceHolder: string) {
        let pipe = new DatePipe('en-US');
        let date = new Date();
        return pipe.transform(date, defaultplaceHolder);
    }

    getCtrlImage(ctrlObj: any) {
        if (ctrlObj.ControlName === 'Checkbox') {
            if ((this.isNullOrEmptyCheck(ctrlObj.ControlValue) && ctrlObj.ControlValue.toLowerCase() == "true") || (!this.isNullOrEmptyCheck(ctrlObj.SenderControlValue) && ctrlObj.SenderControlValue.toLowerCase() == "true")) {
                return this.checkboxCheckedImageSrc;
            }
            else {
                return this.checkboxNotCheckedImageSrc;
            }
        } else if (ctrlObj.ControlName === 'Radio') {
            if ((this.isNullOrEmptyCheck(ctrlObj.ControlValue)) && ctrlObj.ControlValue.toLowerCase() == "true") {
                return this.radioCheckedImageSrc;
            }
            else {
                return this.radioNotCheckedImageSrc;
            }
        } else {
            return "";
        }
    }

    getTextCtrlInlineStyle(ctrlObj: any) {
        return {
            'width': ctrlObj.Width + 'px', 'white-space': 'inherit', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px',
            'font-family': ctrlObj.FontName, 'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
            'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
        };
    }

    getCtrlLabelStyleIsReadOnlyNull(ctrlObj: any) {
        return this.styleService.getCtrlLabelStyleIsReadOnlyNull(ctrlObj);
    }

    getIphoneClass() {
        if (navigator.userAgent.includes('iPhone')) {
            return 'stampicon-iphone';
        }
        return;
    }

    getIphoneBoxSizingClass() {
        if (navigator.userAgent.includes('iPhone')) {
            return 'iphone-boxSizing';
        }
        return;
    }

    getIphoneTooltipLabelText() {
        if (navigator.userAgent.includes('iPhone')) {
            return 'iphone-tooltip-label-text';
        }
        return
    }

    getPencilClass(ctrlObj: any) {
        return this.styleService.getPencilClass(ctrlObj);
    }

    getRequiredControlClass(ctrlObj: any, ctrlValue: any) {
        return this.styleService.getRequiredControlClass(ctrlObj, ctrlValue);
    }

    getRequiredDropdownControlClass(ctrlObj: any) {
        return this.styleService.getRequiredDropdownControlClass(ctrlObj);
    }

    isNumberKey(evt: any, ctr: any) {
        this.expandableService.isNumberKey(evt, ctr);
    }

    isMobileViewNumberKey(evt: any) {
        this.commonService.isMobileViewNumberKey(evt);
    }

    controlDependencyControllingField(controlObj: any) {
        return controlObj.ConditionDetails.ControllingFieldID === null ? 'null' : controlObj.ConditionDetails.ControllingFieldID;
    }

    getTextNotDateAndEmailCtrlStyle(ctrlObj: any) {
        return this.styleService.getTextNotDateAndEmailCtrlStyle(ctrlObj);
    }

    controlHeightWidth(controlObj: any) {
        return { 'width': controlObj.Width + 'px', 'height': controlObj.Height + 'px', 'background': 'none' };
    }

    controlLabelHeight(controlObj: any) {
        return { 'height': controlObj.Height + 'px', 'text-align':controlObj.TextAlign  };
    }

    controlHeightWidthAuto(controlObj: any) {
        return { 'width': 'auto', 'height': controlObj.Height + 'px' };
    }

    getSignatureImageStyle(controlObj: any, type: any) {
        return (type === 0) ? { 'width': 'auto', 'height': (controlObj.Height) + 'px', 'max-width': '100%' } : { 'width': 'auto', 'height': controlObj.Height + 'px', 'max-width': '100%' };
    }

    isNullOrEmptyCheck(stringValueFlag: any) {
        let boolFlag = false;
        if (typeof stringValueFlag != 'undefined' && stringValueFlag !== '' && stringValueFlag !== null && stringValueFlag !== 'false') {
            boolFlag = true;
        }
        return boolFlag;
    }

    getLanguageControlName(control: any) {
        let label;
        if (control.ControlName.toLowerCase() === 'text') {
            label = control.Label;
        }
        else if (control.ControlName.toLowerCase() === 'checkbox') {
            if (control.GroupName != '' && control.GroupName != null) {
                label = control.GroupName + "-" + control.Label + "-" + "P" + control.PageNo;
            }
            else {
                label = this.translationsService.getControlLanguageControlName(control);
            }
        }
        else if (control.ControlName.toLowerCase() === 'radio') {
            label = control.GroupName + "-" + control.Label + "-" + "P" + control.PageNo;
        }
        else {
            label = this.translationsService.getControlLanguageControlName(control);
        }
        return label;
    }

    getTooltipAlertposition(ctrlObj: any) {
        return this.styleService.getTooltipAlertposition(ctrlObj, this.webOrMobile);
    }

    getTooltipTrangleposition(ctrlObj: any) {
        return this.styleService.getTooltipTrangleposition(ctrlObj, this.webOrMobile);
    }

    getSelectedDocument() {
        let currentPagination: any = localStorage.getItem('currentPagination');
        let currentPageValue: any = parseInt(currentPagination);
        this.onPaginateChange(currentPageValue, '');
    }

    onPreviewImageSelection(event: any, id: any) {
        this.commonService.setUser(id);
        localStorage.setItem('currentPagination', id);
        let currentPagination: any = localStorage.getItem('currentPagination');
        let currentPageValue: any = parseInt(currentPagination);
        this.onPaginateChange(currentPageValue, '');
        if (this.webOrMobile === 'mobile') {
            let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
            closeMoreActionsMenu.click();
        }
        this.getPreviewSelectedColor(currentPageValue);
        event.preventDefault();
    }

    onUpdatePageScrollAndCount(id: any) {
        if (id !== undefined && (id <= this.documentData.length)) {
            let imgControl: any = $(".imgControl");
            imgControl.disabled = true;
            let pageNoAttr: any = $('div.imgControl[page-no="' + id + '"]').offset()?.top;
            let firstpageNoAttr: any = $('div.imgControl[page-no="1"]').offset()?.top;
            var scrollTo = pageNoAttr - firstpageNoAttr - 20;
            $('html, body').animate({ scrollTop: scrollTo }, 100);
            localStorage.setItem('currentPagination', id);
        }
    }

    onPaginateChange(id: any, ctrlObj: any) {
        this.onUpdatePageScrollAndCount(id);
    }

    expand(index: any) {
        if (this.currentIndex === index) {
            this.currentIndex = null;
            return;
        }
        this.currentIndex = index;
    }

    //Filter dropdown selection and updating searchterm filter
    onChangeDropdownSelection(event: any, val: any) {
        let selectedValue: any = event.currentTarget.value;
        if (selectedValue) {
            if (selectedValue === 'Select All') {
                this.searchTerm = '';
                this.searchTermDisplay = '';
            }
            else if (selectedValue === 'Initials') {
                this.searchTerm = 'NewInitials';
                this.searchTermDisplay = 'Initials';
            }
            else {
                this.searchTerm = selectedValue;
                this.searchTermDisplay = this.searchTerm;
            }
        }
        else {
            this.searchTerm = '';
            this.searchTermDisplay = '';
        }
    }

    onFilterControlClick(ctrlObj: any, pageIndex: any) {
        if (ctrlObj.ControlName === 'Signature' || ctrlObj.ControlName === 'NewInitials') {
            this.signClickCount = this.signClickCount + 1;
        }
        localStorage.setItem('currentPagination', ctrlObj.PageNo);
        if (this.webOrMobile === 'mobile') {
            this.closeMoreActionsMenu();
            if (ctrlObj.ControlName === 'Date') {
                this.scrollToElement(ctrlObj, ctrlObj.PageNo);
            } else if (ctrlObj.ControlName === 'Text' && ctrlObj.TextType === 'Date') {
                this.scrollToElement(ctrlObj, ctrlObj.PageNo);
            }
        } else {
            this.scrollToElement(ctrlObj, ctrlObj.PageNo);
        }
        if (ctrlObj.ControlName === 'Text' && ctrlObj.TextType === 'Date') {

        } else if (ctrlObj.ControlName !== 'Date') {
            this.onShowControlTooltip(ctrlObj, 'selectedFromFilter');
        }
    }

    scrollToElement(obj: any, pageNo: any) {
        if (obj.ControlName === 'Signature') {
            setTimeout(() => {
                document.getElementById('control_' + obj.ControlHtmlID)!.focus();
                this.onClickSignature(obj.ControlHtmlID);
            }, 500);
        } else if (obj.ControlName === 'NewInitials') {
            setTimeout(() => {
                document.getElementById('control_' + obj.ControlHtmlID)!.focus();
                this.onClickInitials(obj.ControlHtmlID);
            }, 500);
        } else if (obj.ControlName === 'Date') {
            // setTimeout(() => {
            document.getElementById('control_' + obj.ControlHtmlID)!.focus();
            // }, 300);
        } else if (obj.ControlName === 'Title' || obj.ControlName === 'Name' || obj.ControlName === 'Company' ||
            obj.ControlName === 'Email' || obj.ControlName === 'Text' || obj.ControlName === 'Checkbox' || obj.ControlName === 'Radio' ||
            obj.ControlName === 'DropDown') {
            let control = document.getElementById('control_' + obj.ControlHtmlID)!
            if (control) {
                if (this.webOrMobile === 'mobile') {
                    let controlEle: any = document.getElementById('control_' + obj.ControlHtmlID);
                    controlEle?.blur();
                }
                setTimeout(() => {
                    document.getElementById('control_' + obj.ControlHtmlID)!.focus();
                    document.getElementById('control_' + obj.ControlHtmlID)!.style.outline = 'none';
                }, 300);
            }
        }
    }

    //Terms of service print functionality
    onTermsOfServicePrint() {
        this.popupWin = window.open('', 'Terms of Service', 'menubar=0,location=0,height=700,width=700');
        this.popupWin.document.body.innerHTML = document.getElementById('disclaimerTermsAndServiceBody')!.outerHTML;
        this.popupWin.print();
    }

    onClickSignature(controleID: any) {
        if (controleID === "FooterSignNav") {
            let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
            closeMoreActionsMenu.click();
        }

        if (this.IsEnableClickToSign) {
            this.openClickToSignModalOrSignaturePadModal(controleID);
            this.signClickCount = 0;
        }
        else {
            this.openSignaturePadModel(controleID);
        }
    }

    openSignaturePadModel(controleID: any) {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let slControl = document.getElementById(controleID);
            let dataFontColor: any;
            if (slControl != undefined && slControl != null) {
                let slControlImg: any = slControl.getElementsByTagName('img');
                dataFontColor = slControlImg[0].attributes["data-font-color"] != undefined ? slControlImg[0].attributes["data-font-color"].value : ''
            }
            const modalRef = this.modalService.open(SignaturePadModalComponent, { windowClass: 'signaturePadModalComponent-class', keyboard: false, backdrop: 'static' });
            modalRef.componentInstance.signControlId = controleID;
            modalRef.componentInstance.dataFontColor = dataFontColor;
            modalRef.componentInstance.defaultSignature = this.defaultSignatureObj[0].ControlName;
            modalRef.componentInstance.checkListData = this.documentCheckListsResponse;
            modalRef.componentInstance.filterCheckListSingature.subscribe((singObj: any) => {
                if (singObj.status === 'true') {
                    this.sigHandUpload = singObj.originalSignImage;
                    this.getTotalRemaingFieldsControls('');
                    setTimeout(() => {
                        this.getControlNextNav('');
                    }, 100);
                }
            });

            modalRef.componentInstance.isSignaturePopupClosed.subscribe((isClosedPopup: any) => {
                if (isClosedPopup === 'true') {
                    this.signClickCount = 0;
                    this.checkTooltipIsApplicable(controleID);
                }
            });
        }
    }

    onClickInitials(controleID: any) {
        if (this.IsEnableClickToSign) {
            this.openClickToSignModalOrInitialPadModal(controleID);
            this.signClickCount = 0;
        }
        else {
            this.openInitialPadModel(controleID);
        }
    }

    openInitialPadModel(controleID: any) {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let slControl = document.getElementById(controleID);
            let dataFontColor: any;
            if (slControl != undefined && slControl != null) {
                let slControlImg: any = slControl.getElementsByTagName('img');
                dataFontColor = slControlImg[0].attributes["data-font-color"] != undefined ? slControlImg[0].attributes["data-font-color"].value : ''
            }

            const modalRef = this.modalService.open(InitialsPadComponent, { windowClass: 'initialsPadModalComponent-class', keyboard: false, backdrop: 'static' });
            modalRef.componentInstance.signControlId = controleID;
            modalRef.componentInstance.dataFontColor = dataFontColor;
            modalRef.componentInstance.checkListData = this.documentCheckListsResponse;
            modalRef.componentInstance.filterCheckListInitials.subscribe((isfilter: any) => {
                if (isfilter === 'true') {
                    this.getTotalRemaingFieldsControls('');
                    setTimeout(() => {
                        this.getControlNextNav('');
                    }, 100);
                }
            });
            modalRef.componentInstance.isInitialsPopupClosed.subscribe((isInitClosed: any) => {
                if (isInitClosed === 'true') {
                    this.signClickCount = 0;
                    this.checkTooltipIsApplicable(controleID);
                }
            });
        }
    }

    validateEmail(email: any) {
        return this.commonService.validateEmail(email);
    }

    validateInputText(value: any, ctrlObj: any) {
        this.commonService.validateInputText(value, ctrlObj);
        setTimeout(() => {
            this.getTotalRemaingFieldsControls('');
        }, 10);
    }

    applyStylesToCheckList(type: any, ControlHtmlID: any) {
        this.commonService.applyStylesToCheckList(type, ControlHtmlID);
    }

    getDependencies(ctrlObj: any, eleArr: any, curRoleId: any) {
        let txRadioArray: string[] = [];
        let txAccordinArray: string[] = [];
        eleArr.ControlsData.forEach((childEle: any) => {
            if (ctrlObj.ControlName.toLowerCase() === 'radio') {
                if (curRoleId && (childEle.RecipientId === curRoleId.toLowerCase() || (childEle.RecipientId == null))) {
                    if (ctrlObj.Id === childEle.ConditionDetails.ControllingFieldID) {
                        if (document.getElementById(childEle.ControlHtmlID)) {
                            document.getElementById(childEle.ControlHtmlID)!.style.display = 'block';
                        }

                        if (document.getElementById('anchor_' + childEle.ControlHtmlID)) {
                            document.getElementById('anchor_' + childEle.ControlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + childEle.ControlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + childEle.ControlHtmlID)!.removeAttribute('style');
                            document.getElementById('panel_' + childEle.ControlHtmlID)!.setAttribute('style', '');
                            document.getElementById('panel_' + childEle.ControlHtmlID)!.classList.add('visible');
                            txAccordinArray.push(childEle.ControlHtmlID);
                        }
                    }
                    else {
                        if (ctrlObj.GroupName === childEle.GroupName && ctrlObj.Id !== childEle.Id) {
                            eleArr.ControlsData.forEach((childEle11: any) => {
                                if (childEle11.RecipientId === curRoleId.toLowerCase() || (childEle11.RecipientId == null)) {
                                    if (childEle.Id === childEle11.ConditionDetails.ControllingFieldID) {

                                        if (document.getElementById(childEle11.ControlHtmlID)) {
                                            document.getElementById(childEle11.ControlHtmlID)!.style.display = 'none';
                                        }
                                        if (document.getElementById('anchor_' + childEle11.ControlHtmlID)) {
                                            document.getElementById('anchor_' + childEle11.ControlHtmlID)!.style.display = 'none';
                                            document.getElementById('panel_' + childEle11.ControlHtmlID)!.style.display = 'none';
                                        }
                                    }

                                    if (childEle11.ControlName.toLowerCase() === 'radio' && childEle11.GroupName != '' && ctrlObj.GroupName === childEle11.GroupName && ctrlObj.Id !== childEle11.Id) {
                                        if (!txRadioArray.includes(childEle11.ControlHtmlID)) {
                                            txRadioArray.push(childEle11.ControlHtmlID);
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'checkbox') {
                if (curRoleId && childEle.RecipientId === curRoleId.toLowerCase() || (childEle.RecipientId == null)) {
                    if (ctrlObj.Id === childEle.ConditionDetails.ControllingFieldID) {
                        let value: any = (<HTMLInputElement>document.getElementById('control_' + ctrlObj.ControlHtmlID)).checked;
                        if (value == false && childEle.ConditionDetails.ControllingConditionID && childEle.ConditionDetails.ControllingConditionID.toLowerCase() == "e6fc0258-fbd1-4b11-99a7-138193af2e64") {
                            if (document.getElementById(childEle.ControlHtmlID)) {
                                document.getElementById(childEle.ControlHtmlID)!.style.display = 'block';
                                if (document.getElementById('anchor_' + childEle.ControlHtmlID)) {
                                    document.getElementById('anchor_' + childEle.ControlHtmlID)!.style.display = 'block';
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.style.display = 'block';
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.removeAttribute('style');
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.setAttribute('style', '');
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.classList.add('visible');

                                    txAccordinArray.push(childEle.ControlHtmlID);
                                }
                            }
                        }
                        else if (value == true && childEle.ConditionDetails.ControllingConditionID && childEle.ConditionDetails.ControllingConditionID.toLowerCase() == "1765014e-6611-4c9a-9268-833e7d853d1a") {
                            if (document.getElementById(childEle.ControlHtmlID)) {
                                document.getElementById(childEle.ControlHtmlID)!.style.display = 'block';
                                if (document.getElementById('anchor_' + childEle.ControlHtmlID)) {
                                    document.getElementById('anchor_' + childEle.ControlHtmlID)!.style.display = 'block';
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.style.display = 'block';
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.removeAttribute('style');
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.setAttribute('style', '');
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.classList.add('visible');

                                    txAccordinArray.push(childEle.ControlHtmlID);
                                }
                            }
                        }
                        else {
                            if (document.getElementById(childEle.ControlHtmlID)) {
                                document.getElementById(childEle.ControlHtmlID)!.style.display = 'none';
                                if (document.getElementById('anchor_' + childEle.ControlHtmlID)) {
                                    document.getElementById('anchor_' + childEle.ControlHtmlID)!.style.display = 'none';
                                    document.getElementById('panel_' + childEle.ControlHtmlID)!.style.display = 'none';
                                }
                            }
                        }
                    }
                    else {
                        if (ctrlObj.GroupName === childEle.GroupName && ctrlObj.Id !== childEle.Id) {
                            eleArr.ControlsData.forEach((childEle11: any) => {
                                if (childEle11.RecipientId === curRoleId.toLowerCase() || (childEle11.RecipientId == null)) {
                                    if (childEle.Id == ctrlObj.Id && childEle.Id === childEle11.ConditionDetails.ControllingFieldID) {
                                        if (document.getElementById(childEle11.ControlHtmlID)) {
                                            document.getElementById(childEle11.ControlHtmlID)!.style.display = 'none';
                                            if (document.getElementById('anchor_' + childEle11.ControlHtmlID)) {
                                                document.getElementById('anchor_' + childEle11.ControlHtmlID)!.style.display = 'none';
                                                document.getElementById('panel_' + childEle11.ControlHtmlID)!.style.display = 'none';
                                            }
                                        }
                                    }

                                    if (childEle11.ControlName.toLowerCase() === 'checkbox' && childEle11.GroupName != '' && ctrlObj.GroupName === childEle11.GroupName && ctrlObj.Id !== childEle11.Id) {
                                        if (!txRadioArray.includes(childEle11.ControlHtmlID)) {
                                            txRadioArray.push(childEle11.ControlHtmlID);
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            }
        });

        this.commonService.applyControlsStylesToCheckList(txRadioArray, ctrlObj, txAccordinArray);
    }

    //Checking the dependecies (Radio, Checkbox and Dropdown) and showing the dependecies controls and updating the checklist array.
    checkDependenciesControl(ctrlObj: any, selectedDropdownValue: any, type: any) {
        let curRoleId: any = localStorage.getItem("CurrentRecipientID");
        if (curRoleId == null) {
            curRoleId = localStorage.getItem("RecipientID");
        }

        if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
            this.commonService.appliChildDependencyForDropdown(ctrlObj, selectedDropdownValue);
        }
        else if (type != "1") {
            if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
                this.documentControlsResponse.EnvelopeImageControlData.forEach((ele: any) => {
                    if (ele.ControlsData && ele.ControlsData.length) {
                        if (ctrlObj.ControlName.toLowerCase() !== 'dropdown') {
                            this.getDependencies(ctrlObj, ele, curRoleId);
                        }
                    }
                });
            }
        }

        this.ShowDefaultSignature();
        if (this.searchTermDisplay != '') {
            this.searchTerm = '';
            this.searchTermDisplay = '';
            this.onFilterApply();
        }
        else {
            this.getTotalRemaingFieldsControls('');
        }
    }

    showCheckListAccordinLeftSide(txtArray: any) {
        this.commonService.showCheckListAccordinLeftSide(txtArray);
    }

    controlOnchangeEvent(event: Event, controlObj: any) {
        let controlId = document.getElementById('control_' + controlObj.ControlHtmlID)!;

        if (controlId.getAttribute('data-title') === 'Zip' || controlId.getAttribute('data-title') === 'SSN') {
            this.commonService.maskSSN(event, controlId.getAttribute('id'));
        }

        if (controlId.getAttribute('data-title') === 'Date') {
            this.updateChecklist(controlId.getAttribute('id'));
        }
    }

    mobileViewControlOnchangeEvent(event: Event) {
        let controlId: any = document.getElementById('txtMaximizeControl')!;

        if (controlId.getAttribute('data-title') === 'Zip' || controlId.getAttribute('data-title') === 'SSN') {
            this.commonService.maskSSN(event, controlId.getAttribute('id'));
        }

        if (controlId.getAttribute('data-title') === 'Date') {
            this.updateChecklist(controlId.getAttribute('id'));
        }
    }

    mobileViewDateControlOnchangeEvent(event: any, typeVal: any) {
        let controlId: any = document.getElementById(typeVal)!;
        if (controlId.getAttribute('data-title') === 'Date') {
            if (controlId.value) {
                var charCode = event.keyCode || event.which;
                if (charCode > 31 && ((charCode < 45 || charCode > 57) && (charCode < 189 || charCode > 192))) {
                    controlId.value = '';
                }
                else {
                    this.updateChecklist(controlId.getAttribute('id'));
                }
            }
        }
    }

    //Text Dependencies Control functionality.
    checkTextDependenciesControl(event: any, ctrlObj: any) {
        this.showTextDependenciesControl(ctrlObj, event.target.value);

        if (this.searchTermDisplay != '') {
            this.searchTerm = '';
            this.searchTermDisplay = '';
            this.onFilterApply();
        }
    }

    showTextDependenciesControl(ctrlObj: any, controlVal: any) {
        let curRoleId: any = localStorage.getItem("CurrentRecipientID");
        if (curRoleId == null) {
            curRoleId = localStorage.getItem("RecipientID");
        }
        let txAccordinArray: string[] = [];
        if (ctrlObj && ctrlObj.ConditionDetails.DependentFields && ctrlObj.ConditionDetails.DependentFields.length) {
            ctrlObj.ConditionDetails.DependentFields.forEach((eleChildDep: any) => {

                let controlItem: any = document.querySelector('[data-document-content-id="' + eleChildDep.ControlID + '"]');
                let controlHtmlID: any = controlItem?.getAttribute('id').replace("control_", "");
                let cntrlId: any = '';
                let recpId: any = '';
                if (document.getElementById(controlHtmlID)) {
                    recpId = document.getElementById(controlHtmlID)?.getAttribute('data-recipientid');
                    cntrlId = document.getElementById(controlHtmlID)?.getAttribute('data-unique');
                }

                if (curRoleId && (recpId === curRoleId.toLowerCase() || recpId == null)) {
                    if (eleChildDep.ControlID === cntrlId && eleChildDep.ConditionID == '8E2B3EC5-6CD1-4F3E-8025-4FDC7D6E35E3'.toLowerCase() &&
                        eleChildDep.SupportText != '' && controlVal != null &&
                        eleChildDep.SupportText.toLowerCase() === controlVal.toLowerCase()) {
                        if (document.getElementById(controlHtmlID)) {
                            document.getElementById(controlHtmlID)!.style.display = 'block';
                            if (document.getElementById('anchor_' + controlHtmlID)) {
                                document.getElementById('anchor_' + controlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + controlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + controlHtmlID)!.removeAttribute('style');
                                document.getElementById('panel_' + controlHtmlID)!.setAttribute('style', '');
                                document.getElementById('panel_' + controlHtmlID)!.classList.add('visible');

                                txAccordinArray.push(controlHtmlID);

                            }
                            this.globalCtrlObj = controlItem;
                        }
                    }
                    else if (eleChildDep.ControlID === cntrlId && eleChildDep.ConditionID == '68569338-7AEA-481F-988C-B70D13607E81'.toLowerCase() &&
                        eleChildDep.SupportText != '' && controlVal != null && controlVal.toLowerCase().indexOf(eleChildDep.SupportText.toLowerCase()) > -1) {
                        if (document.getElementById(controlHtmlID)) {
                            document.getElementById(controlHtmlID)!.style.display = 'block';
                            if (document.getElementById('anchor_' + controlHtmlID)) {
                                document.getElementById('anchor_' + controlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + controlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + controlHtmlID)!.removeAttribute('style');
                                document.getElementById('panel_' + controlHtmlID)!.setAttribute('style', '');
                                document.getElementById('panel_' + controlHtmlID)!.classList.add('visible');

                                txAccordinArray.push(controlHtmlID);

                            }
                            this.globalCtrlObj = controlItem;
                        }
                    }
                    else if (eleChildDep.ControlID === cntrlId && (eleChildDep.ConditionID == '0B069009-E271-4534-8C8B-21D876CC31DE'.toLowerCase() || eleChildDep.ConditionID == '7B108AA4-B5F9-489D-930B-2AEB90811518'.toLowerCase()) &&
                        controlVal == '') {
                        if (document.getElementById(controlHtmlID)) {
                            document.getElementById(controlHtmlID)!.style.display = 'block';
                            if (document.getElementById('anchor_' + controlHtmlID)) {
                                document.getElementById('anchor_' + controlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + controlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + controlHtmlID)!.removeAttribute('style');
                                document.getElementById('panel_' + controlHtmlID)!.setAttribute('style', '');
                                document.getElementById('panel_' + controlHtmlID)!.classList.add('visible');

                                txAccordinArray.push(controlHtmlID);

                            }
                            this.globalCtrlObj = controlItem;
                        }
                    }
                    else if (eleChildDep.ControlID === cntrlId && (eleChildDep.ConditionID == 'E8351007-1A7E-449C-B3FD-AFE619CFFA8D'.toLowerCase() || eleChildDep.ConditionID == '2380C0B0-4A55-4B33-9E22-A93C55A78CC3'.toLowerCase()) &&
                        controlVal !== '') {
                        if (document.getElementById(controlHtmlID)) {
                            document.getElementById(controlHtmlID)!.style.display = 'block';
                            if (document.getElementById('anchor_' + controlHtmlID)) {
                                document.getElementById('anchor_' + controlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + controlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + controlHtmlID)!.removeAttribute('style');
                                document.getElementById('panel_' + controlHtmlID)!.setAttribute('style', '');
                                document.getElementById('panel_' + controlHtmlID)!.classList.add('visible');

                                txAccordinArray.push(controlHtmlID);
                            }
                            this.globalCtrlObj = controlItem;
                        }
                    }
                    else if (eleChildDep.ControlID === cntrlId && eleChildDep.SupportText != '' && controlVal != null &&
                        eleChildDep.SupportText.toLowerCase() !== controlVal.toLowerCase()) {
                        if (document.getElementById(controlHtmlID)) {
                            document.getElementById(controlHtmlID)!.style.display = 'none';
                            if (document.getElementById('anchor_' + controlHtmlID)) {
                                document.getElementById('anchor_' + controlHtmlID)!.style.display = 'none';
                                document.getElementById('panel_' + controlHtmlID)!.style.display = 'none';
                            }
                        }
                    }
                    else if (eleChildDep.ControlID === cntrlId) {
                        if (document.getElementById(controlHtmlID)) {
                            document.getElementById(controlHtmlID)!.style.display = 'none';
                            if (document.getElementById('anchor_' + controlHtmlID)) {
                                document.getElementById('anchor_' + controlHtmlID)!.style.display = 'none';
                                document.getElementById('panel_' + controlHtmlID)!.style.display = 'none';
                            }
                        }
                    }
                }

                if (txAccordinArray && txAccordinArray.length > 0) {
                    setTimeout(() => {
                        this.showCheckListAccordinLeftSide(txAccordinArray);
                    }, 20);
                }
            });


        } else {
            this.globalCtrlObj = null;
        }
        this.ShowDefaultSignature();
    }

    //on focus date picker, to show popup
    onShowControlDateTooltip(d: any) {
        d.toggle();
        this.isCheckDatePopupOpen();
    }

    getIsRequired(ctrlObj: any) {
        return this.commonService.getIsRequiredLabel(ctrlObj, "1");
    }

    getIsRequiredLabel(ctrlObj: any) {
        return this.commonService.getIsRequiredLabel(ctrlObj, "1");
    }

    getInputIsRequired(ctrlObj: any) {
        return this.commonService.getInputIsRequired(ctrlObj, "1");
    }

    isCheckDatePopupOpen() {
        if (this.webOrMobile === 'mobile') {
            setTimeout(() => {
                // console.log('>>>>>>>.',document.querySelectorAll('ngb-datepicker'));
                let prevNextBtnAlignELe: any = document.getElementById('prevNextBtnAlignMobileContainer');
                if (document.querySelectorAll('ngb-datepicker') && document.querySelectorAll('ngb-datepicker')[0] && document.querySelectorAll('ngb-datepicker')[0].classList.value === 'dropdown-menu show') {
                    prevNextBtnAlignELe.classList.add('iphone-date-popup-align');
                } else {
                    prevNextBtnAlignELe.classList.remove('iphone-date-popup-align');
                }
            }, 50);
        }
    }

    onShowDatePicker(dateObj: any, ctrlTabindex: any, contrlObj: any) {
        for (var i = 0; i < this.mobileControlArr.length; ++i) {
            if (this.mobileControlArr[i].tabindex == ctrlTabindex) {
                this.tabIndex = i;
                break;
            }
        }
        this.hideAllControlTooltips();

        if (this.webOrMobile === 'mobile') {
            let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
            if (closeMoreActionsMenu) {
                closeMoreActionsMenu.click();
            }

            if (this.pinchZoom.pinchZoom.initialScale === 1) {
                this.pinchZoom.toggleZoom();
                setTimeout(() => {
                    this.updateControlPositions(contrlObj.ControlHtmlID);
                }, 300);
            } else {
                if (this.pinchZoom.pinchZoom.initialScale > 1) {
                    this.updateControlPositions(contrlObj.ControlHtmlID);
                }
            }

            document.getElementById('control_' + contrlObj.ControlHtmlID)!.style.border = '1px solid orange';
            setTimeout(() => {
                this.checkisLastControl(contrlObj.TabIndex);
                this.responsiveOpenModelPopup(contrlObj.ControlHtmlID);
            }, 300);
        } else {
            this.showMobilePopup = 'none';
            setTimeout(() => {
                dateObj.toggle();
            }, 50);
            document.getElementById('control_' + contrlObj.ControlHtmlID)?.blur();
        }
        document.getElementById('tooltip_' + contrlObj.ControlHtmlID)!.style.display = 'none';

        if (this.tabIndex == this.mobileControlArr.length - 1) {
            this.mobileNextBtn = 'false';
        } else {
            this.mobileNextBtn = 'true';
        }

        if (this.tabIndex == 0) {
            this.mobilePrevBtn = 'false';
        } else {
            this.mobilePrevBtn = 'true';
        }
    }

    onShowControlTooltip(contrlObj: any, selectedFromFilter: any) {
        this.hideAllControlTooltips();

        for (var i = 0; i < this.mobileControlArr.length; ++i) {
            if (this.mobileControlArr[i].tabindex == contrlObj.TabIndex) {
                this.tabIndex = i;
                break;
            }
        }

        if (this.mobileControlArr.length === 0 && this.tabIndex !== 0) {
            this.tabIndex = this.tabIndex - 1;
        }
        if (this.webOrMobile === 'web') {
            let controlEle: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
            controlEle.style.outline = 'none';
            let tooltipControlEle: any = document.getElementById('tooltip_' + contrlObj.ControlHtmlID);
            if (contrlObj.ControlName === 'Signature' || contrlObj.ControlName === 'NewInitials') {
                if (controlEle.getAttribute('src') === '' || controlEle.getAttribute('src') === null || controlEle.getAttribute('src') === undefined) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    tooltipControlEle.style.display = 'none';
                }
            } else if (contrlObj.ControlName === 'Text' || contrlObj.ControlName === 'Title' ||
                contrlObj.ControlName === 'Name' || contrlObj.ControlName === 'Company' ||
                contrlObj.ControlName === 'Email') {
                let ele: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
                let tooltiplEle: any = document.getElementById('tooltip_' + contrlObj.ControlHtmlID);
                if (ele.value === '' || ele.value === null || ele.value === undefined) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    let cname: any = ele.getAttribute('data-title');
                    if (contrlObj.ControlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                        if (ele.value.indexOf('_') > 0 || ele.value.indexOf('-') > 5) {
                            tooltipControlEle.style.display = 'block';
                            this.checkisLastControl(contrlObj.TabIndex);
                        } else {
                            tooltipControlEle.style.display = 'none';
                        }
                    } else {
                        tooltipControlEle.style.display = 'none';
                    }
                }
            } else if (contrlObj.ControlName === 'Radio' || contrlObj.ControlName === 'Checkbox') {
                if (controlEle.checked === false) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    tooltipControlEle.style.display = 'none';
                }
            } else if (contrlObj.ControlName === 'DropDown') {
                let value: any = controlEle.selectedOptions[0].innerText.trim();
                if (value !== '' && value !== undefined && value != null) {
                    tooltipControlEle.style.display = 'none';
                } else {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                }
            }

        }

        if (this.webOrMobile === 'mobile') {
            let controlEle: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
            let tooltipControlEle: any = document.getElementById('tooltip_' + contrlObj.ControlHtmlID);
            controlEle?.blur();
            this.hideAllOrangeBorder();
            localStorage.setItem('currentPagination', contrlObj.PageNo);
            let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
            if (closeMoreActionsMenu) {
                closeMoreActionsMenu.click();
            }

            if (contrlObj.ControlName === 'Signature') {
            } else if (contrlObj.ControlName === 'NewInitials') {
                let controlValue: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
                let ele: any = document.getElementsByClassName('newInitialsignature') as HTMLCollectionOf<HTMLElement>;
                if (controlValue.getAttribute('src') !== null) {
                    ele[0].style.border = '1px solid #ddd';
                } else {
                    //ele[0].style.border = '1px solid orange';
                }
            } else if (contrlObj.ControlName === 'Text' || contrlObj.ControlName === 'Title' ||
                contrlObj.ControlName === 'Name' || contrlObj.ControlName === 'Company' ||
                contrlObj.ControlName === 'Email') {
                let ele: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
                let tooltiplEle: any = document.getElementById('tooltip_' + contrlObj.ControlHtmlID);
                if (ele.value === '' || ele.value === null || ele.value === undefined) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    let cname: any = ele.getAttribute('data-title');
                    if (contrlObj.ControlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                        if (ele.value.indexOf('_') > 0 || ele.value.indexOf('-') > 5) {
                            tooltipControlEle.style.display = 'block';
                            this.checkisLastControl(contrlObj.TabIndex);
                        } else {
                            tooltipControlEle.style.display = 'none';
                        }
                    } else {
                        tooltipControlEle.style.display = 'none';
                    }
                }
            } else if (contrlObj.ControlName === 'Radio' || contrlObj.ControlName === 'Checkbox') {
                if (controlEle.checked === false) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    tooltipControlEle.style.display = 'none';
                }
            } else if (contrlObj.ControlName === 'DropDown') {
                let value: any = controlEle.selectedOptions[0].innerText.trim();
                if (value !== '' && value !== undefined && value != null) {
                    tooltipControlEle.style.display = 'none';
                } else {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                }
            } else {
                if (contrlObj.ControlName !== 'Signature' && contrlObj.ControlName !== 'NewInitials') {
                    document.getElementById('control_' + contrlObj.ControlHtmlID)!.style.border = '1px solid orange';
                }
            }

            this.checkScrollCountAndUpdatePopup(contrlObj, selectedFromFilter);

        }

        if (this.remainingFieldsCount !== 0) {
            if (this.tabIndex == 0) {
                this.mobilePrevBtn = 'false';
                this.mobileNextBtn = 'true';
            }
            else if (this.tabIndex == this.mobileControlArr.length - 1) {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'false';
            }
            else if (this.tabIndex != this.mobileControlArr.length - 1) {
                let arr: any = this.mobileControlArr[this.tabIndex];
                if (arr) {
                    if (this.mobileControlArr.length && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                        this.mobilePrevBtn = 'true';
                        this.mobileNextBtn = 'false';
                    }
                    else {
                        this.mobilePrevBtn = 'true';
                        this.mobileNextBtn = 'true';
                    }
                }
            }
            else {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'true';
            }
        } else {
            this.hideAllMobilePrevNextDoneBtns();
        }
    }

    //tooltip control positions
    updateControlPositions(ctrlId: any) {
        this.commonService.setPinchZoomScale(this.pinchZoom);
        const zoomStyles: any = getComputedStyle(document.getElementsByClassName('document-controls-zoom')[0]);
        // let zoom: any = zoomStyles.getPropertyValue('zoom');
        let zoom: any = zoomStyles.getPropertyValue('transform').match(/-?[\d\.]+/g)[0];
        let ele: any = document.getElementById(ctrlId.replace('control_', ''))!;
        let posControl: any = ele;
        let pageHeightVal: any = 0;
        let pageEle: any;
        let pageNum: any;
        let currentControlPageNoVal: any = ele.getAttribute('data-page');
        if (currentControlPageNoVal) {
            this.onUpdatePageScrollAndCount(parseInt(currentControlPageNoVal));
        }
        Array.from(document.getElementsByClassName("imgControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((page: any, pageIndex: any) => {
                pageEle = document.getElementById(page.id)!;
                pageNum = pageEle.getAttribute('page-no');
                let currentControlPageNo: any = ele.getAttribute('data-page');
                if ((pageIndex + 1) < parseInt(currentControlPageNo)) {
                    pageHeightVal = pageHeightVal + pageEle.offsetHeight;
                }
            })

        let classTag: any = document.getElementsByClassName('pinch-zoom-content') as HTMLCollectionOf<HTMLElement>;
        let pinchZoomScaleVal: any = this.pinchZoom.pinchZoom.initialScale;
        let left: any = parseFloat(posControl.style.left.split('px')[0]) * parseFloat(zoom);
        //let top: any = pageHeightVal + (parseFloat(posControl.style.top.split('px')[0]) * parseFloat(zoom));
        let top: any = (parseFloat(posControl.style.top.split('px')[0]) * parseFloat(zoom));
        let finaltLeftVal: any;// = left; //(left / pinchZoomScaleVal) * parseFloat(zoom)
        if (left > 10) {
            finaltLeftVal = -left;
        } else {
            finaltLeftVal = 20;
        }

        this.pinchZoom.pinchZoom.moveX = finaltLeftVal;
        this.pinchZoom.pinchZoom.moveY = -top;
        this.pinchZoom.pinchZoom.initialMoveX = this.pinchZoom.pinchZoom.moveX;
        this.pinchZoom.pinchZoom.initialMoveY = this.pinchZoom.pinchZoom.moveY;
        this.pinchZoom.pinchZoom.startX = left;
        this.pinchZoom.pinchZoom.startY = top;

        //console.log('final left and top', left, top);
        //classTag[0].style.transform = "matrix(" + Number(pinchZoomScaleVal) + ", 0, 0, " + Number(pinchZoomScaleVal) + ", " + Number(-this.pinchZoom.pinchZoom.startX) + ", " + Number(-this.pinchZoom.pinchZoom.startY) + ")";
        classTag[0].style.transform = "matrix(" + Number(pinchZoomScaleVal) + ", 0, 0, " + Number(pinchZoomScaleVal) + ", " + finaltLeftVal + ", " + -top + ")";
        this.cdRef.detectChanges();
    }

    onCloseTooltip(contrlId: any) {
        document.getElementById('tooltip_' + contrlId)!.style.display = 'none';
    }

    onPreviewPagesView() {
        this.getPagesListAndTotalpages();
        this.hidePagesViewSection = !this.hidePagesViewSection;
    }

    closeMoreActionsMenu() {
        let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
        closeMoreActionsMenu.click();
        this.commonService.removeBodyTagPositionFixed();
    }

    onMobilePreviewPagesView() {
        this.getPagesListAndTotalpages();
        this.commonService.userSource$.subscribe(data => {
            this.$documentImg = data;
        })
        setTimeout(() => {
            this.getPreviewSelectedColor(this.$documentImg);
            this.getPreviewSelected(this.$documentImg);
        }, 500);
    }

    onWebPreviewPagesView() {
        this.commonService.userSource$.subscribe(data => {
            this.$documentImg = data;
        })
        setTimeout(() => {
            this.getPreviewSelectedColor(this.$documentImg);
            this.getPreviewSelected(this.$documentImg);
        }, 500);
    }

    getPagesListAndTotalpages() {
        if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeImageControlData) {
            this.pageViewArr = this.documentControlsResponse.EnvelopeImageControlData;
            this.pagePreviewTotalPages = this.pageViewArr.length;
        }
    }

    //on zoom in
    onZoomIn() {
        if (this.imageSize < 2) {
            this.imageSize = this.imageSize + this.zoomingVal;
            this.imageSize = parseFloat(this.imageSize.toFixed(2));
            // console.log('zoomin', this.imageSize);
            this.percentageAndToastr(this.imageSize);
        }
    }

    //on zoom out
    onZoomOut() {
        if (this.imageSize > 1) {
            this.imageSize = this.imageSize - this.zoomingVal;
            this.imageSize = parseFloat(this.imageSize.toFixed(2));
            // console.log('zoomout', this.imageSize);
            this.percentageAndToastr(this.imageSize);
        }
    }

    getClass() {
        return this.commonService.getClass(this.isFavorite);
    }

    getLeftSideClass() {
        return this.commonService.getLeftSideClass(this.isFavorite);
    }

    getRightSideClass() {
        return this.commonService.getRightSideClass(this.isFavorite);
    }

    //from dropdown to selcet particular percentage
    onPercentageChange(val: any) {
        // console.log('onPercentageChange', val)
        if (val === -1) {
            this.isFavorite = false;
            this.imageSize = 1;
            this.percentageAndToastr(this.imageSize);
            this.documentWidth = window.outerWidth - 20 + 'px';
        } else {
            this.isFavorite = true;
            this.imageSize = parseFloat(val);
            this.percentageAndToastr(this.imageSize);
        }
    }

    //common function for both percentage value and toastr message
    percentageAndToastr(imgSize: any) {
        // this.renderer2.setStyle(this.documentPageContainer.nativeElement, 'zoom', imgSize);
        if (imgSize == 1.1) {
            this.toastr.success('Zoom', 110 + '%');
            this.percentageValue = '110%';
        } else {
            this.toastr.success('Zoom', imgSize * 100 + '%');
            this.percentageValue = imgSize * 100 + '%';
        }

        let positionAbsolute: any = '';
        let paddingBottom: any = '';
        // let transformOrigin: any = 'top center';
        let footerClass: any = document.getElementsByClassName('footer');
        let documentPageClass = document.getElementsByClassName('documentPage');
        let subdocumentClass = document.getElementsByClassName('subdocument');
        if (footerClass && footerClass[0]) {
            footerClass[0].style.bottom = '-130px';
            footerClass[0].style.position = 'relative';
        }
        if (documentPageClass && documentPageClass[0]) {
            documentPageClass[0].classList.remove(
                'paddingBottom150',
                'paddingBottom200'
            );
        }
        if (subdocumentClass && subdocumentClass[0]) {
            subdocumentClass[0].classList.remove('marginLeft150', 'marginLeft200');
        }

        let zoomOutbtn: any = document.getElementById('zoomOutbtn');
        let zoomInbtn: any = document.getElementById('zoomInbtn');
        if (this.percentageValue === '150%') {
            if (footerClass && footerClass[0]) {
                footerClass[0].style.bottom = '0px';
                footerClass[0].style.position = 'fixed';
                footerClass[0].style.width = '100%';
            }
            if (subdocumentClass && subdocumentClass[0]) {
                subdocumentClass[0].classList.add('marginLeft150');
            }
            if (documentPageClass && documentPageClass[0]) {
                setTimeout(() => {
                    documentPageClass[0].classList.add('paddingBottom150');
                }, 300);
            }
            this.hidePagesViewSection = true;
            if (zoomOutbtn) {
                zoomOutbtn.removeAttribute('disabled');
            }
            if (zoomInbtn) {
                zoomInbtn.removeAttribute('disabled');
            }
        } else if (this.percentageValue === '200%') {
            if (footerClass && footerClass[0]) {
                footerClass[0].style.bottom = '0px';
                footerClass[0].style.position = 'fixed';
                footerClass[0].style.width = '100%';
            }
            if (subdocumentClass && subdocumentClass[0]) {
                subdocumentClass[0].classList.add('marginLeft200');
            }
            if (documentPageClass && documentPageClass[0]) {
                setTimeout(() => {
                    documentPageClass[0].classList.add('paddingBottom200');
                }, 300);
            }
            this.hidePagesViewSection = true;
            if (zoomOutbtn) {
                zoomOutbtn.removeAttribute('disabled');
            }
            if (zoomInbtn) {
                zoomInbtn.setAttribute('disabled', 'disabled');
            }
        } else {
            this.hidePagesViewSection = false;
            if (zoomOutbtn) {
                zoomOutbtn.setAttribute('disabled', 'disabled');
            }
            if (zoomInbtn) {
                zoomInbtn.removeAttribute('disabled');
            }
        }

        this.renderer2.setStyle(this.documentPageContainer.nativeElement, 'transform', 'scale(' + imgSize + ')');
        this.renderer2.setStyle(this.documentPageContainer.nativeElement, 'position', positionAbsolute);
    }

    hideAndShowFilterSection() {
        this.hideFilterSection = !this.hideFilterSection;
    }

    //open modal popup
    openModal(modalVal: any) {
        this.closeCanvasMenu();
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            this.AddEditClicked = "0";
            if (modalVal === 'Add Signature') {
                let userData: any = this.documentControlsResponse.userdata;
                let profileDetails: any;
                profileDetails = this.clickToSignService.getCurrentSessionUserDtls();
                if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0 && profileDetails[0].AcceptandSign == 1 && this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                    localStorage.setItem("isAcceptAndSignClicked", "true");
                }

                let isAcceptAndSignClicked: any = localStorage.getItem("isAcceptAndSignClicked");
                if (isAcceptAndSignClicked && isAcceptAndSignClicked.toLowerCase() == "true") {
                    this.clickToSignPopupStyle = 'block';
                    this.commonService.addModelPopupClassForBodyTag();
                    let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
                    txtsignerProfileName.value = localStorage.getItem("txtsignerProfileNameValue");
                    let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
                    txtsignerProfileInitials.value = localStorage.getItem("txtsignerProfileInitialsValue");

                    if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0 && this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                        if (this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                            // this.AddEditClicked = "1";
                            this.bindsignerPopupDtls();
                            return;
                        }
                    }
                    else {
                        this.UpdateSignimageFromSignerProfileText(0);
                        this.UpdateInitialsimageFromSignerProfileText();
                    }
                    this.clickToSignService.updateSignerProfilePopupheight();
                    if (profileDetails[0].signerProfileName) {
                        txtsignerProfileName.value = profileDetails[0].signerProfileName.trim();
                    }
                    else {
                        txtsignerProfileName.value = "";
                    }
                    if (profileDetails[0].signerProfileInitials) {
                        txtsignerProfileInitials.value = profileDetails[0].signerProfileInitials.trim();
                    }
                    else {
                        txtsignerProfileInitials.value = "";
                    }
                }
                else if (this.isNullOrEmptyCheck(userData)) {
                    this.clickToSignPopupStyle = 'block';
                    this.commonService.addModelPopupClassForBodyTag();
                    this.clickToSignService.updateSignerProfilePopupheight();

                    this.bindDataForSignerProfile(userData, "");
                }
                else {
                    this.clickToSignPopupStyle = 'block';
                    this.commonService.addModelPopupClassForBodyTag();
                    this.clickToSignService.updateSignerProfilePopupheight();
                }

                let btnAccept: any = document.getElementById("btnAccept");
                let isTermsDisabled: any = localStorage.getItem("isDisabled");
                let chkterms: any = document.getElementById("chkterms");
                if (isTermsDisabled) {
                    chkterms?.setAttribute('disabled', 'disabled');
                    chkterms?.setAttribute('checked', 'checked');
                    this.clicktoSignAgree = true;
                    chkterms.style.cursor = "no-drop";
                    btnAccept?.removeAttribute('disabled');
                }
                else {
                    chkterms?.removeAttribute('disabled');
                    chkterms?.removeAttribute('checked');
                    this.clicktoSignAgree = false;
                    chkterms.style.cursor = "pointer";
                    btnAccept?.setAttribute('disabled', 'disabled');
                }
            }
            else if (modalVal === 'Attachments') {
                const modalRef = this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'attachment-class', keyboard: false, backdrop: 'static', });
                modalRef.componentInstance.attachmentList = this.attachmentList;
                modalRef.componentInstance.attachmentListCount = this.attachmentListCount;
                localStorage.setItem("IsGroupTemplateSignerAttachments", "false");

                modalRef.componentInstance.event.subscribe((attachObj: any) => {
                    this.attachmentList = attachObj.attchlist;
                    this.attachmentListCount = attachObj.attchlistCount;
                    this.cdRef.detectChanges();
                });
            }
            else if (modalVal === 'Decline') {
                this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'decline-class', keyboard: false, backdrop: 'static', });
            }
            else if (modalVal === 'Invite Signers') {
                const modalRef = this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'invite-Signers', keyboard: false, backdrop: 'static', });
            }
            else if (modalVal === 'Change Signer') {
                let isToDisable: boolean = this.isDisabledChangeSigner();
                if (!isToDisable) {
                    let modalPopupObj = {
                        envelopeID: this.EnvelopeID,
                        ipAddress: this.ipAddress,
                        recipientID: localStorage.getItem("RecipientID"),
                        isMultiTemplate: false
                    }
                    const modalRef = this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'changeSigner-modal', keyboard: false, backdrop: 'static', });
                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                }
            }
            else {
                let modalPopupObj = {
                    envelopeID: this.EnvelopeID,
                    ipAddress: this.ipAddress,
                    recipientID: ''
                }
                const modalRef = this.modalService.open(this.getModalPopup(modalVal));
                modalRef.componentInstance.modalPopupObj = modalPopupObj;
            }
        }
    }

    //Returns the modal component name based on modal string
    getModalPopup(val: any) {
        return this.commonService.getModalPopup(val);
    }

    getdefaultSelectedColor(isActiveFlag: any) {
        this.commonService.getdefaultSelectedColor(isActiveFlag);
    }

    getPreviewSelectedColor(p: any) {
        this.commonService.getdefaultSelectedColor(p);
    }

    getPreviewSelected(p: any) {
        this.commonService.getPreviewSelected(p);
    }

    // download pdf
    onDownload() {
        this.showLoader = true;
        let envelopeId = localStorage.getItem('EnvelopeID');
        let recipientId = localStorage.getItem('RecipientID')
        let url: any = this.commonConstants.GetDownloadPDFPreview + '/' + envelopeId + '/' + recipientId;
        this.signerLandingService.getDownloadPDFPreview(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                //console.log('res', resp)
                let base64FileData: any = resp.Base64FileData;
                if (base64FileData) {
                    this.toastr.success('Downloaded successfully.', this.lang_success, { timeOut: 2000 });
                    var blob = this.commonService.b64toBlob(base64FileData, 'application/pdf');
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = window.URL.createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    window.URL.revokeObjectURL(bloburl);
                    a.remove();
                }
                this.cdRef.detectChanges();
            });
    }

    onHelp() {
        window.open(this.commonConstants.HelpAndSupport);
    }

    onSignupROneRedirection() {
        window.open(this.commonConstants.SignupROne);
    }

    onTermsOfServiceDownload() {
        let envelopeId = localStorage.getItem('EnvelopeID');
        let isStaticTemplate = false;

        let url: any = this.commonConstants.DownloadDisclaimerPDF + '/' + envelopeId + '/' + isStaticTemplate;
        this.showLoader = true;
        this.signerLandingService.getDownloadDisclaimerPDF(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                //console.log('res', resp)
                let termsOfServiceBase64: any = resp.byteData;
                if (termsOfServiceBase64) {
                    this.toastr.success('Downloaded successfully.', this.lang_success, { timeOut: 2000 });
                    let contentType: any = "application/pdf";
                    if ((/iPhone/i.test(navigator.userAgent))) {
                        contentType = "application/octet-stream";
                    }
                    var blob = this.commonService.b64toBlob(termsOfServiceBase64, contentType);
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = (window.URL || window.webkitURL).createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    (window.URL || window.webkitURL).revokeObjectURL(bloburl);
                    a.remove();

                }
                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy() {
        this.signDestroy$.next();
        this.signDestroy$.complete();
        this.initDestroy$.next();
        this.initDestroy$.complete();
    }

    //Toggle Filter
    toggle() {
        this.show = !this.show;
        // Change the name of the button.
        if (this.show) {
            this.buttonName = "Hide";
        } else {
            this.buttonName = "Show";
        }
    }

    onCloseFilter() {
        this.hideFilter = !this.hideFilter;
        if (this.hideFilter) {
            this.hideFilter = true;
        } else {
            this.hideFilter = false;

        }
    }

    onDateSelected(event: any, ctrlObj: any, formateType: any, dControl: any) {
        let dateVal = this.getDateFormat(event, formateType, this.locale);
        if (dateVal) {
            (<HTMLInputElement>document.getElementById('control_' + ctrlObj.ControlHtmlID)).value = dateVal;
            document.getElementById('control_' + ctrlObj.ControlHtmlID)!.style.outline = 'none';
            this.updateChecklist(ctrlObj.ControlHtmlID);
        }
        else {
            let controlId: any = document.getElementById('control_' + ctrlObj.ControlHtmlID)!.parentNode;
            controlId.classList.add('filledControls');
            this.updateChecklist(ctrlObj.ControlHtmlID);
        }
        dControl.close();
        if(ctrlObj.ControlName.toLowerCase() === 'text' ){
            if (this.documentControlsResponse.EnableAutoFillTextControls == true || this.documentControlsResponse.EnableAutoFillTextControls == "true" || this.documentControlsResponse.EnableAutoFillTextControls == "True") {
                this.updateSimilarTextFields(ctrlObj.ControlHtmlID,dateVal);
            }
        }
        setTimeout(() => {
            this.getControlNextNav('');
        }, 150);
    }

    onMobileDateSelected(event: any, dateControl: any) {
        let hdnDataCtnType: any = document.getElementById('hdnDataCtnType');
        let formateType: any = hdnDataCtnType.value;
        let hdnMaximizeControlId: any = document.getElementById('hdnMaximizeControlId');
        let controlHtmlID = hdnMaximizeControlId.value;
        let datetype = localStorage.getItem("datetype");

        let dateVal = this.getDateFormat(event, formateType, this.locale);
        if (dateVal) {
            if (datetype == "0") {
                (<HTMLInputElement>document.getElementById('txtMaximizeControlTextDate')).value = dateVal;
                document.getElementById('txtMaximizeControlTextDate')!.style.outline = 'none';
            }
            else if (datetype == "1") {
                (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = dateVal;
                document.getElementById('txtMaximizeControlDate')!.style.outline = 'none';
            }
            else {
                (<HTMLInputElement>document.getElementById('txtMaximizeControlTextDate')).value = dateVal;
                (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = dateVal;
                document.getElementById('txtMaximizeControlTextDate')!.style.outline = 'none';
                document.getElementById('txtMaximizeControlDate')!.style.outline = 'none';
            }
            this.updateChecklist(controlHtmlID);
        }
        else {

            if (datetype == "0") {
                let controlId: any = document.getElementById('txtMaximizeControlTextDate')!.parentNode;
                controlId.classList.add('filledControls');
            }
            else if (datetype == "1") {
                let controlId: any = document.getElementById('txtMaximizeControlDate')!.parentNode;
                controlId.classList.add('filledControls');
            }
            else {
                let controlId1: any = document.getElementById('txtMaximizeControlTextDate')!.parentNode;
                controlId1.classList.add('filledControls');
                let controlId2: any = document.getElementById('txtMaximizeControlDate')!.parentNode;
                controlId2.classList.add('filledControls');
            }
            this.updateChecklist(controlHtmlID);
        }
        dateControl.close();
        setTimeout(() => {
            this.getControlNextNav('');
        }, 150);
    }

    addFilledfilledControlsClass(ctrlObj: any) {
        let controlele: any = document.getElementById('control_' + ctrlObj.ControlHtmlID)!
        let className = 'filledControls';
        if (ctrlObj.ControlName.toLowerCase() === 'checkbox') {
            if (controlele.checked === true) {
                let controlId: any = controlele.parentNode.parentNode;
                controlId.classList.add(className);
            } else {
                let controlId: any = controlele.parentNode.parentNode;
                controlId.classList.remove(className);
            }
        }
        else {
            if (controlele.value.trim() != '' && controlele.value.length > 0) {
                let isValid: boolean = false;
                let datatitle: any = controlele.getAttribute("data-title");
                if (datatitle == "SSN" || datatitle == "Zip") {
                    if (controlele.value.indexOf('_') > 0 || controlele.value.indexOf('_') > -1 || controlele.value.indexOf('-') > 5) {
                        isValid = true;
                    }
                    if (controlele.value == "_____" || controlele.value == "___-__-____") {
                        controlele.value = "";
                    }
                }
                let controlId: any = controlele.parentNode;
                if (isValid) {
                    controlId.classList.remove(className);
                }
                else {
                    controlId.classList.add(className);
                }
            }
            else {
                let controlId: any = controlele.parentNode;
                controlId.classList.remove(className);
            }
        }
    }

    //closing date picker popup
    closeFix(event: any, datePicker: any, ctrlObj: any) {
        if (event.target.offsetParent == null) {
            datePicker.close();
        } else if (event.target.offsetParent.nodeName != "NGB-DATEPICKER") {
            datePicker.close();
            // this.validateInputText(true, control)
        }
    }

    updateChecklist(ControlHtmlID: any) {
        if ((<HTMLInputElement>document.getElementById('control_' + ControlHtmlID)).value != "") {
            this.applyStylesToCheckList("add", ControlHtmlID);
            document.getElementById('control_' + ControlHtmlID)!.style.border = '2px solid #38b16e';
        }
        else {
            this.applyStylesToCheckList("remove", ControlHtmlID)
        }

        this.getTotalRemaingFieldsControls('');
    }

    getDateFormat(d: NgbDateStruct, df: any, locale: any) {
        //console.log('getDateFormat', d, df, locale);
        var dateRetVal = null;
        let day: any = d.day
        let month: any = d.month;
        day = (d.day <= 9) ? '0' + d.day : day;
        month = (d.month <= 9) ? '0' + d.month : month;

        dateRetVal = this.commonService.getDateFormat(day, month, d.year, df, d.month);
        return dateRetVal;
    }

    onClearAll() {
        this.requiredFlag = false;
        this.fieldsRemainingFlag = false;
        this.searchTerm = '';
        this.searchTermDisplay = '';
        this.onFilterApply();
    }

    getAllVisibleDocumentControls() {
        let controls: any = [];
        let popupCollection: any = [];
        this.mobileControlArr = [];
        this.mobileControlArrReverse = [];

        Array.from(document.getElementsByClassName("imgControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((imgItem: any) => {
                popupCollection = imgItem.getElementsByClassName('popUp');
                Array.from(popupCollection).forEach((popUpItem: any) => {
                    let isreadonly = false;
                    let inputTag = popUpItem.getElementsByTagName("input");
                    if (inputTag[0] != undefined) {
                        isreadonly = inputTag[0].attributes["readonly"];
                        if (isreadonly == undefined)
                            isreadonly = false;
                        else
                            isreadonly = true;

                        if (this.webOrMobile === 'mobile' && this.showMobilePopup === 'block' && inputTag[0].id === 'control_' + this.popuptempArr[0]) {
                            isreadonly = false;
                        }
                    }

                    let idSplit: any = popUpItem.attributes["id"].value;
                    let ele: any = document.getElementById('control_' + idSplit);
                    let nameCtrl: any = document.getElementById(idSplit);

                    let cntType = popUpItem.attributes["data-ctntype"].value;
                    let cntRequired = popUpItem.attributes["data-rq"].value;
                    if (this.searchTerm != '') {
                        if (cntType == this.searchTerm && window.getComputedStyle(popUpItem).display !== 'none' && !isreadonly && cntType != "Label" && cntType != "Hyperlink" && cntType != "DateTimeStamp")
                            controls.push(popUpItem);
                    }
                    else {
                        if (window.getComputedStyle(popUpItem).display !== 'none' && !isreadonly && cntType != "Label" && cntType != "Hyperlink" && cntType != "DateTimeStamp") {
                            controls.push(popUpItem);
                        }
                    }
                    if (window.getComputedStyle(popUpItem).display !== 'none' && !isreadonly && cntType != "Label" && cntType != "Hyperlink" && cntType != "DateTimeStamp") {
                        this.mobileControlArr.push({ 'tabindex': ele.getAttribute('tabindex'), 'controlId': 'control_' + idSplit, 'controlName': nameCtrl.getAttribute('data-ctntype'), 'required': cntRequired });
                        this.mobileControlArrReverse.push({ 'tabindex': ele.getAttribute('tabindex'), 'controlId': 'control_' + idSplit, 'controlName': nameCtrl.getAttribute('data-ctntype'), 'required': cntRequired });
                    }
                });
            });
        if (this.mobileControlArrReverse.length > 0) {
            this.mobileControlArrReverse = this.mobileControlArrReverse.reverse();
        }
        return controls;
    }

    onFilterApply() {
        setTimeout(() => {
            let controls: any = this.getAllVisibleDocumentControls();
            let filterCount: any = 0;
            let controlCollection: any = [];
            Array.from(controls).forEach((ctrlItem: any) => {
                let ctrlId = ctrlItem.attributes["id"].value;
                let controlType = ctrlItem.attributes["data-ctntype"].value;
                controlCollection = this.commonService.onFilterApply(ctrlId, controlType, ctrlItem, controlCollection, this.requiredFlag, this.fieldsRemainingFlag);
            });

            if (controlCollection && controlCollection.length > 0) {
                filterCount = controlCollection.length;
            }

            this.getNoFieldsRemaining(filterCount);
            setTimeout(() => {
                this.commonService.getUpdateCheckList(this.documentControlsResponse.CheckListData);
                this.currentIndex = this.commonService.showHideCheckListData(this.documentControlsResponse, controlCollection, filterCount, this.currentIndex);
                this.cdRef.detectChanges();
            }, 100);
            this.cdRef.detectChanges();
        }, 10);
        this.cdRef.detectChanges();
    }

    getNoFieldsRemaining(filterCount: any) {
        if (filterCount === 0) { //&& this.fieldsRemainingFlag === true
            this.showNoFieldsRemainingMsg = true;
        } else {
            this.showNoFieldsRemainingMsg = false;
        }
    }

    hideValidationErrormessages() {
        this.commonService.hideValidationErrormessages(this.documentControlsResponse);
    }

    getEachControlValidations(ctrlResp: any, isFinishLater: boolean) {
        let showDefaultSigContol: any = document.getElementById("showDefaultSignatureContol");
        if (showDefaultSigContol != undefined && showDefaultSigContol != null && window.getComputedStyle(showDefaultSigContol).display != 'none') {
            if (this.defaultSignatureObj[0].ControlName === 'FooterSignature') {
                this.defaultSignatureFlag = false;
                let launguageSignImageName: string = this.translationsService.getLanguageValidationVal('lang_yourNameImg');
                let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
                let defaultUpdatedImage: any = '';
                let footerSignNav: any = document.getElementById("FooterSignNav");
                if (footerSignNav != undefined && footerSignNav != null) {
                    let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                    defaultUpdatedImage = footerSignNavCol[0].attributes["src"] != undefined ? footerSignNavCol[0].attributes["src"].value : ''
                }

                if (!isFinishLater) {
                    if (this.showDefaultSignatureContol == "block") {
                        if (footerSignNav != undefined && footerSignNav != null && defaultUpdatedImage != defaultImage) {
                            this.defaultSignatureFlag = false;
                        }
                        else {
                            this.commonService.showToaster('Please sign default signature.', this.lang_warning);
                            this.defaultSignatureFlag = true;
                            this.hideAndShowFilterSection();
                        }
                    }
                }
            }
        }

        this.validateFlag = this.commonService.getEachControlValidations(this.mobileControlArr, isFinishLater, this.documentControlsResponse);
        let SuccessFlag: boolean = true;
        if (this.validateFlag || this.defaultSignatureFlag) {
            SuccessFlag = false;
        }
        return SuccessFlag;
    }

    //submit
    prepareSubmitEnvelopeObject() {
        let ctrlResp = this.documentControlsResponse;
        let ctrlSigningResp = this.responseSigningUrlModel;
        let envelopeSignDocumentSubmitInfo = {
            EnvelopeID: ctrlSigningResp.EnvelopeID,
            RecipientID: ctrlSigningResp.RecipientID,
            IsFromInbox: ctrlSigningResp.IsFromInbox,
            CopyEmail: ctrlSigningResp.CopyEmailId,
            ControlCollection: this.getControlCollection(ctrlResp, false),
            CertificateSignature: this.getCertificateSignature(),
            IsReviewed: this.isFileReviewed,
            IsConfirmationEmailReq: this.getConfirmationEmailReq(ctrlResp),
            IpAddress: this.ipAddress
        };
        return envelopeSignDocumentSubmitInfo;
    }

    submitEnvelope() {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let ctrlResp = this.documentControlsResponse;
            let successFlag: boolean = true;
            successFlag = this.getEachControlValidations(ctrlResp, false);
            if (successFlag) {
                let envelopeSignDocumentSubmitInfo: any = this.prepareSubmitEnvelopeObject();
                //  console.log(envelopeSignDocumentSubmitInfo);
                this.envelopeSignDocumentSubmitRequestInfo = envelopeSignDocumentSubmitInfo;

                //Mandatory Attachment files Validation
                let mandatoryCount: any;
                if (this.ShowAttachmentNavMenu) {
                    mandatoryCount = localStorage.getItem("MandatoryAttachmentFileCount");
                    if (mandatoryCount > 0) { //when attachment model popup opened.
                        this.validationPopupStyle = 'block';
                    }
                    else if (mandatoryCount != "0" && this.EnvelopeAdditionalUploadInfoList && this.EnvelopeAdditionalUploadInfoList.length > 0) { ////when attachment model popup not opened.
                        Array.from(this.EnvelopeAdditionalUploadInfoList).forEach((item: any) => {
                            if (item.IsRequired) {
                                if (item.FileName == "" || item.FileName == null) {
                                    mandatoryCount = mandatoryCount + 1;
                                }
                            }
                        });
                        if (mandatoryCount > 0) {
                            this.validationPopupStyle = 'block';
                        }
                        else {
                            this.filesReviewCheckAndSubmit(ctrlResp);
                        }
                    }
                    else {
                        this.filesReviewCheckAndSubmit(ctrlResp);
                    }
                }
                else {
                    this.filesReviewCheckAndSubmit(ctrlResp);
                }
            }
            // this.showFinishModalDailog="none";
        }
    }

    filesReviewCheckAndSubmit(ctrlResp: any) {
        if (ctrlResp.FileReviewCount > 0) {
            this.openFilesReview();
            let btnConfirm: any = document.getElementById('btnConfirm');
            let btnFileReviewDownload: any = document.getElementById('btnFileReviewDownload');
            if (this.isFileReviewed == "" || this.isFileReviewed == null) {
                btnConfirm?.setAttribute('disabled', 'disabled');
                btnFileReviewDownload?.setAttribute('background-color', '');
                btnFileReviewDownload?.removeAttribute('disabled');
            } else {
                this.isFileReviewed = '2';
                btnConfirm?.removeAttribute('disabled');
            }
        }
        else {
            this.envelopeSignDocumentSubmitRequestInfo.IsReviewed = this.isFileReviewed;
            //Call common submit method
            this.commonSubmitClickevent(this.envelopeSignDocumentSubmitRequestInfo);
        }
    }

    finishLater(val: any) {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let ctrlResp = this.documentControlsResponse;
            let ctrlSigningResp = this.responseSigningUrlModel;
            let successFlag: boolean = this.getEachControlValidations(ctrlResp, true);
            let isSendEmailOnFinishLater: boolean = false;
            if (val === '') {
                localStorage.setItem('IsFromSessionExpired', '0');
                isSendEmailOnFinishLater = false;
            } else if (val === 'sessionExpired') {
                localStorage.setItem('IsFromSessionExpired', '1');
                isSendEmailOnFinishLater = true;
            } else if (val === 'onUserSelectedFinishLater') {
                localStorage.setItem('IsFromSessionExpired', '0');
                isSendEmailOnFinishLater = true;
            }
            if (successFlag) {
                let envelopeFinishLaterSubmitInfo = {
                    EnvelopeID: ctrlSigningResp.EnvelopeID,
                    RecipientID: ctrlSigningResp.RecipientID,
                    ControlCollection: this.getControlCollection(ctrlResp, true),
                    LanguageCode: localStorage.getItem("cultureInfo"),
                    IsBack: false,
                    IsSaveControl: false,
                    UrlForFinishLater: localStorage.getItem("actualSigningUrl"),
                    InviteSignerModels: [],
                    IpAddress: this.ipAddress,
                    IsSendEmailOnFinishLater: isSendEmailOnFinishLater
                };

                //Call envelope submit api
                let finishLaterUrl = this.commonConstants.FinishLater;
                this.showLoader = true;
                this.signerLandingService.finishLater(finishLaterUrl, envelopeFinishLaterSubmitInfo).subscribe(
                    (responseDetails: any) => {
                        this.showLoader = false;
                        let returnUrl: string = "";
                        if (responseDetails && responseDetails.StatusCode == 200 && responseDetails.StatusMessage.toLowerCase() == "ok") {
                            localStorage.setItem("DisplayMessage", responseDetails.Message);
                            localStorage.setItem("MessageType", "Success");
                            localStorage.setItem("IsFromFinishLater", "true");
                            returnUrl = RouteUrls.Info;

                            let lang_envelopeDetailsSuccessArray = this.translationsService.getLanguageValidationVal("lang_envelopeDetailsSuccess"); //jsonLanguageValidationArray.filter((c: any) => c.KeyName == "lang_envelopeDetailsSuccess");
                            this.toastr.success(lang_envelopeDetailsSuccessArray, this.lang_success, { timeOut: 1000 });
                        }
                        else if (responseDetails && responseDetails.originalError && responseDetails.originalError.error && responseDetails.originalError.error.StatusCode == 400) {
                            localStorage.setItem("DisplayMessage", responseDetails.originalError.error.Message);
                            localStorage.setItem("MessageType", "Warning");
                            returnUrl = RouteUrls.Info;
                            this.toastr.warning(responseDetails.originalError.error.Message, this.lang_warning, { timeOut: 1000 })
                        }
                        this.commonService.getReturnUrlDirection(returnUrl);
                        this.cdRef.detectChanges();
                    });
            }
        }
    }

    getControlCollection(ctrlResp: any, isFinishLater: boolean) {
        let controls: any = this.getAllDocumentControls();
        // console.log(controls);
        let controlCollection: any = this.addControlsToCollection(controls, isFinishLater);
        controlCollection = this.addDefaultSignatureInitialControls(controlCollection, ctrlResp, isFinishLater);
        //console.log(controlCollection);
        return controlCollection;
    }

    addDefaultSignatureInitialControls(controlCollection: any, ctrlResp: any, isFinishLater: boolean) {
        //Default Signature

        let launguageSignImageName: string = this.translationsService.getLanguageValidationVal('lang_yourNameImg');
        let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
        let defaultUpdatedImage: any = '';
        let footerSignNav: any = document.getElementById("FooterSignNav");
        if (footerSignNav != undefined && footerSignNav != null) {
            let footerSignNavCol = footerSignNav.getElementsByTagName('img');
            defaultUpdatedImage = footerSignNavCol[0].attributes["src"] != undefined ? footerSignNavCol[0].attributes["src"].value : null
        }
        let footerSignControlData: any;

        let signatureText: any = (localStorage.getItem("data-sign-text") == undefined || localStorage.getItem("data-sign-text") == null) ? null : localStorage.getItem("data-sign-text");
        let signImageCol = controlCollection.filter((c: any) => c.ControlName == "Signature");
        let signaturePresent: boolean = false;
        let signatureFont: boolean = false;
        if (isFinishLater || (signatureText != null && signatureFont) || localStorage.getItem('signatureTypeID') == "AEB3D4A0-9DCF-4441-98A8-8ECDB7169936" || ((signaturePresent && (signImageCol != null && signImageCol.length > 0 && signImageCol[0].ControlValue) != '')) || (localStorage.getItem('prefill') == 'prefill' && !signaturePresent)) {
            if (signImageCol != null && signImageCol.length == 0 && footerSignNav != undefined && footerSignNav != null && window.getComputedStyle(footerSignNav).display !== 'none' && defaultUpdatedImage != defaultImage) {
                footerSignControlData = {
                    ControlHtmlID: localStorage.getItem('signatureTypeID'), // for only sign
                    ControlValue: defaultUpdatedImage,
                    SignatureString: (localStorage.getItem("data-sign-text") == undefined || localStorage.getItem("data-sign-text") == null) ? "" : localStorage.getItem("data-sign-text"),
                    SignatureFont: '',
                    ControlName: "Signature"
                };
                controlCollection.push(footerSignControlData);
            }
            else {
                let signCtrlVal: any = '';

                if (signImageCol != null && signImageCol.length > 0) {
                    signCtrlVal = signImageCol[0].ControlValue;
                    footerSignControlData = {
                        ControlHtmlID: localStorage.getItem('signatureTypeID'), // for only sign
                        ControlValue: signCtrlVal,
                        SignatureString: (localStorage.getItem("data-sign-text") == undefined || localStorage.getItem("data-sign-text") == null) ? "" : localStorage.getItem("data-sign-text"),
                        SignatureFont: '',
                        ControlName: "Signature"
                    };
                    controlCollection.push(footerSignControlData);
                }
            }
        }

        if (signImageCol != null && signImageCol.length == 0 && footerSignNav != undefined && footerSignNav != null && window.getComputedStyle(footerSignNav).display !== 'none' && defaultUpdatedImage != defaultImage) {
            footerSignControlData = {
                ControlHtmlID: localStorage.getItem('signatureTypeID'), // for only sign
                ControlValue: defaultUpdatedImage,
                SignatureString: (localStorage.getItem("data-sign-text") == undefined || localStorage.getItem("data-sign-text") == null) ? "" : localStorage.getItem("data-sign-text"),
                SignatureFont: '',
                ControlName: "Signature"
            };
            controlCollection.push(footerSignControlData);
        }

        //Default Initial
        //Frst check initial control exists or not
        let defaultInitialControlData: any;
        let initialCtrlVal: any = '';
        let initialImageCol = controlCollection.filter((c: any) => c.ControlName == "NewInitials");
        if (initialImageCol != null && initialImageCol.length > 0) {
            initialCtrlVal = initialImageCol[0].ControlValue;

            defaultInitialControlData = {
                ControlHtmlID: localStorage.getItem('InitialTypeID'), // for only sign
                ControlValue: initialCtrlVal,
                SignatureString: (localStorage.getItem("data-initials-text") == undefined || localStorage.getItem("data-initials-text") == null) ? "" : localStorage.getItem("data-initials-text"),
                SignatureFont: '',
                ControlName: "NewInitials"
            };
            controlCollection.push(defaultInitialControlData);
        }
        return controlCollection;
    }

    getAllDocumentControls() {
        let controls: any = [];
        let popupCollection: any = [];
        Array.from(document.getElementsByClassName("imgControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((imgItem: any) => {
                popupCollection = imgItem.getElementsByClassName('popUp');
                Array.from(popupCollection).forEach((popUpItem: any) => {
                    let isreadonly = false;
                    let inputTag = popUpItem.getElementsByTagName("input");
                    if (inputTag[0] != undefined) {
                        isreadonly = inputTag[0].attributes["readonly"];
                        if (isreadonly == undefined)
                            isreadonly = false;
                        else
                            isreadonly = true;
                    }

                    let cntType = popUpItem.attributes["data-ctntype"].value;
                    if (cntType != "DateTimeStamp" && window.getComputedStyle(popUpItem).display !== 'none' && !isreadonly) {
                        controls.push(popUpItem);
                    }
                    else if (cntType == "DateTimeStamp") {
                        controls.push(popUpItem);
                    }
                });
            });
        return controls;
    }

    addControlsToCollection(controls: any, isFinishLater: boolean) {
        let controlCollection: any = [];
        if (localStorage.getItem('signatureTypeID') == null || localStorage.getItem('signatureTypeID') == undefined ||
            localStorage.getItem('signatureTypeID') == "") {
            localStorage.setItem('signatureTypeID', SignatureType.Auto);
        }

        if (localStorage.getItem('InitialTypeID') == null || localStorage.getItem('InitialTypeID') == undefined ||
            localStorage.getItem('InitialTypeID') == "") {
            localStorage.setItem('InitialTypeID', InitialType.Auto);
        }
        Array.from(controls).forEach((ctrlItem: any) => {
            let ctrlId = ctrlItem.attributes["id"].value;
            let controlType = ctrlItem.attributes["data-ctntype"].value;
            let ctrlIsFixedwidth: any = null;
            if (ctrlItem && ctrlItem.attributes["data-isfixed-width"] && ctrlItem.attributes["data-isfixed-width"].value) {
                ctrlIsFixedwidth = ctrlItem.attributes["data-isfixed-width"].value;
            }
            let ctrlData: any;
            let ctrlValue: any;
            switch (controlType) {
                case "Signature":
                    let sigImageCollection = ctrlItem.getElementsByTagName('img');
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: localStorage.getItem('signatureTypeID'),
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: sigImageCollection[0].attributes["src"] != undefined ? sigImageCollection[0].attributes["src"].value : null,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "NewInitials":
                    let initialImageCollection = ctrlItem.getElementsByTagName('img');
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: localStorage.getItem('InitialTypeID'),
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: initialImageCollection[0].attributes["src"] != undefined ? initialImageCollection[0].attributes["src"].value : null,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Text":
                    let currentTextCtrl: any = document.getElementById("control_" + ctrlId);
                    let currentTextCtrlTitle: any = currentTextCtrl.attributes['data-title'] != undefined ? currentTextCtrl.attributes['data-title'].value : '';
                    if (currentTextCtrlTitle === 'Date') {
                        ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                    }
                    else if (currentTextCtrlTitle === 'Email') {
                        ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                    }
                    else {
                        ctrlValue = ctrlItem.getElementsByTagName("textarea") != undefined ? ctrlItem.getElementsByTagName("textarea")[0].value : '';
                    }

                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: parseInt(currentTextCtrl.style.width.split('px')[0]),
                        Height: parseInt(currentTextCtrl.style.height.split('px')[0]),
                        IsFixedwidth: JSON.parse(ctrlIsFixedwidth),
                        ZCoordinate: currentTextCtrl.attributes["data-zcoordinate"] ? parseInt(currentTextCtrl.attributes["data-zcoordinate"].value) : 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Email":
                    let currEmailCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currEmailCtrl.value != undefined ? currEmailCtrl.value : '';
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Name":
                    let currNameCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currNameCtrl.value != undefined ? currNameCtrl.value : '';
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: parseInt(currNameCtrl.style.width.split('px')[0]),
                        Height: parseInt(currNameCtrl.style.height.split('px')[0]),
                        IsFixedwidth: JSON.parse(ctrlIsFixedwidth),
                        ZCoordinate: currNameCtrl.attributes["data-zcoordinate"] ? parseInt(currNameCtrl.attributes["data-zcoordinate"].value) : 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Date":
                case "Initials":
                case "Title":
                    let currTitleCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currTitleCtrl.value != undefined ? currTitleCtrl.value : '';
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: parseInt(currTitleCtrl.style.width.split('px')[0]),
                        Height: parseInt(currTitleCtrl.style.height.split('px')[0]),
                        IsFixedwidth: JSON.parse(ctrlIsFixedwidth),
                        ZCoordinate: currTitleCtrl.attributes["data-zcoordinate"] ? parseInt(currTitleCtrl.attributes["data-zcoordinate"].value) : 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Company":
                    let currCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currCtrl.value != undefined ? currCtrl.value : '';
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Checkbox":
                    let curCheckboxctrl: any = document.getElementById("control_" + ctrlId);
                    let isChecked: string = "false";

                    isChecked = curCheckboxctrl.checked ? "true" : "false";

                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        GroupName: curCheckboxctrl.attributes["name"] != undefined ? curCheckboxctrl.attributes["name"].value : '',
                        ControlValue: isChecked,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Radio":
                    let curRadioctrl: any = document.getElementById("control_" + ctrlId);
                    let isRadioChecked: string = "false";


                    isRadioChecked = curRadioctrl.checked ? "true" : "false";

                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: isRadioChecked,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "DropDown":
                    let currentdrpCtrl: any = (document.getElementById("control_" + ctrlId)) as HTMLSelectElement;
                    var sel = currentdrpCtrl.selectedIndex;
                    var opt = currentdrpCtrl.options[sel];
                    var curValue = (<HTMLSelectElement>opt).value;

                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: curValue,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "DateTimeStamp":
                    if (!isFinishLater) {
                        let currentdtsCtrl: any = (document.getElementById("control_" + ctrlId)) as HTMLSelectElement;
                        let dateTimeStampValue: any = this.getDateTimeStampValue();
                        ctrlData = {
                            ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                            ControlHtmlID: ctrlId,
                            PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                            Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                            ControlName: controlType,
                            ControlValue: dateTimeStampValue,
                            Width: 0,
                            Height: 0,
                            IsFixedwidth: null,
                            ZCoordinate: 0
                        };
                        controlCollection.push(ctrlData);
                    }
                    break;
            }
        });
        return controlCollection;
    }

    getCertificateSignature() {
        let allReqCommonSignatureControl: any = [];
        Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((item: any) => {
                if (window.getComputedStyle(item).display !== 'none') {
                    let dataReq = item.attributes["data-rq"].value;
                    if (dataReq) {
                        allReqCommonSignatureControl.push(item);
                    }
                }
            });

        let allNonReqCommonSignatureControl: any = [];
        Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((item: any) => {
                if (window.getComputedStyle(item).display !== 'none') {
                    let dataReq = item.attributes["data-rq"].value;
                    if (!dataReq && item.getElementsByClassName("filledControls").length > 0) {
                        allNonReqCommonSignatureControl.push(item);
                    }
                }
            });

        if (allReqCommonSignatureControl.length > 0) {
            allReqCommonSignatureControl.forEach((item: any) => {
                let imageCollection = item.getElementsByTagName('img');
                this.myArray.push({
                    ID: item.attributes["data-unique"].value,
                    Tabindex: imageCollection[0].attributes["tabindex"].value
                });
            });
        }
        else if (allNonReqCommonSignatureControl.length > 0) {
            allNonReqCommonSignatureControl.forEach((item: any) => {
                let imageCollection = item.getElementsByTagName('img');
                this.myArray.push({
                    ID: item.attributes["data-unique"].value,
                    Tabindex: imageCollection[0].attributes["tabindex"].value
                });
            });
            this.myArray.sort(function (a: { Tabindex: number; }, b: { Tabindex: number; }) {
                return a.Tabindex - b.Tabindex
            });
            this.myArray.reverse();
        }

        return this.myArray.length > 0 ? this.myArray[0].ID : '';
    }

    getConfirmationEmailReq(ctrlResp: any) {
        let isStaticTemplate = false;
        let isConfirmationReq: boolean = false;

        this.allSignControls = Array.from(document.getElementsByClassName("emailControlsInput") as HTMLCollectionOf<HTMLElement>)
            .forEach((item: any) => {
                if (window.getComputedStyle(item).display !== 'none') {
                    if (!isConfirmationReq) {
                        isConfirmationReq = item.attributes["data-submit-required"].value == 1 ? true : false;
                    }
                }
            });

        let isSendConfirmationEmail: boolean = ctrlResp.EnvelopeInfo.IsSendConfirmationEmail;
        if ((ctrlResp.EnvelopeInfo.IsStatic == true && ctrlResp.IsDefaultSignatureForStaticTemplate == false) || (ctrlResp.IsSharedTemplateContentUnEditable == true && ctrlResp.IsDefaultSignatureForStaticTemplate == false)) {
            isStaticTemplate = true;
        }

        let AllowStaticMultiSigner: string = String(ctrlResp.AllowStaticMultiSigner);
        let isConfirmationReqValue: any = isStaticTemplate && AllowStaticMultiSigner.toLowerCase() == 'true' ? isSendConfirmationEmail : (isStaticTemplate && isConfirmationReq ? isConfirmationReq : isSendConfirmationEmail)
        if (isConfirmationReqValue == 'true' || isConfirmationReqValue == true || isConfirmationReqValue == 'TRUE')
            isConfirmationReq = true;
        else
            isConfirmationReq = false;

        return isConfirmationReq;
    }

    isStaticTemplate(ctrlResp: any) {
        var isStaticTemplate = false;
        if ((ctrlResp.EnvelopeInfo.IsStatic == true && ctrlResp.IsDefaultSignatureForStaticTemplate == false) || (ctrlResp.IsSharedTemplateContentUnEditable == true && ctrlResp.IsDefaultSignatureForStaticTemplate == false)) {
            isStaticTemplate = true;
        }

        if (isStaticTemplate) {
            localStorage.setItem("isStaticTemplate", "true");
        }
        else {
            localStorage.setItem("isStaticTemplate", "false");
        }
        return isStaticTemplate;
    }

    getDateTimeStampValue() {
        let dateFormat: string;
        dateFormat = this.documentControlsResponse.DateFormat;
        if (dateFormat == "dd-mmm-yyyy")
            dateFormat = dateFormat.replace("mmm", "MMM");
        else
            dateFormat = dateFormat.replace("mm", "MM");

        let currentDate: any = new Date();
        let date: any = this.getDefaultplaceHolder(dateFormat); // $.datepicker.formatDate(dateFormat, new Date());
        var time = currentDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        var timeZone = this.getTimezoneFromMoment();
        return date.concat(" " + time).concat(" " + timeZone);
    }

    getTimezoneFromMoment() {
        let timezone = jstz.determine();
        var m = moment();
        var abbr = m.tz(timezone.name()).zoneAbbr();
        return abbr;
    }

    commonSubmitClickevent(envelopeSignDocumentSubmitInfo: any) {
        this.showLoader = true;
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let ReVerifySignerDocumentSubmit: any = this.documentControlsResponse.ReVerifySignerDocumentSubmit;
            if (this.documentControlsResponse.Prefill.toLowerCase() != 'prefill' && ReVerifySignerDocumentSubmit && ReVerifySignerDocumentSubmit == true) {
                this.envelopeSignDocumentSubmitRequestInfo = envelopeSignDocumentSubmitInfo;
                this.resendVerificationCode();
            }
            else {
                this.callFinalSubmitEnvelopeDocument(envelopeSignDocumentSubmitInfo);
            }
        }
    }

    resendVerificationCode() {
        this.showLoader = true;
        this.userPasscodeOnSubmit = '';
        let envelopeID = localStorage.getItem('EnvelopeID');
        let recipientID = localStorage.getItem('RecipientID');
        let EmailId: any = localStorage.getItem('EmailId');
        const encodedEmail = EmailId;
        const decodedEmail = decodeURIComponent(encodedEmail);
        EmailId = decodedEmail;
        localStorage.setItem('EmailId', EmailId);
        //let envelopeSignDocumentSubmitInfo: any = this.envelopeSignDocumentSubmitRequestInfo;
        let ctrlSigningResp: any = this.responseSigningUrlModel;
        let copyEmailAddress: any = '';

        if (ctrlSigningResp && ctrlSigningResp.CopyEmailId) {
            copyEmailAddress = ctrlSigningResp.CopyEmailId;
        }

        let paramsObj = {
            EnvelopeID: envelopeID,
            RecipientID: recipientID,
            EmailAddress: (copyEmailAddress == '') ? EmailId : copyEmailAddress,
            DialCode: (this.documentControlsResponse.MobileNumber != null && this.documentControlsResponse.MobileNumber != "") ? this.documentControlsResponse.DialCode : "",
            Mobile: this.documentControlsResponse.MobileNumber != null ? this.documentControlsResponse.MobileNumber : "",
            IsCopyEmail: (copyEmailAddress == '') ? false : true
        };

        let confirmUserOnFinalSubmitUrl = this.commonConstants.ConfirmUserOnFinalSubmit;
        this.signerLandingService.postcall(confirmUserOnFinalSubmitUrl, paramsObj).subscribe(
            (resp: any) => {
                this.showLoader = false;
                if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                    this.userPasscodeOnSubmit = resp.VerificationCode;
                    this.emailAddr = (copyEmailAddress == '') ? EmailId : copyEmailAddress;
                    this.Mobile = this.documentControlsResponse.DialCode + this.documentControlsResponse.MobileNumber;
                    let btnViewandSignDocument: any = document.getElementById("btnViewandSignDocument");
                    btnViewandSignDocument?.setAttribute('disabled', 'disabled');
                    this.sendPasswordPopupStyle = 'block';
                    this.sendAccessCodeTo = resp.SendAccessCodeTo;
                    let VerificationCode: any = document.getElementById("VerificationCode");
                    VerificationCode.value = '';
                    this.toastr.success("Access code sent.", this.lang_success, { timeOut: 1500 });
                    if (this.webOrMobile == 'mobile') {
                        let divFontSendPasswordPopup: any = document.getElementById("divFontSendPasswordPopup");
                        divFontSendPasswordPopup.style["width"] = "358px";
                    }
                    this.cdRef.detectChanges();
                }
                else {
                    this.showLoader = false;
                    this.toastr.warning("Access code not sent.", this.lang_warning, { timeOut: 1500 });
                    this.cdRef.detectChanges();
                }
            });
    }

    callFinalSubmitEnvelopeDocument(envelopeSignDocumentSubmitInfo: any) {
        this.showLoader = true;
        envelopeSignDocumentSubmitInfo.IsReviewed = this.isFileReviewed == undefined ? "0" : String(this.isFileReviewed);
        // this.isDocumentSubmitted = "true";
        this.timerWorker.postMessage({ action: 'stopTimer' });
        this.cdRef.detectChanges();
        let submitDocumentUrl = this.commonConstants.SignDocument;
        this.signerLandingService.submitEnvelope(submitDocumentUrl, envelopeSignDocumentSubmitInfo).subscribe(
            (responseDetails: any) => {
                let encryptedSender: string = "";
                let returnUrl: string = "";
                this.showSessionExpireModalDailog = 'none';
                this.isDocumentSubmitted = "true";
                if (responseDetails && responseDetails.StatusCode == 200 && responseDetails.StatusMessage.toLowerCase() == "ok") {
                    this.showLoader = false;
                    returnUrl = responseDetails.returnUrl;
                    localStorage.setItem("DisplayMessage", responseDetails.message);
                    localStorage.setItem("MessageType", "Success");
                    if (responseDetails.returnUrl == "Info/Index") {
                        if (responseDetails.InfoSenderEmail != "" && responseDetails.InfoSenderEmail != null) {
                            encryptedSender = responseDetails.EncryptSender == null ? "" : responseDetails.EncryptSender;
                            returnUrl = RouteUrls.Info;
                        }
                        else {
                            returnUrl = RouteUrls.Info;
                        }
                        localStorage.setItem("EncryptedSender", encryptedSender);
                    }
                    else {
                        if (responseDetails.returnUrl == "DocumentPackage/DocumentPackageIndex" || responseDetails.returnUrl == "Home/Index") {
                            if (responseDetails.returnUrl != "DocumentPackage/DocumentPackageIndex" && responseDetails.returnUrl != "Home/Index") {
                                localStorage.setItem("DisplayMessage", "Envelope has been sent");
                                localStorage.setItem("MessageType", "Success");
                                returnUrl = RouteUrls.Info;
                            }
                            else
                                returnUrl = environment.uiBaseUrl + responseDetails.returnUrl;
                        }
                        else if (responseDetails.returnUrl == "/SignDocument/SignMultiTemplate") {
                            returnUrl = RouteUrls.SignMultiTemplate;
                        }

                        if (responseDetails.DGReturnURL != "" && responseDetails.DGReturnURL != null) {
                            responseDetails.DGReturnURL = RouteUrls.SignMultiTemplate;
                        }
                    }

                    let lang_documentSent: string = "";
                    let lang_DocumentSentInProcess: string = "";
                    let CopyEmailDownloadFinalDoc: string = "";
                    let lang_DocumentSentInProcessArray = this.translationsService.getLanguageValidationVal("lang_DocumentSentInProcess");
                    let CopyEmailDownloadFinalDocArray = this.translationsService.getLanguageValidationVal("CopyEmailDownloadFinalDoc");
                    lang_DocumentSentInProcess = lang_DocumentSentInProcessArray;
                    let lang_documentSentArray = this.translationsService.getLanguageValidationVal("lang_documentSent");
                    lang_documentSent = lang_documentSentArray;


                    if (responseDetails.success == true) {
                        if (responseDetails.EnableMessageToMobile == "1") {

                            var rdeliverymode: any = responseDetails.data.DeliveryMode;
                            if (rdeliverymode == "1" || rdeliverymode == "2" || rdeliverymode == "3" || rdeliverymode == "11") {
                                lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentSent");
                            }
                            else if (rdeliverymode == "8" || rdeliverymode == "9" || rdeliverymode == "10" || rdeliverymode == "12") {
                                lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentEmailMobileSent");;
                            }
                            else if (rdeliverymode == "4" || rdeliverymode == "5" || rdeliverymode == "6" || rdeliverymode == "7") {
                                lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentMobileSent");;
                            }
                            else {
                                lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentSent");;
                            }
                        }
                        else {
                            lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentSent");;
                        }
                    }
                    else {
                        lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentSent");;
                    }

                    if (responseDetails.success == false) {
                        this.showLoader = false;
                        if (responseDetails.success == false && responseDetails.field == 'Transferred') {
                            this.toastr.warning(responseDetails.message, this.lang_warning, { timeOut: 2000 });
                            if (returnUrl != undefined && returnUrl != null && returnUrl != '') {
                                setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 1500);
                            }
                        }
                        else {
                            this.showLoader = false;
                            let launguageValidationArray = this.translationsService.getLanguageValidationVal("ConatctSender");
                            this.toastr.warning(launguageValidationArray, this.lang_warning, { timeOut: 2000 });
                        }
                    }
                    else if (this.documentControlsResponse.Prefill.toLowerCase() == 'prefill') {
                        this.showLoader = false;
                        let launguageValidationArray = this.translationsService.getLanguageValidationVal("lang_documentSent");
                        this.toastr.success(launguageValidationArray, this.lang_success, { timeOut: 2000 });
                        setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                    }
                    else if (responseDetails.isDGFlow == true) {
                        this.showLoader = false;
                        if (responseDetails.showDGPopup == true) {
                            localStorage.setItem("EnvelopeID", envelopeSignDocumentSubmitInfo.EnvelopeID);
                            localStorage.setItem("RecipientID", envelopeSignDocumentSubmitInfo.RecipientID);
                            this.signAnotherDocumentUrl = responseDetails.returnUrl;
                            this.backToMultiViewUrl = RouteUrls.SignMultiTemplate;
                            this.opendvDGIntermediatePopup();
                        }
                        else {
                            this.showLoader = false;
                            this.toastr.success(lang_documentSent, this.lang_success, { timeOut: 2000 });
                            setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                        }
                    }
                    else if (responseDetails.postSigningLogin == false) {
                        (responseDetails.isSignedDocumentService == true) ? this.toastr.success(lang_DocumentSentInProcess, this.lang_success, { timeOut: 2000 }) :
                            this.toastr.success(lang_documentSent, this.lang_success, { timeOut: 2000 });
                        setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                    }
                    else {
                        let recipientsData: any = responseDetails.data;

                        let modalPopupObj = {
                            returnUrl: returnUrl,
                            MEmail: recipientsData.EmailAddress,
                            spEmail: recipientsData.EmailAddress,
                            isSignedDocumentService: responseDetails.isSignedDocumentService,
                            type: "submit envelope",
                            toastrMessage: (responseDetails.isSignedDocumentService == true) ? lang_DocumentSentInProcess : lang_documentSent,
                            attachSignedPdfID: responseDetails.AttachSignedPdfID,
                            isCopyEmail: recipientsData.IsCopyEmail
                        };
                        localStorage.setItem('EmailId', recipientsData.EmailAddress);
                        localStorage.setItem('RecpMobileNumber', this.documentControlsResponse.DialCode + this.documentControlsResponse.MobileNumber);
                        localStorage.setItem('RecpDeliveryMode', this.documentControlsResponse.DeliveryMode);

                        if (this.AllowSignersDownloadFinalContract == true) {
                            if (recipientsData.Order == 0) {
                                if (responseDetails.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Completed || responseDetails.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Terminated) {
                                    const modalRef = this.modalService.open(SignerRegistrationComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerRegistration-class' });
                                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                                else {
                                    const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                            }
                            else if (recipientsData.Order == 1) {
                                if (responseDetails.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Completed || responseDetails.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Terminated) {
                                    const modalRef = this.modalService.open(SignerIntermediateComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                                else {
                                    const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                            }
                            else if (recipientsData.Order == 2) {
                                const modalRef = this.modalService.open(SignerIntermediateContinueComponent, { size: 'lg', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediateContinue-class' });
                                modalRef.componentInstance.modalPopupObj = modalPopupObj;
                            }
                            else {
                                (responseDetails.isSignedDocumentService == true) ? this.toastr.success(lang_DocumentSentInProcess, this.lang_success, { timeOut: 2000 }) :
                                    this.toastr.success(lang_documentSent, this.lang_success, { timeOut: 2000 });
                                setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                            }
                        }
                        else {
                            (responseDetails.isSignedDocumentService == true) ? this.toastr.success(lang_DocumentSentInProcess, this.lang_success, { timeOut: 2000 }) :
                                this.toastr.success(lang_documentSent, this.lang_success, { timeOut: 2000 });
                            setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                        }
                    }
                }
                else if (responseDetails.originalError.error.StatusCode == 400) {
                    this.showLoader = false;
                    // localStorage.setItem("DisplayMessage", responseDetails.originalError.error.message);
                    let lang_DocumentSubmissionFailed = this.translationsService.getLanguageValidationVal("lang_DocumentSubmissionFailed");
                    if(lang_DocumentSubmissionFailed != "")  localStorage.setItem("DisplayMessage", lang_DocumentSubmissionFailed);
                    else localStorage.setItem("DisplayMessage", "Document submission failed. Please try again.");  
                    localStorage.setItem("MessageType", "Warning");
                    if (responseDetails.originalError.error.returnUrl == "Info/Index") {
                        returnUrl = RouteUrls.Info;
                        this.commonService.getReturnUrlDirection(returnUrl);
                    }
                }
                this.cdRef.detectChanges();
            });
    }

    isTermsModelPopupExists() {
        if (this.documentControlsResponse.EnvelopeInfo.IsDisclaimerEnabled === true && localStorage.getItem('prefill') !== 'prefill') {
            this.modalService.open(this.termsOfService, { windowClass: 'termofService-class', keyboard: false, backdrop: 'static' });

        } else {
            if (this.IsEnableClickToSign) {
                this.enableClickToSign();
            }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onAgree() {
        this.modalService.dismissAll(this.termsOfService);
        this.enableClickToSign();
        if (this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign === false) {
            this.defaultControlShowTooltip();
            this.byDefaultShowCtrlTooltip = true;
        } else if (this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign !== true) {
            this.defaultControlShowTooltip();
            this.byDefaultShowCtrlTooltip = true;
        }
        this.showLoader = false;
        this.cdRef.detectChanges();
    }

    enableClickToSign() {
        this.showLoader = true;
        if (this.IsEnableClickToSign) {
            this.showLoader = true;
            let respuserData: any = this.documentControlsResponse.userdata;
            if (respuserData) { this.bindDataForSignerProfile(respuserData, "1"); }

            setTimeout(() => {
                this.showLoader = true;
                let profileDetails: any;
                profileDetails = this.clickToSignService.getCurrentSessionUserDtls();
                if (profileDetails && profileDetails.length > 0) {
                }
                else if (!profileDetails || profileDetails.length == 0) {
                    let signerTimeStamp: any = this.commonService.getCookie("signerProfileTimeStamp");
                    let signerProfileLocal: any = localStorage.getItem("SignerProfile");

                    if (!this.commonService.isNumber(signerTimeStamp)) {
                        signerTimeStamp = new Date().valueOf();
                        this.commonService.setCookie("signerProfileTimeStamp", signerTimeStamp, 14);
                    }

                    if (signerProfileLocal && signerProfileLocal != "[object Object]" && signerProfileLocal != "null" && signerProfileLocal != "undefined") {
                        signerProfileLocal = JSON.parse(signerProfileLocal);
                        if (signerProfileLocal && signerProfileLocal.length > 0) {
                            let signerProfileObj: any = {};
                            signerProfileObj = signerProfileLocal.filter((element: any) => element.recipientEmailId == "Static" && element.signerProfileTimeStamp == signerTimeStamp);
                            signerProfileObj.signerProfileTimeStamp = signerTimeStamp;
                            localStorage.setItem("SignerProfile", JSON.stringify([signerProfileObj]));
                        }
                    }
                    profileDetails = this.clickToSignService.getCurrentSessionUserDtls();
                }

                let ctrluserData: any = this.documentControlsResponse.userdata;
                if (this.isNullOrEmptyCheck(ctrluserData) && (!profileDetails || profileDetails.length == 0)) {
                    let userData: any = this.documentControlsResponse.userdata;
                    this.clickToSignPopupStyle = 'block';
                    this.commonService.addModelPopupClassForBodyTag();
                    this.bindDataForSignerProfile(userData, "");
                    let btnAccept: any = document.getElementById("btnAccept");
                    btnAccept?.setAttribute('disabled', 'disabled');
                    this.clickToSignService.updateSignerProfilePopupheight();
                }
                else if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0 && this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                    if (this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                        this.bindsignerPopupDtls();

                        this.clickToSignPopupStyle = 'none';
                        this.commonService.removeModelPopupClassForBodyTag();
                        this.clickToSignService.removeSrcForSignatureControls();
                        this.clickToSignService.removeSrcForInitialControls();
                        this.getTotalRemaingFieldsControls('');
                    }
                    else {
                        this.clickToSignPopupStyle = 'block';
                        this.commonService.addModelPopupClassForBodyTag();

                        let btnAccept: any = document.getElementById("btnAccept");
                        btnAccept?.setAttribute('disabled', 'disabled');
                        this.clickToSignService.updateSignerProfilePopupheight();
                    }
                }
                else if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].decline == 1 && profileDetails[0].AcceptandSign != 1) {
                    if (profileDetails[0].decline == 1) {

                    }
                    else if (this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                        this.bindsignerPopupDtls();
                        this.clickToSignPopupStyle = 'none';
                        this.commonService.removeModelPopupClassForBodyTag();
                        this.clickToSignService.removeSrcForSignatureControls();
                        this.clickToSignService.removeSrcForInitialControls();
                        this.getTotalRemaingFieldsControls('');
                    }
                    else {
                        this.clickToSignPopupStyle = 'block';
                        this.commonService.addModelPopupClassForBodyTag();

                        let btnAccept: any = document.getElementById("btnAccept");
                        btnAccept?.setAttribute('disabled', 'disabled');
                        this.clickToSignService.updateSignerProfilePopupheight();
                    }
                }
                else {
                    this.bindsignerPopupDtls();
                }
                this.showLoader = false;
                this.cdRef.detectChanges();
            }, 200);

        }
        this.showLoader = false;
    }

    bindDataForSignerProfile(userData: any, type: any) {
        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        txtsignerProfileName.value = userData.UserName;
        let initialData: any = "";
        if (this.isNullOrEmptyCheck(userData.UserInitials)) {
            initialData = userData.UserInitials;
        }
        else {
            if (this.isNullOrEmptyCheck(userData.UserName) && this.isNullOrEmptyCheck(userData.UserName.trim())) {
                initialData = ((userData.UserName).match(/\b(\w)/g).join('')).substring(0, 4);
            }
        }
        let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
        txtsignerProfileInitials.value = initialData.trim();

        if (type == '') {
            this.UpdateSignimageFromSignerProfileText(0);
            this.UpdateInitialsimageFromSignerProfileText();
        }

        var profileDetails = this.clickToSignService.getCurrentSessionUserDtls();
        if (profileDetails != null && profileDetails.length > 0 && localStorage.getItem("recipientEmail") != profileDetails[0].recipientEmailId) {
            profileDetails[0].signerProfileName = userData.UserName;
            profileDetails[0].signerProfileInitials = initialData.trim();
            localStorage.setItem("SignerProfile", '');
            localStorage.setItem("SignerProfile", JSON.stringify(profileDetails));
        }
    }

    UpdateSignimageFromSignerProfileText(val: any) {
        setTimeout(() => {
            if (val == 0) {
                this.showLoader = true;
            }

            this.clickToSignService.UpdateSignimageFromSignerProfileText(this.documentControlsResponse, val);
            this.showLoader = false;
            this.cdRef.detectChanges();
        }, 0);
    }

    UpdateInitialsimageFromSignerProfileText() {
        setTimeout(() => {
            this.clickToSignService.UpdateInitialsimageFromSignerProfileText();
            this.cdRef.detectChanges();
        }, 0);
    }

    UpdateSigAndInitialProfileText(val: any) {
        this.clickToSignService.UpdateSigAndInitialProfileText(this.documentControlsResponse, val);
        this.cdRef.detectChanges();
    }

    onEnableAcceptAndSign(event: any) {
        this.clickToSignService.onEnableAcceptAndSign(event.target.checked);
    }

    onAcceptAndSign() {
        this.clickToSignService.onAcceptAndSign(this.AddEditClicked, this.sigHandUpload, this.IsStaticTemplate, this.isSignerpopupCount);
        this.clickToSignPopupStyle = 'none';
        this.modalService.dismissAll();
        this.getTotalRemaingFieldsControls('');

        if (this.byDefaultShowCtrlTooltip === false) {
            setTimeout(() => {
                this.defaultControlShowTooltip();
            }, 500);
            this.byDefaultShowCtrlTooltip = true;
        }
    }

    setSessionUserProfileDetails() {
        this.clickToSignService.setSessionUserProfileDetails(this.AddEditClicked, this.sigHandUpload, this.IsStaticTemplate, this.isSignerpopupCount);
        let issignerpopupCount: any = sessionStorage.getItem('isSignerpopupCount');
        if (parseInt(issignerpopupCount) > 0) {
            this.getControlNextNav('');
        }
        issignerpopupCount = parseInt(issignerpopupCount) + 1;
        sessionStorage.setItem('isSignerpopupCount', issignerpopupCount);
        this.cdRef.detectChanges();
    }

    openClickToSignModalOrSignaturePadModal(controlId: any) {
        var profileDetails = this.clickToSignService.getCurrentSessionUserDtls();
        if (profileDetails && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0) {
            if (controlId == "FooterSignNav") {
                this.clickToSignService.applyFooterNavSignature(profileDetails[0].imgProfileSignaturerc);
            }
            else {
                let ctrlImageAttr: any = document.getElementById("control_" + controlId);
                let ctrlImageAttrSrc: any = ctrlImageAttr.attributes["src"];
                if (this.isNullOrEmptyCheck(ctrlImageAttrSrc)) {
                    if (profileDetails[0].checkTermsAndCond == true) {
                        this.bindsignerPopupDtls();
                    }
                }
                else if (!this.isNullOrEmptyCheck(ctrlImageAttrSrc) && this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                    this.clickToSignService.applySignImageToControl(profileDetails[0].imgProfileSignaturerc, controlId);
                    let ctrlImageTooltipId: any = document.getElementById('tooltip_' + controlId);
                    let srcValue: any = ctrlImageAttr.getAttribute("src");
                    if (srcValue != '' && srcValue != null && srcValue != undefined) {
                        ctrlImageTooltipId.style.display = 'none';
                        this.getControlNextNav('headerControls');
                    }

                    this.applyStylesToCheckList("add", controlId);

                }
            }
            this.getTotalRemaingFieldsControls('');
        }
        else {
            this.openSignaturePadModel(controlId);
        }
    }

    openClickToSignModalOrInitialPadModal(controlId: any) {
        setTimeout(() => {
            var profileDetails = this.clickToSignService.getCurrentSessionUserDtls();
            if (profileDetails && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0) {
                let ctrlImageAttr: any = document.getElementById("control_" + controlId);
                let ctrlImageAttrSrc: any = ctrlImageAttr.attributes["src"];
                if (this.isNullOrEmptyCheck(ctrlImageAttrSrc)) {
                    if (profileDetails[0].checkTermsAndCond == true) {
                        this.bindsignerPopupDtls();
                    }
                }
                else if (!this.isNullOrEmptyCheck(ctrlImageAttrSrc) && this.isNullOrEmptyCheck(profileDetails[0].imgProfNewInitialssrc)) {
                    this.clickToSignService.applyInitialImageToControl(profileDetails[0].imgProfNewInitialssrc, controlId);
                    let ctrlImageTooltipId: any = document.getElementById('tooltip_' + controlId);
                    let srcValue: any = ctrlImageAttr.getAttribute("src");
                    if (srcValue != '' && srcValue != null && srcValue != undefined) {
                        ctrlImageTooltipId.style.display = 'none';
                        this.getControlNextNav('documentControls');
                    }

                    this.applyStylesToCheckList("add", controlId);
                }
                this.getTotalRemaingFieldsControls('');
            }
            else {
                this.openInitialPadModel(controlId);
            }
        }, 10);
    }

    bindsignerPopupDtls() {
        this.showLoader = true;
        this.clickToSignService.bindsignerPopupDtls(this.documentControlsResponse);
        var profileDetails = this.clickToSignService.getCurrentSessionUserDtls();
        if (profileDetails && profileDetails.length > 0) {
            this.clickToSignPopupStyle = 'block';
        }
        this.cdRef.detectChanges();
        setTimeout(() => {
            this.showLoader = false;
            this.cdRef.detectChanges();
        }, 1);
    }

    generateClickToSignObj(profileDetails: any) {
        this.clickToSignService.generateClickToSignObj(this.documentControlsResponse);

        let btnAccept: any = document.getElementById("btnAccept");
        let chkterms: any = document.getElementById("chkterms");
        if (profileDetails[0].AcceptandSign == true) {
            chkterms?.setAttribute('disabled', 'disabled');
            chkterms?.setAttribute('checked', 'checked');
            this.clicktoSignAgree = true;
            chkterms.style.cursor = "no-drop";
            btnAccept?.removeAttribute('disabled');
        }
        else {
            chkterms?.removeAttribute('disabled');
            chkterms?.removeAttribute('checked');
            this.clicktoSignAgree = false;
            chkterms.style.cursor = "pointer";
            btnAccept?.setAttribute('disabled', 'disabled');
        }
    }

    //dvDGIntermediate popup starts
    opendvDGIntermediatePopup() {
        this.dvDGIntermediateDisplayStyle = 'block';
    }

    closedvDGIntermediatePopup() {
        this.dvDGIntermediateDisplayStyle = 'none';
    }

    onBtnSignAnotherDocument() {
        this.showLoader = true;
        let signAnotherDocUrl = this.signAnotherDocumentUrl;
        let signingUrl = decodeURI(signAnotherDocUrl.split('?')[1]);
        let signerLandingUrl = environment.uiAngularBaseUrl + RouteUrls.SignerLanding + "?";
        signerLandingUrl = signerLandingUrl + signingUrl;
        sessionStorage.setItem("isFromSignMultiTemplate", "true");
        window.location.href = signerLandingUrl;
    }

    onConfirmBackToMultiViewDocuments() {
        this.showLoader = true;
        this.commonService.getReturnUrlDirection(RouteUrls.SignMultiTemplate);
    }
    //dvDGIntermediate popup ends

    getTotalRemaingFieldsControls(type: any) {
        this.remainingFieldsTotalCount = 0;
        this.remainingFieldsCount = 0;
        let visibleControls: any = this.getAllVisibleDocumentControls();
        this.remainingFieldsTotalCount = visibleControls.length;
        this.remainingFieldsCount = this.commonService.getOnlyControlsPendingCount(visibleControls);
        if (this.remainingFieldsCount == 0) {
            if (type === '') {
                this.hideAllMobilePrevNextDoneBtns();
            }
            this.hideToolTip(null);
        }
    }

    getFileNameIconFromExtension(fileName: any) {
        return this.commonService.getFileNameIconFromExtension(fileName);
    }

    onCloseValidationPopup() {
        this.validationPopupStyle = 'none';
    }

    getControlNextNav(typeOfNavControl: any) {
        if (this.webOrMobile === 'mobile' && this.showMobilePopup === 'block') {
            let controlStringIdVal = document.getElementById(this.mobileControlArr[this.tabIndex].controlId)!;
            controlStringIdVal.removeAttribute('readOnly');
            this.popuptempArr = [];
        }
        this.hideAllControlTooltips();
        this.getTotalRemaingFieldsControls('');
        this.showMobilePopup = 'none';
        localStorage.setItem("datetype", '');
        let tabPos: any = this.checkAllControlsAreFilled();
        this.isCheckDatePopupOpen();
        if (this.webOrMobile === 'mobile') {
            let prevSelectedCtrlIndex: any = this.mobileControlArr[this.tabIndex];
            if (prevSelectedCtrlIndex && prevSelectedCtrlIndex.controlId) {
                let eleId = prevSelectedCtrlIndex.controlId.split('_')[1];
                let controlName: any = document.getElementById(eleId)!.getAttribute('data-ctntype');
                if (controlName != 'Checkbox' && controlName != 'Radio' && controlName != 'DropDown' && controlName != 'Signature' && controlName != 'NewInitials') {
                    this.btnMaximizeControlOk();
                }
            }
        }
        let prevSelectedCtrl: any;
        this.tabIndex = tabPos;
        let initialTabPos = this.tabIndex;
        prevSelectedCtrl = this.mobileControlArr[this.tabIndex];
        if (this.remainingFieldsCount != 0) {
            if (prevSelectedCtrl && prevSelectedCtrl) {
                let eleId = prevSelectedCtrl.controlId.split('_')[1];
                let controlName: any = document.getElementById(eleId)!.getAttribute('data-ctntype');
                let controlEle: any = document.getElementById(prevSelectedCtrl.controlId);
                let cname: any = controlEle.getAttribute('data-title');
                if (controlName === 'Text' && (cname === 'SSN' || cname === 'Zip' || cname === 'Email')) {
                    let inputValue: any = (<HTMLInputElement>controlEle).value;
                    if (cname === 'Email' && inputValue != '' && !this.validateEmail(inputValue)) {
                        if (this.webOrMobile === 'web' && eleId) {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                            document.getElementById('control_' + eleId)!.focus();
                        }
                        this.commonService.showToaster(this.invalidEmail, this.lang_warning);
                        if (this.webOrMobile === 'mobile') {
                            this.showMobilePopup = 'block';
                            if (this.showDateControlPopupDisplay === 'block') {
                                (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                            }
                        }
                        if (eleId) {
                            document.getElementById(eleId)!.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        }
                        return;
                    }
                    else if (inputValue.indexOf('_') > 0 || inputValue.indexOf('_') > -1 || inputValue.indexOf('-') > 5) {
                        if (this.webOrMobile === 'web' && eleId) {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                            document.getElementById('control_' + eleId)!.focus();
                        }
                        if (cname === 'Zip') {
                            this.commonService.showToaster(this.invlaidZIP, this.lang_warning);
                            if (this.webOrMobile === 'mobile') {
                                this.showMobilePopup = 'block';
                                if (this.showDateControlPopupDisplay === 'block') {
                                    (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                                }
                            }
                            if (eleId) {
                                document.getElementById(eleId)!.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            }
                            (<HTMLInputElement>document.getElementById(prevSelectedCtrl.controlId)).value = '';
                        }
                        else if (cname === 'SSN') {
                            this.commonService.showToaster(this.invlaidSSN, this.lang_warning);
                            if (this.webOrMobile === 'mobile') {
                                this.showMobilePopup = 'block';
                                if (this.showDateControlPopupDisplay === 'block') {
                                    (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                                }
                            }
                            if (eleId) {
                                document.getElementById(eleId)!.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            }
                            (<HTMLInputElement>document.getElementById(prevSelectedCtrl.controlId)).value = '';
                        }
                        return;
                    }
                }
                else if (controlName == "Email") {
                    let inputValue: any = (<HTMLInputElement>controlEle).value;
                    if (inputValue != '' && !this.validateEmail(inputValue)) {
                        if (this.webOrMobile === 'web' && eleId) {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                            document.getElementById('control_' + eleId)!.focus();
                        }
                        this.commonService.showToaster(this.invalidEmail, this.lang_warning);
                        if (this.webOrMobile === 'mobile') {
                            this.showMobilePopup = 'block';
                            if (this.showDateControlPopupDisplay === 'block') {
                                (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                            }
                        }
                        if (eleId) {
                            document.getElementById(eleId)!.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        }
                        return;
                    }
                }
                if (this.mobileControlArr.length == 1) {
                    return;
                }
                else {
                    if (eleId) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                    }
                    let controlStringIdVal = document.getElementById(prevSelectedCtrl.controlId)!;
                    (<HTMLInputElement>controlStringIdVal).readOnly = false;
                }
            }

            this.mobilePrevBtn = 'true';
            this.tabIndex = this.tabIndex + 1;
            this.tabIndex = this.returnNextTabIndex(Number(this.tabIndex));

            let selectedNextTabindex: any = this.tabIndex;
            // localStorage.setItem('selectedNextTabindex', selectedNextTabindex);
            let arr: any = this.mobileControlArr[this.tabIndex];

            this.mobileControlArr.forEach((ele: any, eleIndex: any) => {
                if (this.tabIndex === eleIndex) {
                    let controlValue: any = document.getElementById(ele.controlId)!;
                    if (ele.controlName === 'Signature') {
                        if (controlValue !== null) {
                            if (controlValue.getAttribute('src') !== null) {
                                if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                    this.tabIndex = this.tabIndex + 1;
                                }
                                else {
                                    this.tabIndex = initialTabPos;
                                }
                                this.checkTabindexNextControlIsFilledOrNot();
                            } else {
                                let controlEle: any = document.getElementById(ele.controlId);
                                controlEle.focus();
                                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                tooltiplEle.style.display = 'block';
                            }
                        }
                    }
                    else if (ele.controlName === 'NewInitials') {
                        if (controlValue !== null) {
                            if (controlValue.getAttribute('src') !== null) {
                                if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                    this.tabIndex = this.tabIndex + 1;
                                }
                                else {
                                    this.tabIndex = initialTabPos;
                                }
                                this.checkTabindexNextControlIsFilledOrNot();
                            } else {
                                let controlEle: any = document.getElementById(ele.controlId);
                                controlEle.focus();
                                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                tooltiplEle.style.display = 'block';
                            }
                        }
                    }
                    else if (ele.controlName === 'DropDown') {
                        let controlIdEle: any = document.getElementById(ele.controlId);
                        let value: any = controlIdEle.selectedOptions[0].innerText.trim();
                        if (value !== '' && value !== undefined && value != null) {
                            if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                this.tabIndex = this.tabIndex + 1;
                            } else {
                                this.tabIndex = initialTabPos;
                            }
                            this.checkTabindexNextControlIsFilledOrNot();
                        } else {
                            this.validateAndApplyControlShowTooltip(ele, 'next');
                            this.checkTabindexNextControlIsFilledOrNot();
                            let controlEle: any = document.getElementById(ele.controlId);
                            controlEle.focus();
                            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                            tooltiplEle.style.display = 'block';
                        }
                    }
                    else {
                        if (ele.controlName === 'Radio' || ele.controlName === 'Checkbox') {
                            if (controlValue && controlValue.checked) {
                                if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                    this.tabIndex = this.tabIndex + 1;
                                } else {
                                    this.tabIndex = initialTabPos;
                                }
                                this.checkTabindexNextControlIsFilledOrNot();
                            } else {
                                this.validateAndApplyControlShowTooltip(ele, 'next');
                                this.checkTabindexNextControlIsFilledOrNot();
                                let controlEle: any = document.getElementById(ele.controlId);
                                controlEle.focus();
                                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                tooltiplEle.style.display = 'block';
                            }
                        }
                        else {
                            if (controlValue !== null) {
                                let inputValue: any = (<HTMLInputElement>controlValue).value;
                                if (inputValue !== '' && inputValue !== undefined && inputValue !== null) {
                                    let controlEle: any = document.getElementById(ele.controlId);
                                    let cname: any = controlEle.getAttribute('data-title');
                                    if (ele.controlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                                        if (inputValue != '___-__-____' && inputValue != '_____-____' && inputValue != '_____') {
                                            if (inputValue.indexOf('_') > 0 || inputValue.indexOf('_') > -1 || inputValue.indexOf('-') > 5) {
                                                if (cname === 'SSN' || cname === 'Zip') {
                                                    let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                                    tooltiplEle.style.display = 'block';
                                                }
                                            } else {
                                                let maxArrLength: any = this.mobileControlArr.length - 1;
                                                if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                                    this.tabIndex = this.tabIndex + 1;
                                                } else {
                                                    this.tabIndex = initialTabPos;
                                                }
                                                this.checkTabindexNextControlIsFilledOrNot();
                                            }
                                        } else {
                                            this.validateAndApplyControlShowTooltip(ele, 'next');
                                            this.checkTabindexNextControlIsFilledOrNot();
                                        }
                                    }
                                    else {
                                        let maxArrLength: any = this.mobileControlArr.length - 1;
                                        if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                            this.tabIndex = this.tabIndex + 1;
                                        } else {
                                            this.tabIndex = initialTabPos;
                                        }
                                        this.checkTabindexNextControlIsFilledOrNot();
                                    }
                                } else {
                                    this.validateAndApplyControlShowTooltip(ele, 'next');
                                    this.checkTabindexNextControlIsFilledOrNot();
                                }
                            }
                        }
                    }
                }
            })

            if (prevSelectedCtrl && this.tabIndex === initialTabPos) {
                let eleId = prevSelectedCtrl.controlId.split('_')[1];
                if (this.webOrMobile !== 'mobile') {
                    document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                }
                if (prevSelectedCtrl.controlName == "Signature" || prevSelectedCtrl.controlName == "NewInitials") {
                    let controlValue: any = document.getElementById(prevSelectedCtrl.controlId)!;
                    if (controlValue !== null) {
                        if (controlValue.getAttribute('src') !== null) {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                        }
                        else {
                            if (this.webOrMobile === 'mobile') {
                                // this.showMobilePopup = 'block';
                            }
                        }
                    }
                }
                else if (prevSelectedCtrl.controlName == "Date") {
                    // let controlValue: any = document.getElementById(prevSelectedCtrl.controlId)!;
                    document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                }
                else if (prevSelectedCtrl.controlName === 'Radio' || prevSelectedCtrl.controlName === 'Checkbox') {
                    let controlEle: any = document.getElementById(prevSelectedCtrl.controlId);
                    if (controlEle.checked === true) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                    }
                }
                else if (prevSelectedCtrl.controlName === 'DropDown') {
                    let controlEle: any = document.getElementById(prevSelectedCtrl.controlId);
                    let value: any = controlEle.selectedOptions[0].innerText.trim();
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                    }
                }
                else if (prevSelectedCtrl.controlName === 'Text' || prevSelectedCtrl.controlName === 'Title' ||
                    prevSelectedCtrl.controlName === 'Name' || prevSelectedCtrl.controlName === 'Company' ||
                    prevSelectedCtrl.controlName === 'Email') {
                    let ele: any = document.getElementById(prevSelectedCtrl.controlId);
                    if (ele && ele.value != '' && ele.value != null && ele.value != undefined) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                    }
                }
                else {
                    if (this.webOrMobile === 'mobile') {
                        // this.showMobilePopup = 'block';
                    }
                }
            }

            if (this.tabIndex == 0) {
                this.mobilePrevBtn = 'false';
                this.mobileNextBtn = 'true';
            }
            else if (this.tabIndex == this.mobileControlArr.length - 1) {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'false';
            }
            else if (this.tabIndex != initialTabPos) {
                for (let index = this.tabIndex - 1; index >= 0; index--) {
                    const element = this.mobileControlArr[index];
                    if (element) {
                        if (document.getElementById(element.controlId.replace('control_', ''))!.style.display == "none") {
                            this.mobilePrevBtn = 'false';
                            this.mobileNextBtn = 'true';
                        }
                        else {
                            this.mobilePrevBtn = 'true';
                            this.mobileNextBtn = 'true';
                            break;
                        }
                    }
                }
            }

            else {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'true';
            }

            this.mobileControlArr.forEach((ele: any, eleIndex: any) => {
                if (this.webOrMobile === 'mobile') {
                    if (ele.controlName === 'Radio' || ele.controlName === 'Checkbox') {
                        let radioEle: any = document.getElementById(ele.controlId);
                        radioEle.parentNode.style.border = '1px solid #999';
                    } else if (ele.controlName === 'NewInitials') {
                        let ele: any = document.getElementsByClassName('newInitialsignature') as HTMLCollectionOf<HTMLElement>;
                        ele[0].style.border = '1px solid #ddd';
                    } else {
                        if (ele.controlName !== 'Signature' && ele.controlName !== 'NewInitials') {
                            document.getElementById(ele.controlId)!.style.border = '1px solid grey';
                        }
                    }
                }
                if (eleIndex === this.tabIndex) {
                    if (this.webOrMobile === 'mobile') {
                        let control = document.getElementById(ele.controlId)!
                        if (control) {
                            let cntrl: any = ele.controlId.split('_')[1];
                            let cntrEle = document.getElementById(cntrl)!;
                            let cntrlDataType: any = cntrEle.getAttribute('data-ctntype');
                            let cntrlDataId: any = cntrEle.getAttribute('id');
                            let isTextDate: any = document.getElementById(control.id)!.getAttribute('data-title');

                            let controlValue: any = document.getElementById(ele.controlId)!
                            if (ele.controlName === 'Signature') {
                                if (controlValue !== null) {
                                    if (controlValue.getAttribute('src') === null || controlValue.getAttribute('src') === undefined) {
                                        if (this.pinchZoom.pinchZoom.initialScale === 1) {
                                            this.pinchZoom.toggleZoom();
                                            setTimeout(() => {
                                                this.updateControlPositions(ele.controlId);
                                            }, 300);
                                        } else {
                                            if (this.pinchZoom.pinchZoom.initialScale > 1) {
                                                this.updateControlPositions(ele.controlId);
                                            }
                                        }

                                        setTimeout(() => {
                                            this.showMobilePopup = 'none';
                                            this.onClickSignature(cntrl);
                                        }, 500);
                                    }
                                    control.style.border = '0px solid orange';
                                }
                            } else if (ele.controlName === 'NewInitials') {
                                if (controlValue !== null) {
                                    if (controlValue.getAttribute('src') === null || controlValue.getAttribute('src') === undefined) {
                                        if (this.pinchZoom.pinchZoom.initialScale === 1) {
                                            this.pinchZoom.toggleZoom();
                                            setTimeout(() => {
                                                this.updateControlPositions(ele.controlId);
                                            }, 300);
                                        } else {
                                            if (this.pinchZoom.pinchZoom.initialScale > 1) {
                                                this.updateControlPositions(ele.controlId);
                                            }
                                        }
                                        setTimeout(() => {
                                            this.showMobilePopup = 'none';
                                            this.onClickInitials(cntrl);
                                        }, 500);
                                    }
                                    control.style.border = '0px solid orange';
                                }
                            }
                        }
                    }
                }
            })
        } else {
            this.hideAllMobilePrevNextDoneBtns();
        }
    }

    returnNextTabIndex(tabIndex: number): any {
        let controlPos = this.mobileControlArr.length - 1;
        if (controlPos == tabIndex) {
            let arr: any = this.mobileControlArr[tabIndex];
            if (arr && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                return tabIndex - 1;
            }
            return tabIndex;
        }
        else {
            let arr: any = this.mobileControlArr[tabIndex];
            if (arr && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                this.tabIndex = tabIndex + 1;
                return this.returnNextTabIndex(this.tabIndex);
            }
            else if (arr && arr.length == 0) {
                this.tabIndex = tabIndex + 1;
                return this.returnNextTabIndex(this.tabIndex);
            }
            else {
                return tabIndex;
            }
        }
    }

    returnPrevTabIndex(tabIndex: any): any {
        let controlPos = 0;
        if (controlPos == tabIndex) {
            let arr: any = this.mobileControlArr[tabIndex];
            if (arr && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                return tabIndex + 1;
            }
            return tabIndex;
        }
        else if (tabIndex == -1) {
            return tabIndex;
        }
        else {
            let arr: any = this.mobileControlArr[tabIndex];
            if (arr && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                this.tabIndex = tabIndex - 1;
                return this.returnPrevTabIndex(this.tabIndex);
            }
            else if (arr && arr.length == 0) {
                this.tabIndex = tabIndex - 1;
                return this.returnPrevTabIndex(this.tabIndex);
            }
            else {
                return tabIndex;
            }
        }
    }

    getControlPrevNav(typeOfNavControl: any) {
        if (this.webOrMobile === 'mobile' && this.showMobilePopup === 'block') {
            let controlStringIdVal = document.getElementById(this.mobileControlArr[this.tabIndex].controlId)!;
            controlStringIdVal.removeAttribute('readOnly');
            this.popuptempArr = [];
        }
        this.hideAllControlTooltips();
        this.getTotalRemaingFieldsControls('');
        this.showMobilePopup = 'none';
        localStorage.setItem("datetype", '');
        let currentTabIndexPosition: any;
        this.isCheckDatePopupOpen();
        if (this.webOrMobile === 'mobile') {
            let prevSelectedCtrlIndex: any = this.mobileControlArr[this.tabIndex];
            if (prevSelectedCtrlIndex && prevSelectedCtrlIndex.controlId) {
                let eleId = prevSelectedCtrlIndex.controlId.split('_')[1];
                let controlName: any = document.getElementById(eleId)!.getAttribute('data-ctntype');
                if (controlName != 'Checkbox' && controlName != 'Radio' && controlName != 'DropDown' && controlName != 'Signature' && controlName != 'NewInitials') {
                    this.btnMaximizeControlOk();
                }
            }
        }
        let initialTabPos = this.tabIndex;
        let prevSelectedCtrl: any = this.mobileControlArr[this.tabIndex];
        if (this.remainingFieldsCount != 0) {
            if (prevSelectedCtrl) {
                let eleId = prevSelectedCtrl.controlId.split('_')[1];
                let controlName: any = document.getElementById(eleId)!.getAttribute('data-ctntype');
                let controlEle: any = document.getElementById(prevSelectedCtrl.controlId);
                let cname: any = controlEle.getAttribute('data-title');
                if (controlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                    let inputValue: any = (<HTMLInputElement>controlEle).value;
                    if (inputValue.indexOf('_') > 0 || inputValue.indexOf('_') > -1 || inputValue.indexOf('-') > 5) {
                        if (this.webOrMobile === 'web') {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                        }
                        if (cname === 'Zip') {
                            this.commonService.showToaster(this.invlaidZIP, this.lang_warning);
                            if (this.webOrMobile === 'mobile') {
                                this.showMobilePopup = 'block';
                                if (this.showDateControlPopupDisplay === 'block') {
                                    (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                                }
                            }
                        }
                        if (cname === 'SSN') {
                            this.commonService.showToaster(this.invlaidSSN, this.lang_warning);
                            if (this.webOrMobile === 'mobile') {
                                this.showMobilePopup = 'block';
                                if (this.showDateControlPopupDisplay === 'block') {
                                    (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                                }
                            }
                        }
                        return;
                    }
                }
                document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                let controlStringIdVal = document.getElementById(prevSelectedCtrl.controlId)!;
                (<HTMLInputElement>controlStringIdVal).readOnly = false;
            }

            if (this.globalCtrlObj && this.globalCtrlObj.ControlHtmlID) {
                let index: any = this.mobileControlArr.findIndex((ele: { controlId: string; }) => ele.controlId.replace('control_', '') === this.globalCtrlObj.ControlHtmlID);
                this.tabIndex = index;
                this.globalCtrlObj = null;
            } else {
                this.tabIndex = this.tabIndex - 1;
            }
            this.tabIndex = this.returnPrevTabIndex(this.tabIndex);
            // localStorage.setItem('selectedNextTabindex', this.tabIndex);

            let selectedTabindex: any = this.tabIndex;
            this.findPrevControl();
            if (this.tabIndex <= 0) {
                if (prevSelectedCtrl) {
                    let eleId = prevSelectedCtrl.controlId.split('_')[1];
                    if (this.tabIndex == -1) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                        this.tabIndex = initialTabPos;
                        if (this.webOrMobile === 'mobile') {
                            this.hideAllControlTooltips();
                            document.getElementById('control_' + eleId)!.style.border = '1px solid orange';
                            //  this.showMobilePopup = 'block';
                        }
                    }
                    else {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                        if (this.webOrMobile === 'mobile') {
                            // this.showMobilePopup = 'none';
                        }
                    }
                }
            }

            if (this.tabIndex == this.mobileControlArr.length - 1) {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'false';
            }
            else if (this.tabIndex == 0) {
                this.mobilePrevBtn = 'false';
                this.mobileNextBtn = 'true';
            }

            else if (this.tabIndex != initialTabPos) {
                for (let index = this.tabIndex - 1; index >= 0; index--) {
                    const element = this.mobileControlArr[index];
                    if (element) {
                        if (document.getElementById(element.controlId.replace('control_', ''))!.style.display == "none") {
                            this.mobilePrevBtn = 'false';
                            this.mobileNextBtn = 'true';
                        }
                        else {
                            this.mobilePrevBtn = 'true';
                            this.mobileNextBtn = 'true';
                            break;
                        }
                    }
                }
            }

            this.mobileControlArr.forEach((ele: any, eleIndex: any) => {
                if (this.webOrMobile === 'mobile') {
                    if (ele.controlName === 'Radio' || ele.controlName === 'Checkbox') {
                        let radioEle: any = document.getElementById(ele.controlId);
                        radioEle.parentNode.style.border = '1px solid #999';
                    } else if (ele.controlName === 'NewInitials') {
                        let ele: any = document.getElementsByClassName('newInitialsignature') as HTMLCollectionOf<HTMLElement>;
                        ele[0].style.border = '1px solid grey';
                    } else {
                        if (ele.controlName !== 'Signature' && ele.controlName !== 'NewInitials') {
                            document.getElementById(ele.controlId)!.style.border = '1px solid grey';
                        }
                    }
                }
                if (eleIndex === this.tabIndex) {
                    if (this.webOrMobile === 'mobile') {
                        let control = document.getElementById(ele.controlId)!
                        // setTimeout(() => {
                        let cntrl: any = ele.controlId.split('_')[1];
                        let cntrEle = document.getElementById(cntrl)!;
                        let cntrlDataType: any = cntrEle.getAttribute('data-ctntype');
                        let cntrlDataId: any = cntrEle.getAttribute('id');
                        let isTextDate: any = document.getElementById(control.id)!.getAttribute('data-title');

                        let controlValue: any = document.getElementById(ele.controlId)!
                        if (ele.controlName === 'Signature') {
                            if (controlValue.getAttribute('src') === null || controlValue.getAttribute('src') === undefined) {
                                if (this.pinchZoom.pinchZoom.initialScale === 1) {
                                    this.pinchZoom.toggleZoom();
                                    setTimeout(() => {
                                        this.updateControlPositions(ele.controlId);
                                    }, 300);
                                } else {
                                    if (this.pinchZoom.pinchZoom.initialScale > 1) {
                                        this.updateControlPositions(ele.controlId);
                                    }
                                }

                                setTimeout(() => {
                                    this.showMobilePopup = 'none';
                                    this.onClickSignature(cntrl);
                                }, 500);
                            }
                            control.style.border = '0px solid orange';

                        } else if (ele.controlName === 'NewInitials') {
                            if (controlValue.getAttribute('src') === null || controlValue.getAttribute('src') === undefined) {
                                if (this.pinchZoom.pinchZoom.initialScale === 1) {
                                    this.pinchZoom.toggleZoom();
                                    setTimeout(() => {
                                        this.updateControlPositions(ele.controlId);
                                    }, 300);
                                } else {
                                    if (this.pinchZoom.pinchZoom.initialScale > 1) {
                                        this.updateControlPositions(ele.controlId);
                                    }
                                }

                                setTimeout(() => {
                                    this.showMobilePopup = 'none';
                                    this.onClickInitials(cntrl);
                                }, 500);
                            }
                            control.style.border = '0px solid orange';
                        }
                    }
                }
            })
        } else {
            this.hideAllMobilePrevNextDoneBtns();
        }
    }

    findPrevControl() {
        if (this.tabIndex == -1) {
            return;
        }
        else {
            let ele: any = this.mobileControlArr[this.tabIndex];
            if (ele) {
                let controlValue: any = document.getElementById(ele.controlId)!;
                if (ele.controlName === 'Signature') {
                    if (controlValue !== null) {
                        if (controlValue.getAttribute('src') !== null) {
                            this.tabIndex = this.tabIndex - 1;
                            this.checkTabindexPrevControlIsFilledOrNot();
                            this.findPrevControl();
                        } else {
                            let controlEle: any = document.getElementById(ele.controlId);
                            controlEle.focus();
                            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                            tooltiplEle.style.display = 'block';
                        }
                    }
                }
                else if (ele.controlName === 'NewInitials') {
                    if (controlValue !== null) {
                        if (controlValue.getAttribute('src') !== null) {
                            this.tabIndex = this.tabIndex - 1;
                            this.checkTabindexPrevControlIsFilledOrNot();
                            this.findPrevControl();
                        } else {
                            let controlEle: any = document.getElementById(ele.controlId);
                            controlEle.focus();
                            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                            tooltiplEle.style.display = 'block';

                        }
                    }
                }
                else if (ele.controlName === 'DropDown') {
                    let controlIdEle: any = document.getElementById(ele.controlId);
                    let value: any = controlIdEle.selectedOptions[0].innerText.trim();
                    if (value !== '' && value !== undefined && value != null) {
                        this.tabIndex = this.tabIndex - 1;
                        this.checkTabindexPrevControlIsFilledOrNot();
                        this.findPrevControl();
                    } else {
                        this.validateAndApplyControlShowTooltip(ele, 'prev');
                        this.checkTabindexPrevControlIsFilledOrNot();
                        let controlEle: any = document.getElementById(ele.controlId);
                        controlEle.focus();
                        let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                        tooltiplEle.style.display = 'block';
                    }
                }
                else {
                    if (ele.controlName === 'Radio' || ele.controlName === 'Checkbox') {
                        if (controlValue && controlValue.checked) {
                            this.tabIndex = this.tabIndex - 1;
                            this.checkTabindexPrevControlIsFilledOrNot();
                            this.findPrevControl();
                        } else {
                            this.validateAndApplyControlShowTooltip(ele, 'prev');
                            this.checkTabindexPrevControlIsFilledOrNot();
                            let controlEle: any = document.getElementById(ele.controlId);
                            controlEle.focus();
                            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                            tooltiplEle.style.display = 'block';
                        }
                    } else {
                        if (controlValue !== null) {
                            let inputValue: any = (<HTMLInputElement>controlValue).value;
                            if (inputValue !== '' && inputValue !== undefined && inputValue !== null) {
                                let controlEle: any = document.getElementById(ele.controlId);
                                let cname: any = controlEle.getAttribute('data-title');
                                if (ele.controlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                                    if (inputValue != '___-__-____' && inputValue != '_____-____' && inputValue != '_____') {
                                        if (inputValue.indexOf('_') > 0 || inputValue.indexOf('_') > -1 || inputValue.indexOf('-') > 5) {
                                            if (cname === 'SSN' || cname === 'Zip') {
                                                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                                tooltiplEle.style.display = 'block';
                                            }
                                        } else {
                                            this.tabIndex = this.tabIndex - 1;
                                            this.checkTabindexPrevControlIsFilledOrNot();
                                            this.findPrevControl();
                                        }
                                    } else {
                                        this.validateAndApplyControlShowTooltip(ele, 'prev');
                                        this.checkTabindexPrevControlIsFilledOrNot();
                                    }
                                } else {
                                    this.tabIndex = this.tabIndex - 1;
                                    this.checkTabindexPrevControlIsFilledOrNot();
                                    this.findPrevControl();
                                }
                            } else {
                                this.validateAndApplyControlShowTooltip(ele, 'prev');
                                this.checkTabindexPrevControlIsFilledOrNot();

                            }
                        }
                    }
                }
            }
        }
    }

    isPinchZoomApply(ele: any) {
        let textDateDataTitle: any = document.getElementById(ele.ControlHtmlID);
        if (ele.ControlName !== 'Date' || (ele.ControlName !== 'Text' && textDateDataTitle.getAttribute('data-title') !== 'Date')) {
            if (this.pinchZoom.pinchZoom.initialScale === 1) {
                this.pinchZoom.toggleZoom();
                setTimeout(() => {
                    this.updateControlPositions(ele.controlId);
                }, 300);
            } else {
                if (this.pinchZoom.pinchZoom.initialScale > 1) {
                    this.updateControlPositions(ele.controlId);
                }
            }
        }
    }

    validateAndApplyControlShowTooltip(ele: any, prevOrNext: string) {
        if (this.webOrMobile === 'mobile') {
            this.commonService.validateAndApplyControlShowMobileTooltip(ele, prevOrNext, this.mobileControlArr);
            this.isPinchZoomApply(ele);

            setTimeout(() => {
                let controlEle: any = document.getElementById(ele.controlId);
                let attrDataTitle: any = controlEle.getAttribute('data-title');
                if (ele.controlName !== 'Signature' && ele.controlName !== 'NewInitials' &&
                    ele.controlName !== 'Radio' && ele.controlName !== 'Checkbox' &&
                    ele.controlName !== 'DropDown') {
                    setTimeout(() => {
                        this.checkisLastControl(parseInt(ele.tabindex));
                        this.responsiveOpenModelPopup(ele.controlId.replace('control_', ''));
                    }, 300);
                    this.isDateSelected = 'false';
                } else {
                    if (ele.controlName !== 'Date' && attrDataTitle !== 'Date') {
                        this.showMobilePopup = 'none';
                        this.isDateSelected = 'false';
                    }
                    this.commonService.updatedmobileControlPosition(ele.controlId.replace('control_', ''));
                }
            }, 300);
        }
        else {
            this.commonService.validateAndApplyControlShowWebTooltip(ele, prevOrNext);
        }
    }

    closeCanvasMenu() {
        let closeCanvasMenu: any = document.getElementById("closeCanvasMenu");
        closeCanvasMenu.click();
        this.commonService.removeBodyTagPositionFixed();
    }

    applyStyleFordefaultSignNavControlIndicator() {
        this.commonService.applyStyleFordefaultSignNavControlIndicator();
    }

    closeMobilePopup(event: any) {
        this.showMobilePopup = 'none';
        let dvMaximizeControlModalPopupEle: any = document.getElementById('dvMaximizeControlModalPopup');
        if (dvMaximizeControlModalPopupEle && dvMaximizeControlModalPopupEle.style) {
            dvMaximizeControlModalPopupEle.style.display = 'none';
        }
        this.hideAllOrangeBorder();
        let hdnMaximizeControlId: any = document.getElementById('hdnMaximizeControlId');
        let ctrlId: any = hdnMaximizeControlId.value;
        let controlStringIdVal: any = document.getElementById('control_' + ctrlId)!;
        let value: any = controlStringIdVal.value;
        // controlStringIdVal.value = '';
        if (controlStringIdVal.value !== '') {
            (<HTMLInputElement>document.getElementById('control_' + ctrlId)).style.border = '1px solid grey';
            this.updateControlsValue(ctrlId, controlStringIdVal.value);
        } else {
            if (controlStringIdVal.value === '') {
                this.mobileCheckTextDependenciesControl(ctrlId, controlStringIdVal.value);
                controlStringIdVal.parentNode.classList.remove("filledControls");
            }
            (<HTMLInputElement>document.getElementById('control_' + ctrlId)).style.border = '1px solid orange';
        }
        (<HTMLInputElement>controlStringIdVal).readOnly = false;
        this.popuptempArr = [];
        localStorage.setItem("datetype", '');
        this.getTotalRemaingFieldsControls('');
    }

    responsiveOpenModelPopup(controlId: any) {
        this.commonService.updatedmobileControlPosition(controlId);
        if (this.remainingFieldsCount === 0) {
            this.hideAllMobilePrevNextDoneBtns();
        }
        setTimeout(() => {
            this.showMobilePopup = 'block';
            this.cdRef.detectChanges();
        }, 10);
        let dialogControl: any = controlId.replace('control_', '');
        let controlIdVal = document.getElementById(controlId)!;
        let dialogControlId: any = document.getElementById(dialogControl);
        let checkTextDatePicker: any = document.getElementById('control_' + controlId);
        let checkTextDatePickerVal: any = checkTextDatePicker.getAttribute('data-title');
        if (dialogControlId.getAttribute('data-ctntype') === 'Date' || (dialogControlId.getAttribute('data-ctntype') === 'Text' && checkTextDatePickerVal === 'Date')) {
            let controlStringIdVal = document.getElementById('control_' + controlId)!;
            if (dialogControlId.getAttribute('data-ctntype') === 'Date') {
                this.showDateControlPopup = 'true';
                this.showDateControlPopupDisplay = 'block';
                this.showNotDateControlPopupDisplay = 'none';
                this.showTextDateControlPopupDisplay = 'none';
            } else {
                this.showDateControlPopup = 'true';
                this.showDateControlPopupDisplay = 'none';
                this.showNotDateControlPopupDisplay = 'none';
                this.showTextDateControlPopupDisplay = 'block';
            }
            var dataCntType = dialogControlId.getAttribute('data-ctntype');
            var dataplaceholderDate = controlStringIdVal.getAttribute('placeholder');
            var dataTitle = controlStringIdVal.getAttribute('title');
            var dataName = controlStringIdVal.getAttribute('name');

            var datavalue = controlStringIdVal.getAttribute('value');
            var datamaskValue = controlStringIdVal.getAttribute('data-mask');
            var dataName = controlStringIdVal.getAttribute('name');

            let txtDataTitle: any = '';

            let spnMaximizeControlModalErrorMessage: any = document.getElementById('spnMaximizeControlModalErrorMessage');
            spnMaximizeControlModalErrorMessage.text = '';

            if (dialogControlId.getAttribute('data-ctntype') == 'Text') {
                txtDataTitle = controlStringIdVal.getAttribute('data-title');
                this.showMobilePopup = 'block';
                document.getElementById(dialogControl)?.focus();
            }
            else {
                txtDataTitle = controlStringIdVal.getAttribute('data-title');
                this.showMobilePopup = 'block';
            }

            let spnMaximizeControlTitle: any = document.getElementById('spnMaximizeControlTitle');
            spnMaximizeControlTitle.innerText = dataCntType;
            this.spnMaximizeControlTitle = dataCntType;
            let hdnDataCtnType: any = document.getElementById('hdnDataCtnType');
            hdnDataCtnType.value = checkTextDatePicker.getAttribute('data-ctntype');

            var existingValue = (<HTMLInputElement>controlStringIdVal).value ? (<HTMLInputElement>controlStringIdVal).value : '';
            let txtMaximizeControlDate: any;
            if (dialogControlId.getAttribute('data-ctntype') === 'Date') {
                txtMaximizeControlDate = document.getElementById('txtMaximizeControlDate');
                txtMaximizeControlDate.setAttribute('data-ctntype', controlStringIdVal.getAttribute('dateformat'));
                hdnDataCtnType.value = controlStringIdVal.getAttribute('dateformat');
                localStorage.setItem("datetype", "1");
            } else {
                txtMaximizeControlDate = document.getElementById('txtMaximizeControlTextDate');
                txtMaximizeControlDate.setAttribute('data-ctntype', checkTextDatePicker.getAttribute('data-ctntype'));
                hdnDataCtnType.value = checkTextDatePicker.getAttribute('data-ctntype');
                localStorage.setItem("datetype", "0");
            }
            if (txtMaximizeControlDate) {
                txtMaximizeControlDate.value = existingValue;
                txtMaximizeControlDate.setAttribute('placeholder', dataplaceholderDate);
                txtMaximizeControlDate.setAttribute('title', dataTitle);
                txtMaximizeControlDate.setAttribute('name', dataName);
                txtMaximizeControlDate.setAttribute('data-title', txtDataTitle);
                txtMaximizeControlDate.setAttribute('value', datavalue);
                txtMaximizeControlDate.setAttribute('data-contrl-value', controlStringIdVal.getAttribute('data-contrl-value'));
                txtMaximizeControlDate.setAttribute('data-document-content-id', controlStringIdVal.getAttribute('data-document-content-id'));
                txtMaximizeControlDate.setAttribute('data-parent-id', controlStringIdVal.getAttribute('data-parent-id'));
                txtMaximizeControlDate.setAttribute('dateformat', controlStringIdVal.getAttribute('dateformat'));
                txtMaximizeControlDate.setAttribute('data-defaultrequired', controlStringIdVal.getAttribute('data-defaultrequired'));
                txtMaximizeControlDate.setAttribute('data-default-date', controlStringIdVal.getAttribute('data-default-date'));
            }


            setTimeout(() => {
                if (dialogControlId.getAttribute('data-ctntype') === 'Date') {
                    document.getElementById('txtMaximizeControlDate')!.focus();
                    document.getElementById('txtMaximizeControlDate')!.style.outline = 'none';
                    this.dateControl.toggle();
                    setTimeout(() => {
                        let idELe: any = document.getElementById('prevNextBtnAlignMobileContainer');
                        idELe.classList.add('iphone-date-popup-align');
                    }, 300);
                } else {
                    document.getElementById('txtMaximizeControlTextDate')!.focus();
                    document.getElementById('txtMaximizeControlTextDate')!.style.outline = 'none';
                    this.dateTextDate.toggle();
                    setTimeout(() => {
                        let idELe: any = document.getElementById('prevNextBtnAlignMobileContainer');
                        idELe.classList.add('iphone-date-popup-align');
                    }, 300);
                }
            }, 300);

            let hdnMaximizeControlId: any = document.getElementById('hdnMaximizeControlId');
            hdnMaximizeControlId.value = controlId;
            (<HTMLInputElement>controlStringIdVal).readOnly = true;
            (<HTMLInputElement>controlStringIdVal).blur();
            this.popuptempArr.push(controlId);

            let existingEle: any = document.getElementById('control_' + controlId);
            if (existingEle && existingEle.value === '' || existingEle.value === undefined || existingEle.value === null) {
                this.updateMobileDatePickerValues(existingEle.value, dialogControlId);
            } else {
                this.updateMobileDatePickerValues(existingEle.value, dialogControlId);
            }
        }
        else if (dialogControlId.getAttribute('data-ctntype') === 'Name' || dialogControlId.getAttribute('data-ctntype') === 'Title' ||
            dialogControlId.getAttribute('data-ctntype') === 'Email' || dialogControlId.getAttribute('data-ctntype') === 'Company' ||
            dialogControlId.getAttribute('data-ctntype') === 'Text') {
            this.showDateControlPopup = 'false';
            this.showDateControlPopupDisplay = 'none';
            this.showNotDateControlPopupDisplay = 'block';
            this.showTextDateControlPopupDisplay = 'none';
            let controlStringIdVal = document.getElementById('control_' + controlId)!;
            var dataCntType = dialogControlId.getAttribute('data-ctntype');
            var dataMaxlength = controlStringIdVal.getAttribute('maxLength');
            var dataplaceholder = controlStringIdVal.getAttribute('placeholder');
            var dataTitle = controlStringIdVal.getAttribute('title');
            var dataName = controlStringIdVal.getAttribute('name');

            var datavalue = controlStringIdVal.getAttribute('value');
            var datamaskValue = controlStringIdVal.getAttribute('data-mask');
            var dataName = controlStringIdVal.getAttribute('name');

            let txtDataTitle: any = '';

            let spnMaximizeControlModalErrorMessage: any = document.getElementById('spnMaximizeControlModalErrorMessage');
            spnMaximizeControlModalErrorMessage.text = '';

            if (dialogControlId.getAttribute('data-ctntype') == 'Text') {
                txtDataTitle = controlStringIdVal.getAttribute('data-title');
                if (txtDataTitle != 'Date') {
                    this.showMobilePopup = 'block';
                }
                else {
                    this.showMobilePopup = 'none';
                    document.getElementById(dialogControl)?.focus();
                }
            }
            else {
                this.showMobilePopup = 'block';
            }

            let spnMaximizeControlTitle: any = document.getElementById('spnMaximizeControlTitle');
            spnMaximizeControlTitle.innerText = dataCntType;
            this.spnMaximizeControlTitle = dataCntType;

            var existingValue = (<HTMLInputElement>controlStringIdVal).value ? (<HTMLInputElement>controlStringIdVal).value : '';
            let txtMaximizeControl: any = document.getElementById('txtMaximizeControl');
            txtMaximizeControl.value = existingValue;

            if (dialogControlId.getAttribute('data-ctntype') == 'Text') {
                txtDataTitle = controlStringIdVal.getAttribute('data-title');
                if (txtDataTitle != "SSN" && txtDataTitle != "Zip" && txtDataTitle != "Email" && txtDataTitle != "Date") {
                    txtMaximizeControl.setAttribute('maxlength', dataMaxlength);
                }
                else if (txtDataTitle == "Email") {
                    txtMaximizeControl.setAttribute('maxlength', dataMaxlength);
                }
            } else if (dialogControlId.getAttribute('data-ctntype') == 'Email') {
                txtMaximizeControl.setAttribute('maxlength', dataMaxlength);
            } else if (dialogControlId.getAttribute('data-ctntype') == 'Name' || dialogControlId.getAttribute('data-ctntype') == 'Title') {
                txtMaximizeControl.setAttribute('maxlength', dataMaxlength);
            }

            if (txtMaximizeControl) {
                txtMaximizeControl.value = existingValue;
                txtMaximizeControl.setAttribute('placeholder', dataplaceholder);
                txtMaximizeControl.setAttribute('title', dataTitle);
                txtMaximizeControl.setAttribute('name', dataName);
                txtMaximizeControl.setAttribute('data-title', txtDataTitle);
                txtMaximizeControl.setAttribute('value', datavalue);
                txtMaximizeControl.setAttribute('data-mask', datamaskValue);
            }

            setTimeout(() => {
                document.getElementById('txtMaximizeControl')!.focus();
            }, 300);

            let hdnMaximizeControlId: any = document.getElementById('hdnMaximizeControlId');
            hdnMaximizeControlId.value = controlId;
            (<HTMLInputElement>controlStringIdVal).readOnly = true;
            (<HTMLInputElement>controlStringIdVal).blur();
            localStorage.setItem("datetype", "");
            this.popuptempArr.push(controlId);
        }
        else {
            controlIdVal.style.outline = '-webkit-focus-ring-color auto 1px';
        }
    }

    updateControlsValue(hdnMaximizeControlId: any, txtMaximizeControl: any) {
        (<HTMLInputElement>document.getElementById('control_' + hdnMaximizeControlId)).value = txtMaximizeControl;
        let existingEle: any = document.getElementById('control_' + hdnMaximizeControlId);
        existingEle.style.outline = '';
        if (this.showDateControlPopup === 'true') {
            (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = '';
        }

        this.showMobilePopup = 'none';
        this.hideAllOrangeBorder();

        if (txtMaximizeControl !== '' && txtMaximizeControl !== undefined) {
            this.applyStylesToCheckList("add", hdnMaximizeControlId);
        }
        else {
            this.applyStylesToCheckList("remove", hdnMaximizeControlId);
            (<HTMLInputElement>document.getElementById('control_' + hdnMaximizeControlId)).style.border = '1px solid grey';
        }
        this.getTotalRemaingFieldsControls('');

        //expand controls
        this.expandableService.updateExpandControlIfApplicable(hdnMaximizeControlId, txtMaximizeControl, this.controlPositionDetailsArr);
    }

    btnMaximizeControlOk() {
        if (this.webOrMobile === 'mobile') {
            let txtMaximizeControl: any;
            if (this.showDateControlPopup === 'true') {
                if (this.showDateControlPopupDisplay === 'block') {
                    txtMaximizeControl = (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value;
                } else {
                    txtMaximizeControl = (<HTMLInputElement>document.getElementById('txtMaximizeControlTextDate')).value;
                }
            }
            else {
                txtMaximizeControl = (<HTMLInputElement>document.getElementById('txtMaximizeControl')).value;
            }
            let hdnMaximizeControlIdEle: any = document.getElementById('hdnMaximizeControlId')!;
            if (hdnMaximizeControlIdEle && hdnMaximizeControlIdEle.value !== '') {
                var hdnMaximizeControlId = hdnMaximizeControlIdEle.value;
                let controlId: any = document.getElementById('control_' + hdnMaximizeControlId)!;
                let conTitle = controlId.getAttribute("data-title");
                if (txtMaximizeControl !== '') {
                    if (conTitle === 'SSN' || conTitle === 'Zip') {
                        if (txtMaximizeControl.indexOf('_') > 0 || txtMaximizeControl.indexOf('-') > 5) {
                            controlId.parentNode.classList.remove("filledControls");
                        } else {
                            controlId.parentNode.classList.add("filledControls");
                        }
                    } else {
                        controlId.parentNode.classList.add("filledControls");
                    }
                }
                (<HTMLInputElement>controlId).readOnly = false;
                if (conTitle == "Email") {
                    let checkEmail: any = this.validateEmail(txtMaximizeControl);
                    if (checkEmail) {
                        this.updateControlsValue(hdnMaximizeControlId, txtMaximizeControl);
                        this.updateSimilarTextFields(hdnMaximizeControlId, txtMaximizeControl);
                        this.mobileCheckTextDependenciesControl(hdnMaximizeControlId, txtMaximizeControl);

                    } else {
                        this.toastr.warning('Please enter valid email.', this.lang_warning, { timeOut: 2000 });
                        this.showMobilePopup = 'block';
                    }
                } else {
                    if (txtMaximizeControl !== '') {
                        if (conTitle === 'SSN' || conTitle === 'Zip' || conTitle == "Text" || conTitle == "Numeric" || conTitle == "Alphabet" || conTitle == "Email" || conTitle == "Date") {
                            this.updateSimilarTextFields(hdnMaximizeControlId, txtMaximizeControl);
                        }
                        this.updateControlsValue(hdnMaximizeControlId, txtMaximizeControl);
                        this.mobileCheckTextDependenciesControl(hdnMaximizeControlId, txtMaximizeControl);
                    } else {
                        this.updateControlsValue(hdnMaximizeControlId, txtMaximizeControl);
                        if (txtMaximizeControl === '') {
                            if (conTitle === 'SSN' || conTitle === 'Zip' || conTitle == "Text" || conTitle == "Numeric" || conTitle == "Alphabet" || conTitle == "Email" || conTitle == "Date") {
                                this.updateSimilarTextFields(hdnMaximizeControlId, txtMaximizeControl);
                            }
                            this.mobileCheckTextDependenciesControl(hdnMaximizeControlId, txtMaximizeControl);
                            controlId.parentNode.classList.remove("filledControls");
                        }
                    }

                }
                localStorage.setItem("datetype", '');
            }

        }
    }

    onFocusOutEvent(event: any, control: any) {
        if (control.ControlName == "Text" && this.searchTermDisplay != '') {
            this.searchTerm = '';
            this.searchTermDisplay = '';
            this.onFilterApply();
        }
        let currentCtrl: any = document.getElementById("control_" + control.ControlHtmlID)!;
        if (this.documentControlsResponse.EnableAutoFillTextControls == true || this.documentControlsResponse.EnableAutoFillTextControls == "true" || this.documentControlsResponse.EnableAutoFillTextControls == "True") {
            this.updateSimilarTextFields(control.ControlHtmlID, currentCtrl.value);
        }
    }

    updateSimilarTextFields(controlId: any, controlValue: any) {
        this.expandableService.updateSimilarTextFields(controlId, controlValue, this.webOrMobile, this.controlPositionDetailsArr);
        this.getTotalRemaingFieldsControls('');
    }

    // Adding for class name for mobile popUp nav buttons
    getPrevNextBtnAlignmentsMobile() {
        let className: any = (this.mobilePrevBtn !== 'true') ? 'justify-content-end' : 'justify-content-between';
        return className;
    }

    getPreviewImageStyle() {
        return this.styleService.getPreviewImageStyle();
    }

    getPencilIconStyle() {
        return this.styleService.getPencilIconStyle(this.documentControlsResponse.EnvelopeInfo.ElectronicSignIndication);
    }

    ShowDefaultSignature() {
        let count = 0;
        Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((sigItem: any) => {
                let cntType = sigItem.attributes["data-ctntype"].value;
                let display: any = window.getComputedStyle(sigItem).display;
                if (cntType == "Signature" && window.getComputedStyle(sigItem).display !== 'none') {
                    count = count + 1;
                }
            });

        if (count > 0) this.showDefaultSignatureContol = "none";
        else this.showDefaultSignatureContol = "block";
    }

    isDisabledChangeSigner() {
        if (this.IsPrefill == "prefill" || (this.documentControlsResponse != null && this.documentControlsResponse.EnvelopeInfo.IsSingleSigning)) return true;
        else return false;
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    handlePinchZoomEvents(e: any) {
        //console.log('pinchzoom', e);
    }

    updateDefaultValueOnEmailControl() {
        let ProvidedUserEmail: any = localStorage.getItem("ProvidedUserEmail");
        let curRoleId: any = localStorage.getItem("CurrentRecipientID");
        if (curRoleId == null) {
            curRoleId = localStorage.getItem("RecipientID");
        }
        if (ProvidedUserEmail != null && ProvidedUserEmail != "" && ProvidedUserEmail != undefined && ProvidedUserEmail != 'null') {
            this.commonService.updateDefaultValueOnEmailControl(ProvidedUserEmail, curRoleId);
        }
    }

    getSelectTagStyle(ctrlObj: any) {
        return { 'width': '100%', 'font-size': ctrlObj.FontSize + 'px', 'padding': '1px' };
    }

    getPageSectionClass(type: any) {
        return this.commonService.getPageSectionClass(this.webOrMobile, type);
    }

    getDocumentPagStyle() {
        return this.commonService.getDocumentPagStyle(this.webOrMobile);
    }

    checkSignAndInitialsDisabled() {
        return this.commonService.checkSignAndInitialsDisabled(this.signClickCount);
    }

    getFooterAlignment() {
        return this.commonService.getFooterAlignment(this.hidePagesViewSection);
    }

    defaultControlShowTooltip() {
        let currentCtrlIndexPos: any;
        for (let i = 0; i <= this.mobileControlArr.length; i++) {
            let mobileCtrl: any = this.mobileControlArr[i];
            if (mobileCtrl == undefined || mobileCtrl == null) {
                break;
            }
            let controlId = document.getElementById(mobileCtrl.controlId)!;
            currentCtrlIndexPos = i;
            let cTabIndex: any = currentCtrlIndexPos;
            this.tabIndex = parseInt(cTabIndex);
            this.tabIndex = this.returnNextTabIndex(this.tabIndex);
            mobileCtrl = this.mobileControlArr[this.tabIndex];

            if (mobileCtrl.controlName === 'Signature') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let signControlValue: any = controlIdEle.getAttribute('src');
                if (splitControlId) {
                    if (signControlValue !== '' && signControlValue !== undefined && signControlValue != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'NewInitials') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let signControlValue: any = controlIdEle.getAttribute('src');
                if (splitControlId) {
                    if (signControlValue !== '' && signControlValue !== undefined && signControlValue != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Name') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Title') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Company') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Email') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Date') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'DropDown') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle[0].innerText.trim();
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Radio' || mobileCtrl.controlName === 'Checkbox') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.checked;
                if (splitControlId) {
                    if (value && value === true) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Text') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            }
        }
        if (this.tabIndex == 0) {
            this.mobilePrevBtn = 'false';
            this.mobileNextBtn = 'true';
        }
        else if (this.tabIndex == this.mobileControlArr.length - 1) {
            this.mobilePrevBtn = 'true';
            this.mobileNextBtn = 'false';
        }

        else if (this.tabIndex != currentCtrlIndexPos) {
            for (let index = 0; index < this.tabIndex; index++) {
                const element = this.mobileControlArr[index];
                if (element) {
                    if (document.getElementById(element.controlId.replace('control_', ''))!.style.display == "none") {
                        this.mobilePrevBtn = 'false';
                        this.mobileNextBtn = 'true';
                    }
                }
            }
        }
        else {
            this.mobilePrevBtn = 'true';
            this.mobileNextBtn = 'true';
        }

    }

    isControlFilledOrNot(event: any, ctrlObj: any) {
        this.hideAllControlTooltips();

        let value: any = event.target.value
        let controlId = document.getElementById('control_' + ctrlObj.ControlHtmlID)!;
        let controlTooltipId = document.getElementById('tooltip_' + ctrlObj.ControlHtmlID)!;
        if (controlId) {
            controlId.blur();
        }
        let cname: any = controlId.getAttribute('data-title');
        for (var i = 0; i < this.mobileControlArr.length; ++i) {
            if (this.mobileControlArr[i].ControlId == controlId) {
                this.tabIndex = i;
                break;
            }
        }

        this.tabIndex = parseInt(this.tabIndex);

        if (ctrlObj.ControlName.toLowerCase() === 'text') {
            if (cname === 'SSN' || cname === 'Zip') {
                if (value != '___-__-____' && value != '_____-____' && value != '_____') {
                    if (value.indexOf('_') > 0 || value.indexOf('_') > -1 || value.indexOf('-') > 5) {
                        if (cname === 'SSN' || cname === 'Zip') {
                            controlTooltipId.style.display = 'block';
                            if (cname === 'Zip') {
                                this.commonService.showToaster(this.invlaidZIP, this.lang_warning);
                            }
                            if (cname === 'SSN') {
                                this.commonService.showToaster(this.invlaidSSN, this.lang_warning);
                            }
                        }
                    } else {
                        controlTooltipId.style.display = 'none';
                        this.getControlNextNav('');
                    }
                } else {
                    if (cname === 'Zip') {
                        this.commonService.showToaster(this.invlaidZIP, this.lang_warning);
                    }
                    if (cname === 'SSN') {
                        this.commonService.showToaster(this.invlaidSSN, this.lang_warning);
                    }
                }
            } else if (cname === 'Text') {
                value = value.replace(/[\n\r]/g, '');
                var controlHeight = document.getElementById(ctrlObj.ControlHtmlID)!.style.height;
                let calcontrolHeight = controlHeight.replace('px', '');

                if (parseInt(calcontrolHeight) <= 20) {
                    controlTooltipId.style.display = 'none';
                    this.getControlNextNav('');
                }
                else {
                    if (value != '' && value != null && value != undefined) {
                        controlTooltipId.style.display = 'none';
                        return;
                    }
                    else {
                        controlTooltipId.style.display = 'block';
                        //this.commonService.showToaster('Please enter text', this.lang_warning);
                        return;
                    }
                }
            } else if (cname === 'Email') {
                if (value != '' && value != null && value != undefined) {
                    if (this.validateEmail(value)) {
                        controlTooltipId.style.display = 'none';
                        this.getControlNextNav('');
                    } else {
                        controlTooltipId.style.display = 'block';
                        this.commonService.showToaster(this.invalidEmail, this.lang_warning);
                    }
                }
            } else if (cname === 'Date') {
                controlTooltipId.style.display = 'none';
                this.getControlNextNav('');
            } else {
                controlTooltipId.style.display = 'none';
                this.getControlNextNav('');
            }
        } else if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'newinitials') {
            this.getControlNextNav('headerControls');
        } else if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
            let srcValue: any = document.getElementById('control_' + ctrlObj.ControlHtmlID)!;
            let value: any = srcValue.selectedOptions[0].innerText.trim();
            controlTooltipId.style.display = 'none';
            this.getControlNextNav('');
        } else if (ctrlObj.ControlName.toLowerCase() === 'date') {
            controlTooltipId.style.display = 'none';
            this.getControlNextNav('');
        } else {
            controlTooltipId.style.display = 'none';
            this.getControlNextNav('');
        }

    }


    checkAllControlsAreFilled() {
        let arrayLastElementTabIndex = this.mobileControlArr.length - 1;

        let tabPos: any;
        if (this.tabIndex === arrayLastElementTabIndex && this.remainingFieldsCount !== 0) {
            tabPos = -1;
        }
        else if (this.tabIndex === arrayLastElementTabIndex && this.remainingFieldsCount == 0) {
            tabPos = arrayLastElementTabIndex;
            this.hideAllMobilePrevNextDoneBtns();
        }
        else {
            tabPos = this.tabIndex;
        }

        return tabPos;
    }

    checkisLastControl(contrlTabIndex: any) {
        let arrLength = this.mobileControlArr.length;
        let arrayLastElementTabIndex = this.mobileControlArr[arrLength - 1].tabindex;
        if (this.mobileControlArr && this.mobileControlArr.length) {
            if (contrlTabIndex === parseInt(arrayLastElementTabIndex)) {
                this.mobileNextBtn = 'false';
                if (this.remainingFieldsCount === 1) {
                    this.mobileDoneBtn = 'true';
                } else {
                    this.mobileDoneBtn = 'false';
                }
            } else {
                this.mobileDoneBtn = 'false';
            }
        } else {
            this.mobileDoneBtn = 'false';
        }
    }

    hideToolTip(contrlObj: any) {
        if (contrlObj != null) {
            for (var i = 0; i < this.mobileControlArr.length; ++i) {
                if (this.mobileControlArr[i].tabindex == contrlObj.TabIndex) {
                    this.tabIndex = i;
                    break;
                }
            }
        }
        this.hideAllControlTooltips();
    }

    hideAllOrangeBorder() {
        for (let i = 0; i < this.mobileControlArr.length; i++) {
            if (this.mobileControlArr[i].controlName.toLowerCase() !== 'signature' && this.mobileControlArr[i].controlName.toLowerCase() !== 'newinitials') {
                let tooltipControlEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                tooltipControlEle.style.border = '1px solid grey';
            }
        }
    }

    checkTabindexNextControlIsFilledOrNot() {
        if (this.tabIndex <= this.mobileControlArr.length - 1) {
            let tindex: any = this.returnNextTabIndex(this.tabIndex);
            if (tindex != this.tabIndex) {
                this.tabIndex = this.tabIndex + 1;
            }
        }
        return;
    }

    checkTabindexPrevControlIsFilledOrNot() {
        if (this.tabIndex <= this.mobileControlArr.length - 1) {
            let tindex: any = this.returnPrevTabIndex(this.tabIndex);
            if (tindex != this.tabIndex) {
                this.tabIndex = this.tabIndex - 1;
            }
        }
        return;
    }

    checkTooltipIsApplicable(controleID: any) {
        let ele: any = document.getElementById(controleID);
        let controlEle: any = document.getElementById('control_' + controleID);
        let controlTooltipEle: any = document.getElementById('tooltip_' + controleID);
        let ctrlELeName: any = ele.getAttribute('data-ctntype');
        if (ctrlELeName === 'Signature' || ctrlELeName === 'NewInitials') {
            if (controlEle.getAttribute('src') === '' || controlEle.getAttribute('src') === null || controlEle.getAttribute('src') === undefined) {
                controlTooltipEle.style.display = 'block';
            }
        }

        if (this.tabIndex == 0) {
            this.mobilePrevBtn = 'false';
            this.mobileNextBtn = 'true';
        }
        else if (this.tabIndex == this.mobileControlArr.length - 1) {
            this.mobilePrevBtn = 'true';
            this.mobileNextBtn = 'false';
        }
        else if (this.tabIndex != this.mobileControlArr.length - 1) {
            let arr: any = this.mobileControlArr[this.tabIndex];
            if (arr) {
                if (this.mobileControlArr.length && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                    this.mobilePrevBtn = 'true';
                    this.mobileNextBtn = 'false';
                }
                else {
                    this.mobilePrevBtn = 'true';
                    this.mobileNextBtn = 'true';
                }
            }
        }
        else {
            this.mobilePrevBtn = 'true';
            this.mobileNextBtn = 'true';
        }
    }

    //Hide all control tooltips
    hideAllControlTooltips() {
        this.commonService.hideAllControlTooltips(this.mobileControlArr);
    }

    imageLoaded(arr: any): void {
        let apiEnvelopGetImages: string = "";
        if (this.documentControlsResponse.EnvelopeInfo.SubEnvelopeId !== null && this.documentControlsResponse.EnvelopeInfo.SubEnvelopeId != undefined &&
            this.documentControlsResponse.EnvelopeInfo.SubEnvelopeId !== '00000000-0000-0000-0000-000000000000') {
            apiEnvelopGetImages = this.commonConstants.GetImagesForSubEnvelopePrefill;
        } else if (this.documentControlsResponse.EnvelopeInfo.SubEnvelopeId === null &&
            this.documentControlsResponse.IsTemplateShared === true && this.documentControlsResponse.Prefill === 'prefill') {
            apiEnvelopGetImages = this.commonConstants.GetImagesForSubEnvelopePrefill;
        }
        else {
            apiEnvelopGetImages = this.commonConstants.GetEnvelopeImages;
        }
        if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
            if (this.documentControlsResponse.EnvelopeImageControlData.length > 1) {
                let length: any = 1;
                this.showLoader = true;

                this.checkImagesLoaded(arr, length, apiEnvelopGetImages);
            } else {
                let length: any = this.documentControlsResponse.EnvelopeImageControlData.length;
                this.showLoader = true;
                this.checkImagesLoaded(arr, length, apiEnvelopGetImages);
            }
        }
    }

    checkImagesLoaded(arr: any, length: any, apiGetImagesPath: any) {
        for (var i = 0; i < length; i++) {
            let image: any = this.baseUrl + apiGetImagesPath + arr[i].ImagePath;
            this.loadedImagesTemp.push(image);
            let loadedCount = 0;
            for (const url of this.loadedImagesTemp) {
                const img: any = new Image();
                img.onload = () => {
                    this.loadedImages[url] = img.src;
                    loadedCount++;
                    if (loadedCount === this.loadedImagesTemp.length) {
                        this.showControlsFlag = 'block';
                        this.isShowFooter = 'true';
                        this.showLoader = false;
                        let imagePathArr: any = [];
                        if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
                            for (var i = 0; i < this.documentControlsResponse.EnvelopeImageControlData.length; i++) {
                                imagePathArr.push(this.baseUrl + apiGetImagesPath + this.documentControlsResponse.EnvelopeImageControlData[i].ImagePath);
                            }
                        } else {
                            this.removepaginationDisabledClass();
                        }

                        if (imagePathArr && imagePathArr.length) {
                            if(this.webOrMobile === 'mobile'){
                                this.commonService.updatedControlPositionForLandscape();
                            }
                            this.loadImagesAndCheckAllLoaded(imagePathArr);
                        } else {
                            this.removepaginationDisabledClass();
                        }
                        this.addTextAreEventListener();
                    }
                    else {
                        this.showLoader = true;
                    }
                    this.cdRef.detectChanges();
                };
                img.src = url;
            }
        }
        this.showLoader = false;

    }

    assignTimeOutForCtrlFocus(id: any) {
        setTimeout(() => {
            document.getElementById('control_' + id)!.focus();
        }, 500);
    }

    onShowFinishModalClose() {
        this.modalService.dismissAll();
    }

    mobileCheckTextDependenciesControl(ctlid: any, txtMaximizeControlVal: any) {
        let controlObj: any = {};
        let tempcontrolObj: any;
        let isFound: boolean = false;
        if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
            for (var i = 0; i < this.documentControlsResponse.EnvelopeImageControlData.length; i++) {
                if (!isFound && this.documentControlsResponse.EnvelopeImageControlData[i].ControlsData && this.documentControlsResponse.EnvelopeImageControlData[i].ControlsData.length) {
                    for (var j = 0; j < this.documentControlsResponse.EnvelopeImageControlData[i].ControlsData.length; i++) {
                        controlObj = this.documentControlsResponse.EnvelopeImageControlData[i].ControlsData.filter((c: any) => c.ControlHtmlID === ctlid);
                        if (controlObj && controlObj.length) {
                            tempcontrolObj = controlObj[0];
                            isFound = true;
                            break;
                        }
                    }
                }
            }
        }
        if (tempcontrolObj !== undefined) {
            this.showTextDependenciesControl(tempcontrolObj, txtMaximizeControlVal);
            if (this.searchTermDisplay != '') {
                this.searchTerm = '';
                this.searchTermDisplay = '';
                this.onFilterApply();
            }
        }
    }

    checkDocumentControlsResponselocal() {
        let documentControlsRespp: any = localStorage.getItem("documentControlsResponse");
        if (documentControlsRespp == null || documentControlsRespp == undefined || documentControlsRespp == "") {
            return true;
        }
        else {
            let documentControlsResponselocal: any = JSON.parse(documentControlsRespp);
            if (documentControlsResponselocal == null || documentControlsResponselocal == undefined || documentControlsResponselocal == "") {
                return true;
            }
            else {
                return false;
            }
        }
    }

    loadImagesAndCheckAllLoaded(arr: any) {
        this.commonService.loadImages(arr)
            .then((allLoaded) => {
                if (allLoaded) {
                    console.log('All images have been loaded.');
                    this.removepaginationDisabledClass();
                    setTimeout(() => {
                        if (this.documentControlsResponse.EnvelopeInfo.IsDisclaimerEnabled === false && localStorage.getItem('prefill') !== 'prefill' &&
                            this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign === false) {
                            this.defaultControlShowTooltip();
                        }
                    }, 200);
                } else {
                    console.log('Some images failed to load.');
                    this.removepaginationDisabledClass();
                }
            });
        let fotertextClass: any = document.getElementsByClassName('fotertext');
        if (fotertextClass && fotertextClass[0]) {
            fotertextClass[0].style.border = 'left';
        }
    }

    removepaginationDisabledClass() {
        this.commonService.removepaginationDisabledClass();
    }

    hideAllMobilePrevNextDoneBtns() {
        this.mobilePrevBtn = 'false';
        this.mobileNextBtn = 'false';
        this.mobileDoneBtn = 'true';
        this.cdRef.detectChanges();
    }

    handleUpdateSigAndInitialProfileText(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.inputSignChangeSubject.next(value);
    }

    handleUpdateInitialsimageFromSignerProfileText(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.inputInitChangeSubject.next(value);
    }

    checkPinchZoomIsApplicable(ctrlId: any) {
        if (this.pinchZoom.pinchZoom.initialScale === 1) {
            this.pinchZoom.toggleZoom();
            setTimeout(() => {
                this.updateControlPositions(ctrlId);
            }, 300);
        } else {
            if (this.pinchZoom.pinchZoom.initialScale > 1) {
                this.updateControlPositions(ctrlId);
            }
        }
    }

    checkScrollCountAndUpdatePopup(contrlObj: any, selectedFromFilter: any) {
        // Clear any existing scrollingTimeout
        clearTimeout(this.scrollingTimeout);

        // Set a new timeout to detect the end of scrolling
        this.scrollingTimeout = setTimeout(() => {
            // Scrolling has ended, do something here
            console.log('Scrolling has ended.');
            if (contrlObj.ControlName !== 'Date' && contrlObj.ControlName !== 'Radio' && contrlObj.ControlName !== 'Checkbox' &&
                contrlObj.ControlName !== 'Signature' && contrlObj.ControlName !== 'NewInitials' && contrlObj.ControlName !== 'DropDown') {
                this.checkPinchZoomIsApplicable(contrlObj.ControlHtmlID);

                setTimeout(() => {
                    if (contrlObj.ControlName !== 'Signature' && contrlObj.ControlName !== 'NewInitials' &&
                        contrlObj.ControlName !== 'Radio' && contrlObj.ControlName !== 'Checkbox' &&
                        contrlObj.ControlName !== 'DropDown' && contrlObj.ControlName !== 'Date') {
                        let controlStringIdVal = document.getElementById('' + contrlObj.ControlHtmlID)!;
                        controlStringIdVal.removeAttribute('readOnly');
                        setTimeout(() => {
                            this.checkisLastControl(contrlObj.TabIndex);
                            this.responsiveOpenModelPopup(contrlObj.ControlHtmlID);
                        }, 300);
                        this.isDateSelected = 'false';
                    } else {
                        this.showMobilePopup = 'none';
                        this.isDateSelected = 'false';
                    }
                }, 500);
            } else {
                //user clicked from side filter section
                if (selectedFromFilter === 'selectedFromFilter') {
                    this.checkPinchZoomIsApplicable(contrlObj.ControlHtmlID);
                    if (contrlObj.ControlName === 'Signature') {
                        setTimeout(() => {
                            this.onClickSignature(contrlObj.ControlHtmlID);
                        }, 500);
                    } else if (contrlObj.ControlName === 'NewInitials') {
                        this.checkPinchZoomIsApplicable(contrlObj.ControlHtmlID);
                        setTimeout(() => {
                            this.onClickInitials(contrlObj.ControlHtmlID);
                        }, 500);
                    } else if (contrlObj.ControlName === 'Radio' || contrlObj.ControlName === 'Checkbox' ||
                        contrlObj.ControlName === 'DropDown' || contrlObj.ControlName === 'Date') {
                        this.checkPinchZoomIsApplicable(contrlObj.ControlHtmlID);
                    }
                }
            }
            this.cdRef.detectChanges();
        }, 200); // Adjust the timeout value as per your preference
    }

    updateMobileDatePickerValues(dateVal: any, dialogControlId: any) {
        let today: any = new Date();
        if (dateVal != '') {
            today = new Date(dateVal);
        }
        let currentDateVal: any = {
            year: today.getFullYear(),
            month: today.getMonth() + 1, // Note: Months are zero-indexed, so add 1
            day: today.getDate(),
        };
        if (dialogControlId.getAttribute('data-ctntype') === 'Date') {
            if (this.dateControl && this.dateControl._model) {
                this.dateControl._model.day = currentDateVal.day;
                this.dateControl._model.month = currentDateVal.month;
                this.dateControl._model.year = currentDateVal.year;
            }
        } else {
            if (this.dateTextDate && this.dateTextDate._model) {
                this.dateTextDate._model.day = currentDateVal.day;
                this.dateTextDate._model.month = currentDateVal.month;
                this.dateTextDate._model.year = currentDateVal.year;
            }
        }
        this.cdRef.detectChanges();
    }

    closeSendPasswordPopupModal() {
        this.sendPasswordPopupStyle = "none";
    }

    enableSubmitButton(event: any) {
        this.verificationCode = event.target.value;
        let btnViewandSignDocument: any = document.getElementById('btnViewandSignDocument');

        if (!this.commonService.isNumberExists(this.verificationCode)) {
            event.target.value = '';
            if (this.verificationCode.length > 0) {
                this.toastr.warning('Please enter only a number.', this.lang_warning, { timeOut: 1000 });
            }
        }
        else if (this.verificationCode.length == 6) {
            btnViewandSignDocument?.removeAttribute('disabled');
        }
        else {
            btnViewandSignDocument?.setAttribute('disabled', 'disabled');
        }
    }

    getCodeAndValidateSigner() {
        if (this.verificationCode != "" || this.verificationCode != null) {
            this.showLoader = true;

            let envelopeID = localStorage.getItem('EnvelopeID');
            let recipientID = localStorage.getItem('RecipientID');

            let validateFinalSubmitOTP = this.commonConstants.ValidateFinalSubmitOTP + "/" + envelopeID + "/" + recipientID + "/" + this.verificationCode;
            this.signerLandingService.getcall(validateFinalSubmitOTP).subscribe(
                (resp: any) => {
                    this.showLoader = false;
                    if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                        if (this.checkDocumentControlsResponselocal()) {
                            this.commonService.checkIsUrlExistsInBrowserMemory();
                            return;
                        }
                        else {
                            this.sendPasswordPopupStyle = 'none';
                            let envelopeSignDocumentSubmitInfo: any = this.prepareSubmitEnvelopeObject();
                            this.callFinalSubmitEnvelopeDocument(envelopeSignDocumentSubmitInfo);
                        }
                    }
                    else if (resp && resp.StatusMessage && resp.StatusMessage.toLowerCase() == "failed") {
                        this.showLoader = false;
                        this.toastr.warning("Please enter valid code.", this.lang_warning, { timeOut: 1000 });
                        this.cdRef.detectChanges();
                    }
                    else {
                        this.showLoader = false;
                        this.toastr.warning("Please enter valid code.", this.lang_warning, { timeOut: 1000 });
                        this.cdRef.detectChanges();
                    }
                });
        }
        else {
            this.showLoader = false;
            this.toastr.warning("Please enter valid code.", this.lang_warning, { timeOut: 1000 });
            this.cdRef.detectChanges();
        }
    }

    handleIdleTime() {
        if (this.isDocumentSubmitted == "false") {
            let sessionInActivity: any = environment.sessionInActivity;
            if (this.router && this.router.routerState && this.router.routerState.snapshot &&
                this.router.routerState.snapshot.url && this.router.routerState.snapshot.url === '/' + RouteUrls.SignDocument) {
                // this.sessionTimer();

                this.timerWorker.postMessage({ action: 'startTimer', value: (this.idleMaxTimerCount + sessionInActivity) });
                // Listen for messages from the Web Worker
                if (this.timerWorker) {
                    this.timerWorker.onmessage = (e: any) => {
                        // console.log('IsDocumentSubmitted:', this.isDocumentSubmitted + " and "+ e.data.value);
                        if (this.isDocumentSubmitted == "false") {
                            this.timer = e.data.value;
                            // console.log('11111111', this.timer);
                            if (this.timer && this.isDocumentSubmitted == "false") {
                                if (this.timer === -1) {
                                    this.showSessionExpireModalDailog = 'none';
                                    this.commonService.removeBodyTagPositionFixed();
                                    this.modalService.dismissAll(this.termsOfService);
                                    this.clickToSignPopupStyle = 'none';
                                    this.timerWorker.postMessage({ action: 'stopTimer' });
                                    this.finishLater('sessionExpired');
                                    this.sessionTimeoutVal = '';
                                    this.cdRef.detectChanges();
                                } else if (this.timer <= this.popupShowCounter) {
                                    this.showSessionExpireModalDailog = 'block';
                                    this.cdRef.detectChanges();
                                }
                                if (this.timer < 0) {
                                    this.showSessionExpireModalDailog = 'none';
                                } else if (this.timer >= this.idleMaxTimerCount) {
                                    this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
                                } else {
                                    this.showDefaultSessionTimeOut = true;
                                    this.idleTimerMinsValue = (Math.floor(this.timer / 60)).toString();
                                    this.idleTimerSecondsValue = this.addZero(Math.floor((this.timer % 60))).toString();
                                    this.sessionTimeoutVal = (Math.floor(this.timer / 60)).toString() + ':' + this.addZero(Math.floor((this.timer % 60))).toString() + " M";
                                    this.cdRef.detectChanges();
                                }
                            }
                        }
                    };
                }
            }
        }
    }

    //reset Timer
    onContinue() {
        this.showSessionExpireModalDailog = 'none';
        this.commonService.removeBodyTagPositionFixed();
        // clearTimeout(this.idleTimer);
        this.idleTimerMinsValue = (Math.floor(this.idleMaxTimerCount / 60)).toString();
        this.idleTimerSecondsValue = '00';
        this.sessionTimeReset();
        this.resumeTimer();
        this.cdRef.detectChanges();
    }

    onSaveAndFinishLater(sessionKeepOut: any) {
        this.showSessionExpireModalDailog = 'none';
        this.commonService.removeBodyTagPositionFixed();
        // clearTimeout(this.idleTimer);
        this.sessionTimeReset();
        this.modalService.dismissAll(this.termsOfService);
        this.clickToSignPopupStyle = 'none';
        this.timerWorker.postMessage({ action: 'stopTimer' });
        this.finishLater('onUserSelectedFinishLater');
        this.sessionTimeoutVal = '';
    }

    sessionTimer() {
        this.idleCounter--;
        if (this.idleCounter === -1) {
            this.showSessionExpireModalDailog = 'none';
            this.commonService.removeBodyTagPositionFixed();
            this.modalService.dismissAll(this.termsOfService);
            this.clickToSignPopupStyle = 'none';
            this.finishLater('sessionExpired');
            this.sessionTimeoutVal = '';
        } else if (this.idleCounter <= this.popupShowCounter) {
            this.showSessionExpireModalDailog = 'block';
        }
        if (this.idleCounter < 0) {
            clearTimeout(this.idleTimer);
        } else {
            this.showDefaultSessionTimeOut = true;
            this.idleTimerMinsValue = (Math.floor(this.idleCounter / 60)).toString();
            this.idleTimerSecondsValue = this.addZero(Math.floor((this.idleCounter % 60))).toString();
            if (this.showSessionExpireModalDailog === 'none') {
                this.sessionTimeoutVal = (Math.floor(this.idleCounter / 60)).toString() + ':' + this.addZero(Math.floor((this.idleCounter % 60))).toString() + " M";
            } else {
                this.sessionTimeoutVal = '';
            }
            this.idleTimer = setTimeout(() => { this.sessionTimer() }, 1000);
        }
        this.cdRef.detectChanges();
    }

    addZero(i: any) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    sessionTimeReset() {
        this.showDefaultSessionTimeOut = false;
        this.sessionTimeoutVal = '';
        this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
    }

    resetSessionTimer() {
        if (this.showSessionExpireModalDailog === 'none') {
            if (this.showDefaultSessionTimeOut === true) {
                //reset Timer
                this.onContinue();
            } else {
                this.idleTimerMinsValue = (Math.floor(this.idleMaxTimerCount / 60)).toString();
                this.idleTimerSecondsValue = this.addZero(Math.floor((this.idleMaxTimerCount % 60))).toString();
                this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
                this.resumeTimer();
                this.cdRef.detectChanges();
            }
        }
        this.cdRef.detectChanges();
    }

    resumeTimer() {
        // Send a message to the Web Worker to reset the timer
        this.timerWorker.postMessage({ action: 'resetTimer' });
        // Start the timer again
        this.handleIdleTime();
        // this.timerWorker.postMessage({ action: 'startTimer' });
    }

    getAllControlPositionDetail() {
        // this.controlPositionDetailsArr = [];

        //call RetriveAllDocumentControlData for getting other signer's controls
        let IsFixedWidthControlExists: any = this.documentControlsResponse.EnvelopeInfo.IsFixedWidthControlExists;
        if (IsFixedWidthControlExists == false) {
            let envelopeID = localStorage.getItem('EnvelopeID');
            let recipientID = localStorage.getItem('RecipientID');

            if (envelopeID == '' || envelopeID == 'null' || envelopeID == null || envelopeID == undefined) {
                envelopeID = this.responseSigningUrlModel.EnvelopeID;
            }
            if (recipientID == '' || recipientID == 'null' || recipientID == null || recipientID == undefined) {
                recipientID = this.responseSigningUrlModel.RecipientID;
            }

            let url: any = this.commonConstants.RetriveAllDocumentControlData + "/" + envelopeID + "/" + recipientID;

            this.signerLandingService.getcall(url).subscribe(
                (resp: any) => {
                    this.showLoader = false;
                    if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                        if (resp.AllDocumentControls && resp.AllDocumentControls.length > 0) {
                            resp.AllDocumentControls.forEach((ctrlObj: any) => {
                                this.calculateTopLeftForAllControls(ctrlObj);
                            });
                        }
                    }
                });
        }
    }

    checkAutoExpand(event: any, ctrl: any) {
        this.expandableService.checkAutoExpand(event, ctrl, this.controlPositionDetailsArr);
    }

    calculateTopLeftForAllControls(ctrlObj: any) {
        let left: any = 0;
        let top: any = 0;
        let calculatedLeftSplitVal: any;
        if (ctrlObj.CalculatedLeft) {
            calculatedLeftSplitVal = ctrlObj.CalculatedLeft.split(':')[1].trim();
            let tempLeft: any = calculatedLeftSplitVal;
            left = tempLeft.split('px')[0];
        }
        let calculatedTopSplitVal: any;
        if (ctrlObj.CalculatedTop) {
            calculatedTopSplitVal = ctrlObj.CalculatedTop.split(':')[1].trim();
            let tempTop: any = calculatedTopSplitVal;
            top = tempTop.split('px')[0];
        }
        this.controlPositionDetailsArr.push({
            'left': parseFloat(left), 'top': parseFloat(top),
            'width': parseFloat(ctrlObj.Width),
            'height': parseFloat(ctrlObj.Height),
            'tabindex': ctrlObj.TabIndex,
            'controlId': 'control_' + ctrlObj.ControlHtmlID,
            'controlName': ctrlObj.ControlName,
            'required': ctrlObj.Required,
            'docPage': ctrlObj.PageNo
        });

        this.removeDuplicateObjects();
    }

    //Removing duplicate objects based on controlId
    removeDuplicateObjects() {
        let objectsArray: any = this.controlPositionDetailsArr;
        let uniqueIds: any = {};
        let uniqueObjects: any = [];

        objectsArray.forEach((obj: any) => {
            if (!uniqueIds[obj.controlId]) {
                uniqueIds[obj.controlId] = true;
                uniqueObjects.push(obj);
            }
        });

        this.controlPositionDetailsArr = uniqueObjects;
    }

    getControlLanguageControlName(control: any) {
        return this.translationsService.getControlLanguageControlName(control);
    }

    getInitializeSignDocument1() {
        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        if (documentControlsResp == null || documentControlsResp == undefined) {
            let actualSigningUrl: any = localStorage.getItem("actualSigningUrl");
            if (actualSigningUrl == null || actualSigningUrl == undefined) {
                this.showLoader = false;
                localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
                localStorage.setItem("MessageType", "Warning");
                this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else {
                window.location.href = actualSigningUrl;
            }
        }
        else {
            let respSigningUrlModel: any = localStorage.getItem("responseSigningUrlModel");
            this.responseSigningUrlModel = JSON.parse(respSigningUrlModel);
            let apiResponseData = JSON.parse(documentControlsResp);
            let userdata: any = localStorage.getItem("Userdata");
            let envelopeInfo: any = localStorage.getItem("EnvelopeInfo");
            apiResponseData.userdata = JSON.parse(userdata);
            apiResponseData.EnvelopeInfo = JSON.parse(envelopeInfo);

            apiResponseData.Delegated = apiResponseData.EnvelopeInfo.SignerStatusId == Signer.Delegated ? "Delegated" : "NotDelegated";
            apiResponseData.Prefill = apiResponseData.EnvelopeInfo.RecipientTypeIDPrefill == RecipientType.Prefill ? "prefill" : "";
            //let DatePlaceHolder: any = DateFormatString.US_mm_dd_yyyy_slash;
            let DateFormat = "";
            // if ((apiResponseData.EnvelopeInfo.DateFormatID.toLowerCase() == DateFormatGuid.US_mm_dd_yyyy_slash.toLowerCase())) {
            //     DatePlaceHolder = DateFormatString.US_mm_dd_yyyy_slash; DateFormat = "mm/dd/yy";
            // }
            // else if ((apiResponseData.EnvelopeInfo.DateFormatID.toLowerCase() == DateFormatGuid.US_mm_dd_yyyy_colan.toLowerCase())) {
            //     DatePlaceHolder = DateFormatString.US_mm_dd_yyyy_colan; DateFormat = "mm-dd-yy";
            // }
            // else if ((apiResponseData.EnvelopeInfo.DateFormatID.toLowerCase() == DateFormatGuid.US_mm_dd_yyyy_dots.toLowerCase())) {
            //     DatePlaceHolder = DateFormatString.US_mm_dd_yyyy_dots; DateFormat = "mm.dd.yy";
            // }
            // else if ((apiResponseData.EnvelopeInfo.DateFormatID.toLowerCase() == DateFormatGuid.Europe_mm_dd_yyyy_slash.toLowerCase())) {
            //     DatePlaceHolder = DateFormatString.Europe_mm_dd_yyyy_slash; DateFormat = "dd/mm/yy";
            // }
            // else if ((apiResponseData.EnvelopeInfo.DateFormatID.toLowerCase() == DateFormatGuid.Europe_mm_dd_yyyy_colan.toLowerCase())) {
            //     DatePlaceHolder = DateFormatString.Europe_mm_dd_yyyy_colan; DateFormat = "dd-mm-yy";
            // }
            // else if ((apiResponseData.EnvelopeInfo.DateFormatID.toLowerCase() == DateFormatGuid.Europe_mm_dd_yyyy_dots.toLowerCase())) {
            //     DatePlaceHolder = DateFormatString.Europe_mm_dd_yyyy_dots; DateFormat = "dd.mm.yy";
            // }
            // else if ((apiResponseData.EnvelopeInfo.DateFormatID.toLowerCase() == DateFormatGuid.Europe_yyyy_mm_dd_dots.toLowerCase())) {
            //     DatePlaceHolder = DateFormatString.Europe_yyyy_mm_dd_dots; DateFormat = "yy.mm.dd.";
            // }
            // else if ((apiResponseData.EnvelopeInfo.DateFormatID.toLowerCase() == DateFormatGuid.US_dd_mmm_yyyy_colan.toLowerCase())) {
            //     DatePlaceHolder = DateFormatString.US_dd_mmm_yyyy_colan; DateFormat = "dd-mmm-yy";
            // }
            //apiResponseData.DatePlaceHolder = DatePlaceHolder;
            //apiResponseData.DateFormat = DateFormat;

            apiResponseData.FileReviewInfo = apiResponseData.documentDetails.filter((d: any) => d.ActionType === ActionTypes.Review);
            apiResponseData.FileReviewCount = apiResponseData.FileReviewInfo.length;
            apiResponseData.DocumentNameList = apiResponseData.documentDetails.filter((d: any) => d.ActionType !== ActionTypes.Review);
            apiResponseData.AllowStaticMultiSigner = false;

            let emptyGuidVal = '00000000-0000-0000-0000-000000000000';
            apiResponseData.SignatureTypeID = apiResponseData.userdata != null ? apiResponseData.userdata.SignatureTypeID : emptyGuidVal;
            let sigArray: any = apiResponseData.EnvelopeInfo.ControlsData.filter((c: any) => c.ControlName == "Signature" && (c.IsCurrentRecipient == true));
            if (sigArray && sigArray.length > 0) {
                apiResponseData.IsAnySignatureExists = true;
                apiResponseData.ShowDefaultSignatureContol = "none";
            }
            else {
                apiResponseData.IsAnySignatureExists = false;
                apiResponseData.ShowDefaultSignatureContol = "block";
            }


            let SameRecipientList = apiResponseData.SameRecipientIds;
            let pageCountArray = apiResponseData.EnvelopeInfo.ControlsData.filter((x: any) => (x.IsCurrentRecipient == true || (x.RecipientId != null && x.RecipientId != emptyGuidVal && SameRecipientList != null &&
                SameRecipientList.includes(x.RecipientId.Value) == true)));

            let pageCount = pageCountArray.reduce((p: any, c: any) => p.PageNo > c.PageNo ? p : c).PageNo;
            apiResponseData.PageCount = pageCount == null ? 0 : pageCount;

            var defaultSignatureArray = apiResponseData.EnvelopeInfo.ControlsData.filter((c: any) => c.ControlName == "FooterSignature");
            let defaultSignatureArrayVal: any;
            if (defaultSignatureArray && defaultSignatureArray.length > 0) {
                defaultSignatureArrayVal = defaultSignatureArray[0];
            }

            let radioCntrlOndoc: any = [];
            let checkboxCntrlOndoc: any = [];
            let EditControls: boolean = apiResponseData.CanEdit;

            let controlsInfo: any = apiResponseData.EnvelopeInfo;
            let checkListData: any = [];
            if (apiResponseData.PageCount > 0) {
                if (controlsInfo.ControlsData.length > 1) {
                    let tempDocId = emptyGuidVal;
                    let documentName = "";
                    for (var i = 1; i <= pageCount; i++) {
                        if (controlsInfo.ControlsData.filter((x: any) => x.PageNo == i && ((EditControls == true && (x.ControlName != "Signature" && x.ControlName != "Email" && x.ControlName != "NewInitials"))
                            || x.IsCurrentRecipient == true || (x.RecipientId != null && x.RecipientId != emptyGuidVal && SameRecipientList != null && SameRecipientList.includes(x.RecipientId.Value) == true)) && x.ControlName != "Label" && x.ControlName != "DateTimeStamp" && x.ControlName != "Hyperlink").length > 0) {
                            let ControlsData = controlsInfo.ControlsData.sort((item: any) => item.TabIndex).filter((x: any) => x.PageNo == i && ((EditControls == true && (x.ControlName != "Signature" && x.ControlName != "Email" && x.ControlName != "NewInitials")) || x.IsCurrentRecipient == true ||
                                (x.RecipientId != null && x.RecipientId != emptyGuidVal && SameRecipientList != null && SameRecipientList.includes(x.RecipientId.Value) == true)));

                            if ((tempDocId == emptyGuidVal || (ControlsData != null && ControlsData.length > 0 && tempDocId != ControlsData[0].DocumentId))) {
                                let DocumentNameListArray = apiResponseData.DocumentNameList.filter((d: any) => d.ID == ControlsData[0].DocumentId);
                                documentName = DocumentNameListArray && DocumentNameListArray.length > 0 ? DocumentNameListArray[0].DocumentName : '';
                                tempDocId = ControlsData[0].DocumentId;
                            }

                            let newControlsData: any = [];
                            ControlsData.forEach((cntrl: any) => {
                                if (cntrl.IsReadOnly != true) {
                                    if (cntrl.ControlName.toLowerCase() == "radio" && !(radioCntrlOndoc.includes(cntrl.ControlHtmlID))) {
                                        let radiogrp: any = [];
                                        radiogrp = controlsInfo.ControlsData.filter((x: any) => x.GroupName == cntrl.GroupName && x.ControlName.toLowerCase() == "radio" && x.IsCurrentRecipient == true);
                                        radiogrp.forEach((radio: any) => {
                                            radioCntrlOndoc.push(radio.ControlHtmlID);
                                            radio.Required = cntrl.Required;
                                            newControlsData.push(radio);
                                        });
                                    }
                                    else if (cntrl.ControlName.toLowerCase() == "checkbox" && !(checkboxCntrlOndoc.includes(cntrl.ControlHtmlID))) {
                                        if (cntrl.GroupName != '' && cntrl.GroupName != null) {
                                            let checkgrp: any = [];
                                            checkgrp = controlsInfo.ControlsData.filter((x: any) => x.GroupName == cntrl.GroupName && x.ControlName.toLowerCase() == "checkbox" && x.IsCurrentRecipient == true);
                                            checkgrp.forEach((check: any) => {
                                                checkboxCntrlOndoc.push(check.ControlHtmlID);
                                                check.Required = cntrl.Required;
                                                newControlsData.push(check);
                                            });
                                        }
                                        else {
                                            newControlsData.push(cntrl);
                                        }
                                    }
                                    else if (cntrl.ControlName != "Label" && cntrl.ControlName != "Checkbox" && cntrl.ControlName.toLowerCase() != "radio" && cntrl.ControlName.toLowerCase() != "datetimestamp" && cntrl.ControlName != "Hyperlink") {
                                        newControlsData.push(cntrl);
                                    }
                                }
                            });

                            checkListData.push({
                                PageNumber: i,
                                ControlsData: newControlsData,
                                DocumentName: documentName,
                                DocumentId: tempDocId
                            });
                            apiResponseData.CheckListData = checkListData;
                        }
                    }
                }
            }
            else {
                apiResponseData.DocumentNameList.forEach((element: any) => {
                    checkListData.push({
                        PageNumber: null,
                        ControlsData: null,
                        DocumentName: element.DocumentName,
                        DocumentId: element.ID
                    });
                });
            }
            apiResponseData.CheckListData = checkListData;

            let pageNo = 1;
            let lstEnvelopeImageControlData: any = [];
            controlsInfo.EnvelopeImageCollection.forEach((envelope: any) => {
                let imageid = String(envelope.Id);
                let pageNumber = String(pageNo);
                let DocPageNo = envelope.DocPageNo;
                let imgControlWidth = "";
                if (envelope.IsTemplateDatedBeforePortraitLandscapeFeature == true)
                    imgControlWidth = "";
                else {
                    if (Number(envelope.Dimension.Width) > Number(envelope.Dimension.Height))
                        imgControlWidth = "1015px";
                    else
                        imgControlWidth = "915px";
                }

                let signingEnvelopeDocumentData: any = {};
                signingEnvelopeDocumentData.PageNum = pageNo;
                signingEnvelopeDocumentData.DocId = envelope.Document.Id;
                signingEnvelopeDocumentData.DocName = envelope.Document.Name;
                signingEnvelopeDocumentData.IsPageLoaded = true;

                let imgPath = "";
                if (controlsInfo.SubEnvelopeId != null && controlsInfo.SubEnvelopeId != emptyGuidVal)
                    imgPath = "/" + imageid + "/" + String(controlsInfo.GlobalEnvelopeID) + "/" + envelope.ImagePath.Substring(envelope.ImagePath.LastIndexOf('/') + 1) + "/" + apiResponseData.UNCPath;
                else if (controlsInfo.SubEnvelopeId == null && controlsInfo.IsTemplateShared == true && apiResponseData.Prefill == "prefill")
                    imgPath = "/" + imageid + "/" + String(controlsInfo.GlobalEnvelopeID) + "/" + envelope.ImagePath.Substring(envelope.ImagePath.LastIndexOf('/') + 1) + "/" + apiResponseData.UNCPath;
                else
                    imgPath = "/" + imageid + "/" + String(controlsInfo.GlobalEnvelopeID) + "/" + apiResponseData.UNCPath;

                controlsInfo.ControlsData.forEach((item: any) => {
                    if (item.ControlHtmlID != "FooterSignature" && item.DocumentId == envelope.Document.Id && (item.PageNo == envelope.PageNo)) {

                        if (item.ControlName.toLowerCase() == "checkbox" && this.isNullOrEmptyCheck(item.GroupName) && item.Required != true) {
                            var checkgrp = controlsInfo.ControlsData.filter((x: any) => x.GroupName == item.GroupName && x.ControlName.toLowerCase() == "checkbox" && x.IsCurrentRecipient == true);
                            if (checkgrp.filter((rg: any) => rg.Required != null && rg.Required == true).length > 0) {
                                item.Required = true;
                            }
                        }

                        let style: string = item.ControlHtmlData;
                        let tempTop = "";
                        let left: any = (item.Left == 0.0 ? style.slice(style.indexOf("left"), style.indexOf("px")) : String(item.Left)) + "px";
                        let topIndex: number = style.indexOf("top");
                        let positionIndex: number = style.indexOf("position");
                        // let top: any = (item.Top == 0.0 ? style.slice(topIndex, positionIndex) : String(item.Top));

                        let topValueIndex: number = positionIndex < topIndex ? (style.indexOf(";", topIndex) + 2) : positionIndex;
                        let top: any = (item.Top == 0.0 ? style.substr(topIndex, (topValueIndex - (topIndex + 2))) : String(item.Top));

                        if (item.ControlName == "Name" || item.ControlName == "Email" || item.ControlName == "Title" || item.ControlName == "Label" || item.ControlName == "Company") {
                            top = "top: " + (parseFloat(top.substr(top.indexOf(" "), top.indexOf("px") - top.indexOf(" "))) - 0) + "px";
                        }
                        else if (item.ControlName == "Text") {
                            if (item.ControlType == '8348E5CD-59EA-4A77-8436-298553D286BD') {
                                top = "top: " + (parseFloat(top.substr(top.indexOf(" "), top.indexOf("px") - top.indexOf(" "))) + 2) + "px";
                            }
                            else {
                                if (top.indexOf('.') > 0)
                                    top = "top: " + (parseFloat(top.substr(top.indexOf(" "), top.indexOf("px") - top.indexOf(" "))) - 0.300) + "px";
                                else
                                    top = "top: " + (parseFloat(top.substr(top.indexOf(" "), top.indexOf("px") - top.indexOf(" "))) - 0) + "px";
                            }
                        }
                        else if (item.ControlName == "DropDown") {
                            top = "top: " + (parseFloat(top.substr(top.indexOf(" "), top.indexOf("px") - top.indexOf(" "))) + 2) + "px";
                        }
                        else if (item.ControlName == "Checkbox" && item.Height == 14) {
                            top = "top: " + (parseFloat(top.substr(top.indexOf(" "), top.indexOf("px") - top.indexOf(" "))) - 1) + "px";
                            left = "left: " + (parseFloat(left.substr(left.indexOf(" "), left.indexOf("px") - left.indexOf(" "))) - 1) + "px";
                        }
                        else if (item.ControlName == "Radio" && item.Height == 14) {
                            top = "top: " + (parseFloat(top.substr(top.indexOf(" "), top.indexOf("px") - top.indexOf(" "))) - 1) + "px";
                            left = "left: " + (parseFloat(left.substr(left.indexOf(" "), left.indexOf("px") - left.indexOf(" "))) - 1) + "px";
                        }
                        else if (item.ControlName == "Label") {
                            top = "top: " + (parseFloat(top.substr(top.indexOf(" "), top.indexOf("px") - top.indexOf(" "))) + 1) + "px";
                        }

                        item.CalculatedTop = top;
                        item.CalculatedLeft = left;
                        item.HoverTitle = item.Required || (item.ControlName == "DropDown" && !item.Required && item.ControlOptions[0].OptionText != "") ? "Required" : "Optional";
                        item.CalculatedModalWidth = item.ControlName.toLowerCase() == "text" ? item.Width + 1 : item.Width - 5;
                        if (controlsInfo.ElectronicSignIndication == null) {
                            controlsInfo.ElectronicSignIndication = 1;
                        }

                        if (item.ControlName == "Date") {
                            var dateplaceholder = item.Label;
                            if (dateplaceholder == "dd-mmm-yyyy")
                                item.DefaultDateFormat = dateplaceholder.replace("mmm", "MMM");
                            else
                                item.DefaultDateFormat = dateplaceholder.replace("mm", "MM");
                        }

                        var ExistSameRecipient = false;
                        if (SameRecipientList != null && SameRecipientList.Count > 0 && item.RecipientId != null && item.RecipientId != emptyGuidVal) {
                            ExistSameRecipient = SameRecipientList.includes(item.RecipientId.Value);
                        }

                        item.ExistSameRecipient = ExistSameRecipient;
                        item.EditControls = EditControls;

                        if (item.IsCurrentRecipient == true || ExistSameRecipient == true || (EditControls == true && (item.ControlName != "Signature" && item.ControlName != "Email" && item.ControlName != "NewInitials"))) {

                            if (item.ControlName == "Text") {
                                let textType = "";
                                let textTypeValue = "";
                                let textTypeMask = "";

                                switch (item.ControlType) {
                                    case "D6FBBFC2-C907-4290-929F-175EB437AA81":
                                    case "D1409FCF-5683-4921-A62B-2F635F4E49B7":
                                    case "B0443A47-89C3-4826-BECC-378D81738D03":
                                    case "C175A449-3A22-4FE0-A009-C3F76F612510":
                                    case "73C17C33-F255-474F-9F46-248542ADDACC":
                                        textType = "Numeric";
                                        textTypeValue = "";
                                        textTypeMask = "";
                                        break;
                                    case "F01331D9-3413-466A-9821-2670A8D9F3EE":
                                    case "26C0ACEA-3CC8-43D6-A255-A870A8524A77":
                                    case "CBAF463C-8287-4C04-B90C-C6E2F1EC5299":
                                    case "F690C267-D10F-40AD-A487-D2035D9C3858":
                                    case "126AF3B7-409E-425E-A9C3-A313254ACB03":
                                        textType = "Text";
                                        break;
                                    case "88A0B11E-5810-4ABF-A8B6-856C436E7C49":
                                        textType = "Alphabet";
                                        break;
                                    case "8348E5CD-59EA-4A77-8436-298553D286BD":
                                        textType = "Date";
                                        break;
                                    case "DCBBE75C-FDEC-472C-AE25-2C42ADFB3F5D":
                                        textType = "SSN";
                                        textTypeValue = "___-__-____";
                                        textTypeMask = "___-__-____";
                                        break;
                                    case "5121246A-D9AB-49F4-8717-4EF4CAAB927B":
                                        textType = "Zip";
                                        break;
                                    case "1AD2D4EC-4593-435E-AFDD-F8A90426DE96":
                                        textType = "Email";
                                        break;
                                }

                                switch (item.AdditionalValidationOption) {
                                    case "Zip":
                                        textTypeValue = "_____";
                                        textTypeMask = "_____";
                                        break;
                                    case "Zip+4":
                                        textTypeValue = "_____-____";
                                        textTypeMask = "_____-____";
                                        break;
                                    case "mm/dd/yyyy":
                                        textTypeValue = "mm/dd/yyyy";
                                        textTypeMask = "mm/dd/yyyy";
                                        break;
                                    case "dd/mm/yyyy":
                                        textTypeValue = "dd/mm/yyyy";
                                        textTypeMask = "dd/mm/yyyy";
                                        break;
                                    case "yyyy/mm/dd":
                                        textTypeValue = "yyyy/mm/dd";
                                        textTypeMask = "yyyy/mm/dd";
                                        break;
                                    case "Period":
                                        textTypeValue = "Period";
                                        textTypeMask = "Period";
                                        break;
                                    case "Comma":
                                        textTypeValue = "Comma";
                                        textTypeMask = "Comma";
                                        break;
                                    case "Both":
                                        textTypeValue = "Both";
                                        textTypeMask = "Both";
                                        break;
                                    case "dd-mmm-yyyy":
                                        textTypeValue = "dd-mmm-yyyy";
                                        textTypeMask = "dd-mmm-yyyy";
                                        break;
                                }

                                item.TextType = textType;
                                item.TextTypeValue = textTypeValue;
                                item.TextTypeMask = textTypeMask;

                                let inputMaxLength = 0;
                                let inputMaxChar = 0;
                                if (textType != "Date" && textType != "Email") {
                                    if (style.indexOf("maxlengthallowed") > -1) {
                                        item.MaxInputLength = style.slice(style.indexOf("maxlengthallowed") + 18, style.indexOf("maxlengthallowed") + 22).replace(/\D/g, '');
                                        if (item.MaxInputLength)
                                            inputMaxLength = Number(item.MaxInputLength);
                                        else
                                            inputMaxLength = 20;
                                    }
                                    else {
                                        item.MaxInputLength = "20";
                                        inputMaxLength = 20;
                                    }

                                    if (style.indexOf("maxcharallowed") > -1) {
                                        item.MaxInputCharLimit = style.slice(style.indexOf("maxcharallowed") + 16, style.indexOf("maxcharallowed") + 20).replace(/\D/g, '');
                                        if (item.MaxInputCharLimit)
                                            inputMaxChar = Number(item.MaxInputCharLimit);
                                        else
                                            inputMaxChar = 4000;
                                    }
                                    else {
                                        item.MaxInputCharLimit = "4000";
                                        inputMaxChar = 4000;
                                    }
                                }

                                if (textType == "Alphabet" || textType == "Text" || textType == "Numeric" || textType == "Email") {

                                    if (textType == "Email") {
                                        if (item.IsFixedWidth == false) {
                                            item.MaxInputCharLimit = style.slice(style.indexOf("maxemailcharallowed") + 21, style.indexOf("maxemailcharallowed") + 25).replace(/\D/g, '');

                                            if (item.MaxInputCharLimit)
                                                inputMaxChar = Number(item.MaxInputCharLimit);
                                            else
                                                inputMaxChar = 100;
                                        }
                                        else {
                                            item.MaxInputLength = style.slice(style.indexOf("maxlengthallowed") + 18, style.indexOf("maxlengthallowed") + 22).replace(/\D/g, '');
                                            if (item.MaxInputLength)
                                                inputMaxLength = Number(item.MaxInputLength);
                                            else
                                                inputMaxLength = 20;
                                        }
                                    }
                                    else if (textType == "Text" || textType == "Alphabet" || textType == "Numeric") {
                                        item.MaxInputCharLimit = style.slice(style.indexOf("maxcharallowed") + 16, style.indexOf("maxcharallowed") + 20).replace(/\D/g, '');
                                        if (item.MaxInputCharLimit)
                                            inputMaxChar = Number(item.MaxInputCharLimit);
                                        else
                                            inputMaxChar = 4000;
                                    }
                                    else {
                                        item.MaxInputCharLimit = "4000";
                                        inputMaxChar = 4000;
                                    }

                                    if (!this.isNullOrEmptyCheck(item.CustomToolTip)) {
                                        if (item.IsFixedWidth == true || item.IsFixedWidth == null)
                                            inputMaxChar = inputMaxLength;
                                        else if (item.IsFixedWidth == false)
                                            inputMaxChar = inputMaxChar;

                                        if (textType == "Numeric")
                                            item.CustomToolTip = "Maximum " + inputMaxChar + " digits (0-9) allowed.";
                                        else
                                            item.CustomToolTip = "Approximately " + inputMaxChar + " characters or the control boundaries.";
                                    }
                                }
                                else {
                                    if ((textType == "SSN" || textType == "Zip") && item.CustomToolTip == "") {
                                        item.CustomToolTip = textType;
                                    }
                                }
                            }
                            else if (item.ControlName == "Name") {
                                let inputMaxLength = 0;
                                let inputMaxChar = 0;
                                if (style.indexOf("maxlengthallowed") > -1) {
                                    item.MaxInputLength = style.slice(style.indexOf("maxlengthallowed") + 18, style.indexOf("maxlengthallowed") + 22).replace(/\D/g, '');
                                    if (item.MaxInputLength) {
                                        inputMaxLength = Number(item.MaxInputLength);
                                    }
                                    else {
                                        inputMaxLength = 20;
                                    }
                                }
                                else {
                                    item.MaxInputLength = "20";
                                    inputMaxLength = 20;
                                }

                                if (style.indexOf("maxcharallowed") > -1) // to get maxcharlimit
                                {
                                    item.MaxInputCharLimit = style.slice(style.indexOf("maxcharallowed") + 16, style.indexOf("maxcharallowed") + 20).replace(/\D/g, '');
                                    if (item.MaxInputCharLimit) {
                                        inputMaxChar = Number(item.MaxInputCharLimit);
                                    }
                                    else {
                                        inputMaxChar = 100;
                                    }
                                }
                                else {
                                    item.MaxInputCharLimit = "100";
                                    inputMaxChar = 100;
                                }

                                if (!this.isNullOrEmptyCheck(item.CustomToolTip)) {
                                    if (item.IsFixedWidth == true || item.IsFixedWidth == null)
                                        item.CustomToolTip = "Approximately " + inputMaxLength + " characters or the control boundaries.";
                                    else if (item.IsFixedWidth == false)
                                        item.CustomToolTip = "Approximately " + inputMaxChar + " characters or the control boundaries.";
                                }
                            }
                            else if (item.ControlName == "Title") {
                                let inputMaxLength = 0;
                                let inputMaxChar = 0;
                                if (style.indexOf("maxlengthallowed") > -1) {
                                    item.MaxInputLength = style.slice(style.indexOf("maxlengthallowed") + 18, style.indexOf("maxlengthallowed") + 22).replace(/\D/g, '');
                                    if (item.MaxInputLength) {
                                        inputMaxLength = Number(item.MaxInputLength);
                                    }
                                    else {
                                        inputMaxLength = 20;
                                    }
                                }
                                else {
                                    item.MaxInputLength = "20";
                                    inputMaxLength = 20;
                                }

                                if (style.indexOf("maxcharallowed") > -1) // to get maxcharlimit
                                {
                                    item.MaxInputCharLimit = style.slice(style.indexOf("maxcharallowed") + 16, style.indexOf("maxcharallowed") + 20).replace(/\D/g, '');
                                    if (!this.isNullOrEmptyCheck(item.MaxInputCharLimit)) {
                                        inputMaxChar = Number(item.MaxInputCharLimit);
                                    }
                                    else {
                                        inputMaxChar = 100;
                                    }
                                }
                                else {
                                    item.MaxInputCharLimit = "100";
                                    inputMaxChar = 100;
                                }

                                if (!this.isNullOrEmptyCheck(item.CustomToolTip)) {
                                    if (item.IsFixedWidth == true || item.IsFixedWidth == null)
                                        item.CustomToolTip = "Approximately " + inputMaxLength + " characters or the control boundaries.";
                                    else if (item.IsFixedWidth == false)
                                        item.CustomToolTip = "Approximately " + inputMaxChar + " characters or the control boundaries.";
                                }
                            }
                        }
                        else if (item.IsSigned) {
                            try {

                                tempTop = top.Replace("top:", "").Replace("top :", "").Replace("px", "").Trim();
                                if (item.ControlName == "Signature" || item.ControlName == "NewInitials" || item.ControlName == "DateTimeStamp" || item.ControlName == "Date") {
                                    tempTop = "top: " + String(parseFloat(top)) + "px";
                                }
                                else if (item.ControlName == "Title" || item.ControlName == "Company" || item.ControlName == "Email" || item.ControlName == "Radio" || item.ControlName == "Checkbox") {
                                    tempTop = "top: " + String(parseFloat(top) + 2.0) + "px";
                                }
                                else {
                                    tempTop = "top: " + String(parseFloat(top) + 5.0) + "px";
                                }
                                top = tempTop;

                            }
                            catch (Exception) { }
                            item.CalculatedTop = top;
                        }
                    }
                });

                let envelopeImageControlData: any = {};
                envelopeImageControlData.Id = envelope.Id;
                envelopeImageControlData.PageNum = pageNumber;
                envelopeImageControlData.DocPageNo = DocPageNo;
                envelopeImageControlData.ImgControlWidth = imgControlWidth;
                envelopeImageControlData.ImagePath = imgPath;
                envelopeImageControlData.SigningEnvelopeDocumentData = signingEnvelopeDocumentData;
                envelopeImageControlData.ControlsData = controlsInfo.ControlsData.filter((c: any) => c.DocumentId == envelope.Document.Id && c.PageNo == envelope.PageNo);
                lstEnvelopeImageControlData.push(envelopeImageControlData);

                pageNo++;
            });
            apiResponseData.EnvelopeImageControlData = lstEnvelopeImageControlData;

            this.documentControlsResponse = apiResponseData;
            if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeImageControlData) {
                this.isFileReviewed = this.documentControlsResponse.EnvelopeInfo.IsReviewed;
                this.EnvelopeID = this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID;
                this.commonService.setDocumentData(this.documentControlsResponse.EnvelopeImageControlData);
                this.documentCheckListsResponse = this.documentControlsResponse.CheckListData;
                this.showDefaultSignatureContol = this.documentControlsResponse.ShowDefaultSignatureContol;
                this.defaultSignatureValue = this.translationsService.getLanguageValidationVal('DefaultSignature');
                if (this.documentControlsResponse.EnvelopeInfo.ControlsData && this.documentControlsResponse.EnvelopeInfo.ControlsData.length > 0) {
                    this.defaultSignatureObj = this.documentControlsResponse.EnvelopeInfo.ControlsData.filter((c: any) => c.ControlName === 'FooterSignature');
                }
                this.IsEnableClickToSign = this.documentControlsResponse.EnableClickToSign;
                this.IsStaticTemplate = this.isStaticTemplate(this.documentControlsResponse);
                this.IsSignerAttachFileReqNew = this.documentControlsResponse.EnvelopeInfo.IsSignerAttachFileReqNew;
                this.IsPrefill = this.documentControlsResponse.Prefill;
                this.DisableDownloadOptionOnSignersPage = this.documentControlsResponse.DisableDownloadOptionOnSignersPage;
                this.EnableMessageToMobile = this.documentControlsResponse.EnableMessageToMobile == true ? 1 : 0;
                this.DeliveryMode = this.documentControlsResponse.DeliveryMode;
                localStorage.setItem("StaticEnvelopId", this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID);
                localStorage.setItem("eDisplayCode", this.documentControlsResponse.EnvelopeInfo.EDisplayCode);
                localStorage.setItem('IsAdditionalAttmReq', this.documentControlsResponse.EnvelopeInfo.IsAdditionalAttmReq);
                localStorage.setItem("recipientTypeId", this.documentControlsResponse.EnvelopeInfo.RecipientTypeId);

                this.AllowSignersDownloadFinalContract = this.documentControlsResponse.IsAllowSignerstoDownloadFinalContract;
                localStorage.setItem("AllowSignersDownloadFinalContract", this.AllowSignersDownloadFinalContract == true ? "true" : "false");

                let envelopeAdditionalUploadInfoList: any;
                envelopeAdditionalUploadInfoList = this.documentControlsResponse.EnvelopeAdditionalUploadInfoList;
                if (this.documentControlsResponse.EnvelopeInfo.IsAdditionalAttmReq || (envelopeAdditionalUploadInfoList != null && envelopeAdditionalUploadInfoList.length > 0)) {
                    this.IsAdditionAttamRequest = true;
                }
                if (this.IsSignerAttachFileReqNew > 0 && this.IsPrefill != "prefill" && this.IsAdditionAttamRequest) {
                    this.ShowAttachmentNavMenu = true;
                }
                if (this.documentControlsResponse.EnvelopeInfo.IsSingleSigning == false) {
                    this.ShowFinishLaterNavMenu = true;
                    localStorage.setItem("ShowFinishLaterNavMenu", "true");
                }
                else {
                    localStorage.setItem("ShowFinishLaterNavMenu", "false");
                }
                if (Boolean(this.documentControlsResponse.EnvelopeInfo.IsTemplateShared) == false) {
                    this.ShowDeclineNavMenu = true;
                }
                if (this.IsSignerAttachFileReqNew > 0 && this.IsPrefill != "prefill" && (this.IsAdditionAttamRequest == true || (Boolean(this.documentControlsResponse.EnvelopeInfo.IsTemplateShared) == true))) {
                    this.ShowAttachmentCountDiv = true;
                }
                if (this.documentControlsResponse.FileReviewCount > 0) {
                    this.ShowFilesReviewCountDiv = true;
                }

                setTimeout(() => {
                    if (this.webOrMobile === 'mobile') {
                        this.hidePagesViewSection = false;
                    }

                    this.isTermsModelPopupExists();
                    this.showLoader = false;
                    this.commonService.updateDefaultValueOnDateControl();
                    this.updateDefaultValueOnEmailControl();
                    this.getPreloadControls();
                    this.getTotalRemaingFieldsControls('initial');
                    this.onFilterApply();

                    let footerSigSrc = this.document.getElementById("footerSigSrc");
                    let launguageSignImageName: string = this.translationsService.getLanguageValidationVal('lang_yourNameImg'); //this.documentControlsResponse.Language.filter((c: any) => c.KeyName == "lang_yourNameImg");

                    let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
                    if (footerSigSrc != null && footerSigSrc != undefined) {
                        footerSigSrc.setAttribute("src", defaultImage);
                    }

                    if (this.documentControlsResponse.EnvelopeInfo.IsDisclaimerEnabled === false && localStorage.getItem('prefill') !== 'prefill' &&
                        this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign === false) {
                        setTimeout(() => {
                            this.defaultControlShowTooltip();
                        }, 300);
                    }

                    this.showAndHideDecline = this.documentControlsResponse.DisableDeclineOption;
                    this.showAndHideFinishLater = this.documentControlsResponse.DisableFinishLaterOption;
                    this.showAndHideChangeSigner = this.documentControlsResponse.DisableChangeSigner;

                    this.getAttachmentList(this.documentControlsResponse);

                }, 50);
            }
            else {
                let actualSigningUrl: any = localStorage.getItem("actualSigningUrl");
                if (actualSigningUrl == null || actualSigningUrl == undefined) {
                    this.showLoader = false;
                    localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
                    localStorage.setItem("MessageType", "Warning");
                    this.commonService.getReturnUrlDirection(RouteUrls.Info);
                }
                else {
                    window.location.href = actualSigningUrl;
                }
            }
            localStorage.setItem("signatureInputText", "");
            localStorage.setItem("signatureText", "");
            localStorage.setItem("initialInputText", "");
            localStorage.setItem("initialText", "");

            let beforeRefreshValFrmLocalStorage: any = localStorage.getItem("beforeRefreshVal");
            if (beforeRefreshValFrmLocalStorage == "1") {
                this.isBrowserRefreshed = true;
            }
            else {
                this.isBrowserRefreshed = false;
                localStorage.setItem("beforeRefreshVal", "1");
            }
            this.showLoader = false;
        }

        this.imageLoaded(this.documentControlsResponse.EnvelopeImageControlData);
        this.cdRef.detectChanges();
    }
}

