import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SignerLandingService } from '../services/signer-landing.service';
import { CommonConstants, ResourceKeys } from '../constants/common-constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  laungaueTransalation = [];
  laungaueValidation: [] = [];
  constructor(private router: Router, private signerLandingService: SignerLandingService, private http: HttpClient) { }

  getLanguageTranslations(type: any, key: any) {
    if (sessionStorage.getItem('LaungaueTransalation') == null || sessionStorage.getItem('LanguageValidation') == null
      || sessionStorage.getItem('LaungaueTransalation') == undefined || sessionStorage.getItem('LanguageValidation') == undefined) {
      let culture: any = localStorage.getItem("cultureInfo"); 
      if (culture == null || culture == "" || culture == "null" || culture == "undefined" || culture == undefined) {
        culture = "en-us";
      }
      culture = culture.toLowerCase() + ".json";
      this.getLanguageJSON(culture).subscribe(data => {
        sessionStorage.setItem("LaungaueTransalation", JSON.stringify(data));
      });

      this.getLanguageValidationJSON(culture).subscribe(data => {
        sessionStorage.setItem("LanguageValidation", JSON.stringify(data));
      });
    }
  }

  public getLanguageJSON(cultureInfo: any): Observable<any> {
    return this.http.get("./assets/KeyIdDescription/" + cultureInfo);
  }

  public getLanguageValidationJSON(cultureInfo: any): Observable<any> {
    return this.http.get("./assets/KeyNameDescription/" + cultureInfo);
  }

  getLanguageTranslationVal(resourceKeyId: any) {
    let sessionTranslation: any = sessionStorage.getItem("LaungaueTransalation");
    this.laungaueTransalation = JSON.parse(sessionTranslation);    
    if (this.laungaueTransalation != null && resourceKeyId != undefined) {
      return this.laungaueTransalation[resourceKeyId.toLowerCase()];
    }
    else {
      if (resourceKeyId == ResourceKeys.LearnMoreTextOnly) {
        return CommonConstants.LearnMoreTextOnly;
      }
      else if (resourceKeyId == ResourceKeys.freeTrial) {
        return CommonConstants.FreeTrialText;
      }
      else if (resourceKeyId == ResourceKeys.RSignMakesESignatureText) {
        return CommonConstants.RSignMakesESignatureText;
      }
      else return "";
    }
  }

  getLanguageValidationVal(keyName: any) {
    if(keyName == "DropDown"){keyName = "Dropdown";}      
    if (this.laungaueValidation != null && this.laungaueValidation[keyName] != undefined) {
      return this.laungaueValidation[keyName];
    }
    else {
      let languageValidationArray: any = sessionStorage.getItem("LanguageValidation");
      this.laungaueValidation = JSON.parse(languageValidationArray);
      if (this.laungaueValidation != null) {
        return this.laungaueValidation[keyName];
      }
      else return "";
    }
  }

  getControlLanguageControlName(control: any) {
    return this.getLanguageValidationVal(control.ControlName);
  }
}
