import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ExpandableService {

    controlPositionDetailsArr:any[] = [];
    expandableCtrlNextTopValue:any;

    constructor(
        private toastr: ToastrService
    ) { }

    checkAutoExpand(event: any, ctrl: any, controlPositionDetailsArr:any) {
        //console.log('checkAutoExpand', event)

        this.controlPositionDetailsArr = controlPositionDetailsArr;
        if (event && (event.which !== 37 || event.key !== 'ArrowRight') && (event.which !== 38 || event.key !== 'ArrowUp') &&
        (event.which !== 39 || event.key !== 'ArrowLeft') && (event.which !== 40 || event.key !== 'ArrowDown')) {
            if (ctrl.ControlName === 'Name' || ctrl.ControlName === 'Title' || (ctrl.ControlName === 'Text' && (ctrl.TextType === 'Text' || ctrl.TextType === 'Alphabet' ||
                ctrl.TextType === 'Numeric' || ctrl.TextType === 'Email'))) {
                if (event && (event.which === 8 || event.key === 'Backspace')) {
                    this.resetControlAutoHeightAndWidth(ctrl);
                } else {
                    let myTextArea: any = document.getElementById('control_' + ctrl.ControlHtmlID);
                    let myTextAreaParent: any = document.getElementById(ctrl.ControlHtmlID);
                    let textAreaDefaultHeight: any = myTextAreaParent.style.height;
                    let textAreaDefaultWidth: any = myTextAreaParent.style.width;
                    let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);
                    let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);
                    let currentCtrDetails: any = document.getElementById(ctrl.ControlHtmlID);
                    let currentCtrDetailsStyleLeft: any = parseFloat(currentCtrDetails.style.left.split('px')[0]);
                    let currentCtrDetailsStyleTop: any = parseFloat(currentCtrDetails.style.top.split('px')[0]);
                    let docPage: any = parseInt(myTextAreaParent.getAttribute('data-page'));

                    let topPositionsArr: any = this.getWebTopPositions(docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, myTextWidth);
                    let leftPositionsArr: any = this.getWebLeftPositions(docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, myTextHeight);

                    // console.log('topPositionsArr----->>>>', topPositionsArr);
                    // console.log('leftPositionsArr----->>>>', leftPositionsArr);

                    //validating after paste the text ot number string
                    if (!this.isSingleKeyPress(event)) {
                        this.isNumberKey(event, ctrl.ControlHtmlID);
                    }

                    if (ctrl.IsFixedWidth === false) {
                        this.updateTextWidth(ctrl, leftPositionsArr, event);

                        if (topPositionsArr && topPositionsArr.length > 0) {
                            this.updateControlAutoHeight(ctrl, currentCtrDetailsStyleTop, topPositionsArr, event);
                        } else {
                            this.expandableCtrlNextTopValue = 0;
                            let imagePage: any = document.querySelector("#imgControl_" + ctrl.PageNo)!;
                            let imagePageHeight: any = imagePage.offsetHeight;
                            if ((parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (imagePageHeight - 10)) {
                                if (!this.isSingleKeyPress(event)) {
                                    // myTextArea.value = '';
                                    // myTextArea.style.height = textAreaDefaultHeight;
                                    // myTextArea.style.width = textAreaDefaultWidth;
                                    this.updateCopyAndPasteText(myTextArea, textAreaDefaultHeight, currentCtrDetailsStyleTop, (imagePageHeight - 10));
                                } else {
                                    myTextArea.style.height = (imagePageHeight - 15) - (parseFloat(currentCtrDetailsStyleTop)) + 'px';
                                    myTextArea.value = myTextArea.value.substring(0, myTextArea.value.length - 1);
                                }
                            } else {
                                if ((parseFloat(currentCtrDetailsStyleTop) + myTextHeight) <= (imagePageHeight - 10)) {
                                    myTextArea.style.height = textAreaDefaultHeight;
                                    if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                                        myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                                    }
                                }
                            }
                        }

                        let currentImageHeight: any = document.getElementById('imgControl_' + ctrl.PageNo)!
                        let parentHeight: any;
                        if(currentImageHeight){
                            parentHeight = currentImageHeight.clientHeight;
                        }
                        let outerHeight: any = myTextArea.style.height.split('px')[0];
                        let controlBottom: any = parseInt(parentHeight) - (currentCtrDetails.offsetTop + parseInt(outerHeight));

                        let image: any = document.querySelector("#imgControl_" + ctrl.PageNo)!;

                        const img = new Image();
                        let imgId: any = document.getElementById(image.id);
                        let imageUrl: any = imgId && imgId.children[0] && imgId.children[0].currentSrc;
                        img.onload = function () {
                            let originalWidth: any = img.naturalWidth;
                            let originalHeight: any = img.naturalHeight;
                            let imageWidth: any = currentImageHeight.children[0].width - 2;
                            let imageHeight: any = currentImageHeight.children[0].height - 3;
                            let ratioWidth: any = 1 / (imageWidth / originalWidth);
                            let ratioHeight: any = 1 / (imageHeight / originalHeight);
                            let ZCoordinate: any;
                            if ((controlBottom * ratioHeight) <= 0) {
                                ZCoordinate = 1;
                            } else {
                                ZCoordinate = (controlBottom * ratioHeight);
                            }
                            //console.log('ZCoordinate',ZCoordinate );
                            myTextArea.setAttribute('data-ZCoordinate', ZCoordinate);
                        };
                        img.src = imageUrl;
                    }
                }
            }
        }
    }

    updateControlAutoHeight(ctrl: any, currentCtrDetailsStyleTop: any, topPositions: any, event: any) {
        let myTextArea: any = document.getElementById('control_' + ctrl.ControlHtmlID);
        let myTextAreaParent: any = document.getElementById(ctrl.ControlHtmlID);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);

        let nextTopValue: any;
        if (topPositions.length > 0) {
            topPositions = topPositions.sort(function (a: any, b: any) {
                return a.top - b.top;
            });
            nextTopValue = topPositions[0].top;
        }
        this.expandableCtrlNextTopValue = nextTopValue;
        if (myTextArea.value.length === 0 || myTextArea.value === '') {
            myTextArea.style.height = textAreaDefaultHeight;
        }
        if (myTextArea.value.length && (parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (nextTopValue)) {
            if (!this.isSingleKeyPress(event)) {
                // myTextArea.value = '';
                // myTextArea.style.height = textAreaDefaultHeight;
                // myTextArea.style.width = textAreaDefaultWidth;

                this.updateCopyAndPasteText(myTextArea, textAreaDefaultHeight, currentCtrDetailsStyleTop, nextTopValue);
            } else {
                let getDataTextVal: any = myTextArea.getAttribute('data-textVal');
                if (getDataTextVal) {
                    myTextArea.value = myTextArea.getAttribute('data-textVal');
                } else {
                    myTextArea.value = myTextArea.value;
                }

            }
        } else {
            if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                if (parseFloat(currentCtrDetailsStyleTop) + myTextHeight < nextTopValue - 4) {
                    myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                }
            }
            myTextArea.setAttribute('data-textVal', myTextArea.value);
        }
    }

    updateTextWidth(ctrlObj: any, leftPositionsArr: any, event: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
        let myTextAreaParent: any = document.getElementById(ctrlObj.ControlHtmlID);
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);
        let myTextLength: any = myTextArea.value.length;
        let myTextMinLength: any = parseInt(myTextArea.getAttribute('data-maxlength')); //20;
        let currentCtrDetails: any = document.getElementById(ctrlObj.ControlHtmlID);
        let currentCtrDetailsStyleLeft: any = currentCtrDetails.style.left.split('px')[0];
        let imagePage: any = document.querySelector("#imgControl_" + ctrlObj.PageNo)!;
        let imagePageWidth: any = imagePage.offsetWidth;
        let nearestLeftValue: any;
        let nearestLeftMinusValue: any = 10;
        let imagePageWidthMinusValue: any = 15;
        let maxCharacterLimit: any = myTextArea.getAttribute('maxlength');
        if (maxCharacterLimit != null && maxCharacterLimit != '' && maxCharacterLimit != undefined) {
            maxCharacterLimit = parseInt(myTextArea.getAttribute('maxlength'));
            if (isNaN(parseInt(maxCharacterLimit))) {
                maxCharacterLimit = null;
            }
        }
        if (leftPositionsArr.length > 0) {
            leftPositionsArr = leftPositionsArr.sort(function (a: any, b: any) {
                return a.left - b.left;
            });
            nearestLeftValue = leftPositionsArr[0].left;
        }

        if (!this.isSingleKeyPress(event)) {
            if (myTextLength === 0 || myTextLength < myTextMinLength) {
                myTextWidth = textAreaDefaultWidth.split('px')[0];
            }
            if (myTextLength > myTextMinLength && leftPositionsArr.length > 0) {
                if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                    if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                        let widthArr: any = (myTextLength - myTextMinLength);
                        myTextWidth = parseInt(textAreaDefaultWidth.replace('px', ''));
                        for (var j = 0; j < widthArr; j++) {
                            if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                                myTextWidth += 8;
                            }
                        }
                    }
                }
            } else if (leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                    let widthArr: any = (myTextLength - myTextMinLength);
                    myTextWidth = parseInt(textAreaDefaultWidth.replace('px', ''));
                    for (var j = 0; j < widthArr; j++) {
                        if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                            myTextWidth += 8;
                        }
                    }
                }
            }
        } else {
            if (myTextLength > myTextMinLength && leftPositionsArr.length > 0) {
                if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                    myTextWidth += 8;
                }
            } else if (maxCharacterLimit === null && leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                myTextWidth += 8;
            } else if (leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && myTextLength <= maxCharacterLimit && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                if (myTextLength != maxCharacterLimit) {
                    myTextWidth += 8;
                }
            }
        }

        myTextArea.style.width = myTextWidth + 'px';
    }

    mobileUpdateControlAutoHeight(ctrlId: any, currentCtrDetailsStyleTop: any, sortArrayFirstTopVal: any, myTextAreaVal: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);

        setTimeout(() => {
            let nextTopValue: any;
            if (sortArrayFirstTopVal.length > 0) {
                sortArrayFirstTopVal = sortArrayFirstTopVal.sort(function (a: any, b: any) {
                    return a.top - b.top;
                });
                nextTopValue = sortArrayFirstTopVal[0].top;
            }

            if (myTextAreaVal.length === 0 || myTextAreaVal === '') {
                myTextArea.style.height = textAreaDefaultHeight;
                myTextArea.style.width = textAreaDefaultWidth;
            } else {
                //creating temporary textarea ele and adding same style properties for current control
                // using for loop to assign each character to temporary textarea and removing the temporary string once updating to current control
                let myTempStr: any = myTextAreaVal;
                let myTempStrArray: any = myTempStr.split('');
                let measurementTextAreaEle: any = document.createElement('textarea');
                measurementTextAreaEle.style.visibility = 'hidden';
                measurementTextAreaEle.style.width = myTextArea.style.width;
                measurementTextAreaEle.style.height = myTextArea.style.height;
                measurementTextAreaEle.style.position = 'absolute';
                measurementTextAreaEle.setAttribute('id', 'tempMeasurementCtrl');
                measurementTextAreaEle.style.overflow = myTextArea.style.overflow;
                measurementTextAreaEle.style.resize = myTextArea.style.resize;
                measurementTextAreaEle.style.fontSize = myTextArea.style.fontSize;
                measurementTextAreaEle.style.fontFamily = myTextArea.style.fontFamily;
                measurementTextAreaEle.style.fontWeight = myTextArea.style.fontWeight;
                measurementTextAreaEle.style.fontStyle = myTextArea.style.fontStyle;
                measurementTextAreaEle.style.textDecoration = myTextArea.style.textDecoration;
                measurementTextAreaEle.style.color = myTextArea.style.color;
                measurementTextAreaEle.style.padding = myTextArea.style.padding;
                measurementTextAreaEle.style.boxSizing = myTextArea.style.boxSizing;
                measurementTextAreaEle.style.border = myTextArea.style.border;
                measurementTextAreaEle.style.lineHeight = myTextArea.style.lineHeight;
                document.body.appendChild(measurementTextAreaEle);
                let measurementId: any = document.getElementById('tempMeasurementCtrl');
                if (myTextArea && (parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (nextTopValue)) {
                    myTextArea.style.height = (nextTopValue - 1) - (parseFloat(currentCtrDetailsStyleTop)) + 'px';
                } else {
                    if (myTextArea && myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                        if (parseFloat(currentCtrDetailsStyleTop) + myTextHeight < nextTopValue - 10) {
                            myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                        }
                    }
                }
                if (myTempStrArray && myTempStrArray.length && measurementId && myTextArea && myTextArea.style && myTextArea.style.height) {
                    for (var j = 0; j < myTempStrArray.length; j++) {
                        if ((measurementId.scrollHeight) <= myTextArea.style.height.split('px')[0]) {
                            measurementId.textContent += myTempStrArray[j];
                        } else {
                            if (measurementId && measurementId.textContent) {
                                measurementId.textContent = measurementId.textContent.substring(0, measurementId.textContent.length - 1);
                            } else {
                                measurementId.textContent = myTextAreaVal;
                            }
                            break;
                        }
                    }
                }
                myTextArea.value = measurementId.textContent;
                document.body.removeChild(measurementTextAreaEle);
            }
        }, 500);
    }

    mobileUpdateTextWidth(ctrlId: any, leftPositionsArr: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextLength: any = myTextArea.value.length;
        let myTextMinLength: any = myTextArea.getAttribute('data-maxlength');
        let currentCtrDetails: any = document.getElementById(ctrlId);
        let currentCtrDetailsStyleLeft: any = currentCtrDetails.style.left.split('px')[0];
        let docPage: any = parseInt(myTextAreaParent.getAttribute('data-page'));
        let checkMaxLength: any = myTextArea.getAttribute('maxlength');
        let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
        let imagePageWidth: any = imagePage.style.width.split('px')[0];
        let nearestLeftValue: any;
        let nearestLeftMinusValue: any = 12;
        let imagePageWidthMinusValue: any = 10;
        let maxCharacterLimit: any = myTextArea.getAttribute('maxlength');
        if (maxCharacterLimit != null && maxCharacterLimit != '' && maxCharacterLimit != undefined) {
            maxCharacterLimit = parseInt(myTextArea.getAttribute('maxlength'));
            if (isNaN(parseInt(maxCharacterLimit))) {
                maxCharacterLimit = null;
            }
        }

        if (leftPositionsArr.length > 0) {
            leftPositionsArr = leftPositionsArr.sort(function (a: any, b: any) {
                return a.left - b.left;
            });
            nearestLeftValue = leftPositionsArr[0].left;
        }
        myTextWidth = 0;
        if (myTextLength > myTextMinLength && leftPositionsArr.length > 0) {
            if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                    let widthArr: any = myTextLength;
                    for (var j = 0; j < widthArr; j++) {
                        if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                            myTextWidth += 8;
                        }
                    }
                }
            }
        } else if (leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
            if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                let widthArr: any = myTextLength;
                for (var j = 0; j < widthArr; j++) {
                    if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                        myTextWidth += 8;
                    }
                }
            }
        } else {
            myTextWidth = textAreaDefaultWidth.split('px')[0];
        }

        myTextArea.style.width = myTextWidth + 'px';
    }

    showToaster(msg: string, errorType: any) {
        if (errorType === "Error") {
            this.toastr.error(msg, errorType, { timeOut: 2000 });
        }
        else if (errorType === "Warning") {
            this.toastr.warning(msg, errorType, { timeOut: 2000 });
        }
        else if (errorType === "Success") {
            this.toastr.success(msg, errorType, { timeOut: 2000 });
        }
        else if (errorType === "Attention") {
            this.toastr.info(msg, errorType, { timeOut: 2000 });
        }
    }

    isNumberKey(evt: any, ctr: any) {
        var regExp = /[a-zA-Z ]/;
        var regExpNumber = /[0-9]/;
        var regExpComma = /^[0-9,]+$/;
        var regExpPeriod = /^[0-9.]+$/;
        let controlId: any = document.getElementById('control_' + ctr)!;
        let cname: any = controlId.getAttribute('data-title');
        if (cname == "SSN" || cname == "Zip") {
            controlId.removeAttribute('maxlength');
            var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            if (regExpNumber.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Please enter a number.', 'Warning');
                }
                return false;
            }
        }
        if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') === '') {
            var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            if (regExpNumber.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                //if user copy paste the text string
                //validating each chracter if it is text then reset to empty
                if (!this.isSingleKeyPress(evt)) {
                    if (controlId && controlId.value) {
                        let arrstr: any = controlId.value.split('');
                        for (var i = 0; i < arrstr.length; i++) {
                            if (!regExpNumber.test(arrstr[i])) {
                                controlId.value = '';
                                if (evt.charCode !== 13) {
                                    this.showToaster('Please enter a number.', 'Warning');
                                }
                                break;
                            }
                        }
                    }
                } else {
                    if (evt.charCode !== 13) {
                        this.showToaster('Please enter a number.', 'Warning');
                    }
                }
                return false;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Comma') {
            var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            var asciiCode = (evt.which) ? evt.which : evt.keyCode;

            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split(',') : '';
            if (number.length > 1 && asciiCode == 44) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Only one comma allowed.', 'Warning');
                }
                return false;
            }
            if (regExpComma.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Numeric 0 to 9 only and comma.', 'Warning');
                }
                return false;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Both') {
            var charCodeValue = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split('.') : [];
            if (number.length > 1 && charCode == 46) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Only one decimal allowed.', 'Warning');
                }
                return false;
            } else if (number[1] && number[1].length > 1) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Only two digits allowed after decimal.', 'Warning');
                }
                return false;
            }
            if (regExpComma.test(charCodeValue) || regExpPeriod.test(charCodeValue)) {
                return true;
            } else {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Numeric 0 to 9 only with comma and decimal.', 'Warning');
                }
                return false;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Period') {
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split('.') : [];
            if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Numeric 0 to 9 only and Period.', 'Warning');
                }
                return false;
            }
            if (number.length > 1 && charCode == 46) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Only one decimal allowed.', 'Warning');
                }
                return false;
            }
            return;
        }
        else if (controlId.getAttribute('data-title') === 'Alphabet') {
            var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            var controlHeight = document.getElementById(controlId.getAttribute('id')!.replace("control_", ""))!.style.height;
            if (evt.charCode == 13) {
                let calcontrolHeight = controlHeight.replace('px', '');
                if (parseInt(calcontrolHeight) <= 20) {
                    if (controlId.value == '' || controlId.value == null) {
                        evt.preventDefault();
                    }

                    if (evt.charCode !== 13) {
                        this.showToaster('Enter not allowed.', 'Warning');
                    }
                    return false;
                }
                return true;
            }
            else if (regExp.test(charCode) == false) {
                evt.preventDefault();
                //if user copy paste the number string
                //validating each characters if it is number then reset to empty
                if (!this.isSingleKeyPress(evt)) {
                    if (controlId && controlId.value) {
                        let arrstr: any = controlId.value.split('');
                        for (var i = 0; i < arrstr.length; i++) {
                            if (!regExp.test(arrstr[i])) {
                                controlId.value = '';
                                if (evt.charCode !== 13) {
                                    this.showToaster('Characters only.', 'Warning');
                                }
                                break;
                            }
                        }
                    }
                } else {
                    if (evt.charCode !== 13) {
                        this.showToaster('Characters only.', 'Warning');
                    }
                }
                return false;
            }
            return true;
        }
        else if (controlId.getAttribute('data-title') === 'Text') {
            var controlHeight = document.getElementById(controlId.getAttribute('id')!.replace("control_", ""))!.style.height;
            if (evt.charCode == 13) {
                let calcontrolHeight = controlHeight.replace('px', '');
                if (parseInt(calcontrolHeight) <= 20) {
                    if (controlId.value == '' || controlId.value == null) {
                        evt.preventDefault();
                    }
                    if (evt.charCode !== 13) {
                        this.showToaster('Enter not allowed.', 'Warning');
                    }
                    return false;
                }
            }
            return true;
        }
        else {
            return true;
        }
    }

    //function for to get ZCoordinate after expand control
    updateZCoordinate(docPage: any, myTextArea: any, currentCtrDetails: any) {
        let currentImageHeight: any = document.getElementById('imgControl_' + docPage)!
        let parentHeight: any;
        if(currentImageHeight){
            parentHeight = currentImageHeight.clientHeight;
        }
        let outerHeight: any = myTextArea.style.height.split('px')[0];
        let controlBottom: any = parseInt(parentHeight) - (currentCtrDetails.offsetTop + parseInt(outerHeight));

        let image: any = document.querySelector("#imgControl_" + docPage)!;

        const img = new Image();
        let imgId: any = document.getElementById(image.id);
        let imageUrl: any = imgId && imgId.children[0] && imgId.children[0].currentSrc;
        img.onload = function () {
            let originalWidth: any = img.naturalWidth;
            let originalHeight: any = img.naturalHeight;
            let imageWidth: any = currentImageHeight.children[0].width - 2;
            let imageHeight: any = currentImageHeight.children[0].height - 3;
            let ratioWidth: any = 1 / (imageWidth / originalWidth);
            let ratioHeight: any = 1 / (imageHeight / originalHeight);
            let ZCoordinate: any;
            if ((controlBottom * ratioHeight) <= 0) {
                ZCoordinate = 1;
            } else {
                ZCoordinate = (controlBottom * ratioHeight);
            }
            //console.log('ZCoordinate',ZCoordinate );
            myTextArea.setAttribute('data-ZCoordinate', ZCoordinate);
        };
        img.src = imageUrl;
    }

    //checking expandable is applicable or not for similar text fields
    updateAutoExpand(controlId: any) {
        let myTextArea: any = document.getElementById('control_' + controlId);
        let myTextAreaParent: any = document.getElementById(controlId);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);
        let currentCtrDetails: any = document.getElementById(controlId);
        let docPage: any = parseInt(myTextAreaParent.getAttribute('data-page'));
        let currentCtrDetailsStyleLeft: any = parseFloat(currentCtrDetails.style.left.split('px')[0]);
        let currentCtrDetailsStyleTop: any = parseFloat(currentCtrDetails.style.top.split('px')[0]);

        let topPositionsArr: any = this.getWebTopPositions(docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, myTextWidth);
        let leftPositionsArr: any = this.getWebLeftPositions(docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, myTextHeight);

        let isFixedWidth: any = myTextArea.getAttribute('data-isfixed-width');
        if (isFixedWidth === false || isFixedWidth === 'false') {
            let myTextLength: any = myTextArea.value.length;
            let myTextMinLength: any = parseInt(myTextArea.getAttribute('data-maxlength')); //20;
            let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
            let imagePageWidth: any = imagePage.offsetWidth;
            let nearestLeftValue: any;
            let nearestLeftMinusValue: any = 10;
            let imagePageWidthMinusValue: any = 15;
            let maxCharacterLimit: any = myTextArea.getAttribute('maxlength');
            if (maxCharacterLimit != null && maxCharacterLimit != '' && maxCharacterLimit != undefined) {
                maxCharacterLimit = parseInt(myTextArea.getAttribute('maxlength'));
                if (isNaN(parseInt(maxCharacterLimit))) {
                    maxCharacterLimit = null;
                }
            }
            if (leftPositionsArr.length > 0) {
                leftPositionsArr = leftPositionsArr.sort(function (a: any, b: any) {
                    return a.left - b.left;
                });
                nearestLeftValue = leftPositionsArr[0].left;
            }

            if (myTextLength > myTextMinLength && leftPositionsArr.length > 0) {
                if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                    if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                        let widthArr: any = (myTextLength - myTextMinLength);
                        myTextWidth = parseInt(textAreaDefaultWidth.replace('px', ''));
                        for (var j = 0; j < widthArr; j++) {
                            if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                                myTextWidth += 8;
                            }
                        }
                    }
                }
            } else if (leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                this.updateSimilarTextFieldsText(myTextArea, maxCharacterLimit);
                if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                    let widthArr: any = (myTextArea.value.length - myTextMinLength);
                    myTextWidth = parseInt(textAreaDefaultWidth.replace('px', ''));
                    for (var j = 0; j < widthArr; j++) {
                        if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                            myTextWidth += 8;
                        }
                    }
                }
            }

            myTextArea.style.width = myTextWidth + 'px';

            //checking height
            if (topPositionsArr && topPositionsArr.length > 0) {
                setTimeout(() => {
                    let nextTopValue: any;
                    if (topPositionsArr.length > 0) {
                        topPositionsArr = topPositionsArr.sort(function (a: any, b: any) {
                            return a.top - b.top;
                        });
                        nextTopValue = topPositionsArr[0].top;
                    }
                    this.expandableCtrlNextTopValue = nextTopValue;
                    if (myTextArea.value.length === 0 || myTextArea.value === '') {
                        myTextArea.style.height = textAreaDefaultHeight;
                    }
                    if (myTextArea.value.length && (parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (nextTopValue)) {
                        this.updateCopyAndPasteText(myTextArea, textAreaDefaultHeight, currentCtrDetailsStyleTop, nextTopValue);
                    } else {
                        if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                            if (parseFloat(currentCtrDetailsStyleTop) + myTextHeight < nextTopValue - 4) {
                                myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                            }
                        }
                        myTextArea.setAttribute('data-textVal', myTextArea.value);
                    }
                }, 100);
            } else {
                this.expandableCtrlNextTopValue = 0;
                let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
                let imagePageHeight: any = imagePage.offsetHeight;
                if ((parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (imagePageHeight - 10)) {
                    this.updateCopyAndPasteText(myTextArea, textAreaDefaultHeight, currentCtrDetailsStyleTop, (imagePageHeight - 10));
                } else {
                    if ((parseFloat(currentCtrDetailsStyleTop) + myTextHeight) <= (imagePageHeight - 10)) {
                        myTextArea.style.height = textAreaDefaultHeight;
                        if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                            myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                        }
                    }
                }
            }

            this.updateZCoordinate(docPage, myTextArea, currentCtrDetails);

        }
    }

    updateCopyAndPasteText(myTextArea: any, textAreaDefaultHeight: any, currentCtrDetailsStyleTop: any, nextTopValue: any) {
        let charactersArr: any = myTextArea.value.split('');
        const dummyTextarea = document.createElement('textarea');
        dummyTextarea.value = '';
        dummyTextarea.classList.add('textsignerControl');
        document.body.appendChild(dummyTextarea);

        let scrollHeight: any = parseInt(textAreaDefaultHeight.split('px')[0]);
        for (let i = 0; i < charactersArr.length - 1; i++) {
            // Add a character
            dummyTextarea.style.width = myTextArea.style.width;
            dummyTextarea.style.height = myTextArea.style.height;
            dummyTextarea.style.fontFamily = myTextArea.style.fontFamily;
            dummyTextarea.style.fontSize = myTextArea.style.fontSize;
            dummyTextarea.style.fontWeight = myTextArea.style.fontWeight;
            dummyTextarea.style.fontStyle = myTextArea.style.fontStyle;
            dummyTextarea.style.overflow = myTextArea.style.overflow;
            dummyTextarea.style.textDecoration = myTextArea.style.textDecoration;
            dummyTextarea.style.boxSizing = myTextArea.style.boxSizing;
            dummyTextarea.style.resize = myTextArea.style.resize;
            dummyTextarea.style.lineHeight = myTextArea.style.lineHeight;
            dummyTextarea.style.visibility = 'hidden';
            dummyTextarea.style.border = myTextArea.style.border;
            dummyTextarea.style.padding = myTextArea.style.padding;
            dummyTextarea.style.outline = myTextArea.style.outline;

            if (dummyTextarea.value !== '') {
                scrollHeight = dummyTextarea.scrollHeight;
            }
            if ((parseFloat(currentCtrDetailsStyleTop) + scrollHeight) < nextTopValue) {
                dummyTextarea.value += charactersArr[i];
                myTextArea.setAttribute('data-textVal', dummyTextarea.value);
                myTextArea.value = dummyTextarea.value;
                myTextArea.style.height = (scrollHeight) + 'px';
            } else {
                let getDataTextVal: any = myTextArea.getAttribute('data-textVal');
                if (getDataTextVal) {
                    let str: any = myTextArea.getAttribute('data-textVal');
                    myTextArea.value = str.substring(0, str.length - 1);
                } else {
                    myTextArea.value = myTextArea.value;
                }
            }
        }

        document.body.removeChild(dummyTextarea);
    }

    mobileResetControlAutoHeightAndWidth(ctrlId: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextMinLength: any = myTextArea.getAttribute('data-maxlength');//20;

        if (myTextArea.style.height !== 'auto') {
            myTextArea.style.height = textAreaDefaultHeight;
            if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
            }
        }

        if (myTextArea && (myTextArea.value === '' || myTextArea.value.length === 0)) {
            myTextArea.style.width = textAreaDefaultWidth;
        } else if (myTextArea && myTextArea.value.length < parseInt(myTextMinLength)) {
            myTextArea.style.width = textAreaDefaultWidth;
            myTextArea.style.height = textAreaDefaultHeight;
        }
    }

    resetControlAutoHeightAndWidth(ctrl: any) {
        let myTextArea: any = document.getElementById('control_' + ctrl.ControlHtmlID);
        let myTextAreaParent: any = document.getElementById(ctrl.ControlHtmlID);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextMinLength: any = myTextArea.getAttribute('data-maxlength'); //20;

        if (myTextArea.style.height !== 'auto') {
            myTextArea.style.height = textAreaDefaultHeight;
            if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
            }
        }

        if (myTextArea && (myTextArea.value === '' || myTextArea.value.length === 0)) {
            myTextArea.style.width = textAreaDefaultWidth;
        } else if (myTextArea && myTextArea.value.length < parseInt(myTextMinLength)) {
            myTextArea.style.width = textAreaDefaultWidth;
            myTextArea.style.height = textAreaDefaultHeight;
        }

        myTextArea.setAttribute('data-textVal', myTextArea.value);
    }

    //Updating maxCharacterLimit of text field when similar text fields identical
    updateSimilarTextFieldsText(myTextArea:any, maxCharacterLimit:any){
        let charactersArr: any = myTextArea.value.split('');
        const dummyTextarea = document.createElement('textarea');
        dummyTextarea.value = '';
        dummyTextarea.classList.add('textsignerControl');
        document.body.appendChild(dummyTextarea);

        for (let i = 0; i < charactersArr.length; i++) {
            // Add a character
            dummyTextarea.style.width = myTextArea.style.width;
            dummyTextarea.style.height = myTextArea.style.height;
            dummyTextarea.style.fontFamily = myTextArea.style.fontFamily;
            dummyTextarea.style.fontSize = myTextArea.style.fontSize;
            dummyTextarea.style.fontWeight = myTextArea.style.fontWeight;
            dummyTextarea.style.fontStyle = myTextArea.style.fontStyle;
            dummyTextarea.style.overflow = myTextArea.style.overflow;
            dummyTextarea.style.textDecoration = myTextArea.style.textDecoration;
            dummyTextarea.style.boxSizing = myTextArea.style.boxSizing;
            dummyTextarea.style.resize = myTextArea.style.resize;
            dummyTextarea.style.lineHeight = myTextArea.style.lineHeight;
            dummyTextarea.style.visibility = 'hidden';
            dummyTextarea.style.border = myTextArea.style.border;
            dummyTextarea.style.padding = myTextArea.style.padding;
            dummyTextarea.style.outline = myTextArea.style.outline;

            if(i < maxCharacterLimit){
                dummyTextarea.value += charactersArr[i];
                myTextArea.setAttribute('data-textVal', dummyTextarea.value);
                myTextArea.value = dummyTextarea.value;
            }
        }

        document.body.removeChild(dummyTextarea);
    }

    getWebLeftPositions(docPage: any, currentCtrDetailsStyleLeft: any, currentCtrDetailsStyleTop: any, myTextHeight: any) {
        let leftTempPositionsArr: any = [];
        for (var j = 0; j < this.controlPositionDetailsArr.length; j++) {
            if (this.controlPositionDetailsArr[j].docPage === docPage) {
                let ele: any = document.getElementById(this.controlPositionDetailsArr[j].controlId);
                let height: any = 0;
                let width: any = 0;
                if (ele) {
                    if (ele && ele.style && (ele.style.width === 'auto' || ele.style.width === '')) {
                        width = this.controlPositionDetailsArr[j].width;
                    } else if (ele && ele.style && (ele.style.width === '100%')) {
                        let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                        if (parentEle && parentEle.style && parentEle.style.width) {
                            width = parseFloat(parentEle.style.width.split('px')[0]);
                        } else {
                            width = this.controlPositionDetailsArr[j].width;
                        }
                    } else {
                        width = parseFloat(ele.style.width.split('px')[0]);
                    }
                    if (ele.style.height === '100%' || ele.style.height === 'auto' || ele.style.height === '') {
                        height = this.controlPositionDetailsArr[j].height;
                    } else {
                        height = parseFloat(ele.style.height.split('px')[0]);
                    }
                }
                else if (ele && ele.style && (ele.style.height === 'auto' || ele.style.height === '')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else if (ele && ele.style && ele.style.height === '100%') {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else {
                    width = this.controlPositionDetailsArr[j].width;
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (this.controlPositionDetailsArr[j].controlName === 'Signature' || this.controlPositionDetailsArr[j].controlName === 'NewInitials') {
                    height = this.controlPositionDetailsArr[j].height;
                }

                if (currentCtrDetailsStyleTop < (this.controlPositionDetailsArr[j].top + (height - 4)) &&
                    (currentCtrDetailsStyleTop + myTextHeight) > this.controlPositionDetailsArr[j].top) {
                    if ((parseFloat(currentCtrDetailsStyleLeft.toFixed(3)) !== parseFloat(this.controlPositionDetailsArr[j].left.toFixed(3)) &&
                        parseFloat(currentCtrDetailsStyleTop) !== this.controlPositionDetailsArr[j].top) || ((parseFloat(currentCtrDetailsStyleLeft) !== this.controlPositionDetailsArr[j].left) && parseFloat(currentCtrDetailsStyleTop) === this.controlPositionDetailsArr[j].top)) {
                        if (parseFloat(this.controlPositionDetailsArr[j].left) + width > parseFloat(currentCtrDetailsStyleLeft)) {
                            //console.log('leftArr',this.controlPositionDetailsArr[j]);
                            leftTempPositionsArr.push(this.controlPositionDetailsArr[j]);
                        }
                    }
                }
            }
        }
        return leftTempPositionsArr;
    }

    getWebTopPositions(docPage: any, currentCtrDetailsStyleLeft: any, currentCtrDetailsStyleTop: any, myTextWidth: any) {
        let topTempPositionsArr: any = [];
        for (var j = 0; j < this.controlPositionDetailsArr.length; j++) {
            if (this.controlPositionDetailsArr[j].docPage === docPage) {
                let ele: any = document.getElementById(this.controlPositionDetailsArr[j].controlId);
                let height: any = 0;
                let width: any = 0;
                if (ele) {
                    if (ele && ele.style && (ele.style.width === 'auto' || ele.style.width === '')) {
                        width = this.controlPositionDetailsArr[j].width;
                    } else if (ele && ele.style && (ele.style.width === '100%')) {
                        let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                        if (parentEle && parentEle.style && parentEle.style.width) {
                            width = parseFloat(parentEle.style.width.split('px')[0]);
                        } else {
                            width = this.controlPositionDetailsArr[j].width;
                        }
                    } else {
                        width = parseFloat(ele.style.width.split('px')[0]);
                    }

                    if (ele.style.height === '100%' || ele.style.height === 'auto' || ele.style.height === '') {
                        height = this.controlPositionDetailsArr[j].height;
                    } else {
                        height = parseFloat(ele.style.height.split('px')[0]);
                    }
                }
                else if (ele && ele.style && (ele.style.height === 'auto' || ele.style.height === '')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else if (ele && ele.style && (ele.style.height === '100%')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else {
                    width = this.controlPositionDetailsArr[j].width;
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (this.controlPositionDetailsArr[j].controlName === 'Signature' || this.controlPositionDetailsArr[j].controlName === 'NewInitials') {
                    height = this.controlPositionDetailsArr[j].height;
                }
                //if comparing current control left and all array controls left value is same so that we added plus 1 for left value controls
                // add 4px, both controls placed near side by side and comparing the prev control left and width we reduce the some with of previous controls
                if (currentCtrDetailsStyleLeft < (this.controlPositionDetailsArr[j].left + 1) + (width - 4) &&
                    (currentCtrDetailsStyleLeft + myTextWidth) > this.controlPositionDetailsArr[j].left) {
                    if (parseFloat(currentCtrDetailsStyleTop.toFixed(3)) !== parseFloat(this.controlPositionDetailsArr[j].top.toFixed(3))) {
                        if (((this.controlPositionDetailsArr[j].top + (height - 4))) > parseFloat(currentCtrDetailsStyleTop)) {
                            //console.log('topArr',this.controlPositionDetailsArr[j]);
                            topTempPositionsArr.push(this.controlPositionDetailsArr[j]);
                        }
                    }
                }

            }
        }
        return topTempPositionsArr;
    }

    getTopPosition(ctrlId: any, docPage: any, currentCtrDetailsStyleLeft: any, currentCtrDetailsStyleTop: any, myTextAreaVal: any) {
        let topPositionsArr: any = [];
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);
        let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);

        for (var j = 0; j < this.controlPositionDetailsArr.length; j++) {
            if (this.controlPositionDetailsArr[j].docPage === docPage) {
                let ele: any = document.getElementById(this.controlPositionDetailsArr[j].controlId);
                let height: any = 0;
                let width: any = 0;
                if (ele) {
                    if (ele && ele.style && (ele.style.width === 'auto' || ele.style.width === '')) {
                        width = this.controlPositionDetailsArr[j].width;
                    } else if (ele && ele.style && (ele.style.width === '100%')) {
                        let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                        if (parentEle && parentEle.style && parentEle.style.width) {
                            width = parseFloat(parentEle.style.width.split('px')[0]);
                        } else {
                            width = this.controlPositionDetailsArr[j].width;
                        }
                    } else {
                        width = parseFloat(ele.style.width.split('px')[0]);
                    }

                    if (ele.style.height === '100%' || ele.style.height === 'auto' || ele.style.height === '') {
                        height = this.controlPositionDetailsArr[j].height;
                    } else {
                        height = parseFloat(ele.style.height.split('px')[0]);
                    }
                }
                else if (ele && ele.style && (ele.style.height === 'auto' || ele.style.height === '')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                }
                else if (ele && ele.style && (ele.style.height === '100%')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else {
                    width = this.controlPositionDetailsArr[j].width;
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (this.controlPositionDetailsArr[j].controlName === 'Signature' || this.controlPositionDetailsArr[j].controlName === 'NewInitials') {
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (currentCtrDetailsStyleLeft < (this.controlPositionDetailsArr[j].left + 1) + (width - 4) &&
                    (currentCtrDetailsStyleLeft + myTextWidth) > this.controlPositionDetailsArr[j].left) {
                    if (parseFloat(currentCtrDetailsStyleTop) !== this.controlPositionDetailsArr[j].top) {
                        if (((this.controlPositionDetailsArr[j].top + (height - 4))) > parseFloat(currentCtrDetailsStyleTop)) {
                            //console.log('topArr',this.controlPositionDetailsArr[j]);
                            topPositionsArr.push(this.controlPositionDetailsArr[j]);
                        }
                    }
                }

            }
        }

        // console.log(' mobile topPositionsArr----->>>>', topPositionsArr);
        if (topPositionsArr && topPositionsArr.length > 0) {
            this.mobileUpdateControlAutoHeight(ctrlId, currentCtrDetailsStyleTop, topPositionsArr, myTextAreaVal);
        } else {
            let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
            let imagePageHeight: any;
            // imagePageHeight = 1186;
            let imgId: any = document.getElementById(imagePage.id);
            let imageUrl: any = imgId && imgId.children[0] && imgId.children[0].currentSrc;
            this.getImagePageHeight(imageUrl, myTextArea, currentCtrDetailsStyleTop, myTextHeight, textAreaDefaultHeight);
        }
    }

    getLeftPosition(ctrlId: any, docPage: any, currentCtrDetailsStyleLeft: any, currentCtrDetailsStyleTop: any) {
        let leftPositionsArr: any = [];
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);

        for (var j = 0; j < this.controlPositionDetailsArr.length; j++) {
            if (this.controlPositionDetailsArr[j].docPage === docPage) {
                let ele: any = document.getElementById(this.controlPositionDetailsArr[j].controlId);
                let height: any = 0;
                let width: any = 0;
                if (ele) {
                    if (ele && ele.style && (ele.style.width === 'auto' || ele.style.width === '')) {
                        width = this.controlPositionDetailsArr[j].width;
                    } else if (ele && ele.style && (ele.style.width === '100%')) {
                        let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                        if (parentEle && parentEle.style && parentEle.style.width) {
                            width = parseFloat(parentEle.style.width.split('px')[0]);
                        } else {
                            width = this.controlPositionDetailsArr[j].width;
                        }
                    } else {
                        width = parseFloat(ele.style.width.split('px')[0]);
                    }

                    if (ele.style.height === '100%' || ele.style.height === 'auto' || ele.style.height === '') {
                        height = this.controlPositionDetailsArr[j].height;
                    } else {
                        height = parseFloat(ele.style.height.split('px')[0]);
                    }
                }
                else if (ele && ele.style && (ele.style.height === 'auto' || ele.style.height === '')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                }
                else if (ele && ele.style && ele.style.height === '100%') {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else {
                    width = this.controlPositionDetailsArr[j].width;
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (this.controlPositionDetailsArr[j].controlName === 'Signature' || this.controlPositionDetailsArr[j].controlName === 'NewInitials') {
                    height = this.controlPositionDetailsArr[j].height;
                }

                if (currentCtrDetailsStyleTop < (this.controlPositionDetailsArr[j].top + (height - 4)) &&
                    (currentCtrDetailsStyleTop + myTextHeight) > this.controlPositionDetailsArr[j].top) {
                    if ((parseFloat(currentCtrDetailsStyleLeft) !== this.controlPositionDetailsArr[j].left &&
                        parseFloat(currentCtrDetailsStyleTop) !== this.controlPositionDetailsArr[j].top) || ((parseFloat(currentCtrDetailsStyleLeft) !== this.controlPositionDetailsArr[j].left) && parseFloat(currentCtrDetailsStyleTop) === this.controlPositionDetailsArr[j].top)) {
                        if (this.controlPositionDetailsArr[j].left + width > parseFloat(currentCtrDetailsStyleLeft)) {
                            //console.log('leftArr',this.controlPositionDetailsArr[j]);
                            leftPositionsArr.push(this.controlPositionDetailsArr[j]);
                        }
                    }
                }
            }
        }

        console.log('mobile leftPositionsArr----->>>>', leftPositionsArr);
        this.mobileUpdateTextWidth(ctrlId, leftPositionsArr);
    }

    getImagePageHeight(imageUrl: any, myTextArea: any, currentCtrDetailsStyleTop: any, myTextHeight: any, textAreaDefaultHeight: any) {
        const img = new Image();
        img.onload = function () {
            //console.log('img.height',img.height);
            let imagePageHeight: any = img.height - 48;
            if ((parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (imagePageHeight - 10)) {
                myTextArea.style.maxHeight = (imagePageHeight - 15) - (parseFloat(currentCtrDetailsStyleTop)) + 'px';
            }
            if ((parseFloat(currentCtrDetailsStyleTop) + myTextHeight) <= (imagePageHeight - 10)) {
                myTextArea.style.height = textAreaDefaultHeight;
                if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                    myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                }
            }
        };
        img.src = imageUrl;
    }

    updateExpandControlIfApplicable(ctrlId: any, text: any, controlPositionDetailsArr:any) {
        this.controlPositionDetailsArr = controlPositionDetailsArr;
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let popupTextValue: any = myTextArea.value;
        let myTextLength: any = myTextArea.value.length;
        let myTextMinLength: any = myTextArea.getAttribute('data-maxlength');
        let docPage: any = parseInt(myTextAreaParent.getAttribute('data-page'));
        let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
        let imagePageWidth: any = imagePage.style.width.split('px')[0];
        let isFixedWidth: any = null;
        if (myTextArea.getAttribute('data-isfixed-width') && myTextArea.getAttribute('data-isfixed-width')) {
            isFixedWidth = myTextArea.getAttribute('data-isfixed-width');
        }

        let currentCtrDetails: any = document.getElementById(ctrlId);
        let currentCtrDetailsStyleLeft: any = parseFloat(currentCtrDetails.style.left.split('px')[0]);
        let currentCtrDetailsStyleTop: any = parseFloat(currentCtrDetails.style.top.split('px')[0]);

        if (text === '' || (myTextLength <= myTextMinLength)) {
            if (isFixedWidth !== true || isFixedWidth !== 'true') {
                this.mobileResetControlAutoHeightAndWidth(ctrlId);
            }
        }

        if (isFixedWidth !== true || isFixedWidth !== 'true') {
            this.getLeftPosition(ctrlId, docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop);
            this.getTopPosition(ctrlId, docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, popupTextValue);

            // if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeInfo && this.documentControlsResponse.EnvelopeInfo.EnvelopeImageCollection) {
            //     parentHeight = imagePage.clientHeight;//this.documentControlsResponse.EnvelopeInfo.EnvelopeImageCollection[docPage - 1].Dimension.Height - 48;
            // }

            let isLandscapeDocFlag: any  = false;
            Array.from(document.getElementsByClassName('dispImg') as HTMLCollectionOf<HTMLElement>)
            .forEach((imgItem: any, index:any) => {
                let imageDoc:any = document.getElementById(imgItem.id);
                if(parseInt(imgItem.id) === docPage && imageDoc){
                    //landscape document height is small and checking with mobile device height less then 335
                    if(imageDoc.offsetHeight <= 335){
                        isLandscapeDocFlag = true;
                    }
                }
            })

            let image: any = document.querySelector("#imgControl_" + docPage)!;

            if(image){
                image.classList.add('controlContainerTemp');
                if(isLandscapeDocFlag === true){
                    image.classList.add('controlContainerTempLandscape');
                }
            }

            let style:any = window.getComputedStyle(image, null);
            let parentHeight: any;
            parentHeight = style.getPropertyValue('height').split('px')[0];

            if(image){
                image.classList.remove('controlContainerTemp');
                image.classList.remove('controlContainerTempLandscape');
            }
            const img = new Image();
            let imgId: any = document.getElementById(image.id);
            let imageUrl: any = imgId && imgId.children[0] && imgId.children[0].currentSrc;
            img.onload = function () {
                let outerHeight: any = myTextArea.style.height.split('px')[0];
                //console.log('outerHeight', parseInt(parentHeight), parseInt(outerHeight));
                let controlBottom: any = parseInt(parentHeight) - (currentCtrDetails.offsetTop + parseInt(outerHeight));

                let originalWidth: any = img.naturalWidth;
                let originalHeight: any = img.naturalHeight;
                let imageWidth: any = parseInt(imagePageWidth) - 2;
                let imageHeight: any = parseInt(parentHeight) - 3; // - 48
                let ratioWidth: any = 1 / (imageWidth / originalWidth);
                let ratioHeight: any = 1 / (imageHeight / originalHeight);
                let ZCoordinate: any;
                if ((controlBottom * ratioHeight) <= 0) {
                    ZCoordinate = 1;
                } else {
                    ZCoordinate = (controlBottom * ratioHeight);
                }
                // console.log('ZCoordinate',ZCoordinate );
                myTextArea.setAttribute('data-ZCoordinate', ZCoordinate);

            };
            img.src = imageUrl;
        }

    }

    isSingleKeyPress(event: any) {
        return (
            event.key.length === 1 && // A single character key
            !event.ctrlKey &&          // Control key isn't pressed
            !event.metaKey &&          // Command key isn't pressed (for Mac)
            !event.altKey              // Alt key isn't pressed
        );
    }

    updateSimilarTextFields(controlId: any, controlValue: any,webOrMobile:any, controlPositionDetailsArr:any) {
        this.controlPositionDetailsArr = controlPositionDetailsArr;
        let currentCtrl: any = document.getElementById("control_" + controlId)!;
        let currentCtrlId: any = currentCtrl.getAttribute('id');
        let conTitle = currentCtrl.getAttribute("data-title");
        let currentDateFormate: any = currentCtrl.getAttribute('data-ctntype');
        let currentFocusedControl = document.getElementById(controlId)!.getAttribute("data-recipientid");
        if (conTitle != undefined) {
            let textcontolsCollection: any = document.getElementsByClassName("textsignerControl");
            let currentPlaceHoder = currentCtrl.getAttribute("placeholder");

            let txControlArray: string[] = [];
            txControlArray.push(controlId);
            if (textcontolsCollection != null && textcontolsCollection.length > 0) {
                Array.from(textcontolsCollection).forEach((txtControl: any) => {
                    var placeholder = txtControl.getAttribute('placeholder');
                    var arrControlId: any = txtControl.getAttribute('id');
                    let arrConTitle = txtControl.getAttribute("data-title");
                    let arrDateFormate: any = txtControl.getAttribute('data-ctntype');
                    if (arrControlId) {
                        let arrFocuedctrl: any = arrControlId.replace("control_", "");
                        let arrFocusedControl = document.getElementById(arrFocuedctrl)!.getAttribute("data-recipientid");
                        if (currentFocusedControl == arrFocusedControl) {
                            if (currentCtrlId != arrControlId && arrConTitle == conTitle && currentPlaceHoder.toLowerCase() == placeholder.toLowerCase()) {
                                if ((txtControl.value === '' && conTitle === 'Date') || txtControl.value === '') {
                                    if (conTitle == "Zip" || conTitle == "SSN") {
                                        if (txtControl.getAttribute('data-mask') == currentCtrl.getAttribute('data-mask')) {
                                            txtControl.value = controlValue;
                                            if (controlValue != "") {
                                                txControlArray.push(arrFocuedctrl);
                                            }
                                        }
                                    }
                                    else if (conTitle != "Zip" && conTitle != "SSN") {
                                        if (currentDateFormate && arrDateFormate && conTitle === 'Date'){
                                            if(currentDateFormate == arrDateFormate){
                                                txtControl.value = controlValue;
                                            }
                                        } else {
                                            txtControl.value = controlValue;
                                        }
                                        if (controlValue != "") {
                                            txControlArray.push(arrFocuedctrl);
                                            if (webOrMobile === 'mobile') {
                                                let myTextArea: any = document.getElementById('control_' + arrFocuedctrl);
                                                this.updateExpandControlIfApplicable(arrFocuedctrl, myTextArea.value, this.controlPositionDetailsArr);
                                            } else {
                                                this.updateAutoExpand(arrFocuedctrl);
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                });

                if (txControlArray && txControlArray.length > 0) {
                    for (let index = 0; index < txControlArray.length; index++) {
                        const element = txControlArray[index];
                        let contrl: any = document.getElementById('control_' + element);
                        let isValid: boolean = false;
                        if (contrl) {
                            let datatitle: any = contrl.getAttribute("data-title");
                            if (datatitle == "SSN" || datatitle == "Zip") {
                                if (contrl.value.indexOf('_') > 0 || contrl.value.indexOf('_') > -1 || contrl.value.indexOf('-') > 5) {
                                    isValid = true;
                                }
                            }
                        }
                        if (contrl.value !== '' && contrl.value !== ' ' && contrl.value !== null && !isValid) {
                            this.applyStylesToCheckList("add", element);
                            let controlele: any = document.getElementById('control_' + element)!
                            let controlId: any = controlele.parentNode;
                            controlId.classList.add('filledControls');
                        }
                    }
                }
            }
        }
    }

    applyStylesToCheckList(type: any, ControlHtmlID: any) {
        let currentLblControl: any = document.getElementById("label_" + ControlHtmlID);
        let currentLblIconControl: any = document.getElementById("iicon_" + ControlHtmlID);
        if (currentLblControl && currentLblIconControl && type == "remove") {
            currentLblControl.classList.remove("success-color", "success-icon");
            currentLblControl.classList.add("requried-color");
            currentLblControl.classList.add("requried-color1");
            currentLblControl.classList.add("requried-icon");
            currentLblIconControl.classList.remove("success-icon");
            currentLblIconControl.classList.add("requried-icon");
        }
        else if (currentLblControl && currentLblIconControl && type == "add") {
            currentLblControl.classList.remove("requried-color", "requried-color1", "requried-icon");
            currentLblControl.classList.add("success-color");
            currentLblControl.classList.add("success-icon");
            currentLblIconControl.classList.remove("requried-icon");
            currentLblIconControl.classList.add("success-icon");
        }
    }
}
