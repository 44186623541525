import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants, ResourceKeys, RouteUrls } from 'src/app/constants/common-constants';
import { AuthenticateService } from 'src/app/services/authenticate-signer.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { TranslationsService } from 'src/app/services/translations.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-authenticate-signer',
  templateUrl: './authenticate-signer.component.html',
  styleUrls: ['./authenticate-signer.component.scss']
})
export class AuthenticateSignerComponent implements OnInit {
  title = 'RSign | Authenticate Signer';
  isDataLoaded:boolean = false;
  showLoader: boolean = false;
  emailAddr: any;
  recipientId: any;
  authenticateSignerUrl: any;
  verificationCode: any;
  authenticateUrl: any;
  htmlFooterString: string = '';
  resourceKeys = ResourceKeys;
  DeliveryMode:any;
  Mobile:any;
  EnableMessageToMobile:any; 

  constructor(private router: Router, private authenticateService: AuthenticateService, private translationsService: TranslationsService,
    private toastr: ToastrService, private commonService: CommonService, private titleService:Title) { }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    console.log('Back button pressed');
    window.location.reload();
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.showLoader = true;
    this.htmlFooterString = '<p> ' +
      '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
      '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
      '</p>';    
    this.commonService.saveSignerProfileDetailsToSession();    
    this.authenticateSignerUrl = window.location.href;
    localStorage.setItem("authenticateSignerUrl", this.authenticateSignerUrl);
    this.authenticateSignerUrl = this.authenticateSignerUrl.substring(0, this.authenticateSignerUrl.length - 1);
    this.authenticateUrl = decodeURI(this.authenticateSignerUrl.split('?')[1]);
    // console.log('authenticateUrl', this.authenticateUrl);

    let btnViewandSignDocument: any = document.getElementById("btnViewandSignDocument");
    btnViewandSignDocument?.setAttribute('disabled', 'disabled');

    this.authenticateSigner(this.authenticateUrl, false);
  }

  authenticateSigner(authenticateUrl: string, isResend: boolean) {
    this.showLoader = true;
    let authenticateSignerUrl = CommonConstants.AuthenticateSigner;
    let paramsObj = {
      AuthenticateUrl: authenticateUrl,
      IsResend: isResend
    };
    this.authenticateService.authenticateSigner(authenticateSignerUrl, paramsObj).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
          localStorage.setItem("AutoLockRecpObj", '');
          this.showLoader = false;          
          this.isDataLoaded = true;
          localStorage.setItem("BrandName", (resp.BrandName != null || resp.BrandName != '' )? resp.BrandName: '');
          localStorage.setItem("senderEmail", (resp.SenderEmailAddress != null || resp.SenderEmailAddress != '') ? resp.SenderEmailAddress: '');
          localStorage.setItem("header", (resp.Header != null || resp.Header != '' )? resp.Header: '');
          localStorage.setItem("footer", (resp.Footer != null || resp.Footer != '') ? resp.Footer: '');
          this.commonService.callHeaderCompMethod(resp.BrandName);
          if (resp.IsEnvelopePurging == true || resp.IsEnvelopePurging) {
            this.showLoader = false;
            sessionStorage.removeItem("isFromSignMultiTemplate");
            //localStorage.setItem("encryptedSender", "");
            localStorage.setItem("MessageType", "ArchivedOrPurged");
            localStorage.setItem("IsApplicationError", "false");
            localStorage.setItem("DisplayMessage", resp.Message);
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
          else {           
            this.emailAddr = resp.Email;
            this.recipientId = resp.RecipientId;
            this.DeliveryMode = resp.DeliveryMode;
            this.Mobile = resp.Mobile;
            this.EnableMessageToMobile = resp.EnableMessageToMobile;
            if (isResend) {
              this.toastr.success(resp.Message, 'Success', { timeOut: 1000 });
            }
          }
        }
        else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 403 && 
          (resp.originalError.error.Message == CommonConstants.AIAutoLockEnabled || resp.originalError.error.Message == CommonConstants.RequestAlreadySent || resp.originalError.error.Message == CommonConstants.RequestDeclined )) {
          this.showLoader = false;
          localStorage.setItem("DisplayMessage", CommonConstants.AccessDeniedMessage); 
          localStorage.setItem("MessageType", "Warning");
          localStorage.setItem("BrandName", (resp.originalError.error.BrandName != null || resp.originalError.error.BrandName != '') ? resp.originalError.error.BrandName : '');
          localStorage.setItem("header", (resp.originalError.error.Header != null || resp.originalError.error.Header != '') ? resp.originalError.error.Header : '');
          localStorage.setItem("footer", (resp.originalError.error.Footer != null || resp.originalError.error.Footer != '') ? resp.originalError.error.Footer : '');
          localStorage.setItem('senderEmail', resp.originalError.error.SenderEmailAddress);
          this.commonService.callHeaderCompMethod(resp.originalError.error.BrandName);
          let autoLockResp:any = {
            SenderEmail : resp.originalError.error.SenderEmailAddress,
            EnvelopeTemplateCode : resp.originalError.error.EnvelopeTemplateCode,
            SenderRecId : resp.originalError.error.SenderRecId,
            Subject : resp.originalError.error.Subject,
            RecipientID : resp.originalError.error.RecipientID,
            RecipientEmail : resp.originalError.error.Email,            
            Feature: resp.originalError.error.Feature,
            IPAddress: resp.originalError.error.IPAddress,               
            RecipientType: resp.originalError.error.RecipientType,
            AutoLockMessage: resp.originalError.error.AutoLockMessage,
            ReferenceId : resp.originalError.error.ReferenceId,
            Type: "Envelope",
            CultureInfo: resp.originalError.error.CultureInfo,  
            RecipientMobile: resp.originalError.error.RecipientMobile,
            RecipientDialCode: resp.originalError.error.RecipientDialCode,
            RecipientDeliveryMode: resp.originalError.error.RecipientDeliveryMode,      
        };
        localStorage.setItem("AutoLockRespMessage", JSON.stringify(autoLockResp)); 
        localStorage.setItem("cultureInfo", autoLockResp.CultureInfo);
        sessionStorage.removeItem('LaungaueTransalation');
        this.translationsService.getLanguageTranslations(null, null);
        setTimeout(() => {
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }, 10); 
      }
        else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 400 && resp.originalError.error.ErrorAction  != '') {
          this.showLoader = false;
         // this.toastr.warning(resp.originalError.error.Message, 'Warning', { timeOut: 1000 });
          localStorage.setItem('DisplayMessage', resp.originalError.error.Message);
          localStorage.setItem('MessageType', 'Warning');
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
        else {
          this.showLoader = false;
          this.toastr.warning(resp.originalError.error.Message, 'Warning', { timeOut: 1000 });
          localStorage.setItem('DisplayMessage', resp.originalError.error.Message);
          localStorage.setItem('MessageType', 'Error');
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
        this.showLoader = false;
      })
  }

  enableSubmitButton(event: any) {
    this.verificationCode = event.target.value;
    let btnViewandSignDocument: any = document.getElementById('btnViewandSignDocument');

    if (!this.commonService.isNumberExists(this.verificationCode)) {
      event.target.value = '';
      if (this.verificationCode.length > 0) {
        this.toastr.warning('Please enter only a number.', 'Warning', { timeOut: 1000 });
      }
    }
    else if (this.verificationCode.length == 6) {
      btnViewandSignDocument?.removeAttribute('disabled');
    }
    else {
      btnViewandSignDocument?.setAttribute('disabled', 'disabled');
    }
  }

  resendVerificationCode() {
    this.authenticateSigner(this.authenticateUrl, true);
  }

  getCodeAndValidateSigner() {
    if (this.verificationCode != "" || this.verificationCode != null) {
      this.showLoader = true;
      let verifyCodeUrl = CommonConstants.GetCodeAndValidateSigner;
      let paramsObj = {
        RecipientId: this.recipientId,
        verificationCode: this.verificationCode
      };
      this.authenticateService.getCodeAndValidateSigner(verifyCodeUrl, paramsObj).subscribe(
        (resp: any) => {
          if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
            this.showLoader = false;
            this.toastr.success(resp.Message, 'Success', { timeOut: 1000 });
            let signingUrl: any = localStorage.getItem("authenticateSignerUrl");
            signingUrl = signingUrl.replace(RouteUrls.AuthenticateSigner, RouteUrls.SignerLanding);
            window.location.href = signingUrl;
          }
          else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 400) {
            this.showLoader = false;
            this.toastr.warning("Please enter valid code.", 'Warning', { timeOut: 2000 });
          }
        })
    }
    else {
      this.toastr.warning("Please enter valid code.", 'Warning', { timeOut: 2000 });
    }
  }

  getLanguageTranslationValue(resourcekeyId: any) {
    return this.translationsService.getLanguageTranslationVal(resourcekeyId);
  }
}
