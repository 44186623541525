import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { InfoComponent } from './components/info/info.component';
import { PasswordWindowComponent } from './components/password-window/password-window.component';
import { SignerLandingComponent } from './components/signer-landing/signer-landing.component';
import { StaticLandingComponent } from './components/static-landing/static-landing.component';
import { AuthenticateSignerComponent } from './components/authenticate-signer/authenticate-signer.component';
import { DefaultComponent } from './components/default/default.component';
import { SignMultiTemplateComponent } from './components/sign-multi-template/sign-multi-template.component';
import { SecurityCodeComponent } from './components/security-code/security-code.component';
import { ConfirmToBegainComponent } from 'src/app/components/confirm-to-begain/confirm-to-begain.component';
import { StaticLinkComponent } from './components/static-link/static-link.component';
import { StaticPasswordWindowComponent } from './components/static-password-window/static-password-window.component';
import { SignerValidateComponent } from './components/signer-validate/signer-validate.component';
import { GlobalErrorComponent } from './components/global-error/global-error.component';
import { RclickSignatureComponent } from './components/rclick-signature/rclick-signature.component';
import { PrefillLandingComponent } from './components/prefill-landing/prefill-landing.component';

const routes: Routes = [
  { path: '', component: DefaultComponent },
  { path: 'default', component: DefaultComponent }, 
  //To do 
  { path: 'signer-landing', component: HomeComponent },
  { path: 'sign-document', component: SignerLandingComponent },  
  { path: 'password-window', component: PasswordWindowComponent }, 
  
  { path: 'info', component: InfoComponent },
  { path: 'static-template-landing', component: StaticLinkComponent },
  { path: 'static-password-window', component: StaticPasswordWindowComponent },
  { path: 'static-template', component: StaticLandingComponent },
  { path: 'multiple-signer', component: StaticLandingComponent },  
  { path: 'signer-landing-static-template', component: SignerLandingComponent },
  { path: 'authenticate-signer', component: AuthenticateSignerComponent },
  { path: 'security-code', component: SecurityCodeComponent },
  { path: 'confirm-to-begin', component: ConfirmToBegainComponent },
  { path: 'sign-multi-template', component: SignMultiTemplateComponent },
  { path: 'validate-signer', component: SignerValidateComponent },
  { path: 'global-error', component: GlobalErrorComponent },
  { path: 'rclick', component: RclickSignatureComponent },
  { path: 'prefill-landing', component: PrefillLandingComponent } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
