import { Component, OnInit, Input, HostListener, ChangeDetectorRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
const FILTER_PAG_REGEX = /[^0-9]/g;
import * as $ from 'jquery';

@Component({
	selector: 'app-pagination-customization',
	templateUrl: './pagination-customization.component.html',
	styleUrls: ['./pagination-customization.component.scss']
})
export class PaginationCustomizationComponent implements OnInit {
	page: any = 1;
	collectionSize: any = 10;
	@Input() documentData: any;
	doc2: any;
	documentDataLength: any = 1;
	pageVal: any = 1;
	pageCurrentVal: any = 1;
	webOrMobile: string = 'web';
	isLastPage: boolean = false;
	private scrollingTimeout: any;

	constructor(
		private commonService: CommonService,
		private cdRef: ChangeDetectorRef,
	) {
		this.commonService.setUser(this.page);
		this.commonService.documentData.subscribe(data => {
			this.documentDataLength = data.length;
		});
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(event: Event) {
		// Clear any existing scrollingTimeout
		clearTimeout(this.scrollingTimeout);

		// Set a new timeout to detect the end of scrolling
		this.scrollingTimeout = setTimeout(() => {
			// Scrolling has ended, do something here
			console.log('Scrolling has ended.');
			let page: any = 1;
			let extraValue:any = 200;
			if(this.webOrMobile === 'mobile'){
				let zoomValue:any = this.getZoomValue();
				extraValue = 280;
				$('.imgControl').each(function(index, element) {
					if((window.scrollY / zoomValue) > (element.offsetTop  - extraValue)){
						page = element.id.split('imgControl_')[1];
					}
				});
			} else {
				$('.imgControl').each(function(index, element) {
					if(window.scrollY > (element.offsetTop - extraValue)){
						page = element.id.split('imgControl_')[1];
					}
				});
			}

			this.pageVal = page;
			this.commonService.setUser(page);
			localStorage.setItem('currentPagination', page);
			Array.from(document.getElementsByClassName("prevImg") as HTMLCollectionOf<HTMLElement>)
			.forEach((pcard: any, index: any) => {
				let ele: any = document.getElementById(pcard.id);
				if (ele.classList.contains('active-preview-card')) {
					ele.classList.remove('active-preview-card')
				}
			})

			let prevPageEle: any = document.getElementById('pagePreview' + page);
			if (prevPageEle && prevPageEle.classList) {
				prevPageEle.classList.add('active-preview-card');
			}
			this.getPreviewSelected(page);
			this.cdRef.detectChanges();
		}, 200); // Adjust the timeout value as per your preference
		this.cdRef.detectChanges();
	}

	ngOnInit(): void {
		if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
			this.webOrMobile = 'mobile';
		}
		if (this.documentData && this.documentData.length) {
			this.collectionSize = this.documentData.length * 10;
			this.documentDataLength = this.documentData.length;
		} else {
			this.collectionSize = 10;
			this.documentDataLength = 1;
		}
		this.page = 1;
		localStorage.setItem('currentPagination', this.page);
		this.pageVal = this.page;
	}

	formatInput(input: HTMLInputElement) {
		input.value = input.value.replace(FILTER_PAG_REGEX, '');
	}

	forRestrictNumbers(evt: any) {
		var charCode = (evt.which) ? evt.which : evt.keyCode
		if (charCode > 31 && (charCode < 48 || charCode > 57))
			return false;
		return true;
	}

	onPageSelection(event: any, type: any) {
		let pageCount: any = localStorage.getItem('currentPagination')
		this.page = parseInt(pageCount);

		if (type == 'paginationInput') {
			let myinputVal: any = document.getElementById('paginationInput')!
			let idValue: any = parseInt(event.target.value);
			if (idValue !== 0 && idValue <= this.documentData.length) {

				this.commonService.setUser(idValue);
				localStorage.setItem('currentPagination', idValue);
				this.pageVal = idValue;
				this.onPaginateChange(this.pageVal);
			} else {
				let currentPagination: any = localStorage.getItem('currentPagination');
				let currentPageValue: any = parseInt(currentPagination);
				if(idValue === 0 || idValue > this.documentData.length){
					this.pageVal = currentPageValue;
					event.target.value = currentPageValue;
				}

				this.commonService.setUser(this.pageVal);
				localStorage.setItem('currentPagination', this.pageVal);
				this.pageVal = this.pageVal;
				this.onPaginateChange(this.pageVal);
			}
		}
		if (type == 'first') {
			this.page = 1;
			this.commonService.setUser(this.page);
			localStorage.setItem('currentPagination', this.page);
			this.pageVal = this.page;

			this.onPaginateChange(this.pageVal);
		}
		if (type == 'prev') {
			if (this.page > 1) {
				this.page = this.page - 1;
				this.commonService.setUser(this.page);
				localStorage.setItem('currentPagination', this.page);
				this.pageVal = this.page;
				this.onPaginateChange(this.pageVal);
			}

		}
		if (type == 'next') {
			if (this.page < this.documentData.length) {
				this.page = this.page + 1;
				this.commonService.setUser(this.page);
				localStorage.setItem('currentPagination', this.page);
				this.pageVal = this.page;

				this.onPaginateChange(this.pageVal);
			}
		}
		if (type == 'last') {
			this.page = this.documentData.length;
			this.commonService.setUser(this.page);
			localStorage.setItem('currentPagination', this.page);
			this.pageVal = this.page;

			this.onPaginateChange(this.pageVal);
		}
	}

	updatePageScrollAndCount(id:any){
		if (id !== undefined && (id <= this.documentData.length)) {
            let imgControl: any = $(".imgControl");
            imgControl.disabled = true;
            let pageNoAttr: any = $('div.imgControl[page-no="' + id + '"]').offset()?.top;
            let firstpageNoAttr: any = $('div.imgControl[page-no="1"]').offset()?.top;
            var scrollTo = pageNoAttr - firstpageNoAttr - 20;
            $('html, body').animate({ scrollTop: scrollTo }, 100);
            localStorage.setItem('currentPagination', id);
			this.cdRef.detectChanges();
        }
	}

    onPaginateChange(id: any) {
		if(this.documentData && this.documentData.length){
			this.updatePageScrollAndCount(id);
		}
    }

	onInputChange(e: any, pageid: any) {
		localStorage.setItem('currentPagination', e.target.value);
	}

	getPreviewSelected(p: any) {
        Array.from(document.getElementsByClassName("thumbnails-page-btn") as HTMLCollectionOf<HTMLElement>)
            .forEach((pcard: any, index: any) => {
                let ele: any = document.getElementById(pcard.id);
                if (ele.classList.contains('active-preview')) {
                    ele.classList.remove('active-preview')
                }
            })

        let prevPageEle: any = document.getElementById('pageCountColor' + p);
        if (prevPageEle && prevPageEle.classList) {
            prevPageEle.classList.add('active-preview');
        }
    }

	getPaginationInputWidth(){
		let className:any = '';
		if(this.webOrMobile === 'mobile'){
			className = 'pagination-input-width';
		}
		return className;
	}

	getZoomValue(){
		let pinchZoomValue = this.commonService.getPinchZoomScale();
		let zoomVal:any = 1;
		if(pinchZoomValue && pinchZoomValue.pinchZoom && pinchZoomValue.pinchZoom.initialScale > 1){
			zoomVal = pinchZoomValue.pinchZoom.initialScale;
		}
		return zoomVal;
	}
}
