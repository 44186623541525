import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { TranslationsService } from 'src/app/services/translations.service';
import { AttachSignedPDF, CommonConstants, RPostUrl, ResourceKeys, RouteUrls } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';

@Component({
    selector: 'app-signer-registration',
    templateUrl: './signer-registration.component.html',
    styleUrls: ['./signer-registration.component.scss']
})
export class SignerRegistrationComponent implements OnInit {
    emailAddress: any;
    signerRegObject: any;
    returnUrl: any;
    uiRedirectUrl: any = environment.uiBaseUrl;
    domainUrl: any;
    MEmail: any;
    spEmail: any;
    isSignedDocumentService: boolean = false
    toastrMessage: any;
    resourceKeys = ResourceKeys;
    showLoader: boolean = false;
    attachSignedPdfID: any;
    downloadFinalContractBtn: boolean = false;
    isDeclined: boolean = false;
    mobileNumber: any;
    deliveryMode: any;
    isCopyEmail: any;
    lang_success:any = '';

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private toastr: ToastrService,
        private commonService: CommonService,
        private translationsService: TranslationsService,
        private signerLandingService: SignerLandingService
    ) { }

    ngOnInit(): void {
        this.lang_success = this.translationsService.getLanguageValidationVal('lang_success');
        let encodedEmail:any = localStorage.getItem('EmailId')
        const decodedEmail = decodeURIComponent(encodedEmail);
        this.emailAddress = decodedEmail;
        localStorage.setItem('EmailId', decodedEmail);
        this.signerRegObject = this;
        this.returnUrl = this.signerRegObject.modalPopupObj.returnUrl;
        this.MEmail = this.signerRegObject.modalPopupObj.MEmail;
        this.spEmail = this.signerRegObject.modalPopupObj.spEmail;
        this.attachSignedPdfID = this.signerRegObject.modalPopupObj.attachSignedPdfID;
        this.isSignedDocumentService = this.signerRegObject.modalPopupObj.isSignedDocumentService;
        this.toastrMessage = this.signerRegObject.modalPopupObj.toastrMessage;
        this.isCopyEmail = this.signerRegObject.modalPopupObj.isCopyEmail;

        let isDeclinedText: any = localStorage.getItem('EnvelopeStatus');
        if (isDeclinedText && isDeclinedText == "Declined") {
            this.isDeclined = true;
        }
        if (this.emailAddress == null || this.emailAddress == "null" || this.emailAddress == "" || this.emailAddress == undefined)
            this.emailAddress = this.MEmail;

        this.mobileNumber = localStorage.getItem('RecpMobileNumber');
        this.deliveryMode = localStorage.getItem('RecpDeliveryMode');

        setTimeout(() => {
            if (this.deliveryMode != "0" && this.deliveryMode != null && this.deliveryMode != "null" && this.deliveryMode != "" && this.deliveryMode != undefined) {
                if (this.deliveryMode == "1" || this.deliveryMode == "11") this.emailAddress = this.emailAddress;
                else if (this.deliveryMode == "4" || this.deliveryMode == "6") {
                    this.emailAddress = this.mobileNumber;
                    let spnEmailText: any = document.getElementById("spnEmailText")!;
                    if (spnEmailText) {
                        spnEmailText.innerHTML = "Mobile:";
                    }
                }
                else {
                    if (this.mobileNumber != null && this.mobileNumber != "null" && this.mobileNumber != "" && this.mobileNumber != undefined && this.mobileNumber != ' '
                        && this.emailAddress != null && this.emailAddress != "null" && this.emailAddress != "" && this.emailAddress != undefined && this.emailAddress != ' ') {
                        let mobileStr: string = this.mobileNumber;
                        if (mobileStr.length > 7)
                            this.emailAddress = this.emailAddress + " (" + this.mobileNumber + ")";
                        else this.emailAddress = this.emailAddress;
                    }
                    else if (this.emailAddress != null && this.emailAddress != "null" && this.emailAddress != "" && this.emailAddress != undefined && this.emailAddress != ' ') {
                        this.emailAddress = this.emailAddress;
                    }
                    else if (this.mobileNumber != null && this.mobileNumber != "null" && this.mobileNumber != "" && this.mobileNumber != undefined && this.mobileNumber != ' ') {
                        this.emailAddress = this.mobileNumber;
                    }

                    let spnEmailText: any = document.getElementById("spnEmailText")!;
                    if (spnEmailText) {
                        spnEmailText.innerHTML = "Email & Mobile:";
                    }
                }
            }

            if (this.emailAddress == null || this.emailAddress == "null" || this.emailAddress == "" || this.emailAddress == undefined) {
                let divRegisterEmail: any = document.getElementById("divRegisterEmail")!;
                divRegisterEmail.style.visibility = 'collapse';
            }

            if (this.attachSignedPdfID != null && this.attachSignedPdfID != "" && this.attachSignedPdfID != undefined) {
                if (this.attachSignedPdfID.toUpperCase() == AttachSignedPDF.SenderSigner || this.attachSignedPdfID.toUpperCase() == AttachSignedPDF.RetrievalLink) {
                    //show the download button
                    this.downloadFinalContractBtn = true;
                }
                else {
                    //hide the download button and change text
                    this.downloadFinalContractBtn = false;
                }
            }
        }, 10);
    }

    onGetStartedHere() {
        this.activeModal.dismiss('Cross click');
        this.domainUrl = CommonConstants.RSignSignupCompleted;
        this.commonService.saveSignerProfileDetailsToSession();
        this.commonService.getReturnUrlDirection(this.domainUrl);
    }

    onNoThanks() {
        this.activeModal.dismiss('Cross click');
        if (this.toastrMessage != null && this.toastrMessage != undefined) {
            this.toastr.success(this.toastrMessage, this.lang_success, { timeOut: 2000 });
        }
        if (this.returnUrl != RouteUrls.Info) {
            this.commonService.saveSignerProfileDetailsToSession();
        }
        this.commonService.getReturnUrlDirection(this.returnUrl);
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    onDownload() {
        let envelopeID = localStorage.getItem('EnvelopeID');
        let recipientTypeId = localStorage.getItem('recipientTypeId')
        let url: any = CommonConstants.DownloadFinalContract + '/' + envelopeID + '/0/' + recipientTypeId;
        this.showLoader = true;
        this.signerLandingService.getDownloadFinalContract(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                let base64data: any = resp.Base64FileData;
                if (base64data) {
                    var blob = this.commonService.b64toBlob(base64data, resp.FileType);
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = window.URL.createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    window.URL.revokeObjectURL(bloburl);
                    a.remove();
                }
                else if (resp.originalError && resp.originalError.error && resp.originalError.error.Message
                    && resp.originalError.error.Message == CommonConstants.DeleteFinalContractDisplayMsg || this.isSignedDocumentService) {
                    let documentGenerationMessage: any = this.translationsService.getLanguageTranslationVal(ResourceKeys.DocumentGenerationMessage);
                    if (documentGenerationMessage == null || documentGenerationMessage == undefined)
                        documentGenerationMessage = CommonConstants.DocumentGenerationMessage;
                    this.toastr.info(documentGenerationMessage, 'Attention', { timeOut: 6000 });
                    return;
                }
            });
    }
}
