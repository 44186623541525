import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonConstants } from '../constants/common-constants';

@Injectable({
    providedIn: 'root'
})
export class StyleService {
    commonConstants = CommonConstants;
    baseUrl = environment.apiBaseUrl;

    private getImagePathCached: { [key: string]: any } = {};
    private getStaticImagePathCached: { [key: string]: any } = {};
    private getCtrlInlineStyleIfIsReadOnlyNullCachedStyles: { [key: string]: any } = {};
    private getTextEmailCtrlInlineStyleCachedStyles: { [key: string]: any } = {};
    private getTextInputCtrlInlineStyleCachedStyles: { [key: string]: any } = {};
    private getCtrlLabelStyleIsReadOnlyNullCachedStyles: { [key: string]: any } = {};
    private getTextDateCtrlInlineStyleCachedStyles: { [key: string]: any } = {};
    private getRequiredControlClassCachedStyles: { [key: string]: any } = {};
    private getRequiredDropdownControlClassCachedStyles: { [key: string]: any } = {};
    private getPencilClassCachedStyles: { [key: string]: any } = {};
    private getCtrlDisplayStyleCachedStyles: { [key: string]: any } = {};
    private getTooltipAlertpositionCachedStyles: { [key: string]: any } = {};
    private getTooltipTranglepositionCachedStyles: { [key: string]: any } = {};
    private getTextNotDateAndEmailCtrlStyleCachedStyles: { [key: string]: any } = {};
    private getTextAlignStyleCachedStyles: { [key: string]: any } = {};
    private getCtrlLabelStyleStyleCachedStyles: { [key: string]: any } = {};

    constructor() { }

    controlCalculatedTopVal(ctrlObj: any) {
        let topPos: any;
        if (ctrlObj.CalculatedTop) {
            let ed = ctrlObj.CalculatedTop.split(';');
            if (ed && ed.length > 1) {
                for (let index = 0; index < ed.length; index++) {
                    const element = ed[index];
                    if (element.includes("top")) {
                        topPos = element.split('top:')[1].trim();
                        break;
                    }
                }
            }
            else {
                topPos = ctrlObj.CalculatedTop.split('top:')[1];
            }
            return topPos;
        }
    }

    getImagePath(record: any, documentControlsResponse: any) {
        const key = JSON.stringify(record);

        if (!this.getImagePathCached[key]) {
            this.getImagePathCached[key] = this.getImagePathCachedCompute(record, documentControlsResponse);
        }

        return this.getImagePathCached[key];
    }

    private getImagePathCachedCompute(record: any, documentControlsResponse: any) {
        let apiEnvelopGetImages: string = "";
        if (documentControlsResponse.EnvelopeInfo.SubEnvelopeId !== null && documentControlsResponse.EnvelopeInfo.SubEnvelopeId != undefined &&
            documentControlsResponse.EnvelopeInfo.SubEnvelopeId !== '00000000-0000-0000-0000-000000000000') {
            apiEnvelopGetImages = this.commonConstants.GetImagesForSubEnvelopePrefill;
        } else if (documentControlsResponse.EnvelopeInfo.SubEnvelopeId === null &&
            documentControlsResponse.IsTemplateShared === true && documentControlsResponse.Prefill === 'prefill') {
            apiEnvelopGetImages = this.commonConstants.GetImagesForSubEnvelopePrefill;
        }
        else {
            apiEnvelopGetImages = this.commonConstants.GetEnvelopeImages;
        }
        return this.baseUrl + apiEnvelopGetImages + record.ImagePath;
    }

    getStaticImagePath(record: any, documentControlsResponse: any) {
        const key = JSON.stringify(record);

        if (!this.getStaticImagePathCached[key]) {
            this.getStaticImagePathCached[key] = this.getStaticImagePathCachedCompute(record, documentControlsResponse);
        }

        return this.getStaticImagePathCached[key];
    }

    private getStaticImagePathCachedCompute(record: any, documentControlsResponse: any) {
        let apiEnvelopGetImages: string = "";
        if (documentControlsResponse.EnvelopeInfo.SubEnvelopeId !== null && documentControlsResponse.EnvelopeInfo.SubEnvelopeId != undefined &&
            documentControlsResponse.EnvelopeInfo.SubEnvelopeId !== '00000000-0000-0000-0000-000000000000') {
            apiEnvelopGetImages = this.commonConstants.GetImagesForSubEnvelopePrefill;
        } else if (documentControlsResponse.EnvelopeInfo.SubEnvelopeId === null &&
            documentControlsResponse.IsTemplateShared === true && documentControlsResponse.Prefill === 'prefill') {
            apiEnvelopGetImages = this.commonConstants.GetImagesForSubEnvelopePrefill;
        }
        else {
            apiEnvelopGetImages = this.commonConstants.GetTemplateImages;
        }
        return this.baseUrl + apiEnvelopGetImages + record.ImagePath;
    }

    getCtrlInlineStyleIfIsReadOnlyNull(ctrlObj: any, type: any) {
        const key = JSON.stringify(ctrlObj);

        if (!this.getCtrlInlineStyleIfIsReadOnlyNullCachedStyles[key]) {
            this.getCtrlInlineStyleIfIsReadOnlyNullCachedStyles[key] = this.getCtrlInlineStyleIfIsReadOnlyNullCompute(ctrlObj, type);
        }

        return this.getCtrlInlineStyleIfIsReadOnlyNullCachedStyles[key];
    }

    private getCtrlInlineStyleIfIsReadOnlyNullCompute(ctrlObj: any, type: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        // Return the styles object.
        let blnflag: any;
        let topPos: any;
        let leftPos: any;
        let modalWidth: any;
        if (ctrlObj.CalculatedTop) {
            // topPos = ctrlObj.CalculatedTop.split('top:')[1];
            topPos = this.controlCalculatedTopVal(ctrlObj);
        }
        if (ctrlObj.CalculatedLeft) {
            leftPos = ctrlObj.CalculatedLeft.split('left:')[1];
        }
        if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'newinitials' || ctrlObj.ControlName.toLowerCase() === 'text' ||
            ctrlObj.ControlName.toLowerCase() === 'name' || ctrlObj.ControlName.toLowerCase() === 'email' ||
            ctrlObj.ControlName.toLowerCase() === 'title' || ctrlObj.ControlName.toLowerCase() === 'date' ||
            ctrlObj.ControlName.toLowerCase() === 'company') {
            modalWidth = ctrlObj.CalculatedModalWidth;
        }
        if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
            modalWidth = ctrlObj.CalculatedModalWidth; //'auto';
        }
        if (ctrlObj.ControlName.toLowerCase() === 'label' || ctrlObj.ControlName.toLowerCase() === 'radio' ||
            ctrlObj.ControlName.toLowerCase() === 'checkbox' || ctrlObj.ControlName.toLowerCase() === 'hyperlink') {
            modalWidth = ctrlObj.Width;
        }

        if (type === 'signer') {
            if (ctrlObj.ConditionDetails.ControllingFieldID == null) {
                blnflag = "block";
            } else {
                blnflag = "none";
            }

        } else if (type === 'static') {
            if (ctrlObj.ConditionDetails.ControllingFieldID == null && (ctrlObj.IsCurrentRecipient == true || ctrlObj.IsCurrentRecipient == null)) {
                blnflag = "block";
            } else {
                blnflag = "none";
            }
        }
        if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
            return {
                'display': blnflag, 'left': leftPos, 'top': topPos, 'width': modalWidth + 'px',
                'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                'height': ctrlObj.Height + 'px', 'position': 'absolute'
            };
        } else {
            return { 'display': blnflag, 'left': leftPos, 'top': topPos, 'width': modalWidth + 'px', 'height': ctrlObj.Height + 'px', 'position': 'absolute' };
        }
    }

    getTextEmailCtrlInlineStyle(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getTextEmailCtrlInlineStyleCachedStyles[key]) {
            this.getTextEmailCtrlInlineStyleCachedStyles[key] = this.getTextEmailCtrlInlineStyleCompute(ctrlObj);
        }
        return this.getTextEmailCtrlInlineStyleCachedStyles[key];
    }

    private getTextEmailCtrlInlineStyleCompute(ctrlObj: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        // Return the styles object.
        let modalWidth: any;
        let fontSize: any;
        let fontFamily: any;
        let lineHeight: any;
        let borderSize: any;
        if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'newinitials' || ctrlObj.ControlName.toLowerCase() === 'text' ||
            ctrlObj.ControlName.toLowerCase() === 'name' || ctrlObj.ControlName.toLowerCase() === 'email' ||
            ctrlObj.ControlName.toLowerCase() === 'title' || ctrlObj.ControlName.toLowerCase() === 'date' ||
            ctrlObj.ControlName.toLowerCase() === 'company') {
            modalWidth = ctrlObj.CalculatedModalWidth;
        }
        if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
            modalWidth = 'auto';
        }
        if (ctrlObj.ControlName.toLowerCase() === 'label' || ctrlObj.ControlName.toLowerCase() === 'radio' ||
            ctrlObj.ControlName.toLowerCase() === 'checkbox' || ctrlObj.ControlName.toLowerCase() === 'hyperlink') {
            modalWidth = ctrlObj.Width;
        }
        fontSize = ctrlObj.FontSize + 'px';
        fontFamily = ctrlObj.FontName;
        lineHeight = ctrlObj.FontSize + 'px';
        if (navigator.userAgent.includes('iPhone')) {
            fontSize = '0.35em';
            fontFamily = 'Helvetica';
            lineHeight = "0.35em";
        }
        if (ctrlObj.Required == false) {
            borderSize = '2px solid #3ca6e9';
        }
        else {
            borderSize = '1px solid grey';
        }
        if (ctrlObj.ControlName.toLowerCase() === 'date') {
            return {
                'border': borderSize, 'padding': '1px', 'cursor': 'auto', 'height': 'auto', 'width': modalWidth + 'px', 'font-size': fontSize, 'text-align': ctrlObj.TextAlign,
                'font-family': fontFamily, 'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': 'none', 'line-height': lineHeight,
                'color': ctrlObj.FontColor
            };
        }
        else if (ctrlObj.ControlName.toLowerCase() === 'name' || ctrlObj.ControlName.toLowerCase() === 'title') {
            if (ctrlObj.Height === null || ctrlObj.Height == undefined) {
                ctrlObj.Height = 20;
            }
            if (modalWidth === null) {
                modalWidth = 162;
            } else {
                modalWidth = modalWidth;// + 5;
            }

            return {
                'border': borderSize, 'padding': '1px', 'height': ctrlObj.Height + 'px', 'width': modalWidth + 'px', 'font-size': fontSize,
                'font-family': fontFamily, 'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline, 'text-align': ctrlObj.TextAlign,
                'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px', 'background': 'none', 'box-sizing': 'border-box', 'overflow': 'hidden', 'resize': 'none'
            };
        }
        else if (ctrlObj.ControlName.toLowerCase() === 'email' || ctrlObj.ControlName.toLowerCase() === 'company') {
            return {
                'border': borderSize, 'padding': '1px', 'height': 'auto', 'width': modalWidth + 'px', 'font-size': fontSize, 'text-align': ctrlObj.TextAlign,
                'font-family': fontFamily, 'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px', 'background': 'none'
            };
        }
        else {
            return {
                'border': borderSize, 'padding': '0px 0px', 'height': ctrlObj.Height + 'px', 'width': modalWidth + 'px', 'font-size': fontSize,
                'font-family': fontFamily, 'text-align': ctrlObj.TextAlign,
                'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px', 'background': 'none'
            };
        }
    }

    getTextAlign(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getTextAlignStyleCachedStyles[key]) {
            this.getTextAlignStyleCachedStyles[key] = this.getTextAlignStyleCompute(ctrlObj);
        }
        return this.getTextAlignStyleCachedStyles[key];
    }

    private getTextAlignStyleCompute(ctrlObj: any) {
        return { 'text-align': ctrlObj.TextAlign };
    }

    getCtrlLabelStyle(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getCtrlLabelStyleStyleCachedStyles[key]) {
            this.getCtrlLabelStyleStyleCachedStyles[key] = this.getCtrlLabelStyleStyleCompute(ctrlObj);
        }
        return this.getCtrlLabelStyleStyleCachedStyles[key];
    }

    private getCtrlLabelStyleStyleCompute(ctrlObj: any) {
        let textAlign: any = ctrlObj.TextAlign;
        if (ctrlObj.ControlName.toLowerCase() === 'datetimestamp' || ctrlObj.ControlName.toLowerCase() === 'dropdown') textAlign = 'left';

        let width: any;
        if (ctrlObj.IsSigned) {
            if (ctrlObj.ControlName.toLowerCase() === 'label' || ctrlObj.ControlName.toLowerCase() === 'dropdown') width = ctrlObj.CalculatedModalWidth + 'px';
            else width = 'auto';
            return {
                'width': width, 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline, 'text-align': textAlign,
                'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
            };
        }
        else {
            if (ctrlObj.ControlName.toLowerCase() === 'label') width = ctrlObj.Width + 'px';
            else if (ctrlObj.ControlName.toLowerCase() === 'dropdown') width = ctrlObj.CalculatedModalWidth + 'px';
            else width = 'auto';

            return {
                'width': width + 'px', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline, 'text-align': textAlign,
                'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
            };
        }
    }

    getTextInputCtrlInlineStyle(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getTextInputCtrlInlineStyleCachedStyles[key]) {
            this.getTextInputCtrlInlineStyleCachedStyles[key] = this.getTextInputCtrlInlineStyleCompute(ctrlObj);
        }
        return this.getTextInputCtrlInlineStyleCachedStyles[key];
    }

    private getTextInputCtrlInlineStyleCompute(ctrlObj: any) {
        // Perform the actual computation of styles based on the input 'obj'.

        let fontSize: any;
        let fontFamily: any;

        fontSize = ctrlObj.FontSize + 'px';
        fontFamily = ctrlObj.FontName;

        if (navigator.userAgent.includes('iPhone')) {
            fontSize = '0.35em';
            fontFamily = 'Helvetica';
        }

        let textAlign: any = ctrlObj.TextAlign;
        if (ctrlObj.ControlName.toLowerCase() === 'datetimestamp') {
            textAlign = 'left';
        }
        // Return the styles object.
        //DateTimeStamp
        if (ctrlObj.ControlName.toLowerCase() === 'hyperlink') {
            return {
                'font-size': fontSize, 'font-family': fontFamily, 'text-align': textAlign,
                'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': 'underline',
                'color': ctrlObj.FontColor
            };
        }
        else {
            return {
                'font-size': fontSize, 'font-family': fontFamily, 'text-align': textAlign,
                'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                'color': ctrlObj.FontColor
            };
        }
    }

    getCtrlLabelStyleIsReadOnlyNull(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getCtrlLabelStyleIsReadOnlyNullCachedStyles[key]) {
            this.getCtrlLabelStyleIsReadOnlyNullCachedStyles[key] = this.getCtrlLabelStyleIsReadOnlyNullCompute(ctrlObj);
        }
        return this.getCtrlLabelStyleIsReadOnlyNullCachedStyles[key];
    }

    private getCtrlLabelStyleIsReadOnlyNullCompute(ctrlObj: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        // Return the styles object.
        // console.log('getCtrlLabelStyleIsReadOnlyNullCompute')
        let fontSize: any;
        let fontFamily: any;

        fontSize = ctrlObj.FontSize + 'px';
        fontFamily = ctrlObj.FontName;
        if (navigator.userAgent.includes('iPhone')) {
            fontSize = '0.35em';
            fontFamily = 'Helvetica';
        }
        return {
            'height': 'auto', 'font-size': fontSize, 'font-family': fontFamily, 'text-align': ctrlObj.TextAlign,
            'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
            'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
        };

    }

    getTextDateCtrlInlineStyle(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getTextDateCtrlInlineStyleCachedStyles[key]) {
            this.getTextDateCtrlInlineStyleCachedStyles[key] = this.getTextDateCtrlInlineStyleCompute(ctrlObj);
        }
        return this.getTextDateCtrlInlineStyleCachedStyles[key];
    }

    private getTextDateCtrlInlineStyleCompute(ctrlObj: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        let fontSize: any;
        let fontFamily: any;
        let borderSize: any;

        fontSize = ctrlObj.FontSize + 'px';
        fontFamily = ctrlObj.FontName;

        if (navigator.userAgent.includes('iPhone')) {
            fontSize = '0.35em';
            fontFamily = 'Helvetica';
        }
        if (ctrlObj.Required == false) {
            borderSize = '2px solid #3ca6e9';
        }
        else {
            borderSize = '1px solid grey';
        }
        // Return the styles object.
        return {
            'padding': '1px', 'cursor': 'auto', 'height': 'auto', 'width': '100%', 'font-size': fontSize, 'font-family': fontFamily,
            'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline, 'text-align': ctrlObj.TextAlign,
            'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px', 'border': borderSize
        };
    }

    getRequiredControlClass(ctrlObj: any, ctrlValue: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getRequiredControlClassCachedStyles[key]) {
            this.getRequiredControlClassCachedStyles[key] = this.getRequiredControlClassCompute(ctrlObj, ctrlValue);
        }
        return this.getRequiredControlClassCachedStyles[key];
    }

    isNullOrEmptyCheck(stringValueFlag: any) {
        let boolFlag = false;
        if (typeof stringValueFlag != 'undefined' && stringValueFlag !== '' && stringValueFlag !== null && stringValueFlag !== 'false') {
            boolFlag = true;
        }
        return boolFlag;
    }

    private getRequiredControlClassCompute(ctrlObj: any, ctrlValue: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        let requiredControls: any;
        let filledControls: any;

        if (ctrlObj.Required) {
            requiredControls = 'requiredControls';
        } else {
            requiredControls = 'optionalControls';
        }
        if (this.isNullOrEmptyCheck(ctrlValue)) {
            filledControls = 'filledControls';
        } else {
            filledControls = '';
        }

        return requiredControls + ' ' + filledControls;
    }

    //getRequiredDropdownControlClass
    getRequiredDropdownControlClass(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getRequiredDropdownControlClassCachedStyles[key]) {
            this.getRequiredDropdownControlClassCachedStyles[key] = this.getRequiredDropdownControlClassCompute(ctrlObj);
        }
        return this.getRequiredDropdownControlClassCachedStyles[key];
    }

    private getRequiredDropdownControlClassCompute(ctrlObj: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        let requiredControls: any;
        let filledControls: any;

        if (ctrlObj.Required || (ctrlObj.Required && ctrlObj.ControlOptions[0].OptionText != "")) {
            requiredControls = 'requiredControls';
        } else {
            requiredControls = 'optionalControls';
        }

        if (ctrlObj.ControlOptions[0].OptionText != "") {
            filledControls = 'filledControls';
        } else {
            filledControls = '';
        }
        return requiredControls + ' ' + filledControls;
    }

    getPencilClass(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getPencilClassCachedStyles[key]) {
            this.getPencilClassCachedStyles[key] = this.getPencilClassCompute(ctrlObj);
        }
        return this.getPencilClassCachedStyles[key];
    }

    private getPencilClassCompute(ctrlObj: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        let requiredControlsStamp: any;
        let filledControlsStamp: any;

        if (ctrlObj.Required) {
            requiredControlsStamp = 'requiredControlsStamp';
        } else {
            requiredControlsStamp = 'optionalControlsStamp';
        }

        if (this.isNullOrEmptyCheck(ctrlObj.SignatureScr)) {
            filledControlsStamp = 'filledControlsStamp';
        } else {
            filledControlsStamp = '';
        }
        return requiredControlsStamp + ' ' + filledControlsStamp;
    }

    getCtrlDisplayStyle(ctrlObj: any, ctrl: any, type: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getCtrlDisplayStyleCachedStyles[key]) {
            this.getCtrlDisplayStyleCachedStyles[key] = this.getCtrlDisplayStyleCompute(ctrlObj, ctrl, type);
        }
        return this.getCtrlDisplayStyleCachedStyles[key];
    }

    private getCtrlDisplayStyleCompute(controlingFieldId: any, ctrl: any, type: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        let display: any;
        if (type === 'signer') {
            display = (controlingFieldId !== null) ? 'none' : 'block';
        } else if (type === 'static') {
            display = (controlingFieldId !== null || ctrl.IsCurrentRecipient == false) ? 'none' : 'block';
        }
        return { 'display': display };
    }

    getTooltipAlertposition(ctrlObj: any, webOrMobile: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getTooltipAlertpositionCachedStyles[key]) {
            this.getTooltipAlertpositionCachedStyles[key] = this.getTooltipAlertpositionCompute(ctrlObj, webOrMobile);
        }
        return this.getTooltipAlertpositionCachedStyles[key];
    }

    private getTooltipAlertpositionCompute(ctrlObj: any, webOrMobile: any) {
        let styleObj: any = {};
        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            webOrMobile = 'mobile';
        }
        if (document.getElementById(ctrlObj.ControlHtmlID)) {
            let positionleft = ctrlObj.ControlHtmlID ? parseFloat((<HTMLInputElement>document.getElementById(ctrlObj.ControlHtmlID)).style.left.split('px')[0]) : 0;
            let positionTop = ctrlObj.ControlHtmlID ? parseFloat((<HTMLInputElement>document.getElementById(ctrlObj.ControlHtmlID)).style.top.split('px')[0]) : 0;
            if ((<HTMLInputElement>document.getElementById('imgControl_1'))) {
                let documentpagewidth = (<HTMLInputElement>document.getElementById('imgControl_1')).clientWidth / 2;
                let documentpageHeight = (<HTMLInputElement>document.getElementById('imgControl_1')).clientHeight / 2;
                if (webOrMobile === 'mobile') {
                    documentpagewidth = window.innerWidth; // / 2;
                    documentpageHeight = window.innerHeight; // / 2;
                }
                let width: any = '';
                let minWidth: any = '';
                let left: any = '0%'; let ToolTipTranslate: any = '0%';
                let ele: any = document.getElementById(ctrlObj.ControlHtmlID);
                let ToolTipTranslateX: any = 0;
                if (positionTop <= 120) {
                    ToolTipTranslate = '2%';
                    if (positionleft <= documentpagewidth) {
                        if (ele.getAttribute('data-ctntype').toLowerCase() === 'checkbox') {
                            ToolTipTranslateX = '0%';
                            ToolTipTranslate = '10%';
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'radio') {
                            ToolTipTranslateX = '1%';
                            ToolTipTranslate = '8%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '0%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'dropdown') {
                            let eleRef: any = document.getElementById(ctrlObj.ControlHtmlID);
                            let tooltipEleRef: any = document.getElementById('tooltip_' + ctrlObj.ControlHtmlID);
                            ToolTipTranslate = '-45%';
                            if (eleRef != null && tooltipEleRef != null) {
                                ToolTipTranslateX = (16 + parseInt(eleRef.style.width.split('px')[0])) + 'px';
                                if (webOrMobile === 'mobile') {
                                    ToolTipTranslateX = (10 + parseInt(eleRef.style.width.split('px')[0])) + 'px';
                                    if (navigator.userAgent.includes('iPhone')) {
                                        ToolTipTranslate = '-48%';
                                    }
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'newinitials') {
                            ToolTipTranslateX = '2%';
                            ToolTipTranslate = '4%';
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'signature') {
                            ToolTipTranslate = '6%';
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'text') {
                            let eleRef: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                            let tooltipEleRef: any = document.getElementById('tooltip_' + ctrlObj.ControlHtmlID);
                            if (eleRef != null && tooltipEleRef != null) {
                                tooltipEleRef.style.top = eleRef.style.height;
                            }
                            ToolTipTranslate = '35%';
                        } else {
                            ToolTipTranslate = '2%';
                        }
                    } else {
                        ToolTipTranslateX = '-85%';
                        if (ele.getAttribute('data-ctntype').toLowerCase() === 'checkbox') {
                            ToolTipTranslateX = '-90%';
                            ToolTipTranslate = '11%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-89%';
                                ToolTipTranslate = '11%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslate = '8%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'radio') {
                            ToolTipTranslateX = '-88%';
                            ToolTipTranslate = '8%';
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'dropdown') {
                            ToolTipTranslateX = '-104%';
                            ToolTipTranslate = '-45%';
                            if (webOrMobile === 'mobile') {
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslate = '-48%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'newinitials') {
                            ToolTipTranslateX = '-90%';
                            ToolTipTranslate = '4%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-85%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslateX = '-85%';
                                    ToolTipTranslate = '4%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'signature') {
                            ToolTipTranslate = '6%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '0%';
                                ToolTipTranslate = '7%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'name') {
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-60%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'text') {
                            let eleRef: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                            let tooltipEleRef: any = document.getElementById('tooltip_' + ctrlObj.ControlHtmlID);
                            if (eleRef != null && tooltipEleRef != null) {
                                tooltipEleRef.style.top = eleRef.style.height;
                            }
                            ToolTipTranslate = '35%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '0%';
                            }
                        } else {
                            ToolTipTranslate = '2%';
                        }
                    }
                } else {
                    if (positionleft <= documentpagewidth) {
                        ToolTipTranslate = '-150%';
                        if (ele.getAttribute('data-ctntype').toLowerCase() === 'checkbox') {
                            ToolTipTranslateX = '0%';
                            ToolTipTranslate = '-148%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '0%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslateX = '-2%';
                                    ToolTipTranslate = '-148%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'radio') {
                            ToolTipTranslateX = '0%';
                            ToolTipTranslate = '-148%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '0%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslateX = '-2%';
                                    ToolTipTranslate = '-148%'
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'signature') {
                            ToolTipTranslate = '-178%';
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'newinitials') {
                            ToolTipTranslateX = '2%';
                            ToolTipTranslate = '-157%';
                            if (webOrMobile === 'mobile') {
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslateX = '-10%';
                                    ToolTipTranslate = '-165%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'dropdown') {
                            ToolTipTranslate = '-158%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '0%';
                                ToolTipTranslate = '-160%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslateX = '0%';
                                    ToolTipTranslate = '-160%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'text') {
                            ToolTipTranslate = '-120%';
                            let eleRef: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                            let tooltipEleRef: any = document.getElementById('tooltip_' + ctrlObj.ControlHtmlID);
                            if (eleRef != null && tooltipEleRef != null) {
                                tooltipEleRef.style.top = '0px';
                            }
                        } else {
                            ToolTipTranslate = '-150%';
                        }
                    } else {
                        ToolTipTranslateX = '-85%';
                        ToolTipTranslate = '-150%';
                        if (ele.getAttribute('data-ctntype').toLowerCase() === 'checkbox') {
                            ToolTipTranslateX = '-90%';
                            ToolTipTranslate = '-148%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-90%';
                                ToolTipTranslate = '-150%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslate = '-148%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'radio') {
                            ToolTipTranslateX = '-89%';
                            ToolTipTranslate = '-148%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-88%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslateX = '-90%'
                                    ToolTipTranslate = '-148%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'signature') {
                            ToolTipTranslate = '-178%';
                            if (navigator.userAgent.includes('iPhone')) {
                                ToolTipTranslateX = '-65%';
                                ToolTipTranslate = '-175%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'newinitials') {
                            ToolTipTranslate = '-158%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-85%';
                                ToolTipTranslate = '-155%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    ToolTipTranslateX = '-80%';
                                    ToolTipTranslate = '-160%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'dropdown') {
                            ToolTipTranslate = '-160%';
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-85%';
                                ToolTipTranslate = '-160%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'name') {
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-70%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'email') {
                            if (webOrMobile === 'mobile') {
                                ToolTipTranslateX = '-55%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'text') {
                            ToolTipTranslate = '-120%';
                            let eleRef: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                            let tooltipEleRef: any = document.getElementById('tooltip_' + ctrlObj.ControlHtmlID);
                            if (eleRef != null && tooltipEleRef != null) {
                                tooltipEleRef.style.top = '0px';
                            }
                        } else {
                            ToolTipTranslate = '-150%';
                            if (navigator.userAgent.includes('iPhone')) {
                                ToolTipTranslate = '-148%';
                            }
                        }
                    }
                }
                styleObj = {
                    'display': 'block', 'border-radius': '8px', 'left': left,
                    'transform': "translate(" + ToolTipTranslateX + ", " + ToolTipTranslate + ")"
                };
            }
        }
        return styleObj;
    }

    getTooltipTrangleposition(ctrlObj: any, webOrMobile: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getTooltipTranglepositionCachedStyles[key]) {
            this.getTooltipTranglepositionCachedStyles[key] = this.getTooltipTranglepositionCompute(ctrlObj, webOrMobile);
        }
        return this.getTooltipTranglepositionCachedStyles[key];
    }

    private getTooltipTranglepositionCompute(ctrlObj: any, webOrMobile: any) {
        let styleObj: any = {};
        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            webOrMobile = 'mobile';
        }
        if (document.getElementById(ctrlObj.ControlHtmlID)) {
            let positionleft = ctrlObj.ControlHtmlID ? parseFloat((<HTMLInputElement>document.getElementById(ctrlObj.ControlHtmlID)).style.left.split('px')[0]) : 0;
            let positionTop = ctrlObj.ControlHtmlID ? parseFloat((<HTMLInputElement>document.getElementById(ctrlObj.ControlHtmlID)).style.top.split('px')[0]) : 0;
            if ((<HTMLInputElement>document.getElementById('imgControl_1'))) {
                let documentpagewidth = (<HTMLInputElement>document.getElementById('imgControl_1')).clientWidth / 2;
                let documentpageHeight = (<HTMLInputElement>document.getElementById('imgControl_1')).clientHeight / 2;
                if (webOrMobile === 'mobile') {
                    documentpagewidth = window.innerWidth; // / 2;
                    documentpageHeight = window.innerHeight; // / 2;
                }
                let rotatezDeg: any = '';
                let toolTipRight: any = '85%';
                let toolTipTop: any = '';
                let ele: any = document.getElementById(ctrlObj.ControlHtmlID);
                if (positionTop <= 120) {
                    if (positionleft <= documentpagewidth) {
                        if (ele.getAttribute('data-ctntype').toLowerCase() === 'dropdown') {
                            rotatezDeg = '0';
                            toolTipRight = '100%';
                            toolTipTop = '15%';
                            if (webOrMobile === 'mobile') {
                                toolTipTop = '10%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    toolTipTop = '12%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'radio') {
                            rotatezDeg = '90';
                            toolTipRight = '90%';
                            toolTipTop = '-15%';
                        } else {
                            rotatezDeg = '90';
                            toolTipRight = '90%';
                            toolTipTop = '-15%';
                        }
                    } else {
                        if (ele.getAttribute('data-ctntype').toLowerCase() === 'dropdown') {
                            rotatezDeg = '180';
                            toolTipRight = '-5%';
                            toolTipTop = '15%';
                            if (webOrMobile === 'mobile') {
                                toolTipTop = '10%';
                                if (navigator.userAgent.includes('iPhone')) {
                                    toolTipTop = '12%';
                                }
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'checkbox') {
                            rotatezDeg = '90';
                            toolTipRight = '4%';
                            toolTipTop = '-16%';
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'radio') {
                            rotatezDeg = '90';
                            toolTipRight = '4%';
                            toolTipTop = '-16%';
                        } else {
                            rotatezDeg = '90';
                            toolTipRight = '4%';
                            toolTipTop = '-16%';
                            if (webOrMobile === 'mobile') {
                                toolTipRight = '90%';
                            }
                        }
                    }
                } else {
                    if (positionleft <= documentpagewidth) {
                        if (ele.getAttribute('data-ctntype').toLowerCase() === 'checkbox') {
                            rotatezDeg = '270';
                            toolTipTop = '99%';
                            toolTipRight = '90%';
                            let checkNameisFilled: any = document.getElementById('control_' + ele.id);
                            if (checkNameisFilled.getAttribute('name') === '' && (ele.getAttribute('data-rq') === 'false' || ele.getAttribute('data-rq') === 'true')) {
                                toolTipRight = '92%';
                            } else if (checkNameisFilled.getAttribute('name') !== '' && (ele.getAttribute('data-rq') === 'false')) {
                                toolTipRight = '89%';
                            } else if (checkNameisFilled.getAttribute('name') !== '' && (ele.getAttribute('data-rq') === 'true')) {
                                toolTipRight = '90%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'radio') {
                            rotatezDeg = '270';
                            toolTipTop = '99%';
                            toolTipRight = '90%';
                            if (webOrMobile === 'mobile') {
                                toolTipRight = '89%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'newinitials') {
                            rotatezDeg = '270';
                            toolTipRight = '90%';
                            toolTipTop = '100%';
                            if (navigator.userAgent.includes('iPhone')) {
                                toolTipRight = '77%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'dropdown') {
                            rotatezDeg = '270';
                            toolTipRight = '85%';
                            toolTipTop = '100%';
                            if (navigator.userAgent.includes('iPhone')) {
                                toolTipRight = '90%';
                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'text') {
                            rotatezDeg = '270';
                            toolTipRight = '85%';
                            toolTipTop = '99%';
                        } else {
                            rotatezDeg = '270';
                            toolTipRight = '85%';
                            toolTipTop = '99%';
                        }
                    } else {
                        if (ele.getAttribute('data-ctntype').toLowerCase() === 'checkbox') {
                            rotatezDeg = '270';
                            toolTipRight = '4%';
                            toolTipTop = '99%';
                            if (webOrMobile === 'mobile') {
                                toolTipRight = '4%';

                            }
                        } else if (ele.getAttribute('data-ctntype').toLowerCase() === 'radio') {
                            rotatezDeg = '270';
                            toolTipRight = '5%';
                            toolTipTop = '99%';
                            if (webOrMobile === 'mobile') {
                                toolTipRight = '5%';
                            }
                        } else {
                            rotatezDeg = '270';
                            toolTipRight = '5%';
                            toolTipTop = '99%';
                            if (webOrMobile === 'mobile') {
                                toolTipRight = '6%';
                            }

                        }
                    }
                }
                styleObj = {
                    'display': 'block', 'transform': "rotateZ(" + rotatezDeg + "deg)", 'right': toolTipRight, 'top': toolTipTop
                };
            }
        }
        return styleObj;
    }

    getTextNotDateAndEmailCtrlStyle(ctrlObj: any) {
        const key = JSON.stringify(ctrlObj);
        if (!this.getTextNotDateAndEmailCtrlStyleCachedStyles[key]) {
            this.getTextNotDateAndEmailCtrlStyleCachedStyles[key] = this.getTextNotDateAndEmailCtrlStyleCompute(ctrlObj);
        }
        return this.getTextNotDateAndEmailCtrlStyleCachedStyles[key];
    }

    private getTextNotDateAndEmailCtrlStyleCompute(ctrlObj: any) {
        // Perform the actual computation of styles based on the input 'obj'.
        let fontSize: any;
        let fontFamily: any;
        let borderSize: any;

        fontSize = ctrlObj.FontSize + 'px';
        fontFamily = ctrlObj.FontName;

        if (navigator.userAgent.includes('iPhone')) {
            fontSize = '0.35em';
            fontFamily = 'Helvetica';
        }
        if (ctrlObj.ControlName.toLowerCase() === 'text') {
            if (ctrlObj.Required == false) {
                borderSize = '2px solid #3ca6e9';
            }
            else {
                borderSize = '1px solid grey';
            }
            return {
                'overflow': 'hidden', 'resize': 'none', 'width': ctrlObj.CalculatedModalWidth + 'px', 'height': ctrlObj.Height + 'px',
                'font-size': fontSize, 'font-family': fontFamily, 'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-align': ctrlObj.TextAlign,
                'text-decoration': ctrlObj.FontUnderline, 'color': ctrlObj.FontColor, 'padding': '1px', 'box-sizing': 'border-box', 'border': borderSize, 'line-height': 'normal'
            };
        }
        else {
            return {
                'overflow': 'hidden', 'resize': 'none', 'width': ctrlObj.CalculatedModalWidth + 'px', 'height': ctrlObj.Height + 'px',
                'font-size': fontSize, 'font-family': fontFamily, 'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-align': ctrlObj.TextAlign,
                'text-decoration': ctrlObj.FontUnderline, 'color': ctrlObj.FontColor, 'line-height': 'normal'
            };
        }
    }

    getCtrlInlineStyle(ctrlObj: any) {
        let topPos: any;
        let leftPos: any;
        let modalWidth: any;
        if (ctrlObj.CalculatedTop) {
            topPos = this.controlCalculatedTopVal(ctrlObj);
        }

        if (ctrlObj.CalculatedLeft) {
            leftPos = ctrlObj.CalculatedLeft.split('left:')[1];
        }

        if (ctrlObj.IsSigned) {
            if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'label' || ctrlObj.ControlName.toLowerCase() === 'name' || ctrlObj.ControlName.toLowerCase() === 'title'
                || ctrlObj.ControlName.toLowerCase() === 'newinitials' || ctrlObj.ControlName.toLowerCase() === 'email' || ctrlObj.ControlName.toLowerCase() === 'company' || ctrlObj.ControlName.toLowerCase() === 'date'
                || ctrlObj.ControlName.toLowerCase() === 'initials') {
                modalWidth = ctrlObj.CalculatedModalWidth + 'px';
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'text' || ctrlObj.ControlName.toLowerCase() === 'radio' || ctrlObj.ControlName.toLowerCase() === 'checkbox' || ctrlObj.ControlName.toLowerCase() === 'dropdown') {
                modalWidth = ctrlObj.Width + 'px';
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'datetimestamp') {
                modalWidth = 'auto';
            }
        }
        else {
            if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'newinitials' || ctrlObj.ControlName.toLowerCase() === 'dropdown') {
                modalWidth = ctrlObj.CalculatedModalWidth + 'px';
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'text' || ctrlObj.ControlName.toLowerCase() === 'name' ||
                ctrlObj.ControlName.toLowerCase() === 'email' || ctrlObj.ControlName.toLowerCase() === 'title' ||
                ctrlObj.ControlName.toLowerCase() === 'date' || ctrlObj.ControlName.toLowerCase() === 'company') {
                modalWidth = 'auto';
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'label' || ctrlObj.ControlName.toLowerCase() === 'radio' ||
                ctrlObj.ControlName.toLowerCase() === 'checkbox') {
                modalWidth = ctrlObj.Width + 'px';
            }
        }
        return { 'left': leftPos, 'top': topPos, 'width': modalWidth, 'height': ctrlObj.Height + 'px', 'position': 'absolute' };
    }

    getImageInlineStyle(ctrlObj: any) {
        let modalWidth: any;
        if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'newinitials') {
            modalWidth = ctrlObj.CalculatedModalWidth;
        }
        else {
            modalWidth = ctrlObj.Width;
        }
        return { 'max-width': modalWidth + 'px', 'max-height': ctrlObj.Height + 'px' };
    }

    getPencilIconStyle(electronicSignIndication: any) {
        if (electronicSignIndication != null && electronicSignIndication != undefined && electronicSignIndication != "") {
            electronicSignIndication = parseInt(electronicSignIndication);
        }
        else {
            electronicSignIndication = 1;
        }

        if (electronicSignIndication > 1) {
            return { 'display': 'block' };
        }
        else {
            return { 'display': 'none' };
        }
    }


    getPreviewImageStyle() {
        let className: any = '';
        let isUploadImgFlag: any = localStorage.getItem('isUploadImgFlag');
        if (isUploadImgFlag === '1') {
            className = 'mainPreviewImageStyle';
        }
        return className;
    }
}
