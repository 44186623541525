import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants, RouteUrls } from '../../constants/common-constants';
import { SignerLandingService } from '../../services/signer-landing.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { CommonService } from 'src/app/services/common.service';
import { ResourceKeys } from 'src/app/constants/common-constants';
import { TranslationsService } from 'src/app/services/translations.service';

@Component({
    selector: 'app-signer-intermediate-continue',
    templateUrl: './signer-intermediate-continue.component.html',
    styleUrls: ['./signer-intermediate-continue.component.scss']
})
export class SignerIntermediateContinueComponent implements OnInit {
    emailAddress: any;
    signerRegObject: any;
    returnUrl: any;
    uiRedirectUrl: any = environment.uiBaseUrl;
    domainUrl: any;
    showLoader: boolean = false;
    MEmail: any;
    spEmail: any;
    isSignedDocumentService: boolean = false
    toastrMessage: any;
    resourceKeys = ResourceKeys;
    lang_success: any = '';

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private commonService: CommonService,
        private toastr: ToastrService,
        private signerLandingService: SignerLandingService,
        private translationsService: TranslationsService
    ) { }

    ngOnInit(): void {
        this.lang_success = this.translationsService.getLanguageValidationVal('lang_success');
        let encodedEmail:any = localStorage.getItem('EmailId')
        const decodedEmail = decodeURIComponent(encodedEmail);
        this.emailAddress = decodedEmail;
        localStorage.setItem('EmailId', decodedEmail);
        this.signerRegObject = this;
        this.returnUrl = this.signerRegObject.modalPopupObj.returnUrl;
        this.MEmail = this.signerRegObject.modalPopupObj.MEmail;
        this.spEmail = this.signerRegObject.modalPopupObj.spEmail;
        this.isSignedDocumentService = this.signerRegObject.modalPopupObj.isSignedDocumentService;
        this.toastrMessage = this.signerRegObject.modalPopupObj.toastrMessage;
        if (this.emailAddress == null || this.emailAddress == "null" || this.emailAddress == "" || this.emailAddress == undefined)
            this.emailAddress = this.MEmail;

        // if (this.isSignedDocumentService) {
        //     let downloadFinalContractBtn: any = document.getElementById("downloadFinalContractBtn");
        //     downloadFinalContractBtn?.setAttribute('disabled', 'disabled');
        // }
    }

    onNoThanks() {
        this.activeModal.dismiss('Cross click');
        if (this.toastrMessage != null && this.toastrMessage != undefined) {
            this.toastr.success(this.toastrMessage, this.lang_success, { timeOut: 2000 });
        }
        if (this.returnUrl != RouteUrls.Info) {
            this.commonService.saveSignerProfileDetailsToSession();
        }
        this.commonService.getReturnUrlDirection(this.returnUrl);
    }

    onContinue() {
        this.activeModal.dismiss('Cross click');
        this.commonService.saveSignerProfileDetailsToSession();
        this.domainUrl = this.uiRedirectUrl + "Home/Index";
        this.commonService.getReturnUrlDirection(this.domainUrl);
        // window.location.href = this.domainUrl;
    }

    // download pdf
    onDownload() {
        let envelopeID = localStorage.getItem('EnvelopeID');
        let recipientTypeId = localStorage.getItem('recipientTypeId')
        let url: any = CommonConstants.DownloadFinalContract + '/' + envelopeID + '/0/' + recipientTypeId;
        //console.log('aaaa', url);
        this.showLoader = true;
        this.signerLandingService.getDownloadFinalContract(url).subscribe(
            (resp: any) => {
                //console.log('res')
                this.showLoader = false;
                let base64data: any = resp.Base64FileData;
                if (base64data) {
                    var blob = this.commonService.b64toBlob(base64data, resp.FileType);
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = window.URL.createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    window.URL.revokeObjectURL(bloburl);
                    a.remove();
                }
                else if (resp.originalError.error.Message == CommonConstants.DeleteFinalContractDisplayMsg || this.isSignedDocumentService) {
                    let documentGenerationMessage: any = this.translationsService.getLanguageTranslationVal(ResourceKeys.DocumentGenerationMessage);
                    if (documentGenerationMessage == null || documentGenerationMessage == undefined)
                        documentGenerationMessage = CommonConstants.DocumentGenerationMessage;
                    this.toastr.info(documentGenerationMessage, 'Attention', { timeOut: 6000 });
                    return;
                }
            });
    }
    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

}
