import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ChangeSignerModalComponent } from './modal/change-signer-modal/change-signer-modal.component';
import { DeclineModalComponent } from './modal/decline-modal/decline-modal.component';
import { SignaturePadModalComponent } from './modal/signature-pad-modal/signature-pad-modal.component';
import { SignerLandingService } from './services/signer-landing.service'
import { PaginationCustomizationComponent } from './components/pagination-customization/pagination-customization.component';
import { AttachmentsComponent } from './modal/attachments/attachments.component';
import { InviteSignersComponent } from './modal/invite-signers/invite-signers.component';
import { FilterPipe } from './common/filter.pipe';
import { SignerLandingComponent } from './components/signer-landing/signer-landing.component';
import { PasswordWindowComponent } from './components/password-window/password-window.component';
import { InfoComponent } from './components/info/info.component';
import { InitialsPadComponent } from './modal/initials-pad/initials-pad.component';
import { SignerRegistrationComponent } from './modal/signer-registration/signer-registration.component';
import { SignerIntermediateComponent } from './modal/signer-intermediate/signer-intermediate.component';
import { SignerIntermediateContinueComponent } from './modal/signer-intermediate-continue/signer-intermediate-continue.component';
import { StaticLandingComponent } from './components/static-landing/static-landing.component';
import { AuthenticateSignerComponent } from './components/authenticate-signer/authenticate-signer.component';
import { DefaultComponent } from './components/default/default.component';
import { SignMultiTemplateComponent } from './components/sign-multi-template/sign-multi-template.component';
import { SecurityCodeComponent } from './components/security-code/security-code.component';
import { TranslationsService } from './services/translations.service';
import { ConfirmToBegainComponent } from './components/confirm-to-begain/confirm-to-begain.component';
import { StaticLinkComponent } from './components/static-link/static-link.component';
import { StaticPasswordWindowComponent } from './components/static-password-window/static-password-window.component';
import { StaticDeclineModalComponent } from './modal/static-decline-modal/static-decline-modal.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { SignerValidateComponent } from './components/signer-validate/signer-validate.component';
import { SignerIntermediateCloseComponent } from './modal/signer-intermediate-close/signer-intermediate-close.component';
import { GlobalErrorComponent } from './components/global-error/global-error.component';
import { RclickSignatureComponent } from './components/rclick-signature/rclick-signature.component';
import { PrefillLandingComponent } from './components/prefill-landing/prefill-landing.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ChangeSignerModalComponent,
    DeclineModalComponent,
    SignaturePadModalComponent,
    PaginationCustomizationComponent,
    AttachmentsComponent,
    InviteSignersComponent,
    FilterPipe,
    SignerLandingComponent,
    PasswordWindowComponent,
    InfoComponent,
    InitialsPadComponent,
    SignerRegistrationComponent,
    SignerIntermediateComponent,
    SignerIntermediateContinueComponent,
    StaticLandingComponent,
    AuthenticateSignerComponent,
    DefaultComponent,
    SignMultiTemplateComponent,
    SecurityCodeComponent,
    ConfirmToBegainComponent,
    StaticLinkComponent,
    StaticPasswordWindowComponent,
    StaticDeclineModalComponent,
    SignerValidateComponent,
    SignerIntermediateCloseComponent,
    GlobalErrorComponent,
    RclickSignatureComponent,
    PrefillLandingComponent    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDatepickerModule,
    NgbModule,
    FormsModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    ToastrModule.forRoot({
      positionClass:'toast-top-right',
      preventDuplicates: true,
      timeOut:1000,
      closeButton: true,
    }),
  PinchZoomModule  
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [DatePipe, SignerLandingService,TranslationsService,Title,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaSiteKey,
      } as RecaptchaSettings,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
