import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailAddressFormat, EnvelopeStatusText, MessageTypes, ResourceKeys, RouteUrls } from 'src/app/constants/common-constants';
import { SignerLandingService } from '../../services/signer-landing.service';
import { CommonConstants } from '../../constants/common-constants';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslationsService } from '../../services/translations.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
    title = 'RSign | Info';
    showLoader: boolean = false;
    isErrorFlag: boolean = false;
    isSuccessFlag: boolean = false;
    isWarningFlag: boolean = false;
    isApplicationErrorFlag: boolean = false;
    displayMessage: any;
    controlInfo: any;
    isExpired: boolean = false;
    isSendConfirmationEmail: boolean = false;
    notExpiredAndEmail: boolean = false;
    isSuccessWarning: boolean = false;

    LearnMoreTextOnly: any;
    freeTrial: any;
    RedirectPostSigningLandingPage: any;
    Sessionpopupseconds: any;
    isSuccessErrorMessage: boolean = false;
    eSignExpiredFeatureList: any;
    eSignExpiredServiceFeature: any;
    RSignMakesESignatureText: any;
    laungaueTransalation = [];
    commonConstants = CommonConstants;
    resourceKeys = ResourceKeys;
    isFromFinishLater: any;
    IsApplicationError: any;
    DateTimeMessage1: any;
    DateTimeMessage2: any;
    htmlFooterString: any;
    isFromSessionExpired: any;
    isDisplayLoginButton: boolean = false;
    isDisplaySignUpButton: boolean = false;
    envelopeCompleted: boolean = false;
    AllowSignersDownloadFinalContract: boolean = false;
    SignInText: any;
    SignUpText: any;
    downloadFinalSignedDocFromCompletedFolderText: any;
    downloadDocAfterSignedText: any;
    signupDownloadFinalSignedDocText: any;
    isDelegated: any = "";
    IsSendConfirmEmailPopupOpen: any = "";
    cultureInfo: any = "en-us.json";
    isAutoLocked: any = false;
    autoLockResp: any;
    isEmptyEnvelopeTemplateCode: boolean = false;
    dvAutoLockContinuePopup: any = 'none';
    webOrMobile: any = 'web';

    constructor(private router: Router, private signerLandingService: SignerLandingService, private sanitizer: DomSanitizer,
        private translationsService: TranslationsService, private commonService: CommonService, private titleService: Title) { }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        console.log('Back button pressed');
        window.location.reload();
    }

    ngOnInit(): void {
        this.titleService.setTitle(this.title);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.IsApplicationError = localStorage.getItem("IsApplicationError");
        let envelopeId = localStorage.getItem("EnvelopeID");
        let emailAddr = localStorage.getItem("EmailId");
        let MessageType = localStorage.getItem("MessageType");
        if (this.IsApplicationError == "true" || MessageType == MessageTypes.Error) {
            this.commonService.getReturnUrlDirection(RouteUrls.GlobalError);
            return;
        }
        else {
            this.htmlFooterString = '<p> ' +
                '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
                '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
                '</p>';

                if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                    this.webOrMobile = 'mobile';
                  }

            let footer: any = localStorage.getItem("htmlFooterString");
            if(footer != null && footer != "" && footer != undefined && footer !='null'){
                this.htmlFooterString = footer;
            }

            this.showLoader = true;
            //let encryptedSender = localStorage.getItem("encryptedSender");
            this.displayMessage = localStorage.getItem("DisplayMessage");
            if (this.displayMessage == CommonConstants.AccessDeniedMessage) {
                this.isAutoLocked = true;
                let autoLockRespMessage: any = localStorage.getItem("AutoLockRespMessage");
                if (autoLockRespMessage) {
                    this.autoLockResp = JSON.parse(autoLockRespMessage);
                    if (this.autoLockResp.EnvelopeTemplateCode) this.isEmptyEnvelopeTemplateCode = false;
                    else this.isEmptyEnvelopeTemplateCode = true;
                }
                else {
                    this.autoLockResp = '';
                    this.isEmptyEnvelopeTemplateCode = true;
                }
                this.showLoader = false;
            }
            else {
                this.controlInfo = localStorage.getItem("ControlInfo");
                this.isFromFinishLater = localStorage.getItem("IsFromFinishLater");
                this.isFromSessionExpired = localStorage.getItem("IsFromSessionExpired");
                this.isDelegated = localStorage.getItem("IsDelegated");
                this.IsSendConfirmEmailPopupOpen = localStorage.getItem("IsSendConfirmEmailPopupOpen");

                if (this.displayMessage == "null" || this.displayMessage == null) this.displayMessage = "";

                if (this.isFromFinishLater) this.displayMessage = this.sanitizer.bypassSecurityTrustHtml(this.displayMessage);

                if (MessageType == MessageTypes.Error) {
                    this.isErrorFlag = true;
                }
                else if (MessageType == MessageTypes.Warning) {
                    this.isWarningFlag = true;
                }
                else if (MessageType == MessageTypes.Success) {
                    this.isSuccessFlag = true;
                }
                else if (MessageType == MessageTypes.SuccessWarning) {
                    this.isSuccessWarning = true;
                }
                else if (MessageType == MessageTypes.SuccessErrorMessage) {
                    this.isSuccessErrorMessage = true;
                }
                else if (MessageType == MessageTypes.ArchivedOrPurged) {
                    this.isSuccessErrorMessage = true;
                }
                else {
                    this.isApplicationErrorFlag = true;
                }

                if (this.controlInfo == MessageTypes.Expired) {
                    this.isExpired = true;
                }
                if (this.controlInfo == MessageTypes.SendConfirmationEmail) {
                    this.isSendConfirmationEmail = true;
                }

                if (this.controlInfo == "" || this.controlInfo == null || this.controlInfo == undefined) {
                    this.notExpiredAndEmail = true;
                }

                if (this.isErrorFlag == false && this.isWarningFlag == false && this.isSuccessFlag == false &&
                    this.isSuccessWarning == false && this.isSuccessErrorMessage == false && this.isSuccessErrorMessage == false
                    && this.displayMessage == "" && (MessageType == "" || MessageType == null)) {
                    this.isApplicationErrorFlag = false;
                    this.displayMessage = "Session expired. Please close this browser tab.";
                    this.isWarningFlag = true;
                }

                let allowSignerDownloadFinalContract: any = localStorage.getItem("AllowSignersDownloadFinalContract");
                if (allowSignerDownloadFinalContract == "true") {
                    let DisplayLoginButton: any = localStorage.getItem("DisplayLoginButton");
                    let DisplaySignUpButton: any = localStorage.getItem("DisplaySignUpButton");
                    let EnvelopeStatus: any = localStorage.getItem("EnvelopeStatus");

                    if (DisplayLoginButton != undefined && DisplayLoginButton != null && DisplayLoginButton != "" && DisplayLoginButton == "true") {
                        this.isDisplayLoginButton = true; this.isDisplaySignUpButton = false;
                    }
                    if (DisplaySignUpButton != undefined && DisplaySignUpButton != null && DisplaySignUpButton != "" && DisplaySignUpButton == "true") {
                        this.isDisplayLoginButton = false; this.isDisplaySignUpButton = true;
                    }

                    if (EnvelopeStatus == EnvelopeStatusText.Completed) this.envelopeCompleted = true;
                    else if (EnvelopeStatus == EnvelopeStatusText.EnvelopeAccepted) this.envelopeCompleted = false;
                    else if (EnvelopeStatus == EnvelopeStatusText.Declined) this.envelopeCompleted = true;
                    this.AllowSignersDownloadFinalContract = true;
                }
                this.getLanguageTranslations();
            }
        }
    }

    redirectCorporateURL(PageName: any, OpenType: any) {
        let getRedirectUrl = this.commonConstants.GetRedirectURL + "/" + PageName;
        this.signerLandingService.getRedirectURL(getRedirectUrl).subscribe(
            (resp: any) => {
                this.commonService.saveSignerProfileDetailsToSession();
                if (resp && resp.StatusCode == 200) {
                    if (resp.Message != null) {
                        OpenType == 'self' ? window.location.href = resp.Message : window.open(resp.Message, "_blank");
                    }
                }
            })
    }

    getLanguageTranslations() {
        if (sessionStorage.getItem('LaungaueTransalation') == null || sessionStorage.getItem('LanguageValidation') == null
            || sessionStorage.getItem('LaungaueTransalation') == undefined || sessionStorage.getItem('LanguageValidation') == undefined) {

            // let requestObj = { EnvelopeId: "", RecipientId: "", CultureInfo: "en-us" };

            // let getLanguageTranslationsUrl = CommonConstants.getLanguageTranslationsUrl;
            // this.signerLandingService.getLanguageTranslations(getLanguageTranslationsUrl, requestObj).subscribe(
            //     (resp: any) => {
            //         if (resp && resp.StatusCode == 200) {
            //             let languageTranslations: any = resp.LanguageTranslationsModel;
            //             sessionStorage.setItem("LaungaueTransalation", JSON.stringify(languageTranslations.Language));
            //             sessionStorage.setItem("LanguageValidation", JSON.stringify(languageTranslations.LanguageValidation));
            //             this.getResourceKeyLanguagevalues();
            //         }
            //         else {
            //             this.showLoader = false;
            //             this.RSignMakesESignatureText = CommonConstants.RSignMakesESignatureText;
            //             this.LearnMoreTextOnly = CommonConstants.LearnMoreTextOnly;
            //             this.freeTrial = CommonConstants.FreeTrialText;
            //         }
            //     })

            let culture: any = localStorage.getItem("cultureInfo");
            if (culture == null || culture == "" || culture == "null" || culture == "undefined" || culture == undefined) {
                culture = "en-us";
            }
            culture = culture.toLowerCase() + ".json";
            this.cultureInfo = culture;
            this.translationsService.getLanguageJSON(culture).subscribe(data => {
                sessionStorage.setItem("LaungaueTransalation", JSON.stringify(data));
            });

            this.translationsService.getLanguageValidationJSON(culture).subscribe(data => {
                sessionStorage.setItem("LanguageValidation", JSON.stringify(data));
            });

            setTimeout(() => {
                this.getResourceKeyLanguagevalues();
            }, 1000);
        }
        else {
            setTimeout(() => {
                this.getResourceKeyLanguagevalues();
            }, 1000);
        }
    }

    getResourceKeyLanguagevalues() {
        let sessionTranslation: any = sessionStorage.getItem("LaungaueTransalation");
        this.laungaueTransalation = JSON.parse(sessionTranslation);
        if (this.laungaueTransalation != null) {
            this.LearnMoreTextOnly = this.laungaueTransalation[ResourceKeys.LearnMoreTextOnly.toLowerCase()];
            this.freeTrial = this.laungaueTransalation[ResourceKeys.freeTrial.toLowerCase()];
            this.RedirectPostSigningLandingPage = this.laungaueTransalation[ResourceKeys.RedirectPostSigningLandingPage.toLowerCase()];
            this.Sessionpopupseconds = this.laungaueTransalation[ResourceKeys.Sessionpopupseconds.toLowerCase()];
            this.eSignExpiredFeatureList = this.laungaueTransalation[ResourceKeys.eSignExpiredFeatureList.toLowerCase()];
            this.eSignExpiredServiceFeature = this.laungaueTransalation[ResourceKeys.eSignExpiredServiceFeature.toLowerCase()];
            this.RSignMakesESignatureText = this.laungaueTransalation[ResourceKeys.RSignMakesESignatureText.toLowerCase()];
            this.SignInText = this.laungaueTransalation[ResourceKeys.lang_LogIn.toLowerCase()];
            this.SignUpText = this.laungaueTransalation[ResourceKeys.SignUp.toLowerCase()];
            this.downloadFinalSignedDocFromCompletedFolderText = this.laungaueTransalation[ResourceKeys.DownloadFinalSignedDocText.toLowerCase()];
            this.downloadDocAfterSignedText = this.laungaueTransalation[ResourceKeys.DownloadDocAfterSignedText.toLowerCase()];
            this.signupDownloadFinalSignedDocText = this.laungaueTransalation[ResourceKeys.SignupDownloadFinalSignedDocText.toLowerCase()];
        }
        else {
            this.translationsService.getLanguageJSON(this.cultureInfo).subscribe(data => {
                sessionStorage.setItem("LaungaueTransalation", JSON.stringify(data));
            });

            this.translationsService.getLanguageValidationJSON(this.cultureInfo).subscribe(data => {
                sessionStorage.setItem("LanguageValidation", JSON.stringify(data));
            });

            setTimeout(() => {
                this.getResourceKeyLanguagevalues();
            }, 1000);
        }

        this.commonService.saveSignerProfileDetailsToSession();
        this.showLoader = false;
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    onSignin() {
        this.commonService.saveSignerProfileDetailsToSession();
        let baseUrl = environment.uiBaseUrl;
        window.location.href = environment.uiBaseUrl + "Account/LogOn";
    }

    onSignup() {
        this.commonService.saveSignerProfileDetailsToSession();
        window.location.href = CommonConstants.RSignSignupCompleted;
    }

    onRequestAccessClick() {
        this.dvAutoLockContinuePopup = 'block';
        $("#txtReasonComments").val('');
        if(this.webOrMobile== 'mobile'){
            $("#txtReasonComments").css('max-width','335px');
            $("#txtReasonComments").css('width','335px');
            $("#txtReasonComments").css('margin-left','1px')
        }
    }

    closedvAutoLockContinuePopup() {
        $("#txtReasonComments").val('');
        this.dvAutoLockContinuePopup = 'none';
    }

    onAutoLockContinue() {
        let txtAccessEmail: any = "";
        let txtMobile: any = "";
        let txtDeliveryMode: any = "";
        let txtReasonComments: any = $("#txtReasonComments").val();

        txtAccessEmail = $('#hdnRecipientEmail').val();
        txtMobile = $('#hdnRecipientMobile').val();
        txtDeliveryMode = $('#hdnRecipientDeliveryMode').val();
        // if ($('#hdnType').val() == "Template") {
        //     $("#divAccessEmail").css('display', 'block');
        //     txtAccessEmail = $("#txtAccessEmail").val();
        //     if (txtAccessEmail == "" || txtAccessEmail == null) {
        //         isSendMail = false;
        //         this.commonService.showToaster("Please enter email address.", "Warning"); return;
        //     }
        //     else if (!this.validateEmail(txtAccessEmail)) {
        //         isSendMail = false;
        //         this.commonService.showToaster("Invalid email address.", "Warning"); return;
        //     }
        //     else
        //         isSendMail = true;
        // }
        // else if ($('#hdnType').val() == "Envelope") {
        //     isSendMail = true;
        //     txtAccessEmail = $('#hdnRecipientEmail').val();
        //     txtMobile = $('#hdnRecipientMobile').val();
        //     txtDeliveryMode = $('#hdnRecipientDeliveryMode').val();
        // }

        this.showLoader = true;
        var requestAccessInfo = {
            type: $('#hdnType').val(),
            eDisplayCode: $('#hdnEnvelopeTemplateCode').val(),
            referenceID: $('#hdnReferenceId').val(),
            senderEmail: $('#hdnSenderEmail').val(),
            senderRecID: $('#hdnSenderRecId').val(),
            recipientID: $('#hdnRecipientID').val(),
            recipientEmail: txtAccessEmail.trim(),
            feature: $('#hdnFeature').val(),
            reasonComments: txtReasonComments,
            recpMobile: txtMobile,
            recpDeliveryMode: txtDeliveryMode,
            recipientType: $('#hdnRecipientType').val(),
            ipAddress: $('#hdnIPAddress').val()
        };

        let sendRequestAccessUrl = CommonConstants.SendRequestAccess;
        this.signerLandingService.postcall(sendRequestAccessUrl, requestAccessInfo).subscribe(
            (resp: any) => {
                if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                    this.showLoader = false;
                    if (resp.Status) {
                        $("#btnRequestAccess").css('display', 'none');
                        $("#h5EnvelopeCode").css('display', 'none');
                        $("#divAccessdenied").css('display', 'none');
                        $("#txtReasonComments").val('');
                        $("#dvAutoLockContinuePopup").hide();
                        $("#divConfirmRequestAccessSent").show();
                        this.commonService.showToaster("Access email sent.", "Success");
                    }
                    else {
                        this.commonService.showToaster(resp.Message, "Warning");
                    }
                    localStorage.setItem("AutoLockRespMessage", "");
                }
                else if (resp && resp.StatusCode == 400) {
                    this.showLoader = false;
                    $("#btnRequestAccess").css('display', 'none');
                    $("#h5EnvelopeCode").css('display', 'none');
                    $("#divAccessdenied").css('display', 'none');
                    $("#txtReasonComments").val('');
                    $("#dvAutoLockContinuePopup").hide();
                    $("#divConfirmRequestAccessSent").hide();
                    this.isEmptyEnvelopeTemplateCode = true;
                    this.commonService.showToaster(resp.Message, "Warning");
                    localStorage.setItem("AutoLockRespMessage", "");
                }
                else {
                    this.showLoader = false;
                    localStorage.setItem("AutoLockRespMessage", "");
                    this.commonService.showToaster("Send access email failed.", "Warning");
                }
            });
    }
}
